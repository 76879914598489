import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Grid, MenuItem, Stack, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import ReactECharts from 'echarts-for-react';
import DashedBarChart from "../../components/common/DashedBarChart";
import { ThemeContext } from "../../App";

import { useSnackbar } from "../../components/context/SnackbarContext";
import useAxios from "../../hooks/useAxios";
import { useLoader } from "../../components/context/LoaderContext";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { capitalize, displayDate } from "../../utils/Utils";
import TopMenu from "./components/TopMenu";


function ReportOverview(props) {
    const navigate = useNavigate();
    const axiosInstance = useAxios();
    const { showSnackbar } = useSnackbar();
    const { showLoader, hideLoader } = useLoader();
    const { preferredDateFormat, preTimeZoneKey } = useSelector((state) => state?.user);
    let { currentTheme } = useContext(ThemeContext);

    const [searchUser, setSearchUser] = useState('All Users');
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    const [resetFilter, setResetFilter] = useState(true);
    const [selectMonth, setSelectMonth] = useState();

    const [funnelChartData, setFunnelChartData] = useState('');
    const [stackChartData, setStackChartData] = useState('');
    const [pieChartData, setPieChartData] = useState('');
    const [sourchChartData, setSourchChartData] = useState('');
    const [userList, setUserList] = useState([])

    useEffect(() => {
        getData()
    }, [resetFilter, selectMonth]);

    const params = {
        user: searchUser === "All Users" ? "" : searchUser,
        from_date: fromDate ? moment(fromDate).format("YYYY-MM-DD") : '',
        to_date: toDate ? moment(toDate).format("YYYY-MM-DD") : '',
        month_range: selectMonth || '',

    }

    const getData = async () => {
        showLoader()
        await axiosInstance.get(`api/dashboard-chart`, { params }).then(response => {
            if (response.status === 200) {
                setFunnelChartData(response.data.data.funnelChartData)
                setStackChartData(response.data.data.stackedChartData)
                setPieChartData(response.data.data.pieChartData)
                setSourchChartData(response.data.data.leadSourceChartData)
                setUserList(response.data.data.users)
                hideLoader()
            }
        }).then(json => {
        }).catch(error => {
            if (error.response) {
                hideLoader();
                if (error.response.status === 400) {
                    showSnackbar(error.response.data.message, "warning");
                    navigate("/dashboard");
                }
            }
        });
    }

    const searchFilter = (e) => {
        setResetFilter(resetFilter ? false : true)
    }

    const resetFilterButton = () => {
        setResetFilter(resetFilter ? false : true)
        setSearchUser('All Users')
        setFromDate()
        setToDate()
        setSelectMonth()
    }

    const leadSourceNames = sourchChartData && sourchChartData.length > 0 && sourchChartData.map(item => item[0]);
    const leadSourceData = sourchChartData && sourchChartData.length > 0 && sourchChartData.map(item => item[1]);

    const monthlyLeads = Object.values(stackChartData?.leadCount || {});
    const monthlyDeals = Object.values(stackChartData?.dealCount || {});

    const leadVsDealOverview = [
        {
            data: funnelChartData?.dealCount || 0,
            label: 'Deals',
            borderColor: currentTheme?.chartColor.colorOne500,
            dashColor: currentTheme?.chartColor.colorOne200
        }, {
            data: funnelChartData?.leadCount || 0,
            label: 'Leads',
            borderColor: currentTheme?.chartColor.colorTwo500,
            dashColor: currentTheme?.chartColor.colorTwo200
        }, {
            data: funnelChartData?.wondeal || 0,
            label: 'Won Deals',
            borderColor: currentTheme?.chartColor.colorThree500,
            dashColor: currentTheme?.chartColor.colorThree200
        }
    ];

    return (
        <>
            <Helmet>
                <title>Reports Overview | {capitalize(props?.name || 'SolvCRM')}</title>
            </Helmet>

            <TopMenu page={'overview'} />

            <Grid container className='page-action-bar'>
                <Grid item xxl={5} xs={6}>
                    <Grid container spacing={1} alignItems={'center'}>
                        <Grid item xs>
                            <TextField
                                select
                                value={searchUser}
                                variant="filled"
                                className='size-small no-label no-margin'
                                onChange={(e) => setSearchUser(e.target.value)}
                            >
                                <MenuItem key={222} value={'All Users'}>All Users</MenuItem>
                                {
                                    userList && userList.length > 0 && userList.map((user, index) => (
                                        <MenuItem key={index} value={user.id}>{user.name}</MenuItem>
                                    ))
                                }
                            </TextField>
                        </Grid>
                        <Grid item xs>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    format={displayDate(preferredDateFormat, 'half')}
                                    views={['year', 'month', 'day']}
                                    slotProps={{
                                        textField: {
                                            variant: 'filled',
                                            name: "start_date",
                                            type: 'text',
                                            placeholder: 'From Date',
                                            className: 'no-label no-margin size-small'
                                        }
                                    }}
                                    name="from_date"
                                    value={fromDate}
                                    onChange={(value) => setFromDate(value)}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    format={displayDate(preferredDateFormat, 'half')}
                                    views={['year', 'month', 'day']}
                                    minDate={fromDate}
                                    slotProps={{
                                        textField: {
                                            variant: 'filled',
                                            name: "start_date",
                                            placeholder: 'To Date',
                                            type: 'text',
                                            className: 'no-label no-margin size-small'
                                        }
                                    }}
                                    name="to_date"
                                    value={toDate}
                                    onChange={(value) => setToDate(value)}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs='auto' sx={{ marginLeft: 'auto' }}>
                    <Stack direction={'row'} spacing={1} alignItems={"center"}>
                        <Button size="small" onClick={() => searchFilter()}>Search</Button>
                        <Button size="small" color="secondary" onClick={() => resetFilterButton()}>Reset</Button>
                    </Stack>
                </Grid>
            </Grid>


            <Grid container spacing={2}>
                <Grid item xxl={8} xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <div className="card p-16">
                                <Stack direction={'row'} justifyContent={"space-between"} alignItems={"center"} className="pb-16">
                                    <Typography variant="title-medium-bold" component={'h6'}>Monthly Lead & Deal Comparison</Typography>

                                    <ul className="chart-legend">
                                        <li style={{ color: currentTheme?.chartColor.colorOne400 }}><span>Deals</span></li>
                                        <li style={{ color: currentTheme?.chartColor.colorTwo400 }}><span>Leads</span></li>
                                    </ul>
                                </Stack>

                                <ReactECharts
                                    option={
                                        {
                                            grid: {
                                                left: 32,
                                                top: 8,
                                                right: 0,
                                                bottom: 32
                                            },
                                            tooltip: {
                                                trigger: 'axis',
                                                backgroundColor: currentTheme?.colors.system.cardBackground,
                                                borderColor: currentTheme?.colors.system.border,
                                                textStyle: {
                                                    fontFamily: currentTheme?.fontFamily,
                                                    fontSize: 14,
                                                    color: currentTheme?.colors.system.textPrimary
                                                }
                                            },
                                            xAxis: [
                                                {
                                                    type: 'category',
                                                    data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                                                    axisPointer: {
                                                        type: 'none'
                                                    },
                                                    axisLabel: {
                                                        color: currentTheme?.colors.system.textSecondary,
                                                        fontSize: 14,
                                                        lineHeight: 20,
                                                        fontFamily: currentTheme?.fontFamily
                                                    },
                                                    axisLine: {
                                                        lineStyle: {
                                                            color: currentTheme?.colors.grey['100'],
                                                            width: 2
                                                        }
                                                    },
                                                    axisTick: {
                                                        show: false
                                                    }
                                                }
                                            ],
                                            yAxis: [
                                                {
                                                    type: 'value',
                                                    name: 'Deals',
                                                    //min: 0,
                                                    //max: 40,
                                                    //interval: 10,
                                                    axisLabel: {
                                                        color: currentTheme?.colors.system.textSecondary,
                                                        fontSize: 14,
                                                        lineHeight: 20,
                                                        fontFamily: currentTheme?.fontFamily,
                                                        align: 'left',
                                                        margin: 32
                                                    },
                                                    splitLine: {
                                                        show: false
                                                    }
                                                },
                                                {
                                                    type: 'value',
                                                    name: 'Leads',
                                                    //min: 0,
                                                    // max: 40,
                                                    //interval: 10,
                                                    axisLabel: {
                                                        color: currentTheme?.colors.system.textSecondary,
                                                        fontSize: 14,
                                                        lineHeight: 20,
                                                        fontFamily: currentTheme?.fontFamily
                                                    },
                                                    splitLine: {
                                                        lineStyle: {
                                                            color: currentTheme?.colors.grey['100']
                                                        }
                                                    }
                                                }
                                            ],
                                            series: [
                                                {
                                                    name: 'Deals',
                                                    type: 'bar',
                                                    data: monthlyDeals,
                                                    itemStyle: { color: currentTheme?.chartColor.colorOne400, borderRadius: [50, 50, 0, 0] },
                                                    barWidth: '16'
                                                },
                                                {
                                                    name: 'Leads',
                                                    type: 'bar',
                                                    data: monthlyLeads,
                                                    itemStyle: { color: currentTheme?.chartColor.colorTwo400, borderRadius: [50, 50, 0, 0] },
                                                    barWidth: '16'
                                                }
                                            ],
                                            barGap: '25%',
                                        }
                                    }
                                    notMerge={true}
                                    lazyUpdate={true}
                                    opts={{
                                        renderer: 'svg'
                                    }}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="card p-16 height-100p">
                                <Typography variant="title-medium-bold" component={'h6'} className="pb-16">Leads vs. Deals Overview</Typography>

                                <DashedBarChart className="mb-12" items={leadVsDealOverview} />

                                <Grid container spacing={1} className="text-center">
                                    {leadVsDealOverview?.map((item, index) => <Grid key={index + ''} item xs={4}>
                                        <Typography variant="body-medium" component={'p'} className="text-secondary">{item.label}</Typography>
                                        <Typography variant="headline-medium-semibold" component={'h6'}>{item.data}</Typography>
                                    </Grid>
                                    )}
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="card p-16 height-100p overflow-hidden pb-56">
                                <Typography variant="title-medium-bold" component={'h6'} className="pb-16">Deals Won vs. Lost</Typography>

                                <div className="semicircle-chart-wrapper">
                                    <ReactECharts
                                        option={
                                            {
                                                tooltip: {
                                                    trigger: 'item',
                                                    backgroundColor: currentTheme?.colors.system.cardBackground,
                                                    textStyle: {
                                                        fontFamily: currentTheme?.fontFamily,
                                                        fontSize: 14,
                                                        color: currentTheme?.colors.system.textPrimary
                                                    }
                                                },
                                                series: [
                                                    {
                                                        name: '',
                                                        type: 'pie',
                                                        radius: ['82%', '100%'],
                                                        center: ['50%', '56%'],
                                                        startAngle: 180,
                                                        endAngle: 360,
                                                        data: [
                                                            { value: pieChartData?.wondealCount || 0, name: 'Deals Won', itemStyle: { color: currentTheme?.chartColor.colorThree400 } },
                                                            { value: pieChartData?.lostdealCount || 0, name: 'Deals Lost', itemStyle: { color: currentTheme?.chartColor.colorFour400 } }
                                                        ],
                                                        itemStyle: {
                                                            borderRadius: 6,
                                                            borderColor: currentTheme?.colors.system.cardBackground,
                                                            borderWidth: 3
                                                        },
                                                        label: {
                                                            show: false
                                                        },
                                                    }
                                                ],
                                                height: '218px'
                                            }
                                        }
                                        notMerge={true}
                                        lazyUpdate={true}
                                        opts={{
                                            renderer: 'svg'
                                        }}
                                    />
                                </div>

                                <Grid container spacing={1} className="text-center">
                                    <Grid item xs={6}>
                                        <Typography variant="body-medium" component={'p'} className="text-secondary">Deals Won</Typography>
                                        <Typography variant="headline-medium-semibold" component={'h6'}>{pieChartData?.wondealCount || 0}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body-medium" component={'p'} className="text-secondary">Deals Lost</Typography>
                                        <Typography variant="headline-medium-semibold" component={'h6'}>{pieChartData?.lostdealCount || 0}</Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xxl={4} xs={12}>
                    <div className="card p-16 height-100p flex">
                        <Typography variant="title-medium-bold" component={'h6'} className="pb-16">Leads by Source</Typography>

                        <ReactECharts
                            className="item-grow"
                            style={{ minHeight: (leadSourceNames?.length * 16) + (leadSourceNames?.length * 12) + 24 + 'px' }}
                            option={
                                {
                                    grid: {
                                        left: 112,
                                        top: 8,
                                        right: 8,
                                        bottom: 32
                                    },
                                    tooltip: {
                                        trigger: 'axis',
                                        backgroundColor: currentTheme?.colors.system.cardBackground,
                                        borderColor: currentTheme?.colors.system.border,
                                        textStyle: {
                                            fontFamily: currentTheme?.fontFamily,
                                            fontSize: 14,
                                            color: currentTheme?.colors.system.textPrimary
                                        }
                                    },
                                    xAxis: [
                                        {
                                            type: 'value',
                                            name: 'Leads',
                                            //min: 0,
                                            //max: 40,
                                            //interval: 10,
                                            axisLabel: {
                                                color: currentTheme?.colors.system.textSecondary,
                                                fontSize: 14,
                                                lineHeight: 20,
                                                fontFamily: currentTheme?.fontFamily
                                            }
                                        }
                                    ],
                                    yAxis: [
                                        {
                                            type: 'category',
                                            data: leadSourceNames,
                                            axisPointer: {
                                                type: 'none'
                                            },
                                            axisLabel: {
                                                color: currentTheme?.colors.system.textSecondary,
                                                fontSize: 12,
                                                lineHeight: 18,
                                                fontFamily: currentTheme?.fontFamily,
                                                align: 'left',
                                                margin: 112
                                            },
                                            axisLine: {
                                                lineStyle: {
                                                    color: currentTheme?.colors.grey['100'],
                                                    width: 2
                                                }
                                            },
                                            axisTick: {
                                                lineStyle: {
                                                    color: currentTheme?.colors.grey['100']
                                                }
                                            }
                                        }
                                    ],
                                    series: [
                                        {
                                            name: 'Leads',
                                            type: 'bar',
                                            data: leadSourceData,
                                            itemStyle: {
                                                color: currentTheme?.chartColor.colorFive400,
                                                borderRadius: [0, 50, 50, 0]
                                            },
                                            barWidth: '16'
                                        }
                                    ]
                                }
                            }
                            notMerge={true}
                            lazyUpdate={true}
                            opts={{
                                renderer: 'svg'
                            }}
                        />
                    </div>
                </Grid>
            </Grid>
        </>
    );
}

export default ReportOverview;