import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { Button, Dialog, Grid, MenuItem, TextField, Tooltip } from "@mui/material";
import React, { useState } from "react";


function ChnageOwnerDialog({ onClose, open, users, handleChangeOwner, type }) {
    const [selectedUser, setSelectedUser] = useState();

    const handleUserChange = (event) => {
        const newValue = event.target.value;
        setSelectedUser(newValue);
    };

    const handleClose = () => {
        setSelectedUser();
        onClose()
    }

    const handleSaveChanges = () => {
        onClose()
        handleChangeOwner(selectedUser);
        setSelectedUser()
    }
    return (
        <>
            <Dialog onClose={handleClose} open={open} maxWidth={'md'}>
                <div className="dialog-title">
                    <h2>Change {type} Owner</h2>
                    <Tooltip title="Close">
                        <button className="icon-button dialog-close" onClick={handleClose}>
                            <Icon path={mdiClose} color={'currentColor'} size={1} />
                        </button>
                    </Tooltip>
                </div>

                <TextField
                    select
                    label="User"
                    variant="filled"
                    value={selectedUser}
                    onChange={handleUserChange}
                >
                    {
                        users && users.length > 0 && users.map((user, index) => (
                            <MenuItem key={index} value={user.id}>{user.name}</MenuItem>
                        ))
                    }

                </TextField>

                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Button className="btn-block" onClick={handleSaveChanges}>Save</Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button color="secondary" className="btn-block" onClick={handleClose}>Cancel</Button>
                    </Grid>
                </Grid>
            </Dialog>
        </>
    );
}

export default ChnageOwnerDialog;