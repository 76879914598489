import { mdiCheck, mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";

export const EasyEditSaveButton = props => {
    return (
        <Icon path={mdiCheck} color={'currentColor'} size={1} />
    )
}

export const EasyEditCancelButton = props => {
    return (
        <Icon path={mdiClose} color={'currentColor'} size={1} />
    )
}