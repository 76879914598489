//import axiosInstance from '../config/axiosConfig';
import Cookies from 'universal-cookie';


const cookies = new Cookies();

const authService = {
    isAuthenticated: () => cookies.get('auth_token'), //!!localStorage.getItem('token'),

    // login: async (email, password) => {
    //     const response = await axiosInstance.post('/api/login', { email, password });
    //     localStorage.setItem('token', response.data.token);
    // },

    // register: async (email, password) => {
    //     await axiosInstance.post('/api/register', { email, password });
    // },

    // logout: () => {
    //     localStorage.removeItem('token');
    //     window.location.href = '/login';
    // }
};

export default authService;