import { TextField } from "@mui/material";
import React, { useState } from "react";

const EasyEditCustomTextbox = props => {
    const [selectvalue, setSelectValue] = useState(props?.value)
    const handleSelect = (event) => {
        props.setParentValue(event.target.value);
        setSelectValue(event.target.value);
    };

    React.useEffect(() => {
        if (props.value === 'N/A') {
            setSelectValue('');
        }
    }, [props.value])

    return (
        <TextField
            variant="filled"
            label={props.label}
            value={selectvalue}
            className="no-margin size-medium control"
            autoFocus
            InputProps={{
                endAdornment: props.endAdornment ? props.endAdornment : null
            }}
            inputProps={{
                onFocus: props.focusCallback,
                maxLength: props?.maxLength || ''
            }}
            onChange={handleSelect}
            error={props?.errorMessages && props?.errorMessages !== undefined}
            helperText={props?.errorMessages && props?.errorMessages !== undefined}
        />
    );
}

export default EasyEditCustomTextbox;