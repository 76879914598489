import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

// Create an instance of axios
const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 40000, // Request timeout (optional)
    headers: {
        'Content-Type': 'multipart/form-data, application/json, application/pdf',
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
    }
});

axiosInstance.interceptors.request.use(
    config => {
        const token = cookies.get('auth_token');
        const tenant = cookies.get('auth_tenant');
        if (token && tenant) {
            config.headers['Authorization'] = `Bearer ${token}`;
            config.headers['tenant'] = tenant ? `${tenant}` : '';
        }

        if (config.data instanceof FormData) {
            config.headers['Content-Type'] = 'multipart/form-data';
        } else if (config.data && config.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
            config.data = transformDataToUrlEncoded(config.data);
        } else {
            config.headers['Content-Type'] = 'application/json,application/pdf';
        }
        return config;
    },
    error => {
        // Handle request error
        return Promise.reject(error);
    }
);

function transformDataToUrlEncoded(data) {
    const params = new URLSearchParams();
    for (const key in data) {
        params.append(key, data[key]);
    }
    return params;
}

// Do not add the response interceptor here

export default axiosInstance;