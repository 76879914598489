import React, { Suspense, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Box, Button, Grid, Menu, MenuItem, Popover, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import Icon from "@mdi/react";
import { mdiCalendarWeekBeginOutline, mdiContentCopy, mdiDotsVertical, mdiEmailArrowRightOutline, mdiFileDocumentOutline, mdiPencilOutline, mdiTrashCanOutline } from "@mdi/js";
import EasyEditCustomTextbox from "../../components/common/EasyEditCustomTextbox";
import FormGroupStatic from "../../components/common/FormGroupStatic";
import { EasyEditCancelButton, EasyEditSaveButton } from "../../components/common/EasyEditButtons";
import EasyEdit, { Types } from "react-easy-edit";
import EasyEditCustomSelect from "../../components/common/EasyEditCustomSelect";
import EasyEditCustomDatePicker from "../../components/common/EasyEditCustomDatePicker";
import SendQuoteViaEmailDialog from "./components/SendQuoteViaEmailDialog";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from '../../components/context/SnackbarContext';
import useAxios from '../../hooks/useAxios';
import { useLoader } from "../../components/context/LoaderContext";
import { useSelector } from "react-redux";
import { capitalize, displayCurrencySymbol, displayDate, validateField } from "../../utils/Utils";
import moment from "moment-timezone";
import FormGroupStaticAmount from "../../components/common/FormGroupStaticAmount";
import CreateEditQuoteItem from "./components/CreateEditQuoteItem";
import Swal from "sweetalert2";
import QuoteEmailHistory from "./components/QuoteEmailHistory";
import EasyEditCustomAutoSearchSelect from "../../components/common/EasyEditCustomAutoSearchSelect";


function QuoteDetails(props) {
    const navigate = useNavigate();
    const { id } = useParams();
    const quoteId = id

    const axiosInstance = useAxios();
    const { showSnackbar } = useSnackbar();
    const { showLoader, hideLoader } = useLoader();
    const { preferredDateFormat, preTimeZoneKey, currencyCode, currencySymbol, currencyStringValue } = useSelector((state) => state?.user);
    const [reloadPage, setReloadPage] = useState(false);
    const userPermissions = useSelector((state) => state.user.permission);
    const [quoteDetail, setQuoteDetail] = useState();
    const [quoteStages, setQuoteStages] = useState();

    const [selectedQuoteStageId, setSelectedQuoteStageId] = useState();
    const [savedQuoteStageId, setSavedQuoteStageId] = useState();

    const [users, setUsers] = useState([]);
    const [deals, setDeals] = useState([]);
    const [selectedUser, setSelectedUser] = useState();
    const [selectedData, setSelectedData] = useState();
    const [countries, setCountries] = useState([]);
    const [products, setProducts] = useState([]);

    const [selectedDealName, setSelectedDealName] = useState();

    const [selectedBCName, setSelectedBCName] = useState();
    const [selectedSCName, setSelectedSCName] = useState();

    const [accounts, setAccounts] = useState();
    const [selectedAccountName, setSelectedAccountName] = useState();

    const [contacts, setContacts] = useState();
    const [selectedContactName, setSelectedContactName] = useState();


    const [errorMessages, setErrorMessages] = useState({});
    const [backendErrorMessages, setBackendErrorMessages] = useState({});

    const [emailData, setEmailData] = useState([]);

    useEffect(() => {
        async function fetchData() {
            await getQuoteData();
        }
        fetchData();
    }, [reloadPage]);

    const getQuoteData = async () => {
        showLoader();
        await axiosInstance.get('api/quote/edit/' + quoteId).then((response) => {
            if (response.status === 200) {
                setQuoteDetail(response?.data?.data?.record || [])
                setQuoteStages(response?.data?.data?.quoteStageLists || [])
                setSelectedQuoteStageId(response?.data?.data?.record?.quote_stage || '')
                setSavedQuoteStageId(response?.data?.data?.record?.quote_stage || '')
                setUsers(response?.data?.data?.users || [])
                setSelectedData(response?.data?.data || '')
                setDeals(response?.data?.data?.deals || [])
                setCountries(response?.data?.data?.countries || [])
                setAccounts(response?.data?.data?.accounts || [])
                setContacts(response?.data?.data?.contacts || [])
                setProducts(response?.data?.data?.product || [])

                const selectAccName = response?.data?.data?.accounts && response.data.data.accounts.find(account => account.id === response?.data?.data?.record?.account_id);
                if (selectAccName) {
                    setSelectedAccountName(selectAccName?.organization_name)
                }

                const selectConName = response?.data?.data?.contacts && response.data.data.contacts.find(contact => contact.id === response?.data?.data?.record?.contact_id);
                if (selectConName) {
                    const displayFullName = [
                        selectConName.salutation ? `${selectConName.salutation}.` : '',
                        selectConName.first_name || '',
                        selectConName.last_name || ''
                    ].filter(Boolean).join(' ');

                    setSelectedContactName(displayFullName)
                } else {
                    setSelectedContactName()
                }
                setEmailData({ fromEmail: response?.data?.data?.fromEmail || '', subject: response?.data?.data?.record?.subject || '' })
                hideLoader();
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                        navigate("/quotes");
                    }
                }
            });
    }


    //======Start::Write code for handle popup for Send email and generate pdf, etc....=======//
    const [quoteContextMenuAnchorEl, setQuoteContextMenuAnchorEl] = React.useState(null);
    const quoteContextMenuOpen = Boolean(quoteContextMenuAnchorEl);
    const handleQuoteContextMenuClick = (event) => {
        setQuoteContextMenuAnchorEl(event.currentTarget);
    };
    const handleQuoteContextMenuClose = () => {
        setQuoteContextMenuAnchorEl(null);
    };


    const [sendQuoteViaEmailDialogOpen, setSendQuoteViaEmailDialogOpen] = React.useState(false);
    const openSendQuoteViaEmailDialog = () => {
        setSendQuoteViaEmailDialogOpen(true);
    }
    const closeSendQuoteViaEmailDialog = () => {
        setSendQuoteViaEmailDialogOpen(false);
    }
    //======End::Write code for handle popup for Send email and generate pdf, etc....=======//

    const [editQuotedItems, setEditQuotedItems] = React.useState(false);

    //=====Start:: Write a code for handle quote stage =====//
    const [editCurrentStatusAnchorEl, setEditCurrentStatusAnchorEl] = React.useState(null);
    const handleEditCurrentStatusClick = (event) => {
        setEditCurrentStatusAnchorEl(event.currentTarget);
    };
    const handleEditCurrentStatusClose = () => {
        setEditCurrentStatusAnchorEl(null);
    };
    const editCurrentStatusOpen = Boolean(editCurrentStatusAnchorEl);

    const handleQuoteStageSubmit = async () => {
        const data = {
            id: quoteId,
            subject: quoteDetail?.subject || '',
            account_id: quoteDetail?.account_id || '',
            valid_until: quoteDetail?.valid_until
                ? moment(quoteDetail.valid_until).format("YYYY-MM-DD HH:mm:ss")
                : "",
            quote_stage: selectedQuoteStageId !== -1 ? selectedQuoteStageId : null,
            editType: "dataOnly",
        }

        setSavedQuoteStageId(selectedQuoteStageId);

        await axiosInstance.post("/api/quote/update", data).then(response => {
            showLoader()
            if (response.status === 200) {
                setReloadPage(reloadPage ? false : true)
                showSnackbar(response.data.message, "success");
                setEditCurrentStatusAnchorEl(null);
            }
        }).then(json => {

        }).catch(error => {
            if (error.response) {
                hideLoader();
                if (error.response.status === 400) {
                    showSnackbar(error.response.data.message, "warning");
                    navigate("/quotes");
                }
            }
        });
    }
    //=====End:: Write a code for handle quote stage =====//

    //get selected Owner Name
    useEffect(() => {
        const selectedOwnerName = users && users.find(user => user.id === quoteDetail.owner_id);

        if (selectedOwnerName) {
            setSelectedUser(selectedOwnerName?.name)
        }
    }, [quoteDetail, reloadPage]);


    //get selected Deal Name
    useEffect(() => {
        const selectedDealName = deals && deals.find(deal => deal.id === quoteDetail.deal_id);

        if (selectedDealName) {
            setSelectedDealName(selectedDealName?.deal_name)
        }

        const selectBillingCoName = countries && countries.find(country => country.id === quoteDetail.quote_billing.country_id);
        if (selectBillingCoName) {
            setSelectedBCName(selectBillingCoName?.country_name)
        }

        const selectShippingCoName = countries && countries.find(country => country.id === quoteDetail.quote_shipping.country_id);
        if (selectShippingCoName) {
            setSelectedSCName(selectShippingCoName?.country_name)
        }

        const selectAccName = accounts && accounts.find(account => account.id === quoteDetail?.account_id);
        if (selectAccName) {
            setSelectedAccountName(selectAccName?.organization_name)
        } else {
            setSelectedAccountName()
        }

        const selectConName = contacts && contacts.find(contact => contact.id === quoteDetail.contact_id);
        if (selectConName) {
            const displayFullName = [
                selectConName.salutation ? `${selectConName.salutation}.` : '',
                selectConName.first_name || '',
                selectConName.last_name || ''
            ].filter(Boolean).join(' ');

            setSelectedContactName(displayFullName)
        } else {
            setSelectedContactName()
        }
    }, [quoteDetail, reloadPage]);

    //======Start::Write a code for default data for editInline==========//
    const [allowEditInline, setAllowEditInline] = useState(true);

    const onCancelEdit = (fieldName) => {
        setErrorMessages({ [fieldName]: '' });
        setAllowEditInline(true);
    }

    const handleClick = (e) => {
        setAllowEditInline(false)
    }

    const handleValidation = (value, fieldName, displayName, type, isrequired, min, max) => {
        const errorMsg = validateField(value, fieldName, displayName, type, isrequired, min, max);
        setErrorMessages(prevState => ({ ...prevState, [fieldName]: errorMsg }));
        return errorMsg === '';
    };

    const handleSaveData = async (value, name, type = null) => {
        const data = {
            id: quoteId,
            subject: quoteDetail?.subject,
            account_id: quoteDetail?.account_id,
            valid_until: quoteDetail?.valid_until ? moment(quoteDetail?.valid_until).format("YYYY-MM-DD HH:mm:ss") : "",
            editType: "dataOnly",
            [name]: value,
        };

        //get value from passing key on obj
        function getValueByKey(recordData, key) {
            return recordData[key];
        }

        // Check if new value and existing value are change than call api
        const newValue = getValueByKey(quoteDetail, name);

        if (newValue !== value && value !== 'N/A') {
            showLoader()
            await axiosInstance.post("api/quote/update", data).then((response) => {
                if (response.status === 200) {
                    setReloadPage(reloadPage ? false : true)
                    showSnackbar(response.data.message, "success");
                    setAllowEditInline(true)
                }
            })
                .then((json) => { })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 422 || error.response.data.validation_errors) {
                            setAllowEditInline(true)
                            const errors = error.response.data.validation_errors;
                            Object.keys(errors).forEach(key => {
                                setBackendErrorMessages({ [key]: errors[key] });
                            });
                        } else {
                            showSnackbar(error.response.data.message, 'error')
                        }
                    }
                });
        } else {
            setReloadPage(reloadPage ? false : true)
            setAllowEditInline(allowEditInline ? false : true)
        }
    }
    //======End::Write a code for default data for editInline==========//

    const handleEditQuotedItems = (type) => {
        setEditQuotedItems(type)
    }

    const handleParentReload = () => {
        setReloadPage(reloadPage ? false : true)
    }

    //=======Start::Write a code for delete lead=======//
    async function deleteData(id) {
        const data = {
            id: id,
        }
        try {
            const willDelete = await Swal.fire({
                title: "Are you sure you want to delete?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!"
            });

            if (willDelete.isConfirmed) {
                axiosInstance.delete(`api/quote/destroy`, { data })
                    .then((response) => {
                        if (response.status === 200) {
                            showSnackbar(response.data.message, "success");
                            hideLoader();
                            setReloadPage(reloadPage ? false : true);
                            navigate("/quotes");
                        }
                    })
                    .then((json) => { })
                    .catch((error) => {
                        if (error.response) {
                            hideLoader();
                            if (error.response.status === 400) {
                                showSnackbar(error.response.data.message, "warning");
                            }
                        }
                    });
            } else {
                hideLoader();
            }
        } catch (error) {
            showSnackbar('Oops Something want wrong..!', "error");
        }
    }
    //=======Start::Write a code for delete lead=======//

    //=====Start::Write a code for generate Quote PDF=====//
    const generatePdfForQuote = async () => {
        showLoader()
        await axiosInstance.get("api/quote-generate/" + quoteId).then((response) => {
            if (response.data.code === 200) {
                if (response.data.data.quoteName && response.data.data.fullPath) {
                    setTimeout(() => {
                        const linkSource =
                            "data:application/pdf;base64, " + response.data.data.fullPath;
                        const downloadLink = document.createElement("a");
                        //const fileName = "convertedPDFFile.pdf";
                        downloadLink.href = linkSource;
                        downloadLink.download = response.data.data.quoteName;
                        downloadLink.click();
                    }, 1000);
                } else {
                    showSnackbar("Sorry...! filename cannot found.", "error");
                }
                hideLoader()
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                        navigate("/quotes");
                    }
                }
            });
    };
    //=====End::Write a code for generate Quote PDF=====//

    return (
        <>
            <Helmet>
                <title>Quote Details | {capitalize(props?.name || 'SolvCRM')}</title>
            </Helmet>

            {
                quoteDetail &&

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div className="card p-16">
                            <Grid container alignItems={"center"} flexWrap={"nowrap"}>
                                <Grid item xs={'auto'} className="flex-shrink-1">
                                    <Typography variant='title-medium-bold' component={'h6'}>{capitalize(quoteDetail?.subject || 'N/A')}</Typography>
                                    <ul className='inline-details'>
                                        <li><Tooltip title="Quote Number"><span>{quoteDetail?.quote_number || 'N/A'}</span></Tooltip></li>
                                        <li><span className="title">Valid until: </span>{quoteDetail?.valid_until
                                            ? displayDate(moment.utc(quoteDetail?.valid_until).tz(preTimeZoneKey).format(preferredDateFormat), 'half')
                                            : "N/A"}</li>
                                        <li>
                                            <Tooltip title="Created Date">
                                                <span>
                                                    <Icon path={mdiCalendarWeekBeginOutline} color="currentColor" size={1} />{quoteDetail?.created_at
                                                        ? moment.utc(quoteDetail?.created_at)
                                                            .tz(preTimeZoneKey)
                                                            .format(preferredDateFormat)
                                                        : "N/A"}
                                                </span>
                                            </Tooltip>
                                        </li>

                                        <li>
                                            <div className="edit-lead-deal-status">
                                                <span className="status-badge warning">{savedQuoteStageId ? quoteStages[savedQuoteStageId] : ''}</span>
                                                <Tooltip title="Change Current Quote Stage">
                                                    <button type="button" onClick={handleEditCurrentStatusClick}>
                                                        <Icon path={mdiPencilOutline} color="currentColor" size={1} />
                                                    </button>
                                                </Tooltip>

                                                <Popover
                                                    open={editCurrentStatusOpen}
                                                    anchorEl={editCurrentStatusAnchorEl}
                                                    onClose={handleEditCurrentStatusClose}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'center',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'center'
                                                    }}
                                                    classes={{
                                                        paper: 'utility-menu'
                                                    }}
                                                >
                                                    <Typography variant="body-large-bold" component={'p'} className="mb-16">Change Quote Stage</Typography>
                                                    <TextField
                                                        select
                                                        label="Quote Stage"
                                                        value={selectedQuoteStageId}
                                                        name="quote_stage"
                                                        variant="filled"
                                                        onChange={(e) => setSelectedQuoteStageId(e.target.value)}
                                                    >
                                                        {
                                                            quoteStages && Object.entries(quoteStages).map(([key, value]) => (
                                                                <MenuItem key={key} value={value}>{key}</MenuItem>
                                                            ))
                                                        }
                                                    </TextField>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={6}>
                                                            <Button size="small" className="btn-block" onClick={() => handleQuoteStageSubmit()}>Save</Button>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Button size="small" color="secondary" className="btn-block" onClick={handleEditCurrentStatusClose}>Cancel</Button>
                                                        </Grid>
                                                    </Grid>
                                                </Popover>

                                            </div>
                                        </li>
                                    </ul>
                                </Grid>
                                <Grid item xs={'auto'} sx={{ marginLeft: 'auto' }}>
                                    <Stack direction={"row"} spacing={1} sx={{ marginRight: 0.5 }}>
                                        <Button size={"small"} onClick={() => openSendQuoteViaEmailDialog()}>
                                            <span>
                                                <Icon path={mdiEmailArrowRightOutline} color={'currentColor'} size={1} />
                                                <span>Send via Email</span>
                                            </span>
                                        </Button>
                                        <Tooltip title="More">
                                            <Button color={'secondary'} className="icon-only" size={'small'} onClick={handleQuoteContextMenuClick}>
                                                <Icon path={mdiDotsVertical} color={'currentColor'} size={1} />
                                            </Button>
                                        </Tooltip>
                                        <Menu
                                            anchorEl={quoteContextMenuAnchorEl}
                                            open={quoteContextMenuOpen}
                                            onClose={handleQuoteContextMenuClose}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right'
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right'
                                            }}
                                        >
                                            <MenuItem onClick={e => { handleQuoteContextMenuClose(); generatePdfForQuote() }}>
                                                <Icon path={mdiFileDocumentOutline} color={'currentColor'} size={1} />
                                                <span>Generate PDF</span>
                                            </MenuItem>
                                            {userPermissions && userPermissions.includes("quote_create") &&
                                                <MenuItem onClick={e => { handleQuoteContextMenuClose(); navigate('/quote-clone/' + quoteId); }}>
                                                    <Icon path={mdiContentCopy} color={'currentColor'} size={1} />
                                                    <span>Clone Quote</span>
                                                </MenuItem>
                                            }
                                            {userPermissions && userPermissions.includes("quote_delete") &&
                                                <MenuItem onClick={e => {
                                                    deleteData(quoteId); handleQuoteContextMenuClose();
                                                }}>
                                                    <Icon path={mdiTrashCanOutline} color={'currentColor'} size={1} />
                                                    <span>Delete</span>
                                                </MenuItem>
                                            }
                                        </Menu>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>

                    <Grid item xl={4} xs={12}>
                        <div className="card p-16 height-100p">
                            <Typography variant="title-medium-large-semibold" component={'h4'} className="mb-16">Quote Information</Typography>

                            <Grid container spacing={1.5}>
                                <Grid item xl={12} xs={6}>
                                    <EasyEdit
                                        type={Types.TEXT}
                                        onSave={(value) => { handleSaveData(value, 'subject') }}
                                        onCancel={() => { onCancelEdit('subject') }}
                                        value={quoteDetail?.subject || 'N/A'}
                                        allowEdit={allowEditInline}
                                        editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Subject" value={quoteDetail?.subject} errorMessages={errorMessages['subject']} maxLength={50} />}
                                        displayComponent={<FormGroupStatic easyEdit label="Subject" value={quoteDetail?.subject || 'N/A'} />}
                                        onValidate={(value) => handleValidation(value, 'subject', 'Subject')}
                                        validationMessage={errorMessages['subject']}
                                        saveButtonLabel={<EasyEditSaveButton />}
                                        cancelButtonLabel={<EasyEditCancelButton />}
                                        onFocus={() => handleClick('')}
                                    />
                                    {backendErrorMessages['subject'] && <span className="easy-edit-validation-error">{backendErrorMessages['subject']}</span>}
                                </Grid>

                                <Grid item xl={12} xs={6}>
                                    <EasyEdit
                                        type={Types.SELECT}
                                        onSave={(value) => { handleSaveData(value, 'owner_id') }}
                                        onCancel={() => { onCancelEdit() }}
                                        value={selectedUser || 'N/A'}
                                        allowEdit={allowEditInline}
                                        editComponent={
                                            <EasyEditCustomSelect focusCallback={e => { setAllowEditInline(false); }} label="Quote Owner" fieldValue={quoteDetail?.owner_id || 'N/A'}
                                                items={
                                                    users && users.length > 0 ?
                                                        users.map((user) => (
                                                            { value: user.id, option: user.name }
                                                        )) :
                                                        { value: '-1', option: '' }
                                                }
                                            />
                                        }
                                        displayComponent={<FormGroupStatic easyEdit label="Quote Owner" value={selectedUser || 'N/A'} />}
                                        saveButtonLabel={<EasyEditSaveButton />}
                                        cancelButtonLabel={<EasyEditCancelButton />}
                                        onFocus={() => handleClick('')}
                                    />
                                    {backendErrorMessages['owner_id'] && <span className="easy-edit-validation-error">{backendErrorMessages['owner_id']}</span>}
                                </Grid>

                                <Grid item xl={12} xs={6}>
                                    <EasyEdit
                                        type={Types.SELECT}
                                        onSave={(value) => { handleSaveData(value, 'deal_id') }}
                                        onCancel={() => { onCancelEdit() }}
                                        value={selectedDealName || 'N/A'}
                                        allowEdit={allowEditInline}
                                        editComponent={
                                            <EasyEditCustomAutoSearchSelect label="Deal Name" fieldValue={quoteDetail?.deal_id || ''}
                                                items={
                                                    deals && deals.length > 0 ?
                                                        deals.map((deal) => (
                                                            { value: deal.id, option: deal.deal_name }
                                                        ))
                                                        :
                                                        { value: -1, option: 'Select' }
                                                }
                                            />
                                        }
                                        displayComponent={<FormGroupStatic easyEdit label="Deal Name" value={selectedDealName || 'N/A'} />}
                                        saveButtonLabel={<EasyEditSaveButton />}
                                        cancelButtonLabel={<EasyEditCancelButton />}
                                        onFocus={() => handleClick('')}
                                    />
                                    {backendErrorMessages['deal_id'] && <span className="easy-edit-validation-error">{backendErrorMessages['deal_id']}</span>}
                                </Grid>

                                <Grid item xl={12} xs={6}>
                                    <EasyEdit
                                        type={Types.SELECT}
                                        value={selectedAccountName || 'N/A'}
                                        onSave={(value) => { handleSaveData(value, 'account_id') }}
                                        onCancel={() => { onCancelEdit() }}
                                        allowEdit={allowEditInline}
                                        editComponent={
                                            <EasyEditCustomSelect focusCallback={e => { setAllowEditInline(false); }} label="Account Name" fieldValue={quoteDetail?.account_id || ''}
                                                items={
                                                    accounts && accounts.length > 0 ?
                                                        accounts.map((account) => (
                                                            { value: account.id, option: account.organization_name }
                                                        ))
                                                        :
                                                        { value: -1, option: 'Select' }
                                                }
                                            />
                                        }
                                        displayComponent={<FormGroupStatic easyEdit label="Account Name" value={selectedAccountName || 'N/A'} />}
                                        saveButtonLabel={<EasyEditSaveButton />}
                                        cancelButtonLabel={<EasyEditCancelButton />}
                                        onFocus={() => handleClick('')}
                                    />
                                    {backendErrorMessages['account_id'] && <span className="easy-edit-validation-error">{backendErrorMessages['account_id']}</span>}
                                </Grid>

                                <Grid item xl={12} xs={6}>
                                    <EasyEdit
                                        type={Types.SELECT}
                                        onSave={(value) => { handleSaveData(value, 'contact_id') }}
                                        onCancel={() => { onCancelEdit() }}
                                        value={selectedContactName || 'N/A'}
                                        allowEdit={allowEditInline}
                                        editComponent={
                                            <EasyEditCustomSelect focusCallback={e => { setAllowEditInline(false); }} label="Contact Name" fieldValue={quoteDetail?.contact_id || ''}
                                                items={
                                                    contacts?.length > 0
                                                        ? contacts.map(contact => ({
                                                            value: contact.id,
                                                            option: [
                                                                contact.salutation ? `${contact.salutation}.` : '',
                                                                contact.first_name || '',
                                                                contact.last_name || ''
                                                            ].filter(Boolean).join(' ')
                                                        }))
                                                        : [{ value: -1, option: 'Select' }]
                                                }
                                            />
                                        }
                                        displayComponent={<FormGroupStatic easyEdit label="Contact Name" value={selectedContactName || 'N/A'} />}
                                        saveButtonLabel={<EasyEditSaveButton />}
                                        cancelButtonLabel={<EasyEditCancelButton />}
                                        onFocus={() => handleClick('')}
                                    />
                                    {backendErrorMessages['contact_id'] && <span className="easy-edit-validation-error">{backendErrorMessages['contact_id']}</span>}
                                </Grid>

                                <Grid item xl={12} xs={6}>
                                    <EasyEdit
                                        type={Types.TEXT}
                                        onSave={(value) => {
                                            handleSaveData(value ? moment(value).format("YYYY-MM-DD")
                                                : "",
                                                'valid_until');
                                        }}
                                        onCancel={() => { onCancelEdit('valid_until') }}
                                        value={quoteDetail?.valid_until ? moment(quoteDetail?.valid_until).format("YYYY-MM-DD") : null}
                                        allowEdit={allowEditInline}
                                        editComponent={
                                            <EasyEditCustomDatePicker
                                                setAllowEditInline={setAllowEditInline}
                                                label="Valid Until"
                                                value={quoteDetail?.valid_until || null}
                                                errorMessages={errorMessages['valid_until']}
                                                preferredDateFormat={preferredDateFormat}
                                            />}
                                        displayComponent={
                                            <FormGroupStaticAmount easyEdit
                                                label="Valid Until"
                                                value={quoteDetail?.valid_until || null}
                                                preTimeZoneKey={preTimeZoneKey}
                                                preferredDateFormat={preferredDateFormat}
                                            />}
                                        saveButtonLabel={<EasyEditSaveButton />}
                                        cancelButtonLabel={<EasyEditCancelButton />}
                                        onFocus={() => handleClick('')}
                                    />
                                    {backendErrorMessages['valid_until'] && <span className="easy-edit-validation-error">{backendErrorMessages['valid_until']}</span>}
                                </Grid>

                                <Grid item xl={12} xs={6}>
                                    <FormGroupStatic easyEdit label="Created by" value={quoteDetail?.created_at ? selectedData?.selectedData?.createdBy?.[0] + ' (' + moment.utc(quoteDetail.created_at).tz(preTimeZoneKey).format(preferredDateFormat) + ')' : ''} />
                                </Grid>

                                <Grid item xl={12} xs={6}>
                                    <FormGroupStatic easyEdit label="Modified by" value={
                                        selectedData?.selectedData?.updatedBy?.length ? (selectedData?.selectedData?.updatedBy?.[0] + ' (' + moment.utc(quoteDetail.updated_at).tz(preTimeZoneKey).format(preferredDateFormat) + ')') : 'N/A'} />
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>

                    <Grid item xl={8} xs={12}>
                        <div className="card p-16 height-100p">
                            <Typography variant="title-medium-large-semibold" component={'h4'} className="mb-16">Address Information</Typography>

                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Typography variant="title-small-bold" component={'h4'} className="mb-8">Billing Address</Typography>

                                    <Stack spacing={1.5}>
                                        <EasyEdit
                                            type={Types.TEXT}
                                            onSave={(value) => { handleSaveData(value, 'address') }}
                                            onCancel={() => { onCancelEdit('address') }}
                                            value={quoteDetail?.quote_billing?.address || 'N/A'}
                                            allowEdit={allowEditInline}
                                            editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Street" value={quoteDetail?.quote_billing?.address} errorMessages={errorMessages['address']} maxLength={100} />}
                                            displayComponent={<FormGroupStatic easyEdit label="Street" value={quoteDetail?.quote_billing?.address || 'N/A'} />}
                                            onValidate={(value) => handleValidation(value, 'address', 'Street', '', false, 2, 100)}
                                            validationMessage={errorMessages['address']}
                                            saveButtonLabel={<EasyEditSaveButton />}
                                            cancelButtonLabel={<EasyEditCancelButton />}
                                            onFocus={() => handleClick('')}
                                        />
                                        {backendErrorMessages['address'] && <span className="easy-edit-validation-error">{backendErrorMessages['address']}</span>}
                                        <EasyEdit
                                            type={Types.TEXT}
                                            onSave={(value) => { handleSaveData(value, 'city') }}
                                            onCancel={() => { onCancelEdit('city') }}
                                            value={quoteDetail?.quote_billing?.city || 'N/A'}
                                            allowEdit={allowEditInline}
                                            editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="City" value={quoteDetail?.quote_billing?.city} errorMessages={errorMessages['city']} maxLength={100} />}
                                            displayComponent={<FormGroupStatic easyEdit label="City" value={quoteDetail?.quote_billing?.city || 'N/A'} />}
                                            onValidate={(value) => handleValidation(value, 'city', 'City', '', false)}
                                            validationMessage={errorMessages['city']}
                                            saveButtonLabel={<EasyEditSaveButton />}
                                            cancelButtonLabel={<EasyEditCancelButton />}
                                            onFocus={() => handleClick('')}
                                        />
                                        {backendErrorMessages['city'] && <span className="easy-edit-validation-error">{backendErrorMessages['city']}</span>}
                                        <EasyEdit
                                            type={Types.TEXT}
                                            onSave={(value) => { handleSaveData(value, 'state') }}
                                            onCancel={() => { onCancelEdit('state') }}
                                            value={quoteDetail?.quote_billing?.state || 'N/A'}
                                            allowEdit={allowEditInline}
                                            editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="State" value={quoteDetail?.quote_billing?.state} errorMessages={errorMessages['state']} maxLength={100} />}
                                            displayComponent={<FormGroupStatic easyEdit label="State" value={quoteDetail?.quote_billing?.state || 'N/A'} />}
                                            onValidate={(value) => handleValidation(value, 'state', 'State', '', false,)}
                                            validationMessage={errorMessages['state']}
                                            saveButtonLabel={<EasyEditSaveButton />}
                                            cancelButtonLabel={<EasyEditCancelButton />}
                                            onFocus={() => handleClick('')}
                                        />
                                        {backendErrorMessages['state'] && <span className="easy-edit-validation-error">{backendErrorMessages['state']}</span>}
                                        <EasyEdit
                                            type={Types.TEXT}
                                            onSave={(value) => { handleSaveData(value, 'zipcode') }}
                                            onCancel={() => { onCancelEdit('zipcode') }}
                                            value={quoteDetail?.quote_billing?.zipcode || 'N/A'}
                                            allowEdit={allowEditInline}
                                            editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Postal Code" value={quoteDetail?.quote_billing?.zipcode} errorMessages={errorMessages['zipcode']} maxLength={100} />}
                                            displayComponent={<FormGroupStatic easyEdit label="Postal Code" value={quoteDetail?.quote_billing?.zipcode || 'N/A'} />}
                                            onValidate={(value) => handleValidation(value, 'zipcode', 'Postal Code', '', false, 2, 10)}
                                            validationMessage={errorMessages['zipcode']}
                                            saveButtonLabel={<EasyEditSaveButton />}
                                            cancelButtonLabel={<EasyEditCancelButton />}
                                            onFocus={() => handleClick('')}
                                        />
                                        {backendErrorMessages['zipcode'] && <span className="easy-edit-validation-error">{backendErrorMessages['zipcode']}</span>}
                                        <EasyEdit
                                            type={Types.SELECT}
                                            onSave={(value) => { handleSaveData(value, 'country_id') }}
                                            onCancel={() => { onCancelEdit() }}
                                            value={selectedBCName || 'N/A'}
                                            allowEdit={allowEditInline}
                                            editComponent={
                                                <EasyEditCustomSelect focusCallback={e => { setAllowEditInline(false); }} label="Country" fieldValue={quoteDetail?.quote_billing?.country_id || ''}
                                                    items={
                                                        countries && countries.length > 0 && countries.map((country) => (
                                                            { value: country.id, option: country.country_name }
                                                        ))
                                                    }
                                                />
                                            }
                                            displayComponent={<FormGroupStatic easyEdit label="Country" value={selectedBCName || 'N/A'} />}
                                            saveButtonLabel={<EasyEditSaveButton />}
                                            cancelButtonLabel={<EasyEditCancelButton />}
                                            onFocus={() => handleClick('')}
                                        />
                                        {backendErrorMessages['country_id'] && <span className="easy-edit-validation-error">{backendErrorMessages['country_id']}</span>}
                                    </Stack>
                                </Grid>

                                <Grid item xs={6}>
                                    <Typography variant="title-small-bold" component={'h4'} className="mb-8">Shipping Address</Typography>

                                    <Stack spacing={1.5}>
                                        <EasyEdit
                                            type={Types.TEXT}
                                            onSave={(value) => { handleSaveData(value, 's_address') }}
                                            onCancel={() => { onCancelEdit('s_address') }}
                                            value={quoteDetail?.quote_shipping?.address || 'N/A'}
                                            allowEdit={allowEditInline}
                                            editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Street" value={quoteDetail?.quote_shipping?.address} errorMessages={errorMessages['s_address']} maxLength={100} />}
                                            displayComponent={<FormGroupStatic easyEdit label="Street" value={quoteDetail?.quote_shipping?.address || 'N/A'} />}
                                            onValidate={(value) => handleValidation(value, 's_address', 'Street', '', false, 2, 100)}
                                            validationMessage={errorMessages['s_address']}
                                            saveButtonLabel={<EasyEditSaveButton />}
                                            cancelButtonLabel={<EasyEditCancelButton />}
                                            onFocus={() => handleClick('')}
                                        />
                                        {backendErrorMessages['s_address'] && <span className="easy-edit-validation-error">{backendErrorMessages['s_address']}</span>}
                                        <EasyEdit
                                            type={Types.TEXT}
                                            onSave={(value) => { handleSaveData(value, 's_city') }}
                                            onCancel={() => { onCancelEdit('s_city') }}
                                            value={quoteDetail?.quote_shipping?.city || 'N/A'}
                                            allowEdit={allowEditInline}
                                            editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="City" value={quoteDetail?.quote_shipping?.city} errorMessages={errorMessages['s_city']} maxLength={100} />}
                                            displayComponent={<FormGroupStatic easyEdit label="City" value={quoteDetail?.quote_shipping?.city || 'N/A'} />}
                                            onValidate={(value) => handleValidation(value, 's_city', 'City', '', false)}
                                            validationMessage={errorMessages['s_city']}
                                            saveButtonLabel={<EasyEditSaveButton />}
                                            cancelButtonLabel={<EasyEditCancelButton />}
                                            onFocus={() => handleClick('')}
                                        />
                                        {backendErrorMessages['s_city'] && <span className="easy-edit-validation-error">{backendErrorMessages['s_city']}</span>}
                                        <EasyEdit
                                            type={Types.TEXT}
                                            onSave={(value) => { handleSaveData(value, 's_state') }}
                                            onCancel={() => { onCancelEdit('s_state') }}
                                            value={quoteDetail?.quote_shipping?.state || 'N/A'}
                                            allowEdit={allowEditInline}
                                            editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="State" value={quoteDetail?.quote_shipping?.state} errorMessages={errorMessages['s_state']} maxLength={100} />}
                                            displayComponent={<FormGroupStatic easyEdit label="State" value={quoteDetail?.quote_shipping?.state || 'N/A'} />}
                                            onValidate={(value) => handleValidation(value, 's_state', 'State', '', false)}
                                            validationMessage={errorMessages['s_state']}
                                            saveButtonLabel={<EasyEditSaveButton />}
                                            cancelButtonLabel={<EasyEditCancelButton />}
                                            onFocus={() => handleClick('')}
                                        />
                                        {backendErrorMessages['s_state'] && <span className="easy-edit-validation-error">{backendErrorMessages['s_state']}</span>}
                                        <EasyEdit
                                            type={Types.TEXT}
                                            onSave={(value) => { handleSaveData(value, 's_zipcode') }}
                                            onCancel={() => { onCancelEdit('s_zipcode') }}
                                            value={quoteDetail?.quote_shipping?.zipcode || 'N/A'}
                                            allowEdit={allowEditInline}
                                            editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Postal Code" value={quoteDetail?.quote_shipping?.zipcode} errorMessages={errorMessages['s_zipcode']} maxLength={10} />}
                                            displayComponent={<FormGroupStatic easyEdit label="Postal Code" value={quoteDetail?.quote_shipping?.zipcode || 'N/A'} />}
                                            onValidate={(value) => handleValidation(value, 's_zipcode', 'Postal Code', '', false, 2, 10)}
                                            validationMessage={errorMessages['s_zipcode']}
                                            saveButtonLabel={<EasyEditSaveButton />}
                                            cancelButtonLabel={<EasyEditCancelButton />}
                                            onFocus={() => handleClick('')}
                                        />
                                        {backendErrorMessages['s_zipcode'] && <span className="easy-edit-validation-error">{backendErrorMessages['s_zipcode']}</span>}
                                        <EasyEdit
                                            type={Types.SELECT}
                                            onSave={(value) => { handleSaveData(value, 's_country_id') }}
                                            onCancel={() => { onCancelEdit() }}
                                            value={selectedSCName || 'N/A'}
                                            allowEdit={allowEditInline}
                                            editComponent={
                                                <EasyEditCustomSelect focusCallback={e => { setAllowEditInline(false); }} label="Country" fieldValue={quoteDetail?.quote_shipping?.country_id || ''}
                                                    items={
                                                        countries && countries.length > 0 && countries.map((country) => (
                                                            { value: country.id, option: country.country_name }
                                                        ))
                                                    }
                                                />
                                            }
                                            displayComponent={<FormGroupStatic easyEdit label="Country" value={selectedSCName || 'N/A'} />}
                                            saveButtonLabel={<EasyEditSaveButton />}
                                            cancelButtonLabel={<EasyEditCancelButton />}
                                            onFocus={() => handleClick('')}
                                        />
                                        {backendErrorMessages['s_country_id'] && <span className="easy-edit-validation-error">{backendErrorMessages['s_country_id']}</span>}
                                    </Stack>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>

                    <Grid item xs={12}>
                        <div className="card p-16">
                            <div className="card-header">
                                <h6 className="title">Quoted Items</h6>
                                {!editQuotedItems && <Button color="secondary" size="small" className="action" onClick={e => { handleEditQuotedItems(true); }}>
                                    <span>
                                        <Icon path={mdiPencilOutline} color={'currentColor'} size={1} />
                                        <span>Edit Items</span>
                                    </span>
                                </Button>}
                            </div>

                            {!editQuotedItems &&
                                <>
                                    <Stack spacing={2}>
                                        <TableContainer>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>No.</TableCell>
                                                        <TableCell className="long-text">Product Name</TableCell>
                                                        <TableCell align="right">Amount</TableCell>
                                                        <TableCell align="right">List Price</TableCell>
                                                        <TableCell align="right">Quantity</TableCell>
                                                        <TableCell align="right">Tax</TableCell>
                                                        <TableCell align="right">Total</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {quoteDetail?.quote_items ?
                                                        quoteDetail.quote_items.map((quotedItem, index) => (
                                                            <TableRow key={index + ''}>
                                                                <TableCell>{index + 1}</TableCell>
                                                                <TableCell>{quotedItem?.product?.name}</TableCell>
                                                                <TableCell align="right">{currencySymbol}{quotedItem?.amount || 0.00}</TableCell>
                                                                <TableCell align="right">{currencySymbol}{quotedItem?.list_price || 0.00}</TableCell>
                                                                <TableCell align="right">{quotedItem?.quantity || 0}</TableCell>
                                                                <TableCell align="right">{currencySymbol}{quotedItem?.tax || 0}</TableCell>
                                                                <TableCell align="right">{quotedItem?.total ? displayCurrencySymbol(quotedItem?.total, currencyCode, currencySymbol, currencyStringValue) : 0.00}</TableCell>
                                                            </TableRow>
                                                        ))
                                                        :
                                                        <TableRow>
                                                            <TableCell>No data available</TableCell>
                                                        </TableRow>
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>

                                        <Grid container direction={"row-reverse"} justifyContent={"space-between"}>
                                            <Grid item xxl={4} lg={5.5} xs={12}>
                                                <Box className="card grey py-12 px-16" sx={{ marginBottom: { lg: 0, xs: 2 } }}>
                                                    <Stack spacing={1}>
                                                        <Stack direction={"row"} spacing={1} justifyContent={'space-between'}>
                                                            <Typography variant="body-medium" className="text-secondary">Subtotal</Typography>
                                                            <Typography variant="body-medium" className="text-right">{quoteDetail?.sub_total ? displayCurrencySymbol(quoteDetail?.sub_total, currencyCode, currencySymbol, currencyStringValue) : 0.00}</Typography>
                                                        </Stack>
                                                        <Stack direction={"row"} spacing={1} justifyContent={'space-between'}>
                                                            <Typography variant="body-medium" className="text-secondary">Discount ({quoteDetail?.discount}{quoteDetail?.discount_type === 'percentage' ? ' %' : ' Flat'})</Typography>
                                                            <Typography variant="body-medium" className="text-right">{quoteDetail?.discount_value ? displayCurrencySymbol(quoteDetail?.discount_value, currencyCode, currencySymbol, currencyStringValue) : 0.00}</Typography>
                                                        </Stack>
                                                        <Stack direction={"row"} spacing={1} justifyContent={'space-between'}>
                                                            <Typography variant="body-medium" className="text-secondary">Tax ({quoteDetail?.tax}{quoteDetail?.tax_type === 'percentage' ? ' %' : ' Flat'})</Typography>
                                                            <Typography variant="body-medium" className="text-right">{quoteDetail?.tax_value ? displayCurrencySymbol(quoteDetail?.tax_value, currencyCode, currencySymbol, currencyStringValue) : 0.00}</Typography>
                                                        </Stack>
                                                        <Stack direction={"row"} spacing={1} justifyContent={'space-between'}>
                                                            <Typography variant="body-medium" className="text-secondary">Adjustment</Typography>
                                                            <Typography variant="body-medium" className="text-right">{quoteDetail?.adjustment_amount ? displayCurrencySymbol(quoteDetail?.adjustment_amount, currencyCode, currencySymbol, currencyStringValue) : 0.00}</Typography>
                                                        </Stack>
                                                    </Stack>
                                                    <hr className="mb-16 mt-12" />
                                                    <Stack direction={"row"} spacing={1} justifyContent={'space-between'} alignItems={'baseline'}>
                                                        <Typography variant="title-large">Grand Total</Typography>
                                                        <Typography variant="headline-medium-semibold" className="text-right">{quoteDetail?.total ? displayCurrencySymbol(quoteDetail?.total, currencyCode, currencySymbol, currencyStringValue) : 0.00}</Typography>
                                                    </Stack>
                                                </Box>
                                            </Grid>

                                            <Grid item xxl={4} lg={5.5} xs={12}>
                                                <Stack spacing={1.5}>
                                                    <EasyEdit
                                                        type={Types.TEXT}
                                                        onSave={(value) => { handleSaveData(value, 'terms_and_condition') }}
                                                        onCancel={() => { onCancelEdit('terms_and_condition') }}
                                                        value={quoteDetail?.terms_and_condition || 'N/A'}
                                                        allowEdit={allowEditInline}
                                                        editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Terms & Conditions" value={quoteDetail?.terms_and_condition} errorMessages={errorMessages['terms_and_condition']} maxLength={150} />}
                                                        displayComponent={<FormGroupStatic easyEdit label="Terms & Conditions" value={quoteDetail?.terms_and_condition || 'N/A'} />}
                                                        onValidate={(value) => handleValidation(value, 'terms_and_condition', 'Terms & Conditions', '', false, '2', '150')}
                                                        validationMessage={errorMessages['terms_and_condition']}
                                                        saveButtonLabel={<EasyEditSaveButton />}
                                                        cancelButtonLabel={<EasyEditCancelButton />}
                                                        onFocus={() => handleClick('')}
                                                    />
                                                    {backendErrorMessages['terms_and_condition'] && <span className="easy-edit-validation-error">{backendErrorMessages['terms_and_condition']}</span>}
                                                    <EasyEdit
                                                        type={Types.TEXT}
                                                        onSave={(value) => { handleSaveData(value, 'description') }}
                                                        onCancel={() => { onCancelEdit('description') }}
                                                        value={quoteDetail?.description || 'N/A'}
                                                        allowEdit={allowEditInline}
                                                        editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Description Information" value={quoteDetail?.description} errorMessages={errorMessages['description']} maxLength={150} />}
                                                        displayComponent={<FormGroupStatic easyEdit label="Terms & Conditions" value={quoteDetail?.description || 'N/A'} />}
                                                        onValidate={(value) => handleValidation(value, 'description', 'Description Information', '', false, '2', '150')}
                                                        validationMessage={errorMessages['description']}
                                                        saveButtonLabel={<EasyEditSaveButton />}
                                                        cancelButtonLabel={<EasyEditCancelButton />}
                                                        onFocus={() => handleClick('')}
                                                    />
                                                    {backendErrorMessages['description'] && <span className="easy-edit-validation-error">{backendErrorMessages['description']}</span>}
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Stack>
                                </>
                            }

                            {editQuotedItems &&
                                <CreateEditQuoteItem data={{ record: quoteDetail, product: products, quoteID: quoteId, term: quoteDetail?.terms_and_condition, description: quoteDetail?.description, handleEditQuotedItems: handleEditQuotedItems, handleParentReload: handleParentReload, backendErrorMessages }} />
                            }
                        </div>
                    </Grid>


                    {
                        quoteId && (
                            <Suspense fallback={<div>Loading...</div>}>
                                <QuoteEmailHistory quoteId={quoteId} reloadPage={reloadPage} />
                            </Suspense>
                        )
                    }
                    {
                        sendQuoteViaEmailDialogOpen && quoteId && (
                            <Suspense fallback={<div>Loading...</div>}>
                                <SendQuoteViaEmailDialog open={sendQuoteViaEmailDialogOpen} onClose={closeSendQuoteViaEmailDialog} quoteId={quoteId} dataRecord={emailData} handleParentReload={handleParentReload} />
                            </Suspense>
                        )
                    }

                </Grid>
            }



        </>
    );
}

export default QuoteDetails;