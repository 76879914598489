import { mdiClose, mdiFileDocument, mdiFilePdfBox, mdiFilePlusOutline, mdiImage, mdiMusicBox } from "@mdi/js";
import Icon from "@mdi/react";
import { Autocomplete, Button, Chip, Dialog, Grid, Stack, TextField, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Editor } from 'react-draft-wysiwyg';
import { convertToRaw, EditorState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useNavigate } from "react-router-dom";
import { useSnackbar } from '../../../components/context/SnackbarContext';
import useAxios from '../../../hooks/useAxios';
import { useLoader } from "../../../components/context/LoaderContext";
import { Formik } from "formik";
import * as yup from "yup";
import draftToHtml from "draftjs-to-html";

function SendQuoteViaEmailDialog({ onClose, open, quoteId, handleParentReload, dataRecord }) {
    const navigate = useNavigate();
    const axiosInstance = useAxios();
    const { showSnackbar } = useSnackbar();
    const { showLoader, hideLoader, loading } = useLoader();

    const [quoteName, setquoteName] = useState();
    const [displayCc, setDisplayCc] = useState(false);
    const [displayBcc, setDisplayBcc] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());


    useEffect(() => {
        async function fetchData() {
            await getData();
        }
        fetchData();
    }, [quoteId]);

    const getData = async () => {
        showLoader()
        await axiosInstance.get('api/quote-generate/' + quoteId).then(response => {
            if (response.status === 200) {
                setquoteName(response?.data?.data?.quoteName || '');
                hideLoader();
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                        navigate("/quote-detail/" + quoteId);
                    }
                }
            });
    };

    const iconMapping = {
        image: mdiImage,
        audio: mdiMusicBox,
        file: mdiFileDocument,
        pdf: mdiFilePdfBox,
        // Add more mappings as needed
    };

    const allowedMimeTypes = [
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.oasis.opendocument.spreadsheet', 'application/vnd.oasis.opendocument.text',
        'application/vnd.oasis.opendocument.presentation', 'text/csv', 'application/pdf',
        'text/plain', 'application/vnd.ms-office', 'text/x-c', 'text/anytext',
        'application/ogg', 'application/x-ogg', 'text/comma-separated-values',
        'application/x-ogg', 'text/anytext'
    ];

    const validationSchema = yup.object().shape({
        toEmails: yup
            .array()
            .of(
                yup
                    .string()
                    .trim()
                    .required('Email is required') // Displayed when an email string is empty
            )
            .min(1, 'Please enter at least one email') // Check for at least one email
            .test(
                'all-valid-emails', // Custom test name for array-level validation
                'Please enter email addresses as valid email address', // Single error message for any invalid emails
                (emails) => emails.every((email) => !email || yup.string().email().isValidSync(email)) // Check if all emails are valid
            )
            .required('Email field is required'), // Required array
        ccEmails: yup
            .array()
            .of(
                yup
                    .string()
                    .trim()

            )
            .test(
                'all-valid-emails', // Custom test name for array-level validation
                'Please enter email addresses as valid email address', // Single error message for any invalid emails
                (emails) => emails.every((email) => !email || yup.string().email().isValidSync(email)) // Check if all emails are valid
            ),
        bccEmails: yup
            .array()
            .of(
                yup
                    .string()
                    .trim()

            )
            .test(
                'all-valid-emails', // Custom test name for array-level validation
                'Please enter email addresses as valid email address', // Single error message for any invalid emails
                (emails) => emails.every((email) => !email || yup.string().email().isValidSync(email)) // Check if all emails are valid
            ),
        subject: yup
            .string()
            .min(2, 'Must be at least 2 characters')
            .max(50, 'Must be at most 50 characters')
            .required('Enter your subject'),
        description: yup
            .string()
            .min(2, 'Must be at least 2 characters')
            .max(500, 'Must be at most 500 characters')
            .required('Enter your message'),
        attachment: yup
            .array()
            .nullable()
            .of(
                yup
                    .mixed()
                    .test('fileFormat', 'Unsupported file format. Please upload a valid file type.', (value) => {
                        return value && allowedMimeTypes.includes(value.type);
                    })
                    .test('fileSize', 'File size exceeds 4 MB', (value) => {
                        return value && value.size <= 4 * 1024 * 1024;
                    })
            )
            //.required('Please select at least one file')
            .test('validFiles', 'Files are not uploaded due to validation errors', function (value) {
                if (value) {
                    return value.every(file => {
                        const isValidFormat = allowedMimeTypes.includes(file.type);
                        const isValidSize = file.size <= 4 * 1024 * 1024;
                        return isValidFormat && isValidSize;
                    });
                }
                return true;
            }),
    });

    const handleFileChange = (event) => {
        const files = Array.from(event.currentTarget.files);
        const errors = [];
        const validFiles = [];

        files.forEach((file) => {
            if (file.size > 4 * 1024 * 1024) {
                errors.push(`${file.name} exceeds 4MB size limit`);
            } else if (!allowedMimeTypes.includes(file.type)) {
                errors.push(`${file.name} is an invalid file type`);
            } else {
                validFiles.push(file);
            }
        });

        if (errors.length > 0) {
            showSnackbar(`${errors.length} file(s) not uploaded due to validation errors`, 'error');
        } else {
            //showSnackbar(`${validFiles.length} file(s) are added successfully`, 'success');
        }

        setSelectedFiles([...selectedFiles, ...validFiles]);
        //setFileErrors(errors);
        event.target.value = ''
    };

    const handleSubmit = async (values, onSubmitProps) => {
        const formData = new FormData();
        formData.append("subject", values.subject);
        formData.append("description", values.description);
        formData.append("default_attachment", quoteName);
        for (var i = 0; i < selectedFiles.length; i++) {
            formData.append('attachment[]', selectedFiles[i])
        }
        for (var j = 0; j < values.toEmails.length; j++) {
            formData.append("toEmails[]", values.toEmails[j]);
        }
        for (var k = 0; k < values.ccEmails.length; k++) {
            formData.append("ccEmails[]", values.ccEmails[k]);
        }
        for (var l = 0; l < values.bccEmails.length; l++) {
            formData.append("bccEmails[]", values.bccEmails[l]);
        }
        formData.append("quote_id", quoteId);

        showLoader()
        await axiosInstance.post("api/quote-history/store", formData).then((response) => {
            if (response.status === 200) {
                hideLoader()
                onClose()
                handleParentReload()
                showSnackbar(response.data.message, "success");
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader()
                    if (error.response.status === 422 || error.response.data.validation_errors) {
                        const errors = error.response.data.validation_errors;
                        Object.keys(errors).forEach(key => {
                            onSubmitProps.setFieldError(key, errors[key]); //handle multiple error on single fields remove this last [0]: errors[key][0]
                        });
                    } else {
                        showSnackbar(error.response.data.message, 'error')
                    }
                }
            });
    };

    const removeFile = (index, setFieldValue, errors) => {
        // Remove file from selectedFiles array
        const newFiles = [...selectedFiles];
        newFiles.splice(index, 1);
        setSelectedFiles(newFiles);

        // Remove the corresponding error from errors.file_url
        //const newErrors = [...errors.file_url];
        const newErrors = Array.isArray(errors.file_url) ? [...errors.file_url] : [];
        newErrors.splice(index, 1);

        // Update Formik's field value
        setFieldValue("attachment", newErrors);
    };

    return (
        <>

            <Dialog onClose={onClose} open={open}>
                <div className="dialog-title">
                    <h2>Send Quote via Email</h2>
                    <Tooltip title="Close">
                        <button className="icon-button dialog-close" onClick={onClose}>
                            <Icon path={mdiClose} color={'currentColor'} size={1} />
                        </button>
                    </Tooltip>
                </div>
                <Formik
                    initialValues={{
                        fromEmail: dataRecord?.fromEmail || '',
                        toEmails: [],
                        ccEmails: [],
                        bccEmails: [],
                        subject: dataRecord?.subject || '',
                        description: "",
                        attachment: null,
                        error_list: [],
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    validateOnChange={true}
                    validateOnBlur={true}
                    enableReinitialize
                >
                    {props => {
                        const {
                            touched,
                            errors,
                            values,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue
                        } = props;
                        return (
                            <form onSubmit={handleSubmit}>
                                <TextField
                                    label="From"
                                    variant="filled"
                                    name="fromEmail"
                                    value={values.fromEmail}
                                    readOnly
                                    error={errors.fromEmail && touched.fromEmail}
                                    helperText={(errors.fromEmail && touched.fromEmail) && errors.fromEmail}
                                />

                                <div className="control-hint">
                                    <Autocomplete
                                        multiple
                                        freeSolo
                                        options={[]} // Free input without predefined options
                                        autoSelect
                                        value={values.toEmails}
                                        onChange={(event, newValue) => {
                                            props.setFieldTouched('toEmails', true, true);
                                            props.setFieldValue('toEmails', newValue);
                                        }}
                                        renderTags={(value, getTagProps) =>
                                            value.map((option, index) => (
                                                <Chip variant="outlined" size="small" label={option} key={index} {...getTagProps({ index })} />
                                            ))
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="filled"
                                                label="To"
                                                error={Boolean(errors.toEmails && touched.toEmails)}
                                                helperText={errors.toEmails && touched.toEmails ? errors.toEmails : ""}
                                            />
                                        )}
                                    />

                                    <Typography className="hint" variant="label-medium-bold">
                                        <Stack direction={"row"} spacing={0.75}>
                                            {
                                                !displayCc &&
                                                <Tooltip title="Add Cc"><span><a href="-" className="primary-link" onClick={(e) => { e.preventDefault(); setDisplayCc(true) }}>Cc</a></span></Tooltip>
                                            }{
                                                !displayBcc &&
                                                <Tooltip title="Add Bcc"><span><a href="-" className="primary-link" onClick={(e) => { e.preventDefault(); setDisplayBcc(true) }}>Bcc</a></span></Tooltip>
                                            }


                                        </Stack>
                                    </Typography>
                                </div>
                                {displayCc ?
                                    <Autocomplete
                                        multiple
                                        freeSolo
                                        options={[]} // Free input without predefined options
                                        autoSelect
                                        value={values.ccEmails}
                                        onChange={(event, newValue) => {
                                            props.setFieldTouched('ccEmails', true, true);
                                            props.setFieldValue('ccEmails', newValue);
                                        }}
                                        renderTags={(value, getTagProps) =>
                                            value.map((option, index) => (
                                                <Chip variant="outlined" size="small" label={option} key={index} {...getTagProps({ index })} />
                                            ))
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="filled"
                                                label="Cc"
                                                error={Boolean(errors.ccEmails && touched.ccEmails)}
                                                helperText={errors.ccEmails && touched.ccEmails ? errors.ccEmails : ""}
                                            />
                                        )}
                                    />
                                    : ''
                                }

                                {
                                    displayBcc ?
                                        <Autocomplete
                                            multiple
                                            freeSolo
                                            options={[]} // Free input without predefined options
                                            autoSelect
                                            value={values.bccEmails}
                                            onChange={(event, newValue) => {
                                                props.setFieldTouched('bccEmails', true, true);
                                                props.setFieldValue('bccEmails', newValue);
                                            }}
                                            renderTags={(value, getTagProps) =>
                                                value.map((option, index) => (
                                                    <Chip variant="outlined" size="small" label={option} key={index} {...getTagProps({ index })} />
                                                ))
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="filled"
                                                    label="Bcc"
                                                    error={Boolean(errors.bccEmails && touched.bccEmails)}
                                                    helperText={errors.bccEmails && touched.bccEmails ? errors.bccEmails : ""}
                                                />
                                            )}
                                        />
                                        :
                                        ''
                                }


                                <TextField
                                    label="Subject"
                                    variant="filled"
                                    name="subject"
                                    value={values.subject}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.subject && touched.subject}
                                    helperText={(errors.subject && touched.subject) && errors.subject}
                                />

                                <Editor
                                    editorState={editorState}
                                    onEditorStateChange={(editorState) => {
                                        setEditorState(editorState);
                                        setFieldValue('description', draftToHtml(convertToRaw(editorState.getCurrentContent())));
                                    }}
                                    toolbar={{
                                        options: ['inline', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'image', 'remove', 'history'],
                                    }}
                                    error={errors.description && touched.description}
                                    helperText={(errors.description && touched.description) && errors.description}
                                />
                                {errors.description && touched.description && (
                                    <span className="easy-edit-validation-error">{errors.description}</span>
                                )}
                                <div className="attachment-center">
                                    <div className="title">
                                        <Typography variant="title-medium-bold">Attachments</Typography> <Typography variant="title-small" className="file-size">(Max Size 4 MB)</Typography>
                                    </div>
                                    <div className="title">
                                        <Typography variant="title-medium-bold">
                                            {
                                                quoteName ? quoteName : dataRecord?.quote_number ? dataRecord?.quote_number + ".pdf" : ''
                                            }
                                        </Typography>
                                    </div>
                                    <div className="psuedo-upload-block">
                                        <div className="content">
                                            <Button color="secondary" className="btn-block" tabIndex={-1}>
                                                <span>
                                                    <Icon path={mdiFilePlusOutline} color={'currentColor'} size={1} />
                                                    <span>Upload File</span>
                                                </span>
                                            </Button>
                                        </div>
                                        <input
                                            type="file"
                                            multiple
                                            name="attachment"
                                            className="psuedo-upload-element"
                                            onChange={(event) => {
                                                handleFileChange(event);
                                                setFieldValue("attachment", Array.from(event.currentTarget.files));
                                            }}
                                            onBlur={handleBlur}
                                            accept="application/pdf, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
text/plain, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/comma-separated-values, text/csv, application/csv"
                                            title="Allow file type are pdf,doc,excel and MAX file size are uo to 4MB"
                                        />
                                    </div>

                                    {selectedFiles && selectedFiles.length > 0 && <Grid container component={'ul'} spacing={1.5} className="attachments">
                                        {selectedFiles.map((attachment, index) => {
                                            const fileExtension = attachment && attachment?.name?.split('.').pop().toLowerCase();
                                            let fileType = '';
                                            if (['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension)) {
                                                fileType = 'image';
                                            } else if (['mp3', 'wav', 'ogg', 'mp4'].includes(fileExtension)) {
                                                fileType = 'audio';
                                            } else if (['pdf'].includes(fileExtension)) {
                                                fileType = 'pdf';
                                            } else {
                                                fileType = 'file';
                                            }
                                            const IconComponent = iconMapping[fileType];

                                            return (
                                                <Grid item component={'li'} xs={12} key={index + ''}>
                                                    <a href={attachment.fileUrl} className={(Array.isArray(errors.file_url) && errors.file_url.length > 0 && errors.file_url[index]) ? 'has-error' : ''}>
                                                        <div className="icon-wrapper">
                                                            {IconComponent && <Icon path={IconComponent} color={'currentColor'} size={1} />}
                                                        </div>
                                                        <div className="info">
                                                            <Typography variant="body-large" component={'p'}>{attachment.name}</Typography>
                                                            {attachment.size && <Typography variant="body-medium">Size: {(attachment?.size / 1024 / 1024).toFixed(2)} MB</Typography>}
                                                        </div>
                                                    </a>
                                                    {selectedFiles && <Tooltip title="Remove">
                                                        <button type="button" className="icon-button size-small" onClick={() => removeFile(index, setFieldValue, errors)}>
                                                            <Icon path={mdiClose} color={'currentColor'} size={1} />
                                                        </button>
                                                    </Tooltip>}
                                                    {Array.isArray(errors.file_url) && errors.file_url[index] && (
                                                        <ul className='error-text'>
                                                            {Array.isArray(errors.file_url[index]) ? errors.file_url[index].map((error, idx) => (
                                                                <li key={idx}>{error}</li>
                                                            )) : <li>{errors.file_url[index]}</li>}
                                                        </ul>
                                                    )}
                                                </Grid>
                                            )
                                        }
                                        )}
                                    </Grid>}
                                </div>

                                <Stack direction="row" spacing={1.5} justifyContent={'flex-end'}>
                                    <Button type="submit">Send Email</Button>
                                    <Button color="secondary" onClick={onClose}>Cancel</Button>
                                </Stack>
                            </form>
                        );
                    }}
                </Formik>
            </Dialog>
        </>
    );
}

export default SendQuoteViaEmailDialog;