import React, { Suspense, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Grid, Menu, MenuItem, Popover, Stack, TextField, Tooltip, Typography } from "@mui/material";
import Icon from "@mdi/react";
import { mdiCalendarCheckOutline, mdiContentCopy, mdiDotsVertical, mdiPencilOutline, mdiThumbDownOutline, mdiThumbUpOutline, mdiTrashCanOutline } from "@mdi/js";
import EasyEdit, { Types } from "react-easy-edit";
import FormGroupStatic from '../../components/common/FormGroupStatic';
import EasyEditCustomTextbox from '../../components/common/EasyEditCustomTextbox';
import EasyEditCustomSelect from '../../components/common/EasyEditCustomSelect';
import { EasyEditCancelButton, EasyEditSaveButton } from "../../components/common/EasyEditButtons";
import ClosedWonDealDialog from "./components/ClosedWonDealDialog";
import CloneDealDialog from "./components/CloneDealDialog";
import EasyEditCustomDatePicker from "../../components/common/EasyEditCustomDatePicker";
import { useSnackbar } from "../../components/context/SnackbarContext";
import useAxios from "../../hooks/useAxios";
import { useLoader } from "../../components/context/LoaderContext";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { capitalize, DeleteDialog, DeleteDialogConfig, displayCurrencySymbol, displayDate, validateField } from "../../utils/Utils";
import NoteList from "../../components/common/NoteList";
import ActivityList from "../../components/common/ActivityList";
import EasyEditCustomMultiSelect from "../../components/common/EasyEditCustomMultiSelect";
import EasyEditCustomAmountTextbox from "../../components/common/EasyEditCustomAmountTextbox";
import FormGroupStaticAmount from "../../components/common/FormGroupStaticAmount";
import DealContactList from "./components/DealContactList";
import DealQuoteList from "./components/DealQuoteList";


function DealDetails(props) {
    const { id } = useParams();
    const dealId = id
    const navigate = useNavigate();
    const axiosInstance = useAxios();
    const { showSnackbar } = useSnackbar();
    const { showLoader, hideLoader } = useLoader();
    const { preferredDateFormat, preTimeZoneKey, currencyCode, currencySymbol, currencyStringValue } = useSelector((state) => state?.user);
    const [reloadPage, setReloadPage] = useState(false);
    const [errorMessages, setErrorMessages] = useState({});
    const [backendErrorMessages, setBackendErrorMessages] = useState({});
    const [closeDealType, setCloseDealType] = useState();
    const userPermissions = useSelector((state) => state.user.permission);
    const [deal, setDeal] = useState([]);
    const [dealStages, setDealStages] = useState();
    const [displayDealStage, setDisplayDealStage] = useState();
    const [defaultStageId, setDefaultStageId] = useState();
    const [dealAccountData, setDealAccountData] = useState([]);
    const [stageId, setStageId] = useState();
    const [selectedProductIds, setSelectedProductIds] = useState([]);
    const [dealSourceArray, setDealSourceArray] = useState([]);
    const [selectedData, setSelectedData] = useState([]);
    const [productList, setProductList] = useState([]);
    const [contactList, setContactList] = useState([]);
    const [selectedContact, setSelectedContact] = useState('');

    useEffect(() => {
        async function fetchData() {
            getData();
        }
        fetchData();
    }, [reloadPage]);


    const getData = async () => {
        showLoader();
        await axiosInstance.get('api/deal/edit/' + dealId).then((response) => {
            if (response.status === 200) {
                setDeal(response?.data?.data?.record?.dealData);
                setDealStages(response?.data?.data?.leadStage)
                setDealAccountData(response?.data?.data?.record?.dealData?.AccountData || [])
                setDisplayDealStage(response?.data?.data?.record?.dealData?.DealStage?.name || '');
                setSelectedProductIds(response?.data?.data?.selected_product)
                setDealSourceArray(response?.data?.data?.LeadSource)
                setSelectedData(response?.data?.data?.selectedData)
                setProductList(response?.data?.data?.products)
                setContactList(response?.data?.data?.contactLists)
                setDefaultStageId(response?.data?.data?.record?.dealData?.stage_id)
                hideLoader();
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                        navigate("/deals");
                    }
                }
            });
    }

    //get selected contact
    useEffect(() => {
        const selectedContactName = contactList && contactList.find(contact => contact.id === deal.contact_id);

        if (selectedContactName) {
            var salutation = selectedContactName?.salutation || ''
            if (salutation) {
                salutation = salutation + '. '
            }

            setSelectedContact(salutation + selectedContactName.first_name + ' ' + selectedContactName.last_name)
        }
    }, [deal?.contact_id]);


    //========Start::Write a code for handle change stage at top bar==========//
    useEffect(() => {
        const selectedDealStage = dealStages && dealStages.find(stage => stage.id === deal?.stage_id);
        if (selectedDealStage) {
            setDisplayDealStage(selectedDealStage.name);
        }
    }, [deal?.stage_id, dealStages]);


    const handleDealStageChange = (e) => {
        const selectedValue = e.target.value;
        const selectedDealStage = dealStages && dealStages.find(stage => stage.id === selectedValue);
        if (selectedDealStage?.name === 'Closed Won') {
            handleEditCurrentStatusClose()
            setStageId(selectedValue);
            setCloseDealType('Won')
            setClosedWonDialogOpen(true);
        } else if (selectedDealStage?.name === 'Closed Lost') {
            handleEditCurrentStatusClose()
            setStageId(selectedValue);
            setCloseDealType('Lost')
            setClosedWonDialogOpen(true);
        } else {
            setDefaultStageId(selectedValue)
        }

    }

    const [editCurrentStatusAnchorEl, setEditCurrentStatusAnchorEl] = React.useState(null);
    const handleEditCurrentStatusClick = (event) => {
        setEditCurrentStatusAnchorEl(event.currentTarget);
    };
    const handleEditCurrentStatusClose = () => {
        setEditCurrentStatusAnchorEl(null);
    };
    const editCurrentStatusOpen = Boolean(editCurrentStatusAnchorEl);

    const handleDealStageSubmit = async () => {
        const data = {
            stage_id: defaultStageId,
            id: dealId,
            contact_id: deal?.contact_id || '',
            organization_name: dealAccountData?.organization_name || '',
            deal_description: deal?.deal_description || '',
            deal_amount: deal?.deal_amount || '',
            deal_probability: deal?.deal_probability || '',
            expected_closing_date: deal?.expected_closing_date || '',
        }

        await axiosInstance.post("/api/deal/update", data).then(response => {
            if (response.status === 200) {
                setReloadPage(reloadPage ? false : true)
                showSnackbar(response.data.message, "success");
                setEditCurrentStatusAnchorEl(null);
            }
        }).then(json => {

        }).catch(error => {
            if (error.response) {
                hideLoader();
                if (error.response.status === 400) {
                    showSnackbar(error.response.data.message, "warning");
                    navigate("/leads");
                }
            }
        });

    }
    //========End::Write a code for handle change stage at top bar==========//

    const handleReloadFunc = () => {
        setReloadPage(reloadPage ? false : true);
    }

    //=======Start::Write a code for delete lead=======//
    async function deleteData(id) {
        const data = {
            id: id,
        }
        try {
            DeleteDialog.fire({
                ...DeleteDialogConfig({
                    confirm: async () => {
                        showLoader()
                        await axiosInstance.delete(`api/deal/destroy`, { data })
                            .then((response) => {
                                if (response.status === 200) {
                                    showSnackbar(response.data.message, "success");
                                    hideLoader();
                                    setReloadPage(reloadPage ? false : true);
                                    navigate("/deals");
                                }
                            })
                            .then((json) => { })
                            .catch((error) => {
                                if (error.response) {
                                    hideLoader();
                                    if (error.response.status === 400) {
                                        showSnackbar(error.response.data.message, "warning");
                                    }
                                }
                            });
                    },
                    cancel: () => {
                        hideLoader();
                    }
                })
            });
        } catch (error) {
            showSnackbar('Oops Something want wrong..!', "error");
        }
    }
    //=======Start::Write a code for delete lead=======//

    //======Start::Write a code for default data for editInline==========//
    const [allowEditInline, setAllowEditInline] = useState(true);

    const onCancelEdit = (fieldName) => {
        setErrorMessages({ [fieldName]: '' });
        setAllowEditInline(true);
    }

    const handleClick = (e) => {
        setAllowEditInline(false)
    }

    const handleValidation = (value, fieldName, displayName, type, isrequired, min, max) => {
        const errorMsg = validateField(value, fieldName, displayName, type, isrequired, min, max);
        setErrorMessages(prevState => ({ ...prevState, [fieldName]: errorMsg }));
        return errorMsg === '';
    };

    const handleSaveData = async (value, name, type = null) => {

        const formData = new FormData();
        formData.append('id', dealId)
        formData.append('contact_id', deal.contact_id)
        formData.append('organization_name', dealAccountData?.organization_name)
        formData.append('deal_description', deal?.deal_description)
        formData.append('deal_amount', deal?.deal_amount)
        formData.append('deal_probability', deal?.deal_probability)
        formData.append('closing_date', deal?.closing_date ? moment(deal.closing_date).format('YYYY-MM-DD') : '')
        formData.append('expected_closing_date', deal?.expected_closing_date ? moment(deal.expected_closing_date).format('YYYY-MM-DD') : '')
        formData.append('deal_source', deal?.deal_source)
        if (type !== 'select') {
            for (var k = 0; k < selectedProductIds.length; k++) {
                formData.append('product_id[]', selectedProductIds[k])
            }
        }

        if (type === 'file') {
            for (var i = 0; i < value.length; i++) {
                formData.append(name + '[]', value[i])
            }
        } else if (type === 'select') {
            for (var j = 0; j < value.length; j++) {
                formData.append(name + '[]', value[j])
            }
        } else {
            formData.append([name], value === 'N/A' ? '' : value)
        }

        //get value from passing key on obj
        function getValueByKey(recordList, key) {
            return recordList[key];
        }

        // Check if new value and existing value are change than call api
        const newValue = getValueByKey(deal, name);

        if (newValue !== value && value !== 'N/A') {
            await axiosInstance.post("api/deal/update", formData).then(response => {
                if (response.status === 200) {
                    setReloadPage(reloadPage ? false : true)
                    showSnackbar(response.data.message, "success");
                    setAllowEditInline(true)
                }
            }).then(json => {

            }).catch(error => {
                if (error.response) {
                    if (error.response.status === 422 || error.response.data.validation_errors) {
                        setAllowEditInline(true)
                        const errors = error.response.data.validation_errors;
                        Object.keys(errors).forEach(key => {
                            setBackendErrorMessages({ [key]: errors[key] });
                        });
                    } else {
                        showSnackbar(error.response.data.message, 'error')
                    }
                }
            });
        } else {
            setAllowEditInline(true)
        }
    }
    //======End::Write a code for default data for editInline==========//

    const [dealContextMenuAnchorEl, setDealContextMenuAnchorEl] = React.useState(null);
    const dealContextMenuOpen = Boolean(dealContextMenuAnchorEl);
    const handleDealContextMenuClick = (event) => {
        setDealContextMenuAnchorEl(event.currentTarget);
    };
    const handleDealContextMenuClose = () => {
        setDealContextMenuAnchorEl(null);
    };

    //=====Start::Write a code for handle deal as close won Or Lost=====//
    const [closedWonDialogOpen, setClosedWonDialogOpen] = React.useState(false);
    const openClosedWonDialog = (type) => {
        const stageType = type === 'Won' ? 'Closed Won' : type === 'Lost' ? 'Closed Lost' : '';
        //setStageid for open popup
        const selectedDealStageId = dealStages && dealStages.find(stage => stage.name === stageType);
        if (selectedDealStageId) {
            setStageId(selectedDealStageId.id);
        }

        setCloseDealType(type)
        setClosedWonDialogOpen(true);
    }
    const closeClosedWonDialog = () => {
        setCloseDealType()
        setClosedWonDialogOpen(false);
    }
    //=====End::Write a code for handle deal as close won Or Lost=====//


    const [cloneDealDialogOpen, setCloneDealDialogOpen] = React.useState(false);
    const openCloneDealDialog = () => {
        setCloneDealDialogOpen(true);
    }
    const closeCloneDealDialog = () => {
        setCloneDealDialogOpen(false);
    }
    return (
        <>
            <Helmet>
                <title>Deal Details | {capitalize(props?.name || 'SolvCRM')}</title>
            </Helmet>
            {
                deal && deal?.created_at &&
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <div className="card p-16">
                                <Grid container alignItems={"center"} flexWrap={"nowrap"}>
                                    <Grid item xs={'auto'} className="flex-shrink-1">
                                        <Typography variant='title-medium-bold' component={'h6'}>{capitalize(deal?.deal_name || 'N/A')}</Typography>
                                        <ul className='inline-details'>
                                            <li><span className="bold">{deal?.deal_amount ? displayCurrencySymbol(deal?.deal_amount, currencyCode, currencySymbol, currencyStringValue) : 'N/A'}</span></li>
                                            <li>
                                                <Tooltip title="Closing Date">
                                                    <span>
                                                        <Icon path={mdiCalendarCheckOutline} color="currentColor" size={1} />{deal?.closing_date
                                                            ? displayDate(moment.utc(deal.closing_date).tz(preTimeZoneKey).format(preferredDateFormat), 'half')
                                                            : "N/A"}
                                                    </span>
                                                </Tooltip>
                                            </li>
                                            {/* <li>
                                            <Tooltip title="Contact Person">
                                                <span>
                                                    <Icon path={mdiBriefcaseAccountOutline} color="currentColor" size={1} />{deal?.ContactData?.Salutation || ''}{deal?.ContactData?.first_name ? capitalize(deal.ContactData.first_name) : ''} {deal?.ContactData?.last_name}
                                                </span>
                                            </Tooltip>
                                        </li> */}
                                            <li>
                                                <div className="edit-lead-deal-status">
                                                    <span className="status-badge warning">{displayDealStage}</span>
                                                    <Tooltip title="Change Current Lead Status">
                                                        <button type="button" onClick={handleEditCurrentStatusClick}>
                                                            <Icon path={mdiPencilOutline} color="currentColor" size={1} />
                                                        </button>
                                                    </Tooltip>
                                                    <Popover
                                                        open={editCurrentStatusOpen}
                                                        anchorEl={editCurrentStatusAnchorEl}
                                                        onClose={handleEditCurrentStatusClose}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'center',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'center'
                                                        }}
                                                        classes={{
                                                            paper: 'utility-menu'
                                                        }}
                                                    >
                                                        <Typography variant="body-large-bold" component={'p'} className="mb-16">Change Deal Stage</Typography>
                                                        <TextField
                                                            select
                                                            label="Deal Stage"
                                                            value={defaultStageId}
                                                            name="stage_id"
                                                            variant="filled"
                                                            onChange={(e) => handleDealStageChange(e)}
                                                        >
                                                            {
                                                                dealStages && dealStages.length > 0 ?
                                                                    dealStages.map((stage, index) => (
                                                                        <MenuItem key={index} value={stage.id}>{stage.name}</MenuItem>
                                                                    )) :
                                                                    <MenuItem value={-1}></MenuItem>
                                                            }
                                                        </TextField>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={6}>
                                                                <Button size="small" className="btn-block" onClick={() => handleDealStageSubmit()}>Save</Button>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Button size="small" color="secondary" className="btn-block" onClick={handleEditCurrentStatusClose}>Cancel</Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Popover>

                                                </div>
                                            </li>
                                        </ul>
                                    </Grid>

                                    <Grid item xs={'auto'} sx={{ marginLeft: 'auto' }}>
                                        <Stack direction={"row"} spacing={1} sx={{ marginRight: 0.5 }}>
                                            <Button size={"small"} onClick={() => openClosedWonDialog('Won')}>
                                                <span>
                                                    <Icon path={mdiThumbUpOutline} color={'currentColor'} size={1} />
                                                    <span>Won</span>
                                                </span>
                                            </Button>
                                            <Button size={"small"} color="secondary" onClick={() => openClosedWonDialog('Lost')}>
                                                <span>
                                                    <Icon path={mdiThumbDownOutline} color={'currentColor'} size={1} />
                                                    <span>Lost</span>
                                                </span>
                                            </Button>
                                            <Tooltip title="More">
                                                <Button color={'secondary'} className="icon-only" size={'small'} onClick={handleDealContextMenuClick}>
                                                    <Icon path={mdiDotsVertical} color={'currentColor'} size={1} />
                                                </Button>
                                            </Tooltip>
                                            <Menu
                                                anchorEl={dealContextMenuAnchorEl}
                                                open={dealContextMenuOpen}
                                                onClose={handleDealContextMenuClose}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right'
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right'
                                                }}
                                            >
                                                {userPermissions && userPermissions.includes("deal_create") &&
                                                    <MenuItem onClick={e => {
                                                        handleDealContextMenuClose();
                                                        openCloneDealDialog();
                                                    }}>
                                                        <Icon path={mdiContentCopy} color={'currentColor'} size={1} />
                                                        <span>Clone Deal</span>
                                                    </MenuItem>
                                                }
                                                {userPermissions && userPermissions.includes("deal_delete") &&
                                                    <MenuItem onClick={e => {
                                                        deleteData(id); handleDealContextMenuClose();
                                                    }}>
                                                        <Icon path={mdiTrashCanOutline} color={'currentColor'} size={1} />
                                                        <span>Delete</span>
                                                    </MenuItem>
                                                }
                                            </Menu>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>

                        <Grid item xxl={8} xs={12}>
                            <div className="card p-16 height-100p">
                                <Typography variant="title-medium-large-semibold" component={'h4'} className="mb-16">Deal Details</Typography>

                                <Grid container spacing={1.5}>
                                    <Grid item lg={4} xs={6}>
                                        <EasyEdit
                                            type={Types.TEXT}
                                            onSave={(value) => { handleSaveData(value, 'deal_name') }}
                                            onCancel={() => { onCancelEdit('deal_name') }}
                                            value={deal?.deal_name || 'N/A'}
                                            allowEdit={allowEditInline}
                                            editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Deal Name" value={deal?.deal_name} errorMessages={errorMessages['deal_name']} maxLength={50} />}
                                            displayComponent={<FormGroupStatic easyEdit label="Deal Name" value={deal?.deal_name || 'N/A'} />}
                                            onValidate={(value) => handleValidation(value, 'deal_name', 'Deal Name', '', true)}
                                            validationMessage={errorMessages['deal_name']}
                                            saveButtonLabel={<EasyEditSaveButton />}
                                            cancelButtonLabel={<EasyEditCancelButton />}
                                            onFocus={() => handleClick('')}
                                        />
                                        {backendErrorMessages['deal_name'] && <span className="easy-edit-validation-error">{backendErrorMessages['deal_name']}</span>}
                                    </Grid>
                                    <Grid item lg={4} xs={6}>
                                        <EasyEdit
                                            type={Types.SELECT}
                                            onSave={(value) => { handleSaveData(value, 'deal_source') }}
                                            onCancel={() => { onCancelEdit() }}
                                            value={deal?.deal_source || 'N/A'}
                                            allowEdit={allowEditInline}
                                            editComponent={
                                                <EasyEditCustomSelect focusCallback={e => { setAllowEditInline(false); }} label="Source" fieldValue={deal?.deal_source || ''}
                                                    items={
                                                        dealSourceArray && dealSourceArray.length > 0 ?
                                                            dealSourceArray.map((source) => (
                                                                { value: source.title, option: source.title }
                                                            )) :
                                                            { value: '-1', option: '' }
                                                    }
                                                />
                                            }
                                            displayComponent={<FormGroupStatic easyEdit label="Source" value={deal?.deal_source || 'N/A'} />}
                                            saveButtonLabel={<EasyEditSaveButton />}
                                            cancelButtonLabel={<EasyEditCancelButton />}
                                            onFocus={() => handleClick('')}
                                        />
                                        {backendErrorMessages['deal_source'] && <span className="easy-edit-validation-error">{backendErrorMessages['deal_source']}</span>}
                                    </Grid>
                                    <Grid item lg={4} xs={6}>
                                        <EasyEdit
                                            type={Types.TEXT}
                                            onSave={(value) => { handleSaveData(value, 'deal_amount') }}
                                            onCancel={() => { onCancelEdit('deal_amount') }}
                                            value={deal?.deal_amount || 'N/A'}
                                            allowEdit={allowEditInline}
                                            editComponent={<EasyEditCustomAmountTextbox setAllowEditInline={setAllowEditInline} name='deal_amount' label="Deal Amount" value={deal?.deal_amount} errorMessages={errorMessages['deal_amount']} maxLength={50} currencySymbol={currencySymbol} currencyCode={currencyCode} currencyStringValue={currencyStringValue} />}
                                            displayComponent={<FormGroupStaticAmount easyEdit label="Deal Amount" value={deal?.deal_amount || 'N/A'} currencyCode={currencyCode} currencySymbol={currencySymbol} currencyStringValue={currencyStringValue} />}
                                            //onValidate={(value) => handleValidation(value, 'deal_amount', 'Deal Amount')}
                                            //validationMessage={errorMessages['deal_amount']}
                                            saveButtonLabel={<EasyEditSaveButton />}
                                            cancelButtonLabel={<EasyEditCancelButton />}
                                            onFocus={() => handleClick('')}
                                        />
                                        {backendErrorMessages['deal_amount'] && <span className="easy-edit-validation-error">{backendErrorMessages['deal_amount']}</span>}
                                    </Grid>

                                    <Grid item lg={4} xs={6}>
                                        <EasyEdit
                                            type={Types.TEXT}
                                            onSave={(value) => { handleSaveData(value, 'deal_probability'); setAllowEditInline(true) }}
                                            onCancel={() => { onCancelEdit('deal_probability') }}
                                            value={deal?.deal_probability || 'N/A'}
                                            allowEdit={allowEditInline}
                                            editComponent={<EasyEditCustomAmountTextbox setAllowEditInline={setAllowEditInline} name='deal_amount' label="Deal Probability" value={deal?.deal_probability} errorMessages={errorMessages['deal_probability']} />}
                                            displayComponent={<FormGroupStaticAmount easyEdit label="Deal Probability" value={deal?.deal_probability || 'N/A'} />}
                                            onValidate={(value) => handleValidation(value, 'deal_probability', 'Deal Probability')}
                                            validationMessage={errorMessages['deal_probability']}
                                            saveButtonLabel={<EasyEditSaveButton />}
                                            cancelButtonLabel={<EasyEditCancelButton />}
                                            onFocus={() => handleClick('')}
                                        />
                                        {backendErrorMessages['deal_probability'] && <span className="easy-edit-validation-error">{backendErrorMessages['deal_probability']}</span>}
                                    </Grid>
                                    <Grid item lg={4} xs={6}>
                                        {/* <EasyEdit
                                            type={Types.TEXT}
                                            onSave={(value) => { handleSaveData(value, 'expected_closing_date'); }}
                                            onCancel={() => { onCancelEdit('expected_closing_date') }}
                                            value={deal?.expected_closing_date || 'N/A'}
                                            allowEdit={allowEditInline}
                                            editComponent={
                                                <EasyEditCustomDatePicker
                                                    setAllowEditInline={setAllowEditInline}
                                                    label="Expected Closing Date"
                                                    value={deal?.expected_closing_date}
                                                    errorMessages={errorMessages['expected_closing_date']}
                                                    preferredDateFormat={preferredDateFormat}
                                                    setParentValue={(value) => {
                                                        handleSaveData(value, 'expected_closing_date');
                                                    }}
                                                />}
                                            displayComponent={<FormGroupStaticAmount easyEdit label="Expected Closing Date" value={deal?.expected_closing_date || null} preTimeZoneKey={preTimeZoneKey} preferredDateFormat={preferredDateFormat} />}
                                            saveButtonLabel={<EasyEditSaveButton />}
                                            cancelButtonLabel={<EasyEditCancelButton />}
                                            onFocus={() => handleClick('')}
                                        /> */}
                                        <EasyEdit
                                            type={Types.TEXT}
                                            onSave={(value) => {
                                                handleSaveData(value ? moment(value).format("YYYY-MM-DD")
                                                    : "",
                                                    'expected_closing_date');
                                            }}
                                            onCancel={() => { onCancelEdit('expected_closing_date') }}
                                            value={deal?.expected_closing_date ? moment(deal?.expected_closing_date).format("YYYY-MM-DD") : 'N/A'}
                                            allowEdit={allowEditInline}
                                            editComponent={
                                                <EasyEditCustomDatePicker
                                                    setAllowEditInline={setAllowEditInline}
                                                    label="Expected Closing Date"
                                                    value={deal?.expected_closing_date || null}
                                                    errorMessages={errorMessages['expected_closing_date']}
                                                    preferredDateFormat={preferredDateFormat}
                                                />}
                                            displayComponent={
                                                <FormGroupStaticAmount easyEdit
                                                    label="Expected Closing Date"
                                                    value={deal?.expected_closing_date || 'N/A'}
                                                    preTimeZoneKey={preTimeZoneKey}
                                                    preferredDateFormat={preferredDateFormat}
                                                />}
                                            saveButtonLabel={<EasyEditSaveButton />}
                                            cancelButtonLabel={<EasyEditCancelButton />}
                                            onFocus={() => handleClick('')}
                                        />
                                        {backendErrorMessages['expected_closing_date'] && <span className="easy-edit-validation-error">{backendErrorMessages['expected_closing_date']}</span>}
                                    </Grid>
                                    {
                                        displayDealStage && displayDealStage == 'Closed Lost' && deal?.note &&
                                        <Grid item lg={4} xs={6}>
                                            <FormGroupStatic easyEdit label="Lost Reason" value={deal?.note} />
                                        </Grid>
                                    }

                                    <Grid item xs={12}>
                                        <EasyEdit
                                            type={Types.SELECT}
                                            onSave={(value) => { handleSaveData(value, 'product_id', 'select') }}
                                            onCancel={() => { onCancelEdit() }}
                                            value={selectedData?.ProductName?.join(', ') || 'N/A'}
                                            allowEdit={allowEditInline}
                                            editComponent={
                                                <EasyEditCustomMultiSelect setAllowEditInline={setAllowEditInline} isMultiSelect={true} label="Product" fieldValue={selectedProductIds || []}
                                                    items={
                                                        productList && productList.length > 0 && productList.map((product) => (
                                                            { key: product.id, value: product.name }
                                                        ))
                                                    }
                                                />
                                            }
                                            displayComponent={<FormGroupStatic easyEdit label="Product" value={selectedData?.ProductName?.join(', ')} />}
                                            saveButtonLabel={<EasyEditSaveButton />}
                                            cancelButtonLabel={<EasyEditCancelButton />}
                                            onFocus={() => handleClick('')}
                                        />
                                        {backendErrorMessages['product_id'] && <span className="easy-edit-validation-error">{backendErrorMessages['product_id']}</span>}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <EasyEdit
                                            type={Types.TEXT}
                                            onSave={(value) => { handleSaveData(value, 'deal_description') }}
                                            onCancel={() => { onCancelEdit('deal_description') }}
                                            value={deal?.deal_description || 'N/A'}
                                            allowEdit={allowEditInline}
                                            editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Description" value={deal?.deal_description} errorMessages={errorMessages['deal_description']} maxLength={150} />}
                                            displayComponent={<FormGroupStatic easyEdit label="Description" value={deal?.deal_description || 'N/A'} />}
                                            onValidate={(value) => handleValidation(value, 'deal_description', 'Description', '', false, '2', '150')}
                                            validationMessage={errorMessages['deal_description']}
                                            saveButtonLabel={<EasyEditSaveButton />}
                                            cancelButtonLabel={<EasyEditCancelButton />}
                                            onFocus={() => handleClick('')}
                                        />
                                        {backendErrorMessages['description'] && <span className="easy-edit-validation-error">{backendErrorMessages['description']}</span>}
                                    </Grid>
                                    <Grid item lg={4} xs={6}>
                                        <FormGroupStatic easyEdit label="Created by" value={deal?.UserName?.name + ' (' + moment.utc(deal.created_at).tz(preTimeZoneKey).format(preferredDateFormat) + ')'} />
                                    </Grid>
                                    <Grid item lg={4} xs={6}>
                                        <FormGroupStatic easyEdit label="Modified by" value={deal?.updated_by ? deal?.updatedUserName?.name + ' (' + moment.utc(deal.updated_at).tz(preTimeZoneKey).format(preferredDateFormat) + ')' : 'N/A'} />
                                    </Grid>
                                </Grid>

                                <hr className="hr my-16" />

                                <Typography variant="title-medium-large-semibold" component={'h4'} className="mb-16">Contact Information</Typography>
                                <Grid container spacing={1.5}>
                                    <Grid item lg={4} xs={6}>
                                        <EasyEdit
                                            type={Types.SELECT}
                                            onSave={(value) => { handleSaveData(value, 'contact_id') }}
                                            onCancel={() => { onCancelEdit() }}
                                            value={selectedContact || 'N/A'}
                                            allowEdit={allowEditInline}
                                            editComponent={
                                                <EasyEditCustomSelect focusCallback={e => { setAllowEditInline(false); }} label="Contact" fieldValue={deal?.contact_id || ''}
                                                    items={
                                                        contactList && contactList.length > 0 ?
                                                            contactList.map((contact) => {
                                                                var salutations = contact?.salutation || ''
                                                                if (salutations) {
                                                                    salutations = salutations + '. '
                                                                }
                                                                return (
                                                                    { value: contact.id, option: salutations + contact?.first_name + ' ' + contact?.last_name }
                                                                )

                                                            }) :
                                                            { value: '-1', option: '' }
                                                    }
                                                />
                                            }
                                            displayComponent={<FormGroupStatic easyEdit label="Contact" value={selectedContact || 'N/A'} />}
                                            saveButtonLabel={<EasyEditSaveButton />}
                                            cancelButtonLabel={<EasyEditCancelButton />}
                                            onFocus={() => handleClick('')}
                                        />
                                        {backendErrorMessages['contact_id'] && <span className="easy-edit-validation-error">{backendErrorMessages['contact_id']}</span>}
                                    </Grid>
                                    <Grid item lg={4} xs={6}>
                                        <EasyEdit
                                            type={Types.TEXT}
                                            onSave={(value) => { handleSaveData(value, 'organization_name') }}
                                            onCancel={() => { onCancelEdit('organization_name') }}
                                            value={dealAccountData?.organization_name || 'N/A'}
                                            allowEdit={allowEditInline}
                                            editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Company Name" value={dealAccountData?.organization_name} errorMessages={errorMessages['organization_name']} maxLength={50} />}
                                            displayComponent={<FormGroupStatic easyEdit label="Company Name" value={dealAccountData?.organization_name || 'N/A'} />}
                                            onValidate={(value) => handleValidation(value, 'organization_name', 'Company Name')}
                                            validationMessage={errorMessages['organization_name']}
                                            saveButtonLabel={<EasyEditSaveButton />}
                                            cancelButtonLabel={<EasyEditCancelButton />}
                                            onFocus={() => handleClick('')}
                                        />
                                        {backendErrorMessages['organization_name'] && <span className="easy-edit-validation-error">{backendErrorMessages['organization_name']}</span>}
                                    </Grid>
                                    <Grid item lg={4} xs={6}>
                                        <EasyEdit
                                            type={Types.TEXT}
                                            onSave={(value) => { handleSaveData(value, 'organization_email') }}
                                            onCancel={() => { onCancelEdit('organization_email') }}
                                            value={dealAccountData?.organization_email || 'N/A'}
                                            allowEdit={allowEditInline}
                                            editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Company Email" value={dealAccountData?.organization_email} errorMessages={errorMessages['organization_email']} maxLength={50} />}
                                            displayComponent={<FormGroupStatic easyEdit label="Company Email" element={
                                                dealAccountData?.organization_email ? <a href={"mailto:" + dealAccountData?.organization_email} className="primary-link">{dealAccountData?.organization_email || 'N/A'}</a> : 'N/A'
                                            } />}
                                            onValidate={(value) => handleValidation(value, 'organization_email', 'Company Email', 'isEmail', false)}
                                            validationMessage={errorMessages['organization_email']}
                                            saveButtonLabel={<EasyEditSaveButton />}
                                            cancelButtonLabel={<EasyEditCancelButton />}
                                            onFocus={() => handleClick('')}
                                        />
                                        {backendErrorMessages['organization_email'] && <span className="easy-edit-validation-error">{backendErrorMessages['organization_email']}</span>}
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>


                        <Grid item xxl={4} xs={12}>
                            {
                                deal && (
                                    <Suspense fallback={<div>Loading...</div>}>
                                        <ActivityList handleReloadFunc={handleReloadFunc} type='deal' dataId={dealId} userPermissions={userPermissions} />
                                    </Suspense>
                                )
                            }
                        </Grid>

                        {userPermissions && userPermissions.includes("note_list") &&
                            <Grid item xs={12}>
                                {
                                    deal && (
                                        <Suspense fallback={<div>Loading...</div>}>
                                            <NoteList handleReloadFunc={handleReloadFunc} type='deal' dataId={dealId} userPermissions={userPermissions} />
                                        </Suspense>
                                    )
                                }
                            </Grid>
                        }
                        {userPermissions && userPermissions.includes("contact_list") &&
                            <Grid item xs={12}>
                                {dealId &&
                                    <Suspense fallback={<div>Loading...</div>}>
                                        <DealContactList
                                            dealId={dealId}
                                            handleReloadParentFunc={handleReloadFunc}
                                            userPermissions={userPermissions}
                                        />
                                    </Suspense>
                                }
                            </Grid>
                        }
                        {userPermissions && userPermissions.includes("quote_list") &&
                            <Grid item xs={12}>
                                {dealId &&
                                    <Suspense fallback={<div>Loading...</div>}>
                                        <DealQuoteList
                                            dealId={dealId}
                                        />
                                    </Suspense>
                                }
                            </Grid>
                        }
                    </Grid >

                    {
                        closedWonDialogOpen && (
                            <Suspense fallback={<div>Loading...</div>}>
                                <ClosedWonDealDialog
                                    open={closedWonDialogOpen}
                                    onClose={closeClosedWonDialog}
                                    handleReloadFunc={handleReloadFunc}
                                    dealData={deal}
                                    dealType={closeDealType}
                                    accountData={dealAccountData}
                                    stageId={stageId}
                                />
                            </Suspense>
                        )
                    }

                    {
                        cloneDealDialogOpen && (
                            <Suspense fallback={<div>Loading...</div>}>
                                <CloneDealDialog
                                    open={cloneDealDialogOpen}
                                    onClose={closeCloneDealDialog}
                                    handleReloadFunc={handleReloadFunc}
                                    dealData={deal}
                                    sources={dealSourceArray}
                                    productList={productList}
                                    selectedProductIds={selectedProductIds}
                                />
                            </Suspense>
                        )
                    }
                </>
            }


        </>
    );
}

export default DealDetails;