import { mdiCheckCircleOutline, mdiHelpCircleOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { Popover, Typography } from "@mui/material";
import React from "react";

function PasswordStrength(props) {
    const [currentStrength, setCurrentStrength] = React.useState('');
    const [currentStrengthClass, setCurrentStrengthClass] = React.useState('');
    const [lengthPass, setLengthPass] = React.useState(false);
    const [uppercasePass, setUppercasePass] = React.useState(false);
    const [lowercasePass, setLowercasePass] = React.useState(false);
    const [digitPass, setDigitPass] = React.useState(false);
    const [specialCharacterPass, setSpecialCharacterPass] = React.useState(false);

    const calculatePasswordStrength = (password) => {
        setCurrentStrength("Very Weak");
        setCurrentStrengthClass("very-weak");

        if (password.length >= 6) {
            setCurrentStrength("Very Weak");
            setCurrentStrengthClass('very-weak');
        }
        if (password.length >= 8 && /[a-z]/.test(password)) {
            setCurrentStrength("Weak");
            setCurrentStrengthClass('weak');
        }
        if (password.length >= 10 && /[a-z]/.test(password) && /[A-Z]/.test(password)) {
            setCurrentStrength("Medium");
            setCurrentStrengthClass('medium');
        }
        if (password.length >= 12 && /[a-z]/.test(password) && /[A-Z]/.test(password) && /\d/.test(password)) {
            setCurrentStrength("Strong");
            setCurrentStrengthClass('strong');
        }
        if (password.length >= 12 && /[a-z]/.test(password) && /[A-Z]/.test(password) && /\d/.test(password) && /[!@#$%^&*]/.test(password)) {
            setCurrentStrength("Very Strong");
            setCurrentStrengthClass('very-strong');
        }
        setLengthPass(password.length >= 8);
        setUppercasePass(/[A-Z]/.test(password));
        setLowercasePass(/[a-z]/.test(password));
        setDigitPass(/\d/.test(password));
        setSpecialCharacterPass(/[!@#$%^&*]/.test(password));
    };

    React.useEffect(() => {
        calculatePasswordStrength(props.password);
    }, [props.password]);

    const [passwordStrengthMenuAnchorEl, setpasswordStrengthMenuAnchorEl] = React.useState(null);

    const handlepasswordStrengthMenuOpen = (event) => {
        setpasswordStrengthMenuAnchorEl(event.currentTarget);
    };

    const handlepasswordStrengthMenuClose = () => {
        setpasswordStrengthMenuAnchorEl(null);
    };

    const passwordStrengthMenuOpen = Boolean(passwordStrengthMenuAnchorEl);

    return (
        <>
            {props.password !== '' && <div className={"password-strength " + currentStrengthClass}>
                <Typography variant='label-medium-semibold' component={'p'}>{currentStrength}</Typography>
                <button type="button" className="help-btn" onMouseEnter={handlepasswordStrengthMenuOpen} onMouseLeave={handlepasswordStrengthMenuClose}>
                    <Icon path={mdiHelpCircleOutline} color={'currentColor'} size={1} />
                </button>
            </div>}

            <Popover
                open={passwordStrengthMenuOpen}
                anchorEl={passwordStrengthMenuAnchorEl}
                onClose={handlepasswordStrengthMenuClose}
                sx={{ pointerEvents: 'none' }}
                disableRestoreFocus
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                classes={{
                    paper: 'password-strength-menu'
                }}
            >
                <ul className="strength-list">
                    <li className={lengthPass ? 'active' : ''}>
                        <Icon path={mdiCheckCircleOutline} color={'currentColor'} size={1} />
                        <span>Minimum of 8 characters</span>
                    </li>
                    <li className={uppercasePass ? 'active' : ''}>
                        <Icon path={mdiCheckCircleOutline} color={'currentColor'} size={1} />
                        <span>Include at least one uppercase letter (A-Z)</span>
                    </li>
                    <li className={lowercasePass ? 'active' : ''}>
                        <Icon path={mdiCheckCircleOutline} color={'currentColor'} size={1} />
                        <span>Include at least one lowercase letter (a-z)</span>
                    </li>
                    <li className={digitPass ? 'active' : ''}>
                        <Icon path={mdiCheckCircleOutline} color={'currentColor'} size={1} />
                        <span>Include at least one number (0-9)</span>
                    </li>
                    <li className={specialCharacterPass ? 'active' : ''}>
                        <Icon path={mdiCheckCircleOutline} color={'currentColor'} size={1} />
                        <span>Include at least one special character</span>
                    </li>
                </ul>
            </Popover>
        </>
    );
}

export default PasswordStrength;