import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { mdiAccountOutline, mdiBriefcaseAccountOutline, mdiCalendarCheckOutline, mdiClose, mdiDomain, mdiDotsVertical, mdiExportVariant, mdiHistory, mdiMagnify, mdiPencilOutline, mdiPlus, mdiTrashCanOutline } from "@mdi/js";
import { Button, Checkbox, Grid, IconButton, InputAdornment, Menu, MenuItem, Stack, TablePagination, TextField, Tooltip, Typography } from "@mui/material";
import Icon from "@mdi/react";
import { useSnackbar } from "../../components/context/SnackbarContext";
import useAxios from "../../hooks/useAxios";
import { useLoader } from "../../components/context/LoaderContext";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import ExportData from "../../components/ExportData";
import { capitalize, DeleteDialog, DeleteDialogConfig, displayCurrencySymbol } from "../../utils/Utils";
import EmptyState from "../../components/common/EmptyState";

function Quotes(props) {
    const navigate = useNavigate();
    const axiosInstance = useAxios();
    const { showSnackbar } = useSnackbar();
    const { showLoader, hideLoader, loading } = useLoader();
    const { preferredDateFormat, preTimeZoneKey, currencyCode, currencySymbol, currencyStringValue } = useSelector((state) => state?.user);
    const userPermissions = useSelector((state) => state.user.permission);

    //add for handle apply button
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(
        parseInt(process.env.REACT_APP_DEFAULT_PAGINATION)
    );
    const [totalCount, setTotalCount] = useState(0);
    const sortOrder = process.env.REACT_APP_DEFAULT_SORT
    const sortBy = "created_at";

    // State management for search fields
    const [search, setSearch] = useState("");
    const [searchQuoteStage, setSearchQuoteStage] = useState('All Stages');

    //handle checkbox
    const [checkedItems, setCheckedItems] = useState({});
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [reloadPage, setReloadPage] = useState(false);

    const [quoteStageList, setQuoteStageList] = useState([]);
    const [quoteList, setQuoteList] = useState([]);
    const [clearType, setClearType] = useState(false);


    useEffect(() => {
        async function fetchData() {
            showLoader();
            await getData(
                currentPage,
                perPage,
                sortBy,
                sortOrder,
                search,
                searchQuoteStage,
            );
        }
        fetchData();
    }, [
        currentPage,
        perPage,
        sortBy,
        sortOrder,
        searchQuoteStage,
        reloadPage,
        clearType,
    ]);

    const getData = async (
        currentPage,
        perPage,
        sortBy,
        sortOrder,
        search,
        search_quote_stage,
    ) => {
        const params = {
            search: search,
            search_quote_stage: search_quote_stage === "All Stages" ? "" : searchQuoteStage,
            sort_field: sortBy,
            sort_order: sortOrder,
            per_page: perPage,
        };

        await axiosInstance.get(`api/quote?page=${currentPage + 1}`, { params }).then((response) => {
            if (response.status === 200) {
                setQuoteList(response?.data?.data?.records?.data);
                setTotalCount(response?.data?.data?.records?.total);
                setQuoteStageList(response?.data?.data?.quoteStage || []);
                //setUserLists(response?.data?.data?.users || [])
                hideLoader();
                setCheckedItems({});
                setIsCheckAll(false);
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                        navigate("/dashboard");
                    }
                }
            });
    };


    //=====Start::Write a code for handle headeMenu option ======//
    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleRowsPerPageChange = (event) => {
        setPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault(); // Prevent the default form submission behavior
            getData(
                currentPage,
                perPage,
                sortBy,
                sortOrder,
                search,
                searchQuoteStage,
            );
        }
    };

    const HandleStageSource = (event) => {
        setSearchQuoteStage(event.target.value);
    };

    const handleCheckAll = (event) => {
        const { checked } = event.target;
        setIsCheckAll(checked);
        const newCheckedItems = {};
        quoteList.forEach((deal) => {
            newCheckedItems[deal.id] = checked;
        });
        setCheckedItems(newCheckedItems);
    };

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setCheckedItems({
            ...checkedItems,
            [name]: checked,
        });

        if (!checked) {
            setIsCheckAll(false);
        } else {
            const allChecked = quoteList.every(
                (deal) => checkedItems[deal.id] || deal.name === name
            );
            setIsCheckAll(allChecked);
        }
    };


    // Get list of checked item IDs
    const getCheckedIds = () => {
        return Object.keys(checkedItems).filter((id) => checkedItems[id]);
    };

    // Handle single delete record
    async function deleteData(id) {
        const data = {
            id: id,
        }
        try {
            DeleteDialog.fire({
                ...DeleteDialogConfig({
                    confirm: async () => {
                        showLoader()
                        await axiosInstance.delete(`api/quote/destroy`, { data })
                            .then((response) => {
                                if (response.status === 200) {
                                    showSnackbar(response.data.message, "success");
                                    setCheckedItems({});
                                    setIsCheckAll(false);
                                    hideLoader();
                                    setReloadPage(reloadPage ? false : true);
                                }
                            })
                            .then((json) => { })
                            .catch((error) => {
                                if (error.response) {
                                    hideLoader();
                                    if (error.response.status === 400) {
                                        showSnackbar(error.response.data.message, "warning");
                                        navigate("/quotes");
                                    }
                                }
                            });
                    },
                    cancel: () => {
                        hideLoader();
                        setCheckedItems({});
                        setIsCheckAll(false);
                    }
                })
            });
        } catch (error) {
            hideLoader();
            showSnackbar('Oops Something want wrong..!', "error");
        }
    }

    // Handle multiple delete records
    async function handleBulkDelete() {
        const checkedIds = getCheckedIds();
        if (checkedIds && checkedIds.length > 0) {
            bulkDeleteData(checkedIds)
        } else {
            showSnackbar('Please select at least one record to Delete.', 'warning')
        }
    }

    // Handle multiple delete records
    const bulkDeleteData = async (ids) => {
        const data = {
            id: ids,
        }

        try {
            DeleteDialog.fire({
                ...DeleteDialogConfig({
                    confirm: async () => {
                        showLoader()
                        await axiosInstance.delete('api/quote/bulk-destroy', { data })
                            .then((response) => {
                                if (response.status === 200) {
                                    showSnackbar(response.data.message, "success");
                                    setCheckedItems({});
                                    setIsCheckAll(false);
                                    hideLoader();
                                    setReloadPage(reloadPage ? false : true);
                                }
                            })
                            .then((json) => { })
                            .catch((error) => {
                                if (error.response) {
                                    hideLoader();
                                    if (error.response.status === 400) {
                                        showSnackbar(error.response.data.message, "warning");
                                        navigate("/quotes");
                                    }
                                }
                            });
                    },
                    cancel: () => {
                        hideLoader();
                        setCheckedItems({});
                        setIsCheckAll(false);
                    }
                })
            });
        } catch (error) {
            showSnackbar('Oops Something want wrong..!', "error");
        }
    }
    //=====End::Write a code for handle headeMenu option ======//


    //======Start:Write a Export data=====//
    const checkedIds = getCheckedIds();
    const expParams = {
        search: search,
        search_quote_stage: searchQuoteStage === "All Stages" ? "" : searchQuoteStage,
        sort_field: sortBy,
        sort_order: sortOrder,
        per_page: perPage,
        selectedIds: checkedIds && checkedIds.length > 0 ? checkedIds : []
    };
    const handleReloadFunc = () => {
        setCheckedItems({});
        setIsCheckAll(false);
        hideLoader();
        setReloadPage(reloadPage ? false : true);
    }
    //======End:Write a Export data=====//

    const [quotePageContextMenuAnchorEl, setQuotePageContextMenuAnchorEl] = React.useState(null);
    const quotePageContextMenuOpen = Boolean(quotePageContextMenuAnchorEl);
    const handleQuotePageContextMenuClick = (event) => {
        setQuotePageContextMenuAnchorEl(event.currentTarget);
    };
    const handleQuotePageContextMenuClose = () => {
        setQuotePageContextMenuAnchorEl(null);
    };

    const clearUserFilter = (type) => {
        setClearType(clearType ? false : true)
        if (type === 'search') {
            setSearch('');
        } else if (type === 'stage') {
            setSearchQuoteStage('All Stages');
        } else {
            setSearchQuoteStage("All Stages");
            setSearch('')
        }

    }

    return (
        <>
            <Helmet>
                <title>Quotes | {capitalize(props?.name || 'SolvCRM')}</title>
            </Helmet>


            <Grid container className='page-action-bar'>
                <Grid item xs={'auto'} sx={{ marginLeft: 'auto', order: 1 }}>
                    <Stack direction="row" spacing={1}>
                        {userPermissions && userPermissions.includes("quote_create") &&
                            <Button size="small" onClick={e => { navigate('/create-quote'); }}>
                                <span>
                                    <Icon path={mdiPlus} color="currentColor" size={1} />
                                    <span>Create New</span>
                                </span>
                            </Button>
                        }
                        <Tooltip title="More">
                            <Button variant="contained" color="secondary" size='small' className='icon-only' onClick={handleQuotePageContextMenuClick}>
                                <Icon path={mdiDotsVertical} color="currentColor" size={1} />
                            </Button>
                        </Tooltip>
                        <Menu
                            anchorEl={quotePageContextMenuAnchorEl}
                            open={quotePageContextMenuOpen}
                            onClose={handleQuotePageContextMenuClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right'
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                        >
                            {quoteList?.length > 0 &&
                                <MenuItem onClick={handleQuotePageContextMenuClose}>
                                    <Icon path={mdiExportVariant} color={'currentColor'} size={1} />
                                    <ExportData params={expParams} fileName="Quote" api="quote" handleReloadFunc={handleReloadFunc} />
                                </MenuItem>
                            }
                            {userPermissions && userPermissions.includes("quote_delete") &&
                                <MenuItem
                                    onClick={() => {
                                        handleBulkDelete(); handleQuotePageContextMenuClose();
                                    }}
                                >
                                    <Icon path={mdiTrashCanOutline} color={'currentColor'} size={1} />
                                    <span>Delete</span>
                                </MenuItem>
                            }
                        </Menu>
                    </Stack>
                </Grid>
                <Grid item xs={'auto'}>
                    <Stack direction="row" spacing={1} alignItems={'center'}>
                        <Checkbox
                            className="grid-checkbox"
                            checked={isCheckAll}
                            onChange={handleCheckAll}
                        />
                        <TextField
                            variant="filled"
                            placeholder="Search quotes"
                            name="searchData"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            onKeyDown={handleKeyDown}
                            className="size-small page-search no-margin"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Icon path={mdiMagnify} color="currentColor" size={1} />
                                        {
                                            search &&
                                            <IconButton onClick={() => clearUserFilter('search')} >
                                                <Icon path={mdiClose} color={'currentColor'} size={'20px'} />
                                            </IconButton>
                                        }

                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            select
                            //defaultValue="All Stages"
                            variant="filled"
                            value={searchQuoteStage}
                            className="size-small no-label no-margin"
                            onChange={HandleStageSource}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end' className='clear-filter'>
                                        {
                                            searchQuoteStage && searchQuoteStage !== 'All Stages' &&
                                            <IconButton onClick={() => clearUserFilter('stage')} >
                                                <Icon path={mdiClose} color={'currentColor'} size={'20px'} />
                                            </IconButton>
                                        }

                                    </InputAdornment>
                                ),
                            }}
                        >
                            <MenuItem key="all-sources" value="All Stages">
                                All Stages
                            </MenuItem>
                            {
                                quoteStageList &&
                                Object.keys(quoteStageList).map((stageKey) => (
                                    <MenuItem key={stageKey} value={quoteStageList[stageKey]}>
                                        {quoteStageList[stageKey]}
                                    </MenuItem>
                                ))
                            }
                        </TextField>
                        {
                            (search || (searchQuoteStage && searchQuoteStage !== 'All Stages')) &&
                            <a href="-" className="primary-link ml-12" onClick={e => { e.preventDefault(); clearUserFilter('all') }}><Typography variant='body-medium-medium'>Clear All</Typography></a>
                        }

                    </Stack>
                </Grid>
            </Grid>

            {quoteList && quoteList.length > 0 && (
                <ul className="primary-list">
                    {quoteList.map((quote, index) =>
                        <li key={index + ''}>
                            <Link to={userPermissions?.includes("quote_edit") ? '/quote-detail/' + quote?.id : '#'} className="card">
                                <Grid container alignItems={'flex-start'}>
                                    <Grid item xs={"auto"}>
                                        <Checkbox
                                            className="grid-checkbox"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                            }}
                                            name={String(quote.id)}
                                            checked={checkedItems[quote.id] || false}
                                            onChange={handleCheckboxChange}
                                        />
                                    </Grid>
                                    <Grid item xs>
                                        <Typography variant='title-medium-bold' component={'h6'}>{capitalize(quote?.subject || 'N/A')}</Typography>
                                        <ul className='inline-details'>
                                            <li><span className="bold">{quote?.total ? displayCurrencySymbol(quote?.total, currencyCode, currencySymbol, currencyStringValue) : 'N/A'}</span></li>
                                            <li><span className='title'>Deal:</span> {quote?.selected_deal_name || 'N/A'}</li>
                                            <li>
                                                <Tooltip title="Company Name">
                                                    <span>
                                                        <Icon path={mdiDomain} color="currentColor" size={1} />{quote?.selected_account_name || 'N/A'}
                                                    </span>
                                                </Tooltip>
                                            </li>
                                            <li>
                                                <Tooltip title="Contact Person">
                                                    <span>
                                                        <Icon path={mdiBriefcaseAccountOutline} color="currentColor" size={1} />{quote?.selected_contact_name || 'N/A'}
                                                    </span>
                                                </Tooltip>
                                            </li>
                                            <li>
                                                <Tooltip title="Created Date">
                                                    <span>
                                                        <Icon path={mdiCalendarCheckOutline} color="currentColor" size={1} />{quote?.created_at
                                                            ? moment.utc(quote?.created_at)
                                                                .tz(preTimeZoneKey)
                                                                .format(preferredDateFormat)
                                                            : "N/A"}
                                                    </span>
                                                </Tooltip>
                                            </li>
                                        </ul>
                                    </Grid>
                                </Grid>
                                <ul className='inline-details other-info no-avatar'>
                                    <li><span className='title'>Valid Until:</span> {quote?.valid_until
                                        ? moment.utc(quote?.valid_until)
                                            .tz(preTimeZoneKey)
                                            .format(preferredDateFormat)
                                        : "N/A"}</li>
                                    <li>
                                        <Tooltip title="Added by">
                                            <span>
                                                <Icon path={mdiAccountOutline} color="currentColor" size={1} /> {quote?.added_by?.name || 'N/A'}
                                            </span>
                                        </Tooltip>
                                    </li>
                                    <li>
                                        <Tooltip title="Last Updated">
                                            <span>
                                                <Icon path={mdiHistory} color="currentColor" size={1} /> {quote?.updated_at
                                                    ? moment.utc(quote?.updated_at)
                                                        .tz(preTimeZoneKey)
                                                        .format(preferredDateFormat)
                                                    : "N/A"}
                                            </span>
                                        </Tooltip>
                                    </li>
                                </ul>
                            </Link>
                            <Stack direction={'row'} className='actions'>
                                {userPermissions && userPermissions.includes("quote_edit") &&
                                    <Tooltip title="Edit">
                                        <span>
                                            <Link to={'/quote-detail/' + quote?.id} className='icon-button'>
                                                <Icon path={mdiPencilOutline} color="currentColor" size={1} />
                                            </Link>
                                        </span>
                                    </Tooltip>
                                }
                                {userPermissions && userPermissions.includes("quote_delete") &&
                                    <Tooltip title="Delete">
                                        <button type="button" className='icon-button' onClick={() => { deleteData(quote?.id) }} >
                                            <Icon path={mdiTrashCanOutline} color="currentColor" size={1} />
                                        </button>
                                    </Tooltip>
                                }
                            </Stack>
                        </li>
                    )}
                </ul>
            )}

            {(quoteList?.length == 0) && !loading &&
                <EmptyState type={'quote'} hideActionBtn />
            }

            {
                quoteList && quoteList.length > 0 &&
                <TablePagination
                    component="div"
                    count={totalCount}
                    page={currentPage}
                    rowsPerPage={perPage}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                />
            }


        </>
    );
}

export default Quotes;