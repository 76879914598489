import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

const EasyEditCustomAutoSearchSelect = (props) => {

    const [selectValue, setSelectValue] = useState(null);

    useEffect(() => {
        if (props.fieldValue && props.items) {
            const selectedItem = props.items.find(item => item.value === props.fieldValue);
            setSelectValue(selectedItem || null);
        }
    }, [props.fieldValue, props.items]);

    const handleChange = (event, newValue) => {
        setSelectValue(newValue);
        if (newValue) {
            props.setParentValue(newValue.value);
        } else {
            props.setParentValue('');
        }
    };

    return (
        <Autocomplete
            options={props.items || []}
            getOptionLabel={(option) => option.option || ''}
            value={selectValue}
            onChange={handleChange}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={props.label}
                    variant="filled"
                    placeholder={`Select ${props.label}`}
                    autoFocus
                />
            )}
        />
    );
};

export default EasyEditCustomAutoSearchSelect;
