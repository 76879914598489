import { DatePicker, LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import React, { useEffect, useState } from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { displayDate } from "../../utils/Utils";
import moment from "moment-timezone";

const EasyEditDateTimePicker = props => {
    const [selectValue, setSelectValue] = useState(
        props.value ? moment(props.value) : moment()
    );

    props.setAllowEditInline(false)

    useEffect(() => {
        if (props.value === 'N/A') {
            setSelectValue('');
        }
    }, [props.value])

    useEffect(() => {
        setSelectValue(props.value ? moment(props.value) : moment());
    }, [props.value]);

    return (

        <LocalizationProvider dateAdapter={AdapterMoment}>
            {
                <DateTimePicker
                    label="Start Date"
                    format={props?.preferredDateFormat}
                    views={["day", "hours", "minutes", "month", "year"]}
                    name="start_date"
                    slotProps={{
                        textField: {
                            variant: 'filled',
                            name: "expected_closing_date",
                            type: 'text',
                            className: "no-margin size-medium control"
                        }
                    }}
                    value={selectValue}
                    onChange={(value) => {
                        const formattedValue = value ? value.format(displayDate(props.preferredDateFormat)) : 'MM-DD-YYYY';
                        props.setParentValue(formattedValue);
                        setSelectValue(value);
                    }}
                    error={!!props.errorMessages}
                    helperText={props.errorMessages || ""}

                />
            }

        </LocalizationProvider>
    );
}

export default EasyEditDateTimePicker;