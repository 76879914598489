import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { ThemeContext } from "../../App";
import { Button, Typography } from "@mui/material";
import Icon from "@mdi/react";
import { mdiHomeOutline } from "@mdi/js";
import { useNavigate } from "react-router-dom";

function InternalServerError() {
    let { currentTheme } = useContext(ThemeContext);

    const navigate = useNavigate();

    return (
        <>
            <Helmet>
                <title>Internal Server Error</title>
            </Helmet>

            <section className="error-page">
                <img src={currentTheme?.internalServerErrorImage} alt="404" />
                <div className="content">
                    <Typography variant="headline-medium-bold" component={"h4"}>Yikes! Something’s Gone Wrong...</Typography>
                    <Typography variant="body-large-medium" component={"p"}>Looks like our system hit a snag and couldn’t process your request. Don’t worry, we’re already on it! In the meantime, you can try refreshing the page or head back to the homepage to explore other options.</Typography>
                </div>
                <Button variant="contained" onClick={e => navigate('/dashboard')}>
                    <span>
                        <Icon path={mdiHomeOutline} color={'currentColor'} size={1} />
                        <span>Go Home Safely</span>
                    </span>
                </Button>
            </section>
        </>
    );
}

export default InternalServerError;