import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { Button, Checkbox, Grid, IconButton, InputAdornment, Menu, MenuItem, Stack, TablePagination, TextField, Tooltip, Typography } from "@mui/material";
import { mdiAccountOutline, mdiBriefcaseAccountOutline, mdiCalendarWeekBeginOutline, mdiClose, mdiDotsVertical, mdiExportVariant, mdiHistory, mdiMagnify, mdiMapMarkerOutline, mdiPencilOutline, mdiTrashCanOutline } from "@mdi/js";
import Icon from "@mdi/react";
import EventDetailsDialog from "./components/EventDetailsDialog";
import useAxios from "../../hooks/useAxios";
import { useSnackbar } from "../../components/context/SnackbarContext";
import { useLoader } from "../../components/context/LoaderContext";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import ExportData from "../../components/ExportData";
import { capitalize, DeleteDialog, DeleteDialogConfig, displayDate } from "../../utils/Utils";
import EmptyState from "../../components/common/EmptyState";

function Events(props) {
    const navigate = useNavigate();
    const axiosInstance = useAxios();
    const [events, setEvents] = useState();
    const { showSnackbar } = useSnackbar();
    const { showLoader, hideLoader, loading } = useLoader();
    const [search, setSearch] = useState("");
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(parseInt(process.env.REACT_APP_DEFAULT_PAGINATION));
    const [reloadPage, setReloadPage] = useState(false);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [checkedItems, setCheckedItems] = useState({});
    const { preferredDateFormat, preTimeZoneKey } = useSelector((state) => state?.user);
    const userPermissions = useSelector((state) => state.user.permission);
    const [eventID, setEventID] = useState(null);
    const [clearType, setClearType] = useState(false);

    useEffect(() => {
        async function fetchData() {
            showLoader();
            await getData(
                currentPage,
                perPage,
                search,
            );
        }
        fetchData();
    }, [
        currentPage,
        perPage,
        reloadPage,
        clearType,
    ]);

    const getData = async (
        currentPage,
        perPage,
        search,
    ) => {
        const params = {
            search: search,
            per_page: perPage,
        };

        await axiosInstance
            .get(`api/activity?page=${currentPage + 1}`, { params })
            .then((response) => {
                if (response.status === 200) {
                    setEvents(response?.data?.data?.records?.data);
                    setTotalCount(response?.data?.data?.records?.total);
                    hideLoader();
                    setCheckedItems({});
                }
            })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                        navigate("/dashboard");
                    }
                }
            });
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            getData(
                currentPage,
                perPage,
                search,
            );
        }
    };

    const handleCheckAll = (event) => {
        const { checked } = event.target;
        setIsCheckAll(checked);
        const newCheckedItems = {};
        events.forEach((lead) => {
            newCheckedItems[lead.id] = checked;
        });
        setCheckedItems(newCheckedItems);
    };

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setCheckedItems({
            ...checkedItems,
            [name]: checked,
        });

        if (!checked) {
            setIsCheckAll(false);
        } else {
            const allChecked = events.every(
                (deal) => checkedItems[deal.id] || deal.name === name
            );
            setIsCheckAll(allChecked);
        }
    };

    const bulkDeleteData = async (ids) => {
        const data = {
            id: ids,
        }

        try {
            DeleteDialog.fire({
                ...DeleteDialogConfig({
                    confirm: async () => {
                        showLoader()
                        await axiosInstance.delete('api/activity-bulk-destroy/', { data })
                            .then((response) => {
                                if (response.status === 200) {
                                    showSnackbar(response.data.message, "success");
                                    setCheckedItems({});
                                    setIsCheckAll(false);
                                    hideLoader();
                                    setReloadPage(reloadPage ? false : true);
                                }
                            })
                            .then((json) => { })
                            .catch((error) => {
                                if (error.response) {
                                    hideLoader();
                                    if (error.response.status === 400) {
                                        showSnackbar(error.response.data.message, "warning");
                                        navigate("/events");
                                    }
                                }
                            });
                    },
                    cancel: () => {
                        hideLoader();
                        setCheckedItems({});
                        setIsCheckAll(false);
                    }
                })
            });
        } catch (error) {
            hideLoader();
            showSnackbar('Oops Something want wrong..!', "error");
        }
    }

    async function handleBulkDelete() {
        const checkedIds = getCheckedIds();
        if (checkedIds && checkedIds.length > 0) {
            bulkDeleteData(checkedIds)
        } else {
            showSnackbar('Please select at least one record to Delete.', 'warning')
        }
    }

    async function deleteData(id) {
        const data = {
            id: id,
        }
        try {
            DeleteDialog.fire({
                ...DeleteDialogConfig({
                    confirm: async () => {
                        showLoader()
                        await axiosInstance.delete(`api/activity/destroy`, { data })
                            .then((response) => {
                                if (response.status === 200) {
                                    hideLoader();
                                    showSnackbar(response.data.message, "success");
                                    setReloadPage(reloadPage ? false : true);
                                    navigate("/events");
                                }
                            })
                            .then((json) => { })
                            .catch((error) => {
                                if (error.response) {
                                    hideLoader();
                                    if (error.response.status === 400) {
                                        showSnackbar(error.response.data.message, "warning");
                                    }
                                }
                            });
                    },
                    cancel: () => {
                        hideLoader();
                    }
                })
            });
        } catch (error) {
            hideLoader();
            showSnackbar('Oops Something want wrong..!', "error");
        }
    }

    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleRowsPerPageChange = (event) => {
        setPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };


    const [eventPageContextMenuAnchorEl, setEventPageContextMenuAnchorEl] = React.useState(null);
    const eventPageContextMenuOpen = Boolean(eventPageContextMenuAnchorEl);
    const handleEventPageContextMenuClick = (event) => {
        setEventPageContextMenuAnchorEl(event.currentTarget);
    };
    const handleEventPageContextMenuClose = () => {
        setEventPageContextMenuAnchorEl(null);
    };


    //========Start::Write a code for edit event popup=========//
    const [eventDetailsDialogOpen, setEventDetailsDialogOpen] = React.useState(false);
    const openEventDetailsDialog = (id) => {
        setEventID(id)
        setEventDetailsDialogOpen(true);
    }
    const closeEventDetailsDialog = () => {
        setEventID('')
        setEventDetailsDialogOpen(false);
    }
    //========End::Write a code for edit event popup=========//


    const handleReloadFunc = () => {
        setCheckedItems({});
        setIsCheckAll(false);
        hideLoader();
        setReloadPage(reloadPage ? false : true);
    }


    const getCheckedIds = () => {
        return Object.keys(checkedItems).filter((id) => checkedItems[id]);
    };
    const checkedIds = getCheckedIds();
    const expParams = {
        search: search,
        per_page: perPage,
        selectedIds: checkedIds && checkedIds.length > 0 ? checkedIds : []
    };

    const clearUserFilter = (type) => {
        if (type === 'search') {
            setSearch('');
            setClearType(clearType ? false : true)
        }
    }

    return (
        <>
            <Helmet>
                <title>Events | {capitalize(props?.name || 'SolvCRM')}</title>
            </Helmet>

            <Grid container className='page-action-bar'>
                <Grid item xs>
                    <Grid container spacing={1} alignItems={'center'}>
                        <Grid item xs={'auto'}>
                            <Checkbox className='grid-checkbox' checked={isCheckAll} onChange={handleCheckAll} />
                        </Grid>
                        <Grid item xs={'auto'}>
                            <TextField
                                variant="filled"
                                placeholder="Search events"
                                onChange={(e) => setSearch(e.target.value)}
                                className='size-small page-search no-margin'
                                onKeyDown={handleKeyDown}
                                value={search}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Icon path={mdiMagnify} color="currentColor" size={1} />
                                            {
                                                search &&
                                                <IconButton onClick={() => clearUserFilter('search')} >
                                                    <Icon path={mdiClose} color={'currentColor'} size={'20px'} />
                                                </IconButton>
                                            }

                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={'auto'} sx={{ marginLeft: 'auto' }}>
                    <Stack direction="row" spacing={1}>
                        <Tooltip title="More">
                            <span>
                                <Button variant="contained" color="secondary" size='small' className='icon-only' onClick={handleEventPageContextMenuClick}>
                                    <Icon path={mdiDotsVertical} color="currentColor" size={1} />
                                </Button>
                            </span>
                        </Tooltip>
                        <Menu
                            anchorEl={eventPageContextMenuAnchorEl}
                            open={eventPageContextMenuOpen}
                            onClose={handleEventPageContextMenuClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right'
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                        >
                            <MenuItem onClick={handleEventPageContextMenuClose}>
                                <Icon path={mdiExportVariant} color={'currentColor'} size={1} />
                                <ExportData params={expParams} fileName="Events" api="activity" handleReloadFunc={handleReloadFunc} />
                            </MenuItem>
                            {userPermissions && userPermissions.includes("event_delete") &&
                                <MenuItem onClick={e => {
                                    handleBulkDelete(); handleEventPageContextMenuClose();
                                }}>
                                    <Icon path={mdiTrashCanOutline} color={'currentColor'} size={1} />
                                    <span>Delete</span>
                                </MenuItem>
                            }
                        </Menu>
                    </Stack>
                </Grid>
            </Grid>

            {events && events.length > 0 && (
                <ul className="primary-list">
                    {events.map((event, index) => {
                        var eStartDate = event?.start_date || ''
                        var eEndDate = event?.end_date || ''

                        eStartDate = eStartDate ? eStartDate.split(' ') : ''
                        eEndDate = eEndDate ? eEndDate.split(' ') : ''
                        var displayEstartDate = ''
                        var displayEendDate = ''

                        if (eStartDate && eStartDate.length === 2) {
                            eStartDate = eStartDate[0]
                            displayEstartDate = event?.start_date ? displayDate(moment.utc(event.start_date).tz(preTimeZoneKey).format(preferredDateFormat), 'half') : null
                        }

                        if (eEndDate && eEndDate.length === 2) {
                            eEndDate = eEndDate[0]
                            displayEendDate = event?.end_date ? displayDate(moment.utc(event.end_date).tz(preTimeZoneKey).format(preferredDateFormat), 'half') : null
                        }
                        return (
                            <li key={index + ''}>
                                <a href="-" onClick={e => {
                                    e.preventDefault();
                                    if (userPermissions && userPermissions.includes("event_edit")) {
                                        openEventDetailsDialog(event?.id);
                                    }
                                }} className="card">
                                    <Grid container alignItems={'flex-start'}>
                                        <Grid item xs={'auto'}>
                                            <Checkbox className='grid-checkbox'
                                                onClick={e => { e.stopPropagation(); }} name={String(event?.id)}
                                                checked={checkedItems[event?.id] || false}
                                                onChange={handleCheckboxChange} />
                                        </Grid>
                                        <Grid item xs>
                                            <Typography variant='title-medium-bold' component={'h6'}>{capitalize(event?.name || 'N/A')}</Typography>
                                            <ul className='inline-details'>
                                                <li>
                                                    <Tooltip title="Contact Name">
                                                        <Icon path={mdiBriefcaseAccountOutline} color="currentColor" size={1} /><span
                                                            tabIndex={0}
                                                            onClick={e => {
                                                                e.stopPropagation();
                                                                e.preventDefault();
                                                                if (event?.activityType === 'Lead') {
                                                                    userPermissions && userPermissions.includes("lead_edit") && window.open(`/lead-detail/${event?.lead_id}`, '_blank');
                                                                } else if (event?.activityType === 'Deal') {
                                                                    userPermissions && userPermissions.includes("deal_edit") && window.open(`/deal-detail/${event?.deal_id}`, '_blank');
                                                                }
                                                            }}
                                                            className="primary-link dark"
                                                        >
                                                            {event?.contact_name?.first_name || 'N/A'} {event?.contact_name?.last_name || 'N/A'}
                                                        </span>
                                                    </Tooltip>
                                                </li>

                                                {
                                                    event?.is_fullday ?
                                                        <li>
                                                            <span className="title">Full Day:</span>
                                                            {eStartDate === eEndDate ?
                                                                displayEstartDate
                                                                :
                                                                displayEstartDate
                                                                + " to " +
                                                                displayEendDate
                                                            }
                                                        </li>
                                                        :
                                                        <li>
                                                            <Tooltip title="Start Date - End Date">
                                                                <span>
                                                                    <Icon path={mdiCalendarWeekBeginOutline} color="currentColor" size={1} />
                                                                    {event?.start_date
                                                                        ? moment.utc(moment(event.start_date)).tz(preTimeZoneKey).format(preferredDateFormat)
                                                                        : 'N/A'}
                                                                    {' - '}
                                                                    {event?.end_date
                                                                        ? moment.utc(moment(event.end_date)).tz(preTimeZoneKey).format(preferredDateFormat)
                                                                        : 'N/A'}
                                                                </span>
                                                            </Tooltip>
                                                        </li>

                                                }

                                                <li>
                                                    <Tooltip title="Location">
                                                        <span>
                                                            <Icon path={mdiMapMarkerOutline} color="currentColor" size={1} />{event.location || 'N/A'}
                                                        </span>
                                                    </Tooltip>
                                                </li>
                                            </ul>
                                        </Grid>
                                    </Grid>
                                    <ul className='inline-details other-info no-avatar'>
                                        <li><span className="title">Related to:</span>{event?.relatedToName?.name || 'N/A'}</li>
                                        <li>
                                            <Tooltip title="Last Updated">
                                                <span>
                                                    <Icon path={mdiHistory} color="currentColor" size={1} /> {event?.updated_at
                                                        ? moment.utc(event?.updated_at)
                                                            .tz(preTimeZoneKey)
                                                            .format(preferredDateFormat)
                                                        : "N/A"}
                                                </span>
                                            </Tooltip>
                                        </li>
                                        <li>
                                            <Tooltip title="Added by">
                                                <span>
                                                    <Icon path={mdiAccountOutline} color="currentColor" size={1} /> {event?.added_by?.name || 'N/A'}
                                                </span>
                                            </Tooltip>
                                        </li>
                                    </ul>
                                </a>
                                <Stack direction={'row'} className='actions'>
                                    {userPermissions && userPermissions.includes("event_edit") &&
                                        <Tooltip title="Edit">
                                            <span>
                                                <button className='icon-button' onClick={() => openEventDetailsDialog(event?.id)}>
                                                    <Icon path={mdiPencilOutline} color="currentColor" size={1} />
                                                </button>
                                            </span>
                                        </Tooltip>
                                    }
                                    {userPermissions && userPermissions.includes("event_delete") &&
                                        <Tooltip title="Delete">
                                            <span>
                                                <button type="button" className='icon-button' onClick={() => { deleteData(event?.id) }}>
                                                    <Icon path={mdiTrashCanOutline} color="currentColor" size={1} />
                                                </button>
                                            </span>
                                        </Tooltip>
                                    }
                                </Stack>
                            </li>
                        )
                    })
                    }
                </ul>
            )}

            {(!events || events.length === 0) && !loading &&
                <EmptyState type={'event'} hideActionBtn />
            }

            {
                events?.length > 0 &&
                <TablePagination
                    component="div"
                    count={totalCount}
                    page={currentPage}
                    rowsPerPage={perPage}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                />
            }

            {
                eventDetailsDialogOpen && eventID &&
                <EventDetailsDialog
                    open={eventDetailsDialogOpen}
                    onClose={closeEventDetailsDialog}
                    eventID={eventID}
                    handleReloadFunc={handleReloadFunc}
                />
            }

        </>
    );
}

export default Events;