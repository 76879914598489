import React from "react";
import { Helmet } from "react-helmet";
import { Grid, Typography } from "@mui/material";
import { capitalize } from "../../../utils/Utils";


function Checkout(props) {
    return (
        <>
            <Helmet>
                <title>Checkout | {capitalize(props?.name || 'SolvCRM')}</title>
            </Helmet>

            <Grid container className="page-action-bar" spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h2" style={{ fontWeight: 'bold' }}>
                        Coming Soon!
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        We're excited to announce that our <strong>Checkout</strong> and <strong>Subscription</strong> features are currently under development. We're working hard to bring you a seamless and secure experience, so stay tuned!
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        <strong style={{ fontSize: '30px' }}>What to Expect:</strong>
                        <ul>
                            <li>Easy and secure checkout process</li>
                            <li>Flexible subscription plans tailored for you</li>
                        </ul>
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
}

export default Checkout;