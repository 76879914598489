/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Checkbox, Stack, TableCell, TableRow, Tooltip } from "@mui/material";
import { DeleteDialog, DeleteDialogConfig } from "../../../utils/Utils";
import { mdiDrag, mdiPencilOutline, mdiPlus, mdiTrashCanOutline } from "@mdi/js";
import CreateNewStageDialog from "../components/CreateNewStageDialog";
import useAxios from "../../../hooks/useAxios";
import Icon from "@mdi/react";
import { useSnackbar } from "../../../components/context/SnackbarContext";
import CreateEditStageDialog from "./CreateEditStageDialog";
import { useSelector } from "react-redux";
const DraggableTableRow = ({ row, handleReloadFunc }) => {
    const axiosInstance = useAxios();
    const { showSnackbar } = useSnackbar();
    const [stageID, setStageID] = useState(null);
    const [stages, setStages] = useState([]);
    const userPermissions = useSelector((state) => state.user.permission);
    const {
        attributes,
        listeners,
        transform,
        transition,
        setNodeRef
    } = useSortable({
        id: row.id
    });

    const handleCheckboxChange = async (event) => {
        const newStatus = event.target.checked ? 1 : 0;
        await axiosInstance.put("api/stage/mode", { id: row.id, status: newStatus }).then(response => {
            if (response.status === 200) {
                showSnackbar(response.data.message, "success");
                handleReloadFunc();
            }
        }).then(json => {

        }).catch(error => {
            if (error.response) {
                if (error.response.status === 400 || error.response.status === 300) {
                    showSnackbar(error.response.data.message, "warning");
                }
            }
        });
    };

    async function deleteData(id) {
        const data = {
            id: id,
        }

        try {
            DeleteDialog.fire({
                ...DeleteDialogConfig({
                    confirm: async () => {
                        await axiosInstance.delete(`api/stage/destroy`, { data }).then((response) => {
                            if (response.status === 200) {
                                showSnackbar(response.data.message, "success");
                                handleReloadFunc();
                            }
                        })
                            .then((json) => { })
                            .catch((error) => {
                                if (error.response) {
                                    if (error.response.status === 400 || error.response.status === 300) {
                                        showSnackbar(error.response.data.message, "warning");
                                    }
                                }
                            });
                    },
                    cancel: () => {
                    }
                })
            });
        } catch (error) {
            showSnackbar('Oops Something want wrong..!', "error");
        }
    }

    const [createNewStageDialogOpen, setCreateNewStageDialogOpen] = React.useState(false);

    const openCreateNewStageDialog = async () => {
        setStageID(row.id);
        try {
            const response = await axiosInstance.get('api/stage/edit/' + row.id);
            if (response.status === 200) {
                setStages(response.data?.data?.record);
                setCreateNewStageDialogOpen(true);
            } else if (response.status === 300) {
                showSnackbar("Unexpected status code 300. Dialog will not open.", "warning");
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 300 || error.response.status === 400) {
                    showSnackbar(error.response.data.message, "warning");
                }
            }
        }
    };

    const closeCreateNewStageDialog = () => {
        setStageID('')
        setCreateNewStageDialogOpen(false);
    }

    return (
        <>
            <TableRow ref={setNodeRef} style={{
                transform: CSS.Transform.toString(transform),
                transition: transition
            }}>
                <TableCell>
                    <button type="button" className="icon-button mx-n8" {...attributes} {...listeners}>
                        <Icon path={mdiDrag} color="currentColor" size={1} />
                    </button>
                </TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.selected_type || 'N/A'}</TableCell>
                <TableCell align="center">
                    <Checkbox
                        className="grid-checkbox"
                        checked={row.status === 1}
                        onChange={handleCheckboxChange}
                    />
                </TableCell>
                <TableCell>
                    <Stack direction="row" className="mr-n8">
                        {row.is_popup !== 1 && (
                            <>
                                {userPermissions && userPermissions.includes("stage_edit") &&
                                    <Tooltip title="Edit">
                                        <span>
                                            <button type="button" className='icon-button' onClick={openCreateNewStageDialog}>
                                                <Icon path={mdiPencilOutline} color="currentColor" size={1} />
                                            </button>
                                        </span>
                                    </Tooltip>
                                }
                                {userPermissions && userPermissions.includes("stage_delete") &&
                                    <Tooltip title="More">
                                        <span>
                                            <button type="button" className='icon-button'

                                                onClick={e => {
                                                    deleteData(row.id);
                                                }}>
                                                <Icon path={mdiTrashCanOutline} color="currentColor" size={1} />
                                            </button>
                                        </span>
                                    </Tooltip>
                                }
                            </>
                        )}
                    </Stack>
                </TableCell>
            </TableRow>

            {createNewStageDialogOpen && (
                <CreateEditStageDialog
                    open={createNewStageDialogOpen}
                    onClose={closeCreateNewStageDialog}
                    handleReloadFunc={handleReloadFunc}
                    stageID={stageID}
                    stages={stages}
                />
            )}
        </>
    );
};

export default DraggableTableRow;