import { createTheme } from "@mui/material";
import { breakpointConfig, componentConfig, typographyConfig } from "./utils/themeComponentConfig";

const themeProvider = (theme) => createTheme({
    breakpoints: {
        ...breakpointConfig
    },
    typography: {
        ...typographyConfig(theme)
    },
    palette: {
        ...theme.colors
    },
    components: {
        ...componentConfig(theme)
    }
});

export default themeProvider;