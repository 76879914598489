import React, { useEffect, useState } from "react";
import { Button, Grid, MenuItem, Typography, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useSnackbar } from '../../../components/context/SnackbarContext';
import useAxios from '../../../hooks/useAxios';
import { useLoader } from "../../../components/context/LoaderContext";
import { useNavigate } from "react-router-dom";

function SendWhatsAppMessage(props) {
    const axiosInstance = useAxios();
    const { showSnackbar } = useSnackbar();
    const { showLoader, hideLoader } = useLoader();
    const navigate = useNavigate();
    //{ id: 1, name: 'hello_world', content: 'Hello World' },
    const [templateList, setTemplateList] = useState([
        { id: 2, name: 'first_marketing_temp', content: 'First Marketing Template' },
    ]);

    const [contactList, setContactList] = useState([
        { id: 919662035673, name: '+91 9662035673' },
        { id: 919427865533, name: '+91 9427865533' },
        { id: 918401908134, name: '+91 8401908134' },
    ]);

    const [templateCategory, setTemplateCategory] = useState([
        { id: 'MARKETING', name: 'MARKETING' },
        { id: 'UTILITY', name: 'UTILITY' },
        { id: 'AUTHENTICATION', name: 'AUTHENTICATION' },
    ]);

    const [templateLanguage, setTemplateLanguage] = useState([
        { id: 'en', name: 'English' },
        { id: 'en_GB', name: 'English (UK)' },
        { id: 'en_US', name: 'English (US)' },
    ]);


    const validationSchema = yup.object().shape({
        template_id: yup.string().required('Please select template'),
        mobile_number: yup.string().required("Please enter mobile number"),
    })


    const handleSubmit = async (values, onSubmitProps) => {
        const data = {
            template_id: values.template_id,
            mobile_number: values.mobile_number,
            text_maessage: values.text_maessage,
        };

        showLoader()
        await axiosInstance.post("/api/send-whatsapp-message/store", data).then(response => {
            if (response.status === 200) {
                hideLoader()
                showSnackbar(response.data.message, 'success')
            }
        }).then(json => {

        }).catch(error => {
            if (error.response) {
                hideLoader()
                onSubmitProps.setSubmitting(false)
                if (error.response.status === 422 || error.response.data.validation_errors) {
                    const errors = error.response.data.validation_errors;
                    Object.keys(errors).forEach(key => {
                        onSubmitProps.setFieldError(key, errors[key]);
                    });
                } else {
                    showSnackbar(error.response.data.message, 'error')
                }
            }
        });
    }


    return (
        <>
            <div className="card p-16">
                <div className="card-header">
                    <h6 className="title">Communication</h6>
                </div>
                <Formik
                    initialValues={{
                        template_id: '',
                        mobile_number: '',
                        text_maessage: '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { resetForm }) => {
                        handleSubmit(values).then(() => {
                            // Assuming handleSubmit is an async function that sends data
                            resetForm(); // Reset the form after successful submission
                        }).catch(error => {
                            console.error("Submission failed", error); // Handle the error appropriately
                        });
                    }}
                    enableReinitialize
                >
                    {props => {
                        const {
                            touched,
                            errors,
                            values,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        } = props;
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container rowSpacing={3} columnSpacing={2} alignItems={'center'}>
                                    <Grid item xxl={12} md>
                                        <TextField
                                            select
                                            label="Select Template"
                                            value={values.template_id}
                                            className="no-margin"
                                            name="template_id"
                                            variant="filled"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.template_id && touched.template_id}
                                            helperText={(errors.template_id && touched.template_id) && errors.template_id}
                                        >
                                            {
                                                templateList?.length > 0 ?
                                                    templateList.map((template, index) => (
                                                        <MenuItem key={index} value={template.name}>
                                                            {template.content}
                                                        </MenuItem>
                                                    )) :
                                                    <MenuItem value={-1}></MenuItem>
                                            }
                                        </TextField>
                                    </Grid>
                                    <Grid item xxl={12} md>
                                        <TextField
                                            select
                                            label="Select Contact"
                                            value={values.mobile_number}
                                            className="no-margin"
                                            name="mobile_number"
                                            variant="filled"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.mobile_number && touched.mobile_number}
                                            helperText={(errors.mobile_number && touched.mobile_number) && errors.mobile_number}
                                        >
                                            {
                                                contactList?.length > 0 ?
                                                    contactList.map((contact, index) => (
                                                        <MenuItem key={index} value={contact.id}>
                                                            {contact.name}
                                                        </MenuItem>
                                                    )) :
                                                    <MenuItem value={-1}></MenuItem>
                                            }
                                        </TextField>
                                    </Grid>
                                    <Grid item xxl={12} md="auto">
                                        <Button type="submit" >Send Message</Button>
                                    </Grid>
                                </Grid>
                            </form>
                        );
                    }}
                </Formik>
            </div>


            {/* <Grid item xs={12}>
                <div className="card p-16">
                    <Typography variant="title-medium-large-semibold" component={'h4'} className="mb-16">Communication</Typography>

                    <Formik
                        initialValues={{
                            template_id: '',
                            mobile_number: '',
                            text_maessage: '',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(values, { resetForm }) => {
                            handleSubmit(values).then(() => {
                                // Assuming handleSubmit is an async function that sends data
                                resetForm(); // Reset the form after successful submission
                            }).catch(error => {
                                console.error("Submission failed", error); // Handle the error appropriately
                            });
                        }}
                        enableReinitialize
                    >
                        {props => {
                            const {
                                touched,
                                errors,
                                values,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                            } = props;
                            return (
                                <form onSubmit={handleSubmit}>
                                    <TextField
                                        select
                                        label="Select Template"
                                        value={values.template_id}
                                        name="template_id"
                                        variant="filled"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={errors.template_id && touched.template_id}
                                        helperText={(errors.template_id && touched.template_id) && errors.template_id}
                                    >
                                        {
                                            templateList?.length > 0 ?
                                                templateList.map((template, index) => (
                                                    <MenuItem key={index} value={template.name}>
                                                        {template.content}
                                                    </MenuItem>
                                                )) :
                                                <MenuItem value={-1}></MenuItem>
                                        }
                                    </TextField>
                                    <TextField
                                        select
                                        label="Select Contact"
                                        value={values.mobile_number}
                                        name="mobile_number"
                                        variant="filled"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={errors.mobile_number && touched.mobile_number}
                                        helperText={(errors.mobile_number && touched.mobile_number) && errors.mobile_number}
                                    >
                                        {
                                            contactList?.length > 0 ?
                                                contactList.map((contact, index) => (
                                                    <MenuItem key={index} value={contact.id}>
                                                        {contact.name}
                                                    </MenuItem>
                                                )) :
                                                <MenuItem value={-1}></MenuItem>
                                        }
                                    </TextField>
                                    <Button type="submit" size="small">Send </Button>
                                </form>
                            );
                        }}
                    </Formik>
                </div>
            </Grid> */}
        </>
    );
}

export default SendWhatsAppMessage;