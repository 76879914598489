import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import Icon from "@mdi/react";
import { mdiBriefcaseCheckOutline, mdiCalendarCheckOutline, mdiCalendarMultiple, mdiCalendarTodayOutline, mdiDomain, mdiNoteTextOutline } from "@mdi/js";
import { Helmet } from "react-helmet";
import AvatarInitials from "../components/common/AvatarInitials";
import { useSnackbar } from '../components/context/SnackbarContext';
import useAxios from '../hooks/useAxios';
import { useLoader } from "../components/context/LoaderContext";
import { Link } from "react-router-dom";
import { capitalize, displayCurrencySymbol } from "../utils/Utils";
import moment from "moment-timezone";
import { useSelector } from "react-redux";


function Dashboard(props) {
    const axiosInstance = useAxios();
    const { showSnackbar } = useSnackbar();
    const { showLoader, hideLoader } = useLoader();
    const [dataCount, setDataCount] = useState({})
    const { preferredDateFormat, preTimeZoneKey, currencyCode, currencySymbol, currencyStringValue } = useSelector(state => state?.user);

    const [tasks, setTasks] = useState([])
    const [events, setEvents] = useState([])
    const [thisMonthClosingDeal, setThisMonthClosingDeal] = useState([])
    const [thisMonthLead, setThisMonthLead] = useState([])

    const displayTaskColor = {
        'medium': 'warning',
        'high': 'danger',
        'low': 'success',
        'N/A': 'info',
    };

    const displayDealColor = {
        'Closed Won': 'success',
        'Closed Lost': 'danger',
        'N/A': 'info',
    };

    useEffect(() => {
        async function fetchData() {
            await getDashboardData();
        }
        fetchData();
    }, []);

    async function getDashboardData() {
        showLoader()
        await axiosInstance.get("api/dashboard-mobile?type=web").then(response => {
            if (response.status === 200) {
                setDataCount({
                    'my_deal': response?.data?.data?.dataCounts?.my_deal,
                    'my_lead': response?.data?.data?.dataCounts?.my_lead,
                    'my_task': response?.data?.data?.dataCounts?.my_task,
                    'my_activities': response?.data?.data?.dataCounts?.my_activities
                })
                setTasks(response?.data?.data?.upcomingTaskRecords)
                setEvents(response?.data?.data?.upcomingEventRecords)
                setThisMonthClosingDeal(response?.data?.data?.thisMonthClosingDealRecords)
                setThisMonthLead(response?.data?.data?.thisMonthLeadRecords)
                hideLoader()
            }
        }).then(json => {

        }).catch(error => {
            if (error.response) {
                hideLoader()
                if (error.response.status === 400) {
                    showSnackbar(error.response.data.message, 'error')
                }
            }
        });
    }

    return (
        <>
            <Helmet>
                <title>Dashboard | {capitalize(props?.name || 'SolvCRM')}</title>
            </Helmet>

            <Grid container spacing={2}>
                <Grid item xl={3} xs={6}>
                    <div className="card dashboard-card">
                        <Typography variant="label-large-bold">Deals</Typography>
                        <Typography variant="display-medium" component={'h2'}>{dataCount?.my_deal || 0}</Typography>
                        <Icon path={mdiBriefcaseCheckOutline} color={'currentColor'} size={1} />
                    </div>
                </Grid>

                <Grid item xl={3} xs={6}>
                    <div className="card dashboard-card">
                        <Typography variant="label-large-bold">Leads</Typography>
                        <Typography variant="display-medium" component={'h2'}>{dataCount?.my_lead || 0}</Typography>
                        <Icon path={mdiNoteTextOutline} color={'currentColor'} size={1} />
                    </div>
                </Grid>

                <Grid item xl={3} xs={6}>
                    <div className="card dashboard-card">
                        <Typography variant="label-large-bold">Tasks</Typography>
                        <Typography variant="display-medium" component={'h2'}>{dataCount?.my_task || 0}</Typography>
                        <Icon path={mdiCalendarCheckOutline} color={'currentColor'} size={1} />
                    </div>
                </Grid>

                <Grid item xl={3} xs={6}>
                    <div className="card dashboard-card">
                        <Typography variant="label-large-bold">Events</Typography>
                        <Typography variant="display-medium" component={'h2'}>{dataCount?.my_activities || 0}</Typography>
                        <Icon path={mdiCalendarMultiple} color={'currentColor'} size={1} />
                    </div>
                </Grid>

                <Grid item xs={12} xl={6}>
                    <div className="card structured height-100p">
                        <div className="card-header">
                            <h6 className="title">Tasks</h6>
                            <Link to="/tasks" className="primary-link ml-auto" title="View All"><Typography variant="body-medium-semibold">View All</Typography></Link>
                        </div>
                        <div className="card-body">
                            <ul className="dashboard-list">
                                {tasks?.length > 0 ? (tasks.map((task, index) =>
                                    <li key={index + ''}>
                                        <Typography variant="title-small-semibold" component={'p'}>{task?.name ? capitalize(task.name) : 'N/A'}</Typography>
                                        <Typography variant="body-medium">{task?.description ? capitalize(task.description) : 'N/A'}</Typography>
                                        <div className="info-block">
                                            <Icon path={mdiCalendarTodayOutline} color={'currentColor'} size={1} />
                                            <Typography variant="body-small">{task?.end_date ? moment.tz(task.end_date, preTimeZoneKey).format(preferredDateFormat) : 'N/A'}</Typography>
                                        </div>
                                        <span className={"status-badge " + displayTaskColor[task?.priority || 'N/A']}>{task?.priority ? capitalize(task.priority) : 'N/A'}</span>
                                    </li>
                                )) : (
                                    <li>
                                        <Typography variant="body-medium">
                                            No task available.
                                        </Typography>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </Grid>

                <Grid item xs={12} xl={6}>
                    <div className="card structured height-100p">
                        <div className="card-header">
                            <h6 className="title">Events</h6>
                            <Link to="/events" className="primary-link ml-auto" title="View All"><Typography variant="body-medium-semibold">View All</Typography></Link>
                        </div>
                        <div className="card-body">
                            <ul className="dashboard-list">
                                {events?.length > 0 ? (events.map((event, index) =>
                                    <li key={index + ''}>
                                        <Typography variant="title-small-semibold" component={'p'}>{event?.name ? capitalize(event.name) : 'N/A'}</Typography>
                                        <Typography variant="body-medium">{event?.description ? capitalize(event.description) : 'N/A'}</Typography>
                                        <div className="info-block">
                                            <Icon path={mdiCalendarTodayOutline} color={'currentColor'} size={1} />
                                            <Typography variant="body-small">{event?.end_date ? moment.tz(event.end_date, preTimeZoneKey).format(preferredDateFormat) : 'N/A'}</Typography>
                                        </div>
                                    </li>
                                )) : (
                                    <li>
                                        <Typography variant="body-medium">
                                            No event available.
                                        </Typography>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </Grid>

                <Grid item xs={12} xl={6}>
                    <div className="card structured height-100p">
                        <div className="card-header">
                            <h6 className="title">This Month’s Closed Deals</h6>
                            <Link to="/deals" className="primary-link ml-auto" title="View All"><Typography variant="body-medium-semibold">View All</Typography></Link>
                        </div>
                        <div className="card-body">
                            <ul className="dashboard-list">
                                {thisMonthClosingDeal?.length > 0 ? (thisMonthClosingDeal.map((deal, index) =>
                                    <li key={index + ''}>
                                        <Typography variant="title-small-semibold" component={'p'}>{deal?.deal_name ? capitalize(deal.deal_name) : 'N/A'}</Typography>
                                        <Typography variant="body-medium">{deal?.expected_closing_date ? moment.tz(deal.expected_closing_date, preTimeZoneKey).format(preferredDateFormat) : 'N/A'}</Typography>
                                        <Typography variant="body-large">{deal?.deal_amount ? displayCurrencySymbol(deal?.deal_amount, currencyCode, currencySymbol, currencyStringValue) : 'N/A'}</Typography>
                                        <span className={"status-badge " + displayDealColor[deal?.get_stage?.name || 'N/A']}>{deal?.get_stage?.name ? capitalize(deal.get_stage.name) : 'N/A'}</span>
                                    </li>
                                )) : (
                                    <li>
                                        <Typography variant="body-medium">
                                            No deals available for this month.
                                        </Typography>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </Grid>

                <Grid item xs={12} xl={6}>
                    <div className="card structured height-100p">
                        <div className="card-header">
                            <h6 className="title">This Month’s Leads</h6>
                            <Link to="/leads" className="primary-link ml-auto" title="View All"><Typography variant="body-medium-semibold">View All</Typography></Link>
                        </div>
                        <div className="card-body">
                            <ul className="dashboard-list">
                                {thisMonthLead?.length > 0 ? (thisMonthLead.map((lead, index) =>
                                    <li key={index + ''} className="lead">
                                        <AvatarInitials name={lead?.first_name + ' ' + lead?.last_name} />
                                        <Typography variant="title-small-semibold" component={'p'}>{lead?.salutation ? lead.salutation + '. ' : ''}{lead?.first_name + ' ' + lead?.last_name}</Typography>
                                        <Typography variant="body-medium">{lead?.title || 'N/A'}</Typography>
                                        <div className="info-block">
                                            <Icon path={mdiDomain} color={'currentColor'} size={1} />
                                            <Typography variant="body-small">{lead?.organization_name || 'N/A'}</Typography>
                                        </div>
                                        <span className={"status-badge"}>{lead?.lead_source || 'N/A'}</span>
                                    </li>
                                )) : (
                                    <li>
                                        <Typography variant="body-medium">
                                            No Leads available for this month.
                                        </Typography>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </>
    );
}

export default Dashboard;