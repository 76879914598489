import { mdiClose, mdiEyeOffOutline, mdiEyeOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { Button, Dialog, Grid, IconButton, InputAdornment, TextField, Tooltip } from "@mui/material";
import React from "react";
import { Formik } from "formik";
import * as Yup from 'yup';
import useAxios from "../../../hooks/useAxios";
import { useSnackbar } from "../../../components/context/SnackbarContext";

function ChangePasswordDialog({ onClose, open }) {
    const axiosInstance = useAxios();
    const [showOldPassword, setShowOldPassword] = React.useState(false);
    const handleClickShowOldPassword = () => setShowOldPassword((show) => !show);
    const handleMouseDownOldPassword = (event) => { event.preventDefault(); };
    const [showNewPassword, setShowNewPassword] = React.useState(false);
    const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
    const handleMouseDownNewPassword = (event) => { event.preventDefault(); };
    const [showConfirmNewPassword, setShowConfirmNewPassword] = React.useState(false);
    const handleClickShowConfirmNewPassword = () => setShowConfirmNewPassword((show) => !show);
    const handleMouseDownConfirmNewPassword = (event) => { event.preventDefault(); };
    const { showSnackbar } = useSnackbar();

    const validationSchema = Yup.object({
        old_password: Yup.string().required('Old password is required'),
        password: Yup.string()
            .required('New password is required')
            .min(8, 'Password must be at least 8 characters long'),
        password_confirm: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Confirm new password is required'),
    });

    const handleSubmit = async (values, onSubmitProps) => {
        try {
            const response = await axiosInstance.post('/api/change-password', values);

            if (response.status === 200) {
                showSnackbar(response.data.message, 'success');
                onSubmitProps.resetForm();
                onClose();
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 422 || error.response.data.validation_errors) {
                    const errors = error.response.data.validation_errors;
                    Object.keys(errors).forEach(key => {
                        onSubmitProps.setFieldError(key, errors[key]);
                    });
                } else {
                    showSnackbar(error.response.data.message, 'error');
                }
            } else {
                showSnackbar('An unexpected error occurred', 'error');
            }
        }
    };


    return (
        <>
            <Dialog onClose={onClose} open={open} maxWidth={'md'}>
                <div className="dialog-title">
                    <h2>Change Password</h2>
                    <Tooltip title="Close">
                        <button className="icon-button dialog-close" onClick={onClose}>
                            <Icon path={mdiClose} color={'currentColor'} size={1} />
                        </button>
                    </Tooltip>
                </div>
                <Formik
                    initialValues={{
                        old_password: '',
                        password: '',
                        password_confirm: ''
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize
                >
                    {props => {
                        const {
                            touched,
                            errors,
                            values,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue
                        } = props;
                        return (
                            <form onSubmit={handleSubmit}>
                                <TextField
                                    label="Old Password"
                                    type={showOldPassword ? 'text' : 'password'}
                                    variant="filled"
                                    name="old_password"
                                    value={values.old_password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.old_password && touched.old_password}
                                    helperText={(errors.old_password && touched.old_password) && errors.old_password}
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowOldPassword}
                                                    onMouseDown={handleMouseDownOldPassword}
                                                >
                                                    {showOldPassword
                                                        ? <Icon path={mdiEyeOffOutline} color={'currentColor'} size={1} />
                                                        : <Icon path={mdiEyeOutline} color={'currentColor'} size={1} />}
                                                </IconButton>
                                            </InputAdornment>
                                    }}
                                />

                                <TextField
                                    label="New Password"
                                    type={showNewPassword ? 'text' : 'password'}
                                    variant="filled"
                                    name="password"
                                    value={values.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.password && touched.password}
                                    helperText={(errors.password && touched.password) && errors.password}
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowNewPassword}
                                                    onMouseDown={handleMouseDownNewPassword}
                                                >
                                                    {showNewPassword
                                                        ? <Icon path={mdiEyeOffOutline} color={'currentColor'} size={1} />
                                                        : <Icon path={mdiEyeOutline} color={'currentColor'} size={1} />}
                                                </IconButton>
                                            </InputAdornment>
                                    }}
                                />

                                <TextField
                                    label="Confirm New Password"
                                    type={showConfirmNewPassword ? 'text' : 'password'}
                                    variant="filled"
                                    name="password_confirm"
                                    value={values.password_confirm}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.password_confirm && touched.password_confirm}
                                    helperText={(errors.password_confirm && touched.password_confirm) && errors.password_confirm}
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowConfirmNewPassword}
                                                    onMouseDown={handleMouseDownConfirmNewPassword}
                                                >
                                                    {showConfirmNewPassword
                                                        ? <Icon path={mdiEyeOffOutline} color={'currentColor'} size={1} />
                                                        : <Icon path={mdiEyeOutline} color={'currentColor'} size={1} />}
                                                </IconButton>
                                            </InputAdornment>
                                    }}
                                />

                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Button type="submit" className="btn-block">Update</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button color="secondary" className="btn-block" onClick={onClose}>Cancel</Button>
                                    </Grid>
                                </Grid>
                            </form>
                        );
                    }}
                </Formik>
            </Dialog>
        </>
    );
}

export default ChangePasswordDialog;