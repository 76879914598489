import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import SettingsTab from "../components/SettingsTab";
import { Button, Popover, Stack, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, Tooltip, Typography } from "@mui/material";
import { mdiCalendarWeekBeginOutline, mdiCheckCircleOutline, mdiCompareVertical, mdiWan } from "@mdi/js";
import Icon from "@mdi/react";
import { useLoader } from "../../../components/context/LoaderContext";
import useAxios from "../../../hooks/useAxios";
import { useSnackbar } from "../../../components/context/SnackbarContext";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import { capitalize } from "../../../utils/Utils";

function ActivityLogs(props) {
    const axiosInstance = useAxios();
    const { preferredDateFormat, preTimeZoneKey } = useSelector((state) => state?.user);
    const { showLoader, hideLoader } = useLoader();
    const { showSnackbar } = useSnackbar();
    const [activityLogs, setActivityLogs] = useState();
    const [reloadPage, setReloadPage] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(
        parseInt(process.env.REACT_APP_DEFAULT_PAGINATION)
    );

    useEffect(() => {
        async function fetchData() {
            showLoader();
            await getData(
                currentPage,
                perPage,
            );
            hideLoader();
        } fetchData();
    }, [currentPage, perPage, reloadPage]);

    const getData = async (
        currentPage,
        perPage,
        sortBy,
    ) => {
        const params = {
            sort_field: sortBy,
            per_page: perPage,
        };

        await axiosInstance
            .get(`api/activity-log?page=${currentPage + 1}`, { params }).then((response) => {
                if (response.status === 200) {
                    setActivityLogs(response?.data?.data?.records?.data);
                    setTotalCount(response?.data?.data?.records?.total);
                }
            })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                    }
                }
            });
    };

    let displayModelName = ''
    if (activityLogs?.modelName === 'ProductInterestDir') {
        let UrlForExp = activityLogs.url.split('lead')
        let modelname = 'Deal'
        let actionType = 'Added'
        if (UrlForExp.length == 2) {
            modelname = 'Lead'
        }
        if (activityLogs.event === 'updated' || activityLogs.event === 'deleted') {
            actionType = 'Remove'
        }
        displayModelName = 'Product ' + actionType + ' on ' + modelname
    }

    const [selectedLog, setSelectedLog] = React.useState(null);
    const formatLabel = (label) => {
        if (!label) return '';
        return label
            .replace(/_/g, ' ') // Replace underscores with spaces
            .replace(/^\w/, c => c.toUpperCase()); // Capitalize the first letter
    };

    const oldValue = selectedLog?.oldValue;
    const newValue = selectedLog?.newValue;

    const [viewChangesAnchorEl, setViewChangesAnchorEl] = React.useState(null);
    const handleViewChangesPopoverOpen = (event, log) => {
        setViewChangesAnchorEl(event.currentTarget);
        setSelectedLog(log);
    };
    const handleViewChangesPopoverClose = () => {
        setViewChangesAnchorEl(null);
        setSelectedLog(null);
    };
    const viewChangesPopoverOpen = Boolean(viewChangesAnchorEl);

    const handlePageChange = (event, newPage) => { setCurrentPage(newPage) };

    const handleRowsPerPageChange = (event) => {
        setPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };

    return (
        <>
            <Helmet>
                <title>Activity Logs | {capitalize(props?.name || 'SolvCRM')}</title>
            </Helmet>

            <SettingsTab />
            {activityLogs && activityLogs.length > 0 && (
                <>
                    <ul className="primary-list">
                        {activityLogs.map((log, index) => (
                            <li key={index + ''}>
                                <div className="card">
                                    <Typography variant='title-medium-bold' component={'h6'}>{log.added_by.name || 'N/A'}</Typography>
                                    <ul className='inline-details'>
                                        <li>
                                            <Tooltip title="Type">
                                                <span>
                                                    {log?.event === 'updated' && <span className="status-badge warning">{log?.event || 'N/A'}</span>}
                                                    {log?.event === 'created' && <span className="status-badge success">{log?.event || 'N/A'}</span>}
                                                    {log?.event === 'deleted' && <span className="status-badge danger">{log?.event || 'N/A'}</span>}
                                                </span>
                                            </Tooltip>
                                        </li>
                                        <li>
                                            <Tooltip title="IP Address">
                                                <span>
                                                    <Icon path={mdiWan} color="currentColor" size={1} />{log.ip_address || 'N/A'}
                                                </span>
                                            </Tooltip>
                                        </li>
                                        <li>
                                            <Tooltip title="Action">
                                                <span>
                                                    <Icon path={mdiCheckCircleOutline} color="currentColor" size={1} />
                                                    {displayModelName ? displayModelName : log.modelName ? log.modelName + ' ' + log.event : 'N/A'}
                                                </span>
                                            </Tooltip>
                                        </li>
                                    </ul>
                                    <ul className='inline-details other-info ml-0'>
                                        <li>
                                            <Tooltip title="Created Date">
                                                <span>
                                                    <Icon path={mdiCalendarWeekBeginOutline} color="currentColor" size={1} />
                                                    {log?.created_at
                                                        ? moment.utc(log.created_at)
                                                            .tz(preTimeZoneKey)
                                                            .format(preferredDateFormat)
                                                        : "N/A"}
                                                </span>
                                            </Tooltip>
                                        </li>
                                    </ul>
                                </div>
                                <Stack direction={'row'} className='actions'>
                                    <Button variant="text" size="small" onClick={e => { handleViewChangesPopoverOpen(e, log); }}>
                                        <span>
                                            <Icon path={mdiCompareVertical} color="currentColor" size={1} />
                                            <span>View Changes</span>
                                        </span>
                                    </Button>
                                </Stack>
                            </li>
                        ))}
                    </ul>
                    <Popover
                        open={viewChangesPopoverOpen}
                        anchorEl={viewChangesAnchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        onClose={handleViewChangesPopoverClose}
                        classes={{
                            paper: 'utility-menu size-lg'
                        }}
                    >
                        <Stack spacing={3} direction={'column'}>
                            {oldValue && Object.keys(oldValue).length > 0 ? (
                                <>
                                    <Typography variant="title-medium-large-semibold" component={'h6'} className="pb-4">
                                        Old Value
                                    </Typography>
                                    <TableContainer>
                                        <Table className="vertical-top">
                                            <TableBody>
                                                {Object.entries(oldValue)
                                                    .filter(([key, value]) => value != null) // Exclude null and undefined values
                                                    .map(([key, value]) => (
                                                        key !== 'data' ?
                                                            <TableRow key={key}>
                                                                <TableCell className="text-secondary">{formatLabel(key)}</TableCell>
                                                                <TableCell>{value}</TableCell>
                                                            </TableRow>
                                                            : ''
                                                    ))}
                                                {Object.entries(oldValue).filter(([key, value]) => value != null).length === 0 && (
                                                    <TableRow>
                                                        <TableCell colSpan={2}>No data available</TableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </>
                            ) : (
                                ''
                            )}

                            {newValue && Object.keys(newValue).length > 0 ? (
                                <>
                                    <Typography variant="title-medium-large-semibold" component={'h6'} className="pb-4">
                                        New Value
                                    </Typography>
                                    <TableContainer>
                                        <Table className="vertical-top">
                                            <TableBody>
                                                {Object.entries(newValue)
                                                    .filter(([key, value]) => value != null)
                                                    .map(([key, value]) => (
                                                        key !== 'data' ?
                                                            <TableRow key={key}>
                                                                <TableCell className="text-secondary">{formatLabel(key)}</TableCell>
                                                                <TableCell>{value}</TableCell>
                                                            </TableRow>
                                                            : ''
                                                    ))}
                                                {Object.entries(newValue).filter(([key, value]) => value != null).length === 0 && (
                                                    <TableRow>
                                                        <TableCell colSpan={2}>No data available</TableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </>
                            ) : (
                                ''
                            )}
                        </Stack>
                    </Popover>
                </>
            )}
            {activityLogs && activityLogs.length > 0 &&
                <TablePagination
                    component="div"
                    count={totalCount}
                    page={currentPage}
                    rowsPerPage={perPage}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                />
            }
        </>
    );
}

export default ActivityLogs;