import React, { Suspense, useEffect, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import useAxios from "../../../hooks/useAxios";
import { useSnackbar } from "../../../components/context/SnackbarContext";
import { useLoader } from "../../../components/context/LoaderContext";
import VirtualizedCardList from "./VirtualizedCardList";
import { Helmet } from "react-helmet";
import { Button, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import Icon from "@mdi/react";
import { mdiClose, mdiFormatListBulleted, mdiMagnify } from "@mdi/js";
import { capitalize, displayCurrencySymbol } from "../../../utils/Utils";
import { useSelector } from "react-redux";
import ClosedWonDealDialog from "./ClosedWonDealDialog";

const DealKanbanView = ({ handleListViewFun, props, userPermissions }) => {

    const axiosInstance = useAxios();
    const { showSnackbar } = useSnackbar();
    const { showLoader, hideLoader } = useLoader();
    const [search, setSearch] = useState();
    const [lanes, setLanes] = useState({})
    const { currencySymbol, currencyCode, currencyStringValue } = useSelector((state) => state?.user);
    const [reloadPage, setReloadPage] = useState(false)

    const [closedWonDialogOpen, setClosedWonDialogOpen] = useState(false);
    const [closeDealType, setCloseDealType] = useState();
    const [stageId, setStageId] = useState();
    const [deal, setDeal] = useState([]);
    const [dealAccountData, setDealAccountData] = useState([]);
    const [updateData, setUpdateData] = useState({});
    const [clearType, setClearType] = useState(false);

    useEffect(() => {
        // Open the dialog only when deal and dealAccountData are populated
        if (deal.id && deal.contact_id && deal.deal_description && deal.deal_probability && dealAccountData.organization_name) {
            setClosedWonDialogOpen(true);
        }
    }, [deal, dealAccountData]);

    useEffect(() => {
        async function fetchData() {
            await getData();
        }
        fetchData();
    }, [reloadPage, clearType]);

    //Load intial data and make required object for Kanban bord
    const getData = async () => {
        const params = {
            search: search,
            search_canban_list: 'yes',
        };
        showLoader();
        try {
            const response = await axiosInstance.get(`api/deal`, { params });
            if (response.status === 200) {
                const lanes = {};
                response?.data?.data?.leadStage?.length && response?.data?.data?.leadStage.forEach(stage => {
                    lanes[`lane${stage.id}`] = {
                        id: stage.id.toString(),
                        title: stage?.name || '',
                        label: `${stage?.dealCount || 0} deals`,
                        amount: stage?.dealAmount || 0,
                        // Filter records based on the stage id and map them into cards
                        cards: response?.data?.data?.records?.length > 0
                            ? response.data.data.records
                                .filter(card => card.stage_id === stage.id) // Only include cards matching the stage
                                .map(card => ({
                                    id: card.id.toString(),
                                    amount: card?.deal_amount ? displayCurrencySymbol(card?.deal_amount, currencyCode, currencySymbol, currencyStringValue) : 'N/A',
                                    dealName: card?.deal_name || '',
                                    stageName: card?.selected_stage_name?.name || '',
                                    salutation: card?.get_contact?.salutation || '',
                                    firstName: card?.get_contact?.first_name || '',
                                    lastName: card?.get_contact?.last_name || '',
                                    profilePic: card?.profile_pic || '',
                                    selectedProduct: card?.selected_product || '',
                                    organizationName: card?.get_account?.organization_name || '',
                                    date: card?.created_at
                                }))
                            : [] // Return an empty array if there are no matching cards
                    };
                });

                setLanes(lanes);
            }
        } catch (error) {
            if (error.response && error.response.status === 400) {
                showSnackbar(error.response.data.message, "warning");
            }
        } finally {
            hideLoader();
        }
    };

    const clearUserFilter = (type) => {
        setClearType(clearType ? false : true)
        if (type === 'search') {
            setSearch('');
        }
    }

    //this code manage close popup for won and loss deal when used move from won stage to loss stage, its wise versa
    const closeClosedWonDialog = () => {
        setCloseDealType()
        setClosedWonDialogOpen(false);
        setStageId()
        setReloadPage(reloadPage ? false : true);
    }

    //Used for this function is get a deal data when a open popup for close won and close loss.
    const getDealData = async (id) => {
        setDeal([])
        setDealAccountData([])
        showLoader();
        try {
            const response = await axiosInstance.get('api/deal/edit/' + id);
            if (response.status === 200) {
                setDeal(response?.data?.data?.record?.dealData || [])
                setDealAccountData(response?.data?.data?.record?.dealData?.AccountData || [])
            }
        } catch (error) {
            if (error.response && (error.response.status === 400 || error.response.status === 300)) {
                showSnackbar(error.response.data.message, "warning");
            }
        } finally {
            hideLoader();
        }
    };

    //used for this function is manage to update deal stage when used move card from one stage to another stage.
    const updateNewState = async (deald, stageId, stageName, destinationIndex) => {
        const data = {
            id: deald,
            stage: stageId,
            position: destinationIndex,
        }

        //need to store data for changes status after submit popup 
        setUpdateData({
            id: deald,
            stage: stageId,
            position: destinationIndex,
        })

        //check card move To Closed Won or Closed Lost stage? if yes than before move need to open popup box and enter required data for than and after submit data popup will close and update stage status on this deal. 
        if (deald && (stageName === 'Closed Won' || stageName === 'Closed Lost')) {
            if (stageName === 'Closed Won' || stageName === 'Closed Lost') {
                setStageId(stageId);
                setCloseDealType(stageName === 'Closed Won' ? 'Won' : 'Lost');

                // Wait for the getDealData function to complete before proceeding
                await getDealData(deald);
            }
        } else {
            //if card move to other stages except Closed Won or Closed Lost stage than direct update stage to deal ne need to open popup box. 
            await axiosInstance.put('api/deal-stage/mode', data).then(response => {
                if (response.status === 200) {
                    showSnackbar(response.data.message, "success");
                    setReloadPage(reloadPage ? false : true)
                }
            }).then(json => {

            }).catch(error => {
                if (error.response) {
                    hideLoader()
                    if (error.response && error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                    }
                }
            });

        }
    }


    //handle drage function for card and get card value for update status on deal
    const handleOnDragEnd = async (result) => {
        if (!result.destination) return;

        const { source, destination } = result;

        const sourceLane = lanes[source.droppableId];
        const destinationLane = lanes[destination.droppableId];

        //get id from result to update stage value on lead
        const stageId = destinationLane?.id ? parseInt(destinationLane.id) : ''
        const draggableDealId = result?.draggableId ? parseInt(result?.draggableId) : ''
        const destinationStageName = destinationLane?.title || ''
        const destinationIndex = destination?.index || 0;
        // Moving within the same lane
        if (source.droppableId === destination.droppableId) {
            const newCards = Array.from(sourceLane.cards);
            const [movedCard] = newCards.splice(source.index, 1);
            newCards.splice(destination.index, 0, movedCard);

            setLanes({
                ...lanes,
                [source.droppableId]: { ...sourceLane, cards: newCards },
            });
        }
        // Moving to a different lane
        else {

            const sourceCards = Array.from(sourceLane.cards);
            const destinationCards = Array.from(destinationLane.cards);

            const [movedCard] = sourceCards.splice(source.index, 1);
            destinationCards.splice(destination.index, 0, movedCard);

            setLanes({
                ...lanes,
                [source.droppableId]: { ...sourceLane, cards: sourceCards },
                [destination.droppableId]: { ...destinationLane, cards: destinationCards },
            });

            //lets update new state for lead
            stageId && draggableDealId && destinationStageName && (await updateNewState(draggableDealId, stageId, destinationStageName, destinationIndex))
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault(); // Prevent the default form submission behavior
            getData(search);
        }
    };

    const backToList = () => {
        handleListViewFun()
    }

    //use for this function is when user move card to won or lost stage at time popup will open ans after success submit that data function 
    //will redirect here and update that stage status on deal.
    const handleReloadFunc = () => {
        if (updateData.id && updateData.stage) {
            axiosInstance.put('api/deal-stage/mode', updateData).then(response => {
                if (response.status === 200) {
                    showSnackbar(response.data.message, "success");
                    setReloadPage(reloadPage ? false : true)
                }
            }).then(json => {

            }).catch(error => {
                if (error.response) {
                    hideLoader()
                    if (error.response && error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                        setReloadPage(reloadPage ? false : true)
                    }
                }
            });
        } else {
            setReloadPage(reloadPage ? false : true)
        }
    }

    return (
        <>
            <Helmet>
                <title>Deals | {capitalize(props?.name || 'SolvCRM')}</title>
            </Helmet>


            <Grid container className='page-action-bar'>
                <Grid item xs={'auto'}>
                    <TextField
                        variant="filled"
                        placeholder="Search deals"
                        name="searchData"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        onKeyDown={handleKeyDown}
                        className="size-small page-search no-margin"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Icon path={mdiMagnify} color="currentColor" size={1} />
                                    {
                                        search &&
                                        <IconButton onClick={() => clearUserFilter('search')} >
                                            <Icon path={mdiClose} color={'currentColor'} size={'20px'} />
                                        </IconButton>
                                    }
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs={'auto'} sx={{ marginLeft: 'auto' }}>
                    <Button variant="contained" color="secondary" size='small' onClick={() => backToList()}>
                        <span>
                            <Icon path={mdiFormatListBulleted} color="currentColor" size={1} />
                            <span>List View</span>
                        </span>
                    </Button>
                </Grid>
            </Grid>
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <div className="kanban-wrapper">
                    <ul className="title-wrapper">
                        {Object.keys(lanes).map((laneId) => (
                            <li key={laneId}>
                                <Typography variant="body-large-semibold">{lanes[laneId]?.title || 'N/A'}</Typography> {lanes[laneId]?.label !== 0 && <span className="status-badge primary-filled">{lanes[laneId]?.label.replace(' deals', '')}</span>} {lanes[laneId].amount !== 0 && <span className="status-badge primary-filled">{displayCurrencySymbol(lanes[laneId].amount, currencyCode, currencySymbol, currencyStringValue)}</span>}
                            </li>
                        ))}
                    </ul>
                    <div className="board-wrapper">
                        {Object.keys(lanes).map((laneId) => (
                            <div key={laneId} className="stage">
                                <VirtualizedCardList
                                    droppableId={laneId}
                                    cards={lanes[laneId].cards}
                                    setCardsInLane={(updatedCards) =>
                                        setLanes({
                                            ...lanes,
                                            [laneId]: { ...lanes[laneId], cards: updatedCards },
                                        })
                                    }
                                    userPermissions={userPermissions}
                                />

                            </div>
                        ))}
                    </div>
                </div>
            </DragDropContext>

            {
                closedWonDialogOpen && (
                    <Suspense fallback={<div>Loading...</div>}>
                        <ClosedWonDealDialog
                            open={closedWonDialogOpen}
                            onClose={() => closeClosedWonDialog()}
                            handleReloadFunc={() => handleReloadFunc()}
                            dealData={deal}
                            dealType={closeDealType}
                            accountData={dealAccountData}
                            stageId={stageId}
                        />
                    </Suspense>
                )
            }
        </>
    );
};

export default DealKanbanView;
