import React, { Suspense, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import SettingsTab from "../components/SettingsTab";
import { Button, Checkbox, Grid, IconButton, InputAdornment, Menu, MenuItem, Stack, TablePagination, TextField, Tooltip, Typography } from "@mui/material";
import Icon from "@mdi/react";
import { mdiAccountOutline, mdiCalendarWeekBeginOutline, mdiClose, mdiDotsVertical, mdiExportVariant, mdiHistory, mdiMagnify, mdiPencilOutline, mdiPlus, mdiSort, mdiTrashCanOutline } from "@mdi/js";
import { Link } from "react-router-dom";
import CreateNewRoleDialog from "../components/CreateNewRoleDialog";
import { useLoader } from "../../../components/context/LoaderContext";
import useAxios from "../../../hooks/useAxios";
import { useSnackbar } from "../../../components/context/SnackbarContext";
import EmptyState from "../../../components/common/EmptyState";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import { capitalize, DeleteDialog, DeleteDialogConfig } from "../../../utils/Utils";
import ExportData from "../../../components/ExportData";


function Roles(props) {
    const axiosInstance = useAxios();
    const { showLoader, hideLoader, loading } = useLoader();
    const { showSnackbar } = useSnackbar();
    const { preferredDateFormat, preTimeZoneKey } = useSelector((state) => state?.user);
    const [clearType, setClearType] = useState(false);
    const [roles, setRoles] = useState([]);
    const userPermissions = useSelector((state) => state.user.permission);
    // Extract URL parameters
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(
        parseInt(process.env.REACT_APP_DEFAULT_PAGINATION)
    );
    const [totalCount, setTotalCount] = useState(0);
    const [sortOrder, setSortOrder] = useState(
        process.env.REACT_APP_DEFAULT_SORT
    );
    const [sortBy, setSortBy] = useState("created_at");

    // State management for search fields
    const [search, setSearch] = useState("");

    //handle checkbox
    const [checkedItems, setCheckedItems] = useState({});
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [reloadPage, setReloadPage] = useState(false);

    useEffect(() => {
        async function fetchData() {
            await getData(
                currentPage,
                perPage,
                sortBy,
                sortOrder,
                search,
            );
        }
        fetchData();
    }, [
        currentPage,
        perPage,
        sortBy,
        sortOrder,
        reloadPage,
        clearType,
    ]);

    const getData = async (
        currentPage,
        perPage,
        sortBy,
        sortOrder,
        search,
    ) => {
        const params = {
            search: search,
            sort_field: sortBy,
            sort_order: sortOrder,
            per_page: perPage,
        };
        showLoader()
        await axiosInstance.get(`api/role?page=${currentPage + 1}`, { params }).then((response) => {
            if (response.status === 200) {
                setRoles(response.data.data.records.data)
                setTotalCount(response?.data?.data?.records?.total);
                hideLoader()
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                    }
                }
            });
    };


    //=====Start::Write a code for handle headeMenu option ======//
    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleRowsPerPageChange = (event) => {
        setPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault(); // Prevent the default form submission behavior
            getData(
                currentPage,
                perPage,
                sortBy,
                sortOrder,
                search,
            );
        }
    };

    const handleCheckAll = (event) => {
        const { checked } = event.target;
        setIsCheckAll(checked);
        const newCheckedItems = {};
        roles.forEach((role) => {
            newCheckedItems[role.id] = checked;
        });
        setCheckedItems(newCheckedItems);
    };

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setCheckedItems({
            ...checkedItems,
            [name]: checked,
        });

        if (!checked) {
            setIsCheckAll(false);
        } else {
            const allChecked = roles.every(
                (role) => checkedItems[role.id] || role.name === name
            );
            setIsCheckAll(allChecked);
        }
    };


    // Get list of checked item IDs
    const getCheckedIds = () => {
        return Object.keys(checkedItems).filter((id) => checkedItems[id]);
    };

    // Handle single delete record
    async function deleteData(id, type) {
        const data = {
            id: id,
        }
        try {
            DeleteDialog.fire({
                ...DeleteDialogConfig({
                    confirm: async () => {
                        showLoader()
                        await axiosInstance.delete(`api/role/destroy`, { data })
                            .then((response) => {
                                if (response.status === 200) {
                                    showSnackbar(response.data.message, "success");
                                    setCheckedItems({});
                                    setIsCheckAll(false);
                                    hideLoader();
                                    setReloadPage(reloadPage ? false : true);

                                }
                            })
                            .then((json) => { })
                            .catch((error) => {
                                if (error.response) {
                                    hideLoader();
                                    if (error.response.status === 400) {
                                        showSnackbar(error.response.data.message, "warning");
                                    }
                                }
                            });
                    },
                    cancel: () => {
                        hideLoader();
                        setCheckedItems({});
                        setIsCheckAll(false);
                    }
                })
            });
        } catch (error) {
            hideLoader();
            showSnackbar('Oops Something want wrong..!', "error");
        }
    }

    // Handle multiple delete records
    async function handleBulkDelete() {
        const checkedIds = getCheckedIds();
        if (checkedIds && checkedIds.length > 0) {
            bulkDeleteData(checkedIds)
        } else {
            showSnackbar('Please select at least one record to Delete.', 'warning')
        }
    }

    // Handle multiple delete records
    const bulkDeleteData = async (ids) => {
        const data = {
            id: ids,
        }

        try {
            DeleteDialog.fire({
                ...DeleteDialogConfig({
                    confirm: async () => {
                        showLoader()
                        await axiosInstance.delete('api/role/bulk-destroy', { data })
                            .then((response) => {
                                if (response.status === 200) {
                                    showSnackbar(response.data.message, "success");
                                    setCheckedItems({});
                                    setIsCheckAll(false);
                                    setReloadPage(reloadPage ? false : true);
                                    hideLoader();

                                }
                            })
                            .then((json) => { })
                            .catch((error) => {
                                if (error.response) {
                                    hideLoader();
                                    if (error.response.status === 400) {
                                        showSnackbar(error.response.data.message, "warning");
                                    }
                                }
                            });
                    },
                    cancel: () => {
                        hideLoader();
                        setCheckedItems({});
                        setIsCheckAll(false);
                    }
                })
            });
        } catch (error) {
            hideLoader();
            showSnackbar('Oops Something want wrong..!', "error");
        }
    }
    //=====End::Write a code for handle headeMenu option ======//

    const handleReloadFunc = () => {
        setCheckedItems({});
        setIsCheckAll(false);
        hideLoader();
        setReloadPage(reloadPage ? false : true);
    }

    //======Start:Write a Export data=====//
    const checkedIds = getCheckedIds();
    const expParams = {
        search: search,
        sort_field: sortBy,
        sort_order: sortOrder,
        per_page: perPage,
        selectedIds: checkedIds && checkedIds.length > 0 ? checkedIds : []
    };

    //======End:Write a Export data=====//

    const clearUserFilter = (type) => {
        setClearType(clearType ? false : true)
        if (type === 'search') {
            setSearch('');
        } else {
            setSearch('')
        }
    }

    const [rolePageContextMenuAnchorEl, setRolePageContextMenuAnchorEl] = React.useState(null);
    const rolePageContextMenuOpen = Boolean(rolePageContextMenuAnchorEl);
    const handleRolePageContextMenuClick = (event) => {
        setRolePageContextMenuAnchorEl(event.currentTarget);
    };
    const handleRolePageContextMenuClose = () => {
        setRolePageContextMenuAnchorEl(null);
    };

    const [createNewRoleDialogOpen, setCreateNewRoleDialogOpen] = React.useState(false);
    const openCreateNewRoleDialog = () => {
        setCreateNewRoleDialogOpen(true);
    }
    const closeCreateNewRoleDialog = () => {
        setCreateNewRoleDialogOpen(false);
    }



    return (
        <>
            <Helmet>
                <title>Roles | {capitalize(props?.name || 'SolvCRM')}</title>
            </Helmet>

            <SettingsTab />

            <Grid container className='page-action-bar'>
                <Grid item xxl={'auto'} xs={12} sx={{ marginLeft: { xxl: 'auto' }, order: { xxl: 1 }, marginBottom: { xxl: 0, xs: '12px' } }}>
                    <Stack direction="row" spacing={1}>
                        {userPermissions && userPermissions.includes("role_create") &&
                            <Button size="small" onClick={openCreateNewRoleDialog}>
                                <span>
                                    <Icon path={mdiPlus} color="currentColor" size={1} />
                                    <span>Create New</span>
                                </span>
                            </Button>
                        }
                        <Tooltip title="More">
                            <span>
                                <Button variant="contained" color="secondary" size='small' className='icon-only' onClick={handleRolePageContextMenuClick}>
                                    <Icon path={mdiDotsVertical} color="currentColor" size={1} />
                                </Button>
                            </span>
                        </Tooltip>

                        <Menu
                            anchorEl={rolePageContextMenuAnchorEl}
                            open={rolePageContextMenuOpen}
                            onClose={handleRolePageContextMenuClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right'
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                        >
                            <MenuItem onClick={(e) => { handleRolePageContextMenuClose() }}>
                                <Icon path={mdiExportVariant} color={"currentColor"} size={1} />
                                <ExportData params={expParams} fileName="Role" api="role" handleReloadFunc={handleReloadFunc} />
                            </MenuItem>
                            {userPermissions && userPermissions.includes("role_delete") &&
                                <MenuItem
                                    onClick={() => {
                                        handleBulkDelete(); handleRolePageContextMenuClose();
                                    }}
                                >
                                    <Icon path={mdiTrashCanOutline} color={'currentColor'} size={1} />
                                    <span>Delete</span>
                                </MenuItem>
                            }
                        </Menu>
                    </Stack>
                </Grid>
                <Grid item xxl={"auto"} xs={12}>
                    <Stack direction="row" spacing={1} alignItems={'center'}>
                        <Checkbox
                            className="grid-checkbox"
                            checked={isCheckAll}
                            onChange={handleCheckAll}
                        />
                        <TextField
                            variant="filled"
                            placeholder="Search roles"
                            name="searchData"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            onKeyDown={handleKeyDown}
                            className="size-small page-search no-margin"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Icon path={mdiMagnify} color="currentColor" size={1} />
                                        {
                                            search &&
                                            <IconButton onClick={() => clearUserFilter('search')} >
                                                <Icon path={mdiClose} color={'currentColor'} size={'20px'} />
                                            </IconButton>
                                        }

                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Stack>
                </Grid>
            </Grid>
            {roles && roles.length > 0 && (
                <ul className="primary-list">
                    {roles.map((role, index) =>
                        <li key={index + ''}>
                            <Link to={userPermissions?.includes("role_edit") && role?.name !== 'SuperAdmin' ? '/settings/role-detail/' + role?.id : '#'} className="card">
                                <Grid container alignItems={'flex-start'}>
                                    <Grid item xs={'auto'}>
                                        <Checkbox
                                            className="grid-checkbox"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                            }}
                                            name={String(role.id)}
                                            checked={checkedItems[role.id] || false}
                                            onChange={handleCheckboxChange}
                                        />
                                    </Grid>
                                    <Grid item xs>
                                        <Typography variant='title-medium-bold' component={'h6'}>{capitalize(role?.name || 'N/A')}</Typography>
                                        <ul className='inline-details'>
                                            <li>
                                                <Tooltip title="Added by">
                                                    <span>
                                                        <Icon path={mdiAccountOutline} color="currentColor" size={1} />{role?.selected_role || 'N/A'}
                                                    </span>
                                                </Tooltip>
                                            </li>
                                            <li>
                                                <Tooltip title="Created Date">
                                                    <span>
                                                        <Icon path={mdiCalendarWeekBeginOutline} color="currentColor" size={1} />
                                                        {role?.created_at
                                                            ? moment.utc(role.created_at)
                                                                .tz(preTimeZoneKey)
                                                                .format(preferredDateFormat)
                                                            : "N/A"}
                                                    </span>
                                                </Tooltip>
                                            </li>
                                        </ul>
                                    </Grid>
                                </Grid>
                                <ul className='inline-details other-info no-avatar'>
                                    <li>
                                        <Tooltip title="Last Updated">
                                            <span>
                                                <Icon path={mdiHistory} color="currentColor" size={1} />
                                                {role?.created_at
                                                    ? moment.utc(role.created_at)
                                                        .tz(preTimeZoneKey)
                                                        .format(preferredDateFormat)
                                                    : "N/A"}
                                            </span>
                                        </Tooltip>
                                    </li>
                                </ul>
                            </Link>

                            <Stack direction={'row'} className='actions'>
                                {userPermissions && userPermissions.includes("role_edit") &&
                                    <Tooltip title={role?.name !== 'SuperAdmin' ? 'Edit' : 'Editing the superAdmin role is not permitted.'}>
                                        <span>
                                            <Link to={role?.name !== 'SuperAdmin' ? '/settings/role-detail/' + role?.id : '#'} className='icon-button'>
                                                <Icon path={mdiPencilOutline} color="currentColor" size={1} />
                                            </Link>
                                        </span>
                                    </Tooltip>
                                }
                                {userPermissions && userPermissions.includes("role_delete") &&
                                    <Tooltip title="Delete">
                                        <span>
                                            <button type="button" className='icon-button' onClick={() => deleteData(role?.id)}>
                                                <Icon path={mdiTrashCanOutline} color="currentColor" size={1} />
                                            </button>
                                        </span>
                                    </Tooltip>
                                }
                            </Stack>
                        </li>
                    )}
                </ul>
            )}

            {(!roles || roles.length === 0) && !loading &&
                <EmptyState type={'role'} hideActionBtn />
            }

            {
                roles && roles.length > 0 &&
                <TablePagination
                    component="div"
                    count={totalCount}
                    page={currentPage}
                    rowsPerPage={perPage}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                />
            }

            {
                createNewRoleDialogOpen && (
                    <Suspense fallback={<div>Loading...</div>}>
                        <CreateNewRoleDialog
                            open={createNewRoleDialogOpen}
                            onClose={closeCreateNewRoleDialog}
                            handleReloadFunc={handleReloadFunc}
                        />
                    </Suspense>
                )}
        </>
    );
}

export default Roles;