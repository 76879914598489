import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { Button, Dialog, Grid, MenuItem, TextField, Tooltip } from "@mui/material";
import React from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { useSnackbar } from '../../../components/context/SnackbarContext';
import useAxios from '../../../hooks/useAxios';
import { useLoader } from "../../../components/context/LoaderContext";

function CreateNewLeadDialog({ onClose, open, leadSalutation, leadSources, handleReloadFunc }) {
    const axiosInstance = useAxios();
    const { showSnackbar } = useSnackbar();
    const { showLoader, hideLoader } = useLoader();

    const validationSchema = yup.object().shape({
        first_name: yup.string().trim('First name cannot include space').strict(true).min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters').required('Enter first name'),
        last_name: yup.string().trim('Last name cannot include space').strict(true).min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters').required('Enter last name'),
        title: yup.string().trim('Title cannot include space').strict(true).min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters'),
        organization_name: yup.string().trim('Company name cannot include space').strict(true).min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters').required('Enter company name'),

    })

    const handleSubmit = async (values, onSubmitProps) => {
        const data = {
            salutation: values.salutation,
            first_name: values.first_name,
            last_name: values.last_name,
            title: values.title,
            organization_name: values.organization_name,
            lead_source: values.lead_source,
        }
        showLoader()
        await axiosInstance.post("/api/lead/store", data).then(response => {
            if (response.status === 200) {
                hideLoader()
                showSnackbar(response.data.message, 'success')
                onClose()
                handleReloadFunc()
            }
        }).then(json => {

        }).catch(error => {
            if (error.response) {
                hideLoader()
                onSubmitProps.setSubmitting(false)
                if (error.response.status === 422 || error.response.data.validation_errors) {
                    const errors = error.response.data.validation_errors;
                    Object.keys(errors).forEach(key => {
                        onSubmitProps.setFieldError(key, errors[key][0]);
                    });
                } else {
                    showSnackbar(error.response.data.message, 'error')
                }
            }
        });
    }

    return (
        <>
            <Dialog onClose={onClose} open={open} maxWidth={'md'}>
                <div className="dialog-title">
                    <h2>Create New Lead</h2>
                    <Tooltip title="Close">
                        <button className="icon-button dialog-close" onClick={onClose}>
                            <Icon path={mdiClose} color={'currentColor'} size={1} />
                        </button>
                    </Tooltip>
                </div>
                <Formik
                    initialValues={{
                        salutation: 'Mr',
                        first_name: '',
                        last_name: '',
                        organization_name: '',
                        error_list: [],
                        lead_source: '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize
                >
                    {props => {
                        const {
                            touched,
                            errors,
                            values,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        } = props;
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container spacing={1.5}>
                                    <Grid item xs="auto">
                                        <TextField
                                            select
                                            className="no-label"
                                            value={values.salutation}
                                            name="salutation"
                                            variant="filled"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.salutation && touched.salutation}
                                            helperText={(errors.salutation && touched.salutation) && errors.salutation}

                                        >
                                            {Object.entries(leadSalutation).map(([key, value], index) => (
                                                <MenuItem key={index} value={key}>
                                                    {value}
                                                </MenuItem>
                                            ))}

                                        </TextField>
                                    </Grid>
                                    <Grid item xs>
                                        <TextField
                                            label="First Name"
                                            variant="filled"
                                            name="first_name"
                                            value={values.first_name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.first_name && touched.first_name}
                                            helperText={(errors.first_name && touched.first_name) && errors.first_name}
                                        />
                                    </Grid>
                                </Grid>

                                <TextField
                                    label="Last Name"
                                    variant="filled"
                                    name="last_name"
                                    value={values.last_name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.last_name && touched.last_name}
                                    helperText={(errors.last_name && touched.last_name) && errors.last_name}
                                />

                                <TextField
                                    label="Title"
                                    variant="filled"
                                    name="title"
                                    value={values.title}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.title && touched.title}
                                    helperText={(errors.title && touched.title) && errors.title}
                                />

                                <TextField
                                    label="Company Name"
                                    variant="filled"
                                    name="organization_name"
                                    value={values.organization_name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.organization_name && touched.organization_name}
                                    helperText={(errors.organization_name && touched.organization_name) && errors.organization_name}
                                />

                                <TextField
                                    select
                                    label="Lead Source"
                                    value={values.lead_source}
                                    name="lead_source"
                                    variant="filled"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.lead_source && touched.lead_source}
                                    helperText={(errors.lead_source && touched.lead_source) && errors.lead_source}

                                >
                                    {
                                        leadSources && leadSources.length > 0 ?
                                            leadSources.map((source, index) => (
                                                <MenuItem key={index} value={source.title}>
                                                    {source.title}
                                                </MenuItem>
                                            )) :
                                            <MenuItem value={-1}></MenuItem>

                                    }

                                </TextField>

                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Button type="submit" className="btn-block">Create</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button color="secondary" className="btn-block" onClick={onClose}>Cancel</Button>
                                    </Grid>
                                </Grid>
                            </form>
                        );
                    }}
                </Formik>
            </Dialog>
        </>
    );
}

export default CreateNewLeadDialog;