import React, { Suspense, useContext, useEffect, useState } from "react";
import { Box, Button, Grid, Stack, Tooltip, Typography } from "@mui/material";
import Icon from "@mdi/react";
import { mdiAttachment, mdiBriefcaseCheckOutline, mdiBullhornVariantOutline, mdiBullseyeArrow, mdiCalendarCheckOutline, mdiCalendarMultiple, mdiCheckboxMultipleMarkedCircleOutline, mdiEmailOutline, mdiEmoticonExcitedOutline, mdiFilterOutline, mdiFormatListChecks, mdiHeadset, mdiMapMarkerOutline, mdiNoteTextOutline, mdiPhoneOutline, mdiThumbUpOutline } from "@mdi/js";
import { Helmet } from "react-helmet";
import AvatarInitials from "../components/common/AvatarInitials";
import DashedBarChart from "../components/common/DashedBarChart";
import { ThemeContext } from "../App";
import AttachmentViewerDialog from "../components/common/AttachmentViewerDialog";
import { RectangleProgress30, RectangleProgress90, RectangleProgress95, UserAvatar2, UserAvatar3 } from "../assets/images";
import FormGroupStatic from "../components/common/FormGroupStatic";
import { useSnackbar } from '../components/context/SnackbarContext';
import useAxios from '../hooks/useAxios';
import { useLoader } from "../components/context/LoaderContext";
import { Link, useNavigate } from "react-router-dom";
import { capitalize, displayDate } from "../utils/Utils";
import moment from "moment-timezone";
import { useSelector } from "react-redux";

function DashboardXlFinancial(props) {
    const { currentTheme } = useContext(ThemeContext);
    const navigate = useNavigate();
    const axiosInstance = useAxios();
    const { showSnackbar } = useSnackbar();
    const { showLoader, hideLoader, loading } = useLoader();
    const [dataCount, setDataCount] = useState({})
    const { preferredDateFormat, preTimeZoneKey } = useSelector(state => state?.user);

    const [funnelChartData, setFunnelChartData] = useState('');
    const [leads, setLeads] = useState([]);
    const [activities, setActivities] = useState([]);
    const [priorityList, setPriorityList] = useState([]);
    const [priorityStatus, setPriorityStatus] = useState([]);
    const [attachments, setAttachments] = useState([]);

    useEffect(() => {
        async function fetchData() {
            await getDashboardData();
        }
        fetchData();
    }, []);

    async function getDashboardData() {
        showLoader()
        await axiosInstance.get("api/dashboard-mobile?type=web").then(response => {
            if (response.status === 200) {
                setDataCount({
                    'my_deal': response?.data?.data?.dataCounts?.my_deal,
                    'my_lead': response?.data?.data?.dataCounts?.my_lead,
                    'my_task': response?.data?.data?.dataCounts?.my_task,
                    'my_activities': response?.data?.data?.dataCounts?.my_activities
                })
                setLeads(response?.data?.data?.leadRecords || [])
                setFunnelChartData(response.data.data.funnelChartData)
                setActivities(response.data.data.activities || [])
                setPriorityList(response?.data?.data?.priority)
                setPriorityStatus(response?.data?.data?.priorityStatus)
                hideLoader()
            }
        }).then(json => {

        }).catch(error => {
            if (error.response) {
                hideLoader()
                if (error.response.status === 400) {
                    showSnackbar(error.response.data.message, 'error')
                }
            }
        });
    }

    useEffect(() => {
        if (funnelChartData) {
            setLeadVsDealOverview([
                {
                    data: funnelChartData?.dealCount || 0,
                    label: 'Deals',
                    borderColor: currentTheme?.chartColor.colorOne500,
                    dashColor: currentTheme?.chartColor.colorOne200
                }, {
                    data: funnelChartData?.leadCount || 0,
                    label: 'Leads',
                    borderColor: currentTheme?.chartColor.colorTwo500,
                    dashColor: currentTheme?.chartColor.colorTwo200
                }, {
                    data: funnelChartData?.wondeal || 0,
                    label: 'Won Deals',
                    borderColor: currentTheme?.chartColor.colorThree500,
                    dashColor: currentTheme?.chartColor.colorThree200
                }
            ]);
        }
    }, [funnelChartData, currentTheme]);

    const [leadVsDealOverview, setLeadVsDealOverview] = useState(null);

    const [attachmentViewerDialogOpen, setAttachmentViewerDialogOpen] = React.useState(false);
    const openAttachmentViewerDialog = (attachment) => {
        setAttachments(attachment)
        setAttachmentViewerDialogOpen(true);
    }

    const closeAttachmentViewerDialog = () => {
        setAttachmentViewerDialogOpen(false);
        setAttachments([])
    }


    return (
        <>
            <Helmet>
                <title>Dashboard | {capitalize(props?.name || 'SolvCRM')}</title>
            </Helmet>

            {
                !loading &&
                <>
                    <Stack spacing={1} direction={'row'} className="page-tabs">
                        <Button variant="contained" color="secondary" size='small'>Link 1</Button>
                        <Button variant="contained" color="secondary" size='small'>Link 2</Button>
                        <Button variant="contained" color="secondary" size='small'>Link 3</Button>
                        <Button variant="contained" color="secondary" size='small'>Link 4</Button>
                        <Button variant="contained" color="secondary" size='small'>Link 5</Button>
                        <Button variant="contained" color="secondary" size='small'>Link 6</Button>
                    </Stack>

                    <Grid container spacing={2}>
                        <Grid item xl={3} xs={6}>
                            <div className="card dashboard-card">
                                <Typography variant="label-large-bold">Deals</Typography>
                                <Typography variant="headline-medium-bold" className="dark-gradient-text" component={'h2'}>{dataCount?.my_deal || 0}</Typography>
                                <Icon path={mdiBriefcaseCheckOutline} color={'currentColor'} size={1} />
                            </div>
                        </Grid>

                        <Grid item xl={3} xs={6}>
                            <div className="card dashboard-card">
                                <Typography variant="label-large-bold">Leads</Typography>
                                <Typography variant="headline-medium-bold" className="dark-gradient-text" component={'h2'}>{dataCount?.my_lead || 0}</Typography>
                                <Icon path={mdiNoteTextOutline} color={'currentColor'} size={1} />
                            </div>
                        </Grid>

                        <Grid item xl={3} xs={6}>
                            <div className="card dashboard-card">
                                <Typography variant="label-large-bold">Tasks</Typography>
                                <Typography variant="headline-medium-bold" className="dark-gradient-text" component={'h2'}>{dataCount?.my_task || 0}</Typography>
                                <Icon path={mdiCalendarCheckOutline} color={'currentColor'} size={1} />
                            </div>
                        </Grid>

                        <Grid item xl={3} xs={6}>
                            <div className="card dashboard-card">
                                <Typography variant="label-large-bold">Events</Typography>
                                <Typography variant="headline-medium-bold" className="dark-gradient-text" component={'h2'}>{dataCount?.my_activities || 0}</Typography>
                                <Icon path={mdiCalendarMultiple} color={'currentColor'} size={1} />
                            </div>
                        </Grid>

                        <Grid item xxl={4} lg={6} xs={12}>
                            <Typography variant="title-medium-large-semibold" component={'h6'} className="pb-16">Leads vs. Deals Overview</Typography>
                            <div className="card p-16 mb-16">
                                <DashedBarChart className="mb-12" items={leadVsDealOverview} />

                                <Grid container spacing={1} className="text-center">
                                    {leadVsDealOverview?.map((item, index) => <Grid key={index + ''} item xs={4}>
                                        <Typography variant="body-medium" component={'p'} className="text-secondary">{item.label}</Typography>
                                        <Typography variant="headline-medium-semibold" component={'span'} className="dark-gradient-text">{item.data}</Typography>
                                    </Grid>
                                    )}
                                </Grid>
                            </div>

                            <Typography variant="title-medium-large-semibold" component={'h6'} className="pb-16">AI Auto-Pilot</Typography>

                            <div className="card p-16 mb-12">
                                <Typography variant="title-medium">Integrated AI Assistant</Typography>
                                <hr className="mt-12 mb-16 strong" />

                                <Grid container spacing={1.5}>
                                    <Grid item xs>
                                        <div className="achievements">
                                            <div className="icon-wrapper">
                                                <Icon path={mdiFormatListChecks} color={'currentColor'} size={1} />
                                            </div>
                                            <Typography variant="headline-medium-semibold" component={'span'} className="dark-gradient-text">150</Typography>
                                            <Typography variant="body-medium" component={'p'} className="text-secondary">Tasks Completed</Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs>
                                        <div className="achievements">
                                            <div className="icon-wrapper">
                                                <Icon path={mdiFilterOutline} color={'currentColor'} size={1} />
                                            </div>
                                            <Typography variant="headline-medium-semibold" component={'span'} className="dark-gradient-text">200</Typography>
                                            <Typography variant="body-medium" component={'p'} className="text-secondary">Lead Engagement</Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs>
                                        <div className="achievements">
                                            <div className="icon-wrapper">
                                                <Icon path={mdiBullseyeArrow} color={'currentColor'} size={1} />
                                                <img src={RectangleProgress95} alt="" className="progress" />
                                            </div>
                                            <Typography variant="headline-medium-semibold" component={'span'} className="dark-gradient-text">95%</Typography>
                                            <Typography variant="body-medium" component={'p'} className="text-secondary">Accuracy Rate</Typography>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>

                            <div className="card p-16 mb-12">
                                <Typography variant="title-medium">Marketing Content Automation</Typography>
                                <hr className="mt-12 mb-16 strong" />

                                <Grid container spacing={3}>
                                    <Grid item xs>
                                        <div className="achievements">
                                            <div className="icon-wrapper">
                                                <Icon path={mdiBullhornVariantOutline} color={'currentColor'} size={1} />
                                            </div>
                                            <Typography variant="headline-medium-semibold" component={'span'} className="dark-gradient-text">10</Typography>
                                            <Typography variant="body-medium" component={'p'} className="text-secondary">Campaigns Created</Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs>
                                        <div className="achievements">
                                            <div className="icon-wrapper">
                                                <Icon path={mdiThumbUpOutline} color={'currentColor'} size={1} />
                                                <img src={RectangleProgress30} alt="" className="progress" />
                                            </div>
                                            <Typography variant="headline-medium-semibold" component={'span'} className="dark-gradient-text">30%</Typography>
                                            <Typography variant="body-medium" component={'p'} className="text-secondary">Engagement Rate</Typography>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>

                            <div className="card p-16">
                                <Typography variant="title-medium">Communication Response Automation</Typography>
                                <hr className="mt-12 mb-16 strong" />

                                <Grid container spacing={3}>
                                    <Grid item xs>
                                        <div className="achievements">
                                            <div className="icon-wrapper">
                                                <Icon path={mdiBullhornVariantOutline} color={'currentColor'} size={1} />
                                            </div>
                                            <Typography variant="headline-medium-semibold" component={'span'} className="dark-gradient-text">-50%</Typography>
                                            <Typography variant="body-medium" component={'p'} className="text-secondary">Response Time</Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs>
                                        <div className="achievements">
                                            <div className="icon-wrapper">
                                                <Icon path={mdiEmoticonExcitedOutline} color={'currentColor'} size={1} />
                                                <img src={RectangleProgress90} alt="" className="progress" />
                                            </div>
                                            <Typography variant="headline-medium-semibold" component={'span'} className="dark-gradient-text">90%</Typography>
                                            <Typography variant="body-medium" component={'p'} className="text-secondary">Satisfaction Rate</Typography>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>

                        <Grid item xxl={4} lg={6} xs={12}>
                            <Typography variant="title-medium-large-semibold" component={'h6'} className="pb-16">Current Leads</Typography>

                            <div className={"overflow-wrapper lg mb-16 " + (leads?.length < 10 ? "hidden" : '')}>
                                <Box className="primary-list content pb-0" component={'ul'} sx={{ maxHeight: { lg: 658, xs: 'auto' } }}>
                                    {leads.map((lead, index) =>
                                        <li key={index + ''}>
                                            <Link to={'/lead-detail/' + lead?.id} className="card">
                                                <Grid container alignItems={'flex-start'} spacing={1.5} className="lead-details">
                                                    <Grid item xs="auto">
                                                        <AvatarInitials name={lead?.first_name + " " + lead?.last_name} />
                                                    </Grid>
                                                    <Grid item xs>
                                                        <Typography variant='title-medium-bold' component={'h6'}>
                                                            {lead?.salutation ? lead.salutation + ". " : ""}{" "}
                                                            {lead?.first_name + " " + lead?.last_name}</Typography>
                                                        <ul className='inline-details'>
                                                            <li><Tooltip title="Title"><span>{lead?.title || "N/A"}</span></Tooltip></li>
                                                        </ul>
                                                    </Grid>
                                                </Grid>
                                            </Link>
                                            <Stack direction={'row'} className='actions'>
                                                {
                                                    lead?.phone1 &&
                                                    <Tooltip title="Call">
                                                        <span>
                                                            <a href={"tel:" + lead?.phone1} className='icon-button'>
                                                                <Icon path={mdiPhoneOutline} color="currentColor" size={1} />
                                                            </a>
                                                        </span>
                                                    </Tooltip>
                                                }
                                                {
                                                    lead.primary_email &&
                                                    <Tooltip title="Mail">
                                                        <span>
                                                            <a href={"mailto:" + lead.primary_email} className='icon-button'>
                                                                <Icon path={mdiEmailOutline} color="currentColor" size={1} />
                                                            </a>
                                                        </span>
                                                    </Tooltip>
                                                }

                                            </Stack>
                                        </li>
                                    )}
                                </Box>
                                <div className="overflow-action">
                                    <Button type="button" onClick={() => navigate('/leads')} size="small" color="secondary">See All</Button>
                                </div>
                            </div>

                            <Typography variant="title-medium-large-semibold" component={'h6'} className="pb-16">Top Performing Sales Reps</Typography>

                            <Grid container spacing={1.5}>
                                <Grid item xs={6}>
                                    <div className="card p-16">
                                        <Stack direction={'row'} alignItems={'center'} spacing={1.25}>
                                            <img src={UserAvatar2} alt="Michael Anderson" className="user-avatar-sm" />
                                            <Typography variant="body-medium" component={'h6'}>Michael Anderson</Typography>
                                        </Stack>
                                        <hr className="strong my-12" />
                                        <Stack direction={'column'} spacing={1.5}>
                                            <FormGroupStatic label="Total Sales" value="$200,000" />
                                            <FormGroupStatic label="Average Deal Size" value="$8,000" />
                                            <FormGroupStatic label="Deals Closed" value="25" />
                                            <FormGroupStatic label="Conversion Rate" value="30%" />
                                        </Stack>
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <div className="card p-16">
                                        <Stack direction={'row'} alignItems={'center'} spacing={1.25}>
                                            <img src={UserAvatar3} alt="Sarah Johnson" className="user-avatar-sm" />
                                            <Typography variant="body-medium" component={'h6'}>Sarah Johnson</Typography>
                                        </Stack>
                                        <hr className="strong my-12" />
                                        <Stack direction={'column'} spacing={1.5}>
                                            <FormGroupStatic label="Total Sales" value="$180,000" />
                                            <FormGroupStatic label="Average Deal Size" value="$8,182" />
                                            <FormGroupStatic label="Deals Closed" value="22" />
                                            <FormGroupStatic label="Conversion Rate" value="28%" />
                                        </Stack>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xxl={4} xs={12}>
                            <Typography variant="title-medium-large-semibold" component={'h6'} className="pb-16">Recent Activities</Typography>

                            <div className={"overflow-wrapper xxl " + (activities?.length < 10 ? "hidden" : '')}>
                                <Box className="content" sx={{ maxHeight: { xxl: 979, xs: 'none' } }}>
                                    <Grid container spacing={1} className="activity-list height-auto" component={'ul'}>
                                        {activities.map((activity, index) => {
                                            var isFullDay = activity?.is_fullday ? true : false
                                            return (
                                                <Grid item component={'li'} xxl={12} lg={6} xs={12} key={index + ''}>
                                                    <div className={"activity card no-action " + (activity?.attachment?.length > 0 ? 'has-attachment ' : '')}>
                                                        <Stack direction={'row'} spacing={1}>
                                                            {activity.activity_type === 'event' && <Icon path={mdiCalendarCheckOutline} color={'currentColor'} size={1} className="icon" />}
                                                            {activity.activity_type === 'task' && <Icon path={mdiCheckboxMultipleMarkedCircleOutline} color={'currentColor'} size={1} className="icon" />}
                                                            {activity.activity_type === 'Log a Visit' && <Icon path={mdiMapMarkerOutline} color={'currentColor'} size={1} className="icon" />}
                                                            <div className="details">
                                                                <Typography variant="title-medium-bold" component={'p'}>{activity?.name || ''}</Typography>
                                                                <ul className="inline-details">
                                                                    <li>{
                                                                        activity?.end_date ? isFullDay ? displayDate(moment.utc(activity.end_date).tz(preTimeZoneKey).format(preferredDateFormat), 'half') : moment.utc(activity.end_date).tz(preTimeZoneKey).format(preferredDateFormat) : 'N/A'
                                                                    }
                                                                    </li>
                                                                    {activity?.activity_type === 'task' && <><li>{activity?.priority ? priorityList[activity?.priority] : ''}</li><li>{activity?.activity_status ? priorityStatus[activity?.activity_status] : ''}</li></>}
                                                                </ul>
                                                                <Typography variant="body-medium" component={'p'}>{activity?.description}</Typography>
                                                            </div>
                                                        </Stack>
                                                        {activity?.attachment?.length > 0 && <Tooltip title="View Attachments">
                                                            <button type="button" className="attachment-btn" onClick={() => openAttachmentViewerDialog(activity?.attachment)}>
                                                                {activity?.attachment?.length}
                                                                <Icon path={mdiAttachment} color={'currentColor'} size={1} />
                                                            </button>
                                                        </Tooltip>}
                                                    </div>
                                                </Grid>
                                            )

                                        })}
                                    </Grid>
                                </Box>
                                <div className="overflow-action">
                                    <Button tydpe="button" size="small" color="secondary">See All</Button>
                                </div>
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <div className="card px-16 py-12">
                                <Stack direction={'row'} alignItems={'center'}>
                                    <Stack direction={"row"} alignItems={'center'} flexGrow={1}>
                                        <Icon path={mdiHeadset} color={'currentColor'} size={1} />
                                        <div className="vr strong ml-12 mr-16"></div>
                                        <Stack direction={'column'}>
                                            <Typography variant="body-large-semibold">Need Help? We're Here for You!</Typography>
                                            <Typography variant="body-medium" className="text-secondary">Our support team is ready to assist with any questions or issues. Reach out anytime.</Typography>
                                        </Stack>
                                    </Stack>
                                    <Button sx={{ flexShrink: 0 }} color="secondary" size="small">Contact Support</Button>
                                </Stack>
                            </div>
                        </Grid>
                    </Grid>
                </>
            }


            {
                attachmentViewerDialogOpen && (
                    <Suspense fallback={<div>Loading...</div>}>
                        <AttachmentViewerDialog open={attachmentViewerDialogOpen} onClose={closeAttachmentViewerDialog} attachments={attachments} attachmentType='/log-visit/' isDelete={false} />
                    </Suspense>
                )
            }
        </>
    );
}

export default DashboardXlFinancial;