import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { Button, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import Icon from '@mdi/react';
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import { useSnackbar } from '../../components/context/SnackbarContext';
import useAxios from '../../hooks/useAxios';
import { useLoader } from "../../components/context/LoaderContext";
import { ThemeContext } from "../../App";
import { capitalize } from "../../utils/Utils";

function ResetPassword(props) {
    const { currentTheme } = useContext(ThemeContext);
    const navigate = useNavigate();
    const axiosInstance = useAxios();
    const { showSnackbar } = useSnackbar();
    const { showLoader, hideLoader } = useLoader();
    const [showNewPassword, setShowNewPassword] = React.useState(false);
    const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
    const [showConfirmNewPassword, setShowConfirmNewPassword] = React.useState(false);
    const handleClickShowConfirmNewPassword = () => setShowConfirmNewPassword((show) => !show);

    const pathname = window.location.pathname;
    const segmentToken = pathname.substring(pathname.lastIndexOf('/') + 1);
    const search = useLocation().search;
    const email = new URLSearchParams(search).get('email');


    const validationSchema = yup.object().shape({
        password: yup.string()
            .required('Password cannot be less than 8 characters')
            .min(8)
            .max(20)
            .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\(\)\+\-\/])(?=.{8,})/, "Must Contain one Uppercase, one Lowercase, one Number and one Special Case Character"),
        confirm_password: yup.string().required('Enter your confirm password').oneOf([yup.ref('password'), null], "Password doesn't match")
    })

    const handleSubmit = async (values, onSubmitProps) => {
        const data = {
            email: email,
            password: values.password,
            confirm_password: values.confirm_password,
            token: segmentToken,
        }
        showLoader()
        await axiosInstance.post("/api/reset-password", data).then(response => {
            if (response.status === 200) {
                hideLoader()
                showSnackbar(response.data.message, 'success')
                navigate('/sign-in')
            }
        }).then(json => {

        }).catch(error => {
            if (error.response) {
                hideLoader()
                onSubmitProps.setSubmitting(false)
                //onSubmitProps.resetForm({ values: '' })
                if (error.response.status === 422 || error.response.data.validation_errors) {
                    const errors = error.response.data.validation_errors;
                    Object.keys(errors).forEach(key => {
                        onSubmitProps.setFieldError(key, errors[key]);
                    });
                } else {
                    showSnackbar(error.response.data.message, 'error')
                    navigate('/forgot-password');
                }

            }
        });
    }

    return (
        <>
            <Helmet>
                <title>Create New Password | {capitalize(props?.name || 'SolvCRM')}</title>
            </Helmet>

            <Grid container spacing={0}>
                <Grid item xs={12} xl={6} sx={{ display: { xl: 'block', xs: 'none' } }}>
                    <div className="login-banner"></div>
                </Grid>
                <Grid item xs={12} xl={6} className="login-form-outer">
                    <div className="login-form-wrapper">
                        <img src={currentTheme?.logoMark} alt={currentTheme?.appName} className="login-logo" />
                        <Typography variant="headline-medium-bold" className="login-title" component={'h2'}>Create New Password</Typography>
                        <Typography variant="body-large-semibold" className="login-subtitle" component={'p'}>Create a strong, memorable password to secure your account and ensure you won't forget it next time.</Typography>

                        <Formik
                            initialValues={{
                                password: '',
                                confirm_password: '',
                                error_list: [],
                            }}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                            enableReinitialize
                        >
                            {props => {
                                const {
                                    touched,
                                    errors,
                                    values,
                                    isSubmitting,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                } = props;
                                return (
                                    <form onSubmit={handleSubmit}>
                                        <TextField
                                            label="New Password"
                                            type={showNewPassword ? 'text' : 'password'}
                                            variant="filled"
                                            InputProps={{
                                                endAdornment:
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowNewPassword}
                                                        >
                                                            {showNewPassword
                                                                ? <Icon path={mdiEyeOffOutline} color={'currentColor'} size={1} />
                                                                : <Icon path={mdiEyeOutline} color={'currentColor'} size={1} />}
                                                        </IconButton>
                                                    </InputAdornment>
                                            }}
                                            name="password"
                                            value={values.password}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.password && touched.password}
                                            helperText={(errors.password && touched.password) && errors.password}
                                        />

                                        <TextField
                                            label="Confirm New Password"
                                            type={showConfirmNewPassword ? 'text' : 'password'}
                                            variant="filled"
                                            InputProps={{
                                                endAdornment:
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowConfirmNewPassword}
                                                        >
                                                            {showConfirmNewPassword
                                                                ? <Icon path={mdiEyeOffOutline} color={'currentColor'} size={1} />
                                                                : <Icon path={mdiEyeOutline} color={'currentColor'} size={1} />}
                                                        </IconButton>
                                                    </InputAdornment>
                                            }}
                                            name="confirm_password"
                                            value={values.confirm_password}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.confirm_password && touched.confirm_password}
                                            helperText={(errors.confirm_password && touched.confirm_password) && errors.confirm_password}
                                        />

                                        <Button type="submit" className="btn-block">Update Password</Button>
                                    </form>
                                );
                            }}
                        </Formik>

                        <div className="login-signup">
                            <Link to="/sign-in" className="primary-link"><Typography variant="label-large">Go to Sign In</Typography></Link>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </>
    );
}

export default ResetPassword;