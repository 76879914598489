import React from "react";

const FormGroupStatic = props => {
    return (
        <>
            <div className={"form-group-static " + (props.easyEdit ? 'easy-edit' : '')}>
                <label className="control-label-static">{props.label}</label>
                <p className={"form-control-static " + (props.value === 'N/A' ? 'text-secondary' : '')}>
                    {/* {!props.element && props.value} */}
                    {
                        (props.label === 'Country' ||
                            props.label === 'Product' ||
                            props.label === 'Contact' ||
                            props.label === 'Quote Owner' ||
                            props.label === 'Account Name' ||
                            props.label === 'Contact Name' ||
                            props.label === 'Deal Name' ||
                            props.label === 'Account' ||
                            props.label === 'Preferred Currency')
                            ? (
                                !isNaN(props.value)
                                    ? 'Please wait...'
                                    : props.value
                            )
                            : !props.element && props.value
                    }
                    {props.label === 'Salutation' && '.'}

                    {props.element}
                </p>
            </div>
        </>
    );
}
export default FormGroupStatic;