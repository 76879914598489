import React, { Suspense, useEffect, useState } from "react";
import {
    Grid,
    TextField,
    MenuItem,
    InputAdornment,
    Button,
    Stack,
    Checkbox,
    Typography,
    Menu,
    Popover,
    FormControlLabel,
    RadioGroup,
    Radio,
    Tooltip,
    IconButton,
} from "@mui/material";
import Icon from "@mdi/react";
import {
    mdiAccountEditOutline,
    mdiAccountOutline,
    mdiBriefcaseCheckOutline,
    mdiCalendarWeekBeginOutline,
    mdiClose,
    mdiDomain,
    mdiDotsVertical,
    mdiExportVariant,
    mdiHistory,
    mdiMagnify,
    mdiPencilOutline,
    mdiPhoneOutline,
    mdiPlus,
    mdiSort,
    mdiTrashCanOutline,
    mdiUploadOutline,
    mdiViewColumnOutline,
} from "@mdi/js";
import TablePagination from "@mui/material/TablePagination";
import AvatarInitials from "../../components/common/AvatarInitials";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import CreateNewLeadDialog from "./components/CreateNewLeadDialog";
import ImportLeadsDialog from "./components/ImportLeadsDialog";
import ChangeOwnerDialog from "./components/ChangeOwnerDialog";
import ConvertLeadToDealDialog from "./components/ConvertLeadToDealDialog";
import { Helmet } from "react-helmet";
import { useSnackbar } from "../../components/context/SnackbarContext";
import useAxios from "../../hooks/useAxios";
import { useLoader } from "../../components/context/LoaderContext";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import ExportData from "../../components/ExportData";
import LeadKanbanView from "./components/LeadKanbanView";
import { capitalize, DeleteDialog, DeleteDialogConfig } from "../../utils/Utils";
import EmptyState from "../../components/common/EmptyState";

function Leads(props) {
    const navigate = useNavigate();
    const axiosInstance = useAxios();
    const { showSnackbar } = useSnackbar();
    const { showLoader, hideLoader, loading } = useLoader();
    const { preferredDateFormat, preTimeZoneKey } = useSelector((state) => state?.user);
    const [listView, setListView] = useState(true);
    const [clearType, setClearType] = useState(false);

    const userPermissions = useSelector((state) => state.user.permission);

    // Extract URL parameters
    const [searchParams] = useSearchParams();
    const orderByParam = searchParams.get("sort_order") || process.env.REACT_APP_DEFAULT_SORT;
    const sortByParam = searchParams.get("sort_field") || "created_at";
    //add for handle apply button
    const [handleSortBy, setHandleSortBy] = useState(sortByParam);
    const [handleOrderBy, setHandleOrderBy] = useState(orderByParam);

    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(
        parseInt(process.env.REACT_APP_DEFAULT_PAGINATION)
    );
    const [totalCount, setTotalCount] = useState(0);
    const [sortOrder, setSortOrder] = useState(
        process.env.REACT_APP_DEFAULT_SORT
    );
    const [sortBy, setSortBy] = useState("created_at");
    const [sortByOpen, setSortByOpen] = useState(false);
    const [sortByAnchorEl, setSortByAnchorEl] = useState(null);

    // State management for search fields
    const [search, setSearch] = useState('');
    const [searchLeadSource, setSearchLeadSource] = useState('All Sources');
    const [searchUser, setSearchUser] = useState('All Users');

    //handle checkbox
    const [checkedItems, setCheckedItems] = useState({});
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [leadSalutation, setLeadSalutation] = useState([]);


    const [userLists, setUserLists] = useState([]);
    const [leadSourceList, setLeadSourceList] = useState([]);
    const [reloadPage, setReloadPage] = useState(false);

    const [leads, setLeads] = useState();

    useEffect(() => {
        async function fetchData() {
            showLoader();
            await getData(
                currentPage,
                perPage,
                sortBy,
                sortOrder,
                search,
                searchLeadSource,
                searchUser
            );
        }
        fetchData();
    }, [
        currentPage,
        perPage,
        sortBy,
        sortOrder,
        searchLeadSource,
        searchUser,
        reloadPage,
        clearType,
        listView,
    ]);

    const getData = async (
        currentPage,
        perPage,
        sortBy,
        sortOrder,
        search,
        searchLeadSource,
        searchUser
    ) => {
        const params = {
            search: search,
            search_user_id: searchUser === "All Users" ? "" : searchUser,
            search_lead_source:
                searchLeadSource === "All Sources" ? "" : searchLeadSource,
            sort_field: sortBy,
            sort_order: sortOrder,
            per_page: perPage,
        };

        await axiosInstance
            .get(`api/lead?page=${currentPage + 1}`, { params }).then((response) => {
                if (response.status === 200) {
                    setLeads(response?.data?.data?.records?.data);
                    setTotalCount(response?.data?.data?.records?.total);
                    setUserLists(response?.data?.data?.users || []);
                    setLeadSourceList(response?.data?.data?.leadSources || []);
                    setLeadSalutation(response?.data?.data?.leadSalutation || '');
                    hideLoader();
                    setCheckedItems({});
                    setIsCheckAll(false);
                }
            })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                        navigate("/dashboard");
                    }
                }
            });
    };

    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleRowsPerPageChange = (event) => {
        setPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };

    const handleSortByClickToOpen = (event) => {
        setSortByAnchorEl(event.currentTarget);
        setSortByOpen(true);
    };

    const handleSortByClose = () => {
        setSortByOpen(false);
        setSortByAnchorEl(null);
    };

    const handleSortByReset = () => {
        setSortByOpen(false);
        setSortByAnchorEl(null);

        setSortBy("created_at");
        setSortOrder("desc");
    };

    const handleSortByApply = () => {
        //click to manage apply button....
        setSortBy(handleSortBy);
        setSortOrder(handleOrderBy);

        setSortByOpen(false);
        setSortByAnchorEl(null);
    };

    const handleSortChange = (event) => {
        const { name, value } = event.target;
        if (name === "sort-by-radio") {
            //setSortBy(value); //if dont want to click on apply button than open it and hide setHandleSortBy
            setHandleSortBy(value);
        } else if (name === "sort-order-radio") {
            //setSortOrder(value);
            setHandleOrderBy(value);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault(); // Prevent the default form submission behavior
            getData(
                currentPage,
                perPage,
                sortBy,
                sortOrder,
                search,
                searchLeadSource,
                searchUser
            );
        }
    };

    const HandleSearchUser = (event) => {
        setSearchUser(event.target.value);
    };

    const HandleSearchSource = (event) => {
        setSearchLeadSource(event.target.value);
    };

    const handleCheckAll = (event) => {
        const { checked } = event.target;
        setIsCheckAll(checked);
        const newCheckedItems = {};
        leads.forEach((lead) => {
            newCheckedItems[lead.id] = checked;
        });
        setCheckedItems(newCheckedItems);
    };

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setCheckedItems({
            ...checkedItems,
            [name]: checked,
        });

        if (!checked) {
            setIsCheckAll(false);
        } else {
            const allChecked = leads.every(
                (lead) => checkedItems[lead.id] || lead.name === name
            );
            setIsCheckAll(allChecked);
        }
    };

    // Get list of checked item IDs
    const getCheckedIds = () => {
        return Object.keys(checkedItems).filter((id) => checkedItems[id]);
    };

    async function deleteData(id) {
        const data = {
            id: id,
        }
        try {
            DeleteDialog.fire({
                ...DeleteDialogConfig({
                    confirm: async () => {
                        showLoader()
                        await axiosInstance.delete(`api/lead/destroy`, { data })
                            .then((response) => {
                                if (response.status === 200) {
                                    showSnackbar(response.data.message, "success");
                                    setCheckedItems({});
                                    setIsCheckAll(false);
                                    hideLoader();
                                    setReloadPage(reloadPage ? false : true);
                                }
                            })
                            .then((json) => { })
                            .catch((error) => {
                                if (error.response) {
                                    hideLoader();
                                    if (error.response.status === 400) {
                                        showSnackbar(error.response.data.message, "warning");
                                        navigate("/leads");
                                    }
                                }
                            });
                    },
                    cancel: () => {
                        hideLoader();
                        setCheckedItems({});
                        setIsCheckAll(false);
                    }
                })
            });
        } catch (error) {
            hideLoader();
            showSnackbar('Oops Something want wrong..!', "error");
        }
    }

    async function handleBulkDelete() {
        const checkedIds = getCheckedIds();
        if (checkedIds && checkedIds.length > 0) {
            bulkDeleteData(checkedIds)
        } else {
            showSnackbar('Please select at least one record to Delete.', 'warning')
        }
    }

    const bulkDeleteData = async (ids) => {
        const data = {
            id: ids,
        }

        try {
            DeleteDialog.fire({
                ...DeleteDialogConfig({
                    confirm: async () => {
                        try {
                            showLoader()
                            const response = await axiosInstance.delete('api/lead/bulk-destroy', { data });
                            if (response.status === 200) {
                                showSnackbar(response.data.message, "success");
                                setCheckedItems({});
                                setIsCheckAll(false);
                                hideLoader();
                                setReloadPage(reloadPage ? false : true);
                            }
                        } catch (error) {
                            if (error.response) {
                                hideLoader();
                                if (error.response.status === 400) {
                                    showSnackbar(error.response.data.message, "warning");
                                    navigate("/leads");
                                }
                            }
                        }
                    },
                    cancel: () => {
                        setCheckedItems({});
                        setIsCheckAll(false);
                    }
                })
            });
        } catch (error) {
            showSnackbar('Oops Something want wrong..!', "error");
        }
    }


    //=======Start:Wtite a logic for change owner===========//
    const [changeOwnerDialogOpen, setChangeOwnerDialogOpen] =
        React.useState(false);
    const openChangeOwnerDialog = () => {
        const checkedIds = getCheckedIds();
        if (checkedIds && checkedIds.length > 0) {
            setChangeOwnerDialogOpen(true);
        } else {
            showSnackbar('Please select at least one record to change owner.', 'warning')
        }

    };

    const closeChangeOwnerDialog = () => {
        setChangeOwnerDialogOpen(false);
    };

    const handleChangeOwner = async (ownerId) => {
        const checkedIds = getCheckedIds();
        if (checkedIds && checkedIds.length > 0 && ownerId) {
            const formData = new FormData();
            formData.append('owner_id', ownerId)
            for (var i = 0; i < checkedIds.length; i++) {
                formData.append('lead_id[]', checkedIds[i])
            }

            await axiosInstance.post("api/lead-change-owner", formData).then(response => {
                if (response.status === 200) {
                    showSnackbar(response.data.message, "success");
                    setCheckedItems({});
                    setIsCheckAll(false);
                    hideLoader();
                    setReloadPage(reloadPage ? false : true);
                }
            }).then(json => {

            }).catch(error => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                        navigate("/leads");
                    }
                }
            });
        } else {
            showSnackbar('Please select at least one record to change owner.', 'warning')
        }
    }
    //=======End:Wtite a logic for change owner===========//

    //======Start:Write a Export data=====//
    const checkedIds = getCheckedIds();
    const expParams = {
        search: search,
        search_user_id: searchUser === "All Users" ? "" : searchUser,
        search_lead_source: searchLeadSource === "All Sources" ? "" : searchLeadSource,
        sort_field: sortBy,
        sort_order: sortOrder,
        per_page: perPage,
        selectedIds: checkedIds && checkedIds.length > 0 ? checkedIds : []
    };
    const handleReloadFunc = () => {
        setCheckedItems({});
        setIsCheckAll(false);
        hideLoader();
        setReloadPage(reloadPage ? false : true);
    }
    //======End:Write a Export data=====//

    //======Start:Write a code for handle add lead direct/import======//
    const [leadPageCreateNewContextMenuAnchorEl, setLeadPageCreateNewContextMenuAnchorEl] = React.useState(null);
    const leadPageCreateNewContextMenuOpen = Boolean(
        leadPageCreateNewContextMenuAnchorEl
    );
    const handleLeadPageCreateNewContextMenuClick = (event) => {
        setLeadPageCreateNewContextMenuAnchorEl(event.currentTarget);
    };
    const handleLeadPageCreateNewContextMenuClose = () => {
        setLeadPageCreateNewContextMenuAnchorEl(null);
    };
    //======End:Write a code for handle add lead direct/import======//

    //========Start:Write a code for create a new Lead==========//
    const [createNewLeadDialogOpen, setCreateNewLeadDialogOpen] = React.useState(false);
    const openCreateNewLeadDialog = () => {
        setCreateNewLeadDialogOpen(true);
    };
    const closeCreateNewLeadDialog = () => {
        setCreateNewLeadDialogOpen(false);
    };
    //========End:Write a code for create a new Lead==========//

    //========Start::Write a code for ImportLead==========//
    const [importLeadsDialogOpen, setImportLeadsDialogOpen] = React.useState(false);
    const openImportLeadsDialog = () => {
        setImportLeadsDialogOpen(true);
    };
    const closeImportLeadsDialog = () => {
        setImportLeadsDialogOpen(false);
    };
    //========End::Write a code for ImportLead==========//


    //========Start::Write a code for Conver lead to deal==========//
    const [convertLeadToDealDialogOpen, setConvertLeadToDealDialogOpen] = React.useState(false);
    const [converLeadState, setConverLeadState] = useState({});
    const openConvertLeadToDealDialog = (id, name, organization, title) => {
        setConverLeadState({ 'id': id, 'name': name, 'organization': organization, 'title': title })
        setConvertLeadToDealDialogOpen(true);
    };

    const closeConvertLeadToDealDialog = () => {
        setConverLeadState()
        setConvertLeadToDealDialogOpen(false);
    };
    //========End::Write a code for Conver lead to deal==========//



    const [leadPageContextMenuAnchorEl, setLeadPageContextMenuAnchorEl] =
        React.useState(null);
    const leadPageContextMenuOpen = Boolean(leadPageContextMenuAnchorEl);
    const handleLeadPageContextMenuClick = (event) => {
        setLeadPageContextMenuAnchorEl(event.currentTarget);
    };



    const handleLeadPageContextMenuClose = () => {
        setLeadPageContextMenuAnchorEl(null);
    };

    const [leadContextMenuAnchorEl, setLeadContextMenuAnchorEl] = React.useState(null);
    const leadContextMenuOpen = Boolean(leadContextMenuAnchorEl);
    const handleLeadContextMenuClick = (event, lead) => {
        setLeadContextMenuAnchorEl(event.currentTarget);
        setCurrentLead(lead);
    };

    const [currentLead, setCurrentLead] = React.useState(null);

    const handleLeadContextMenuClose = () => {
        setLeadContextMenuAnchorEl(null);
        setCurrentLead(null);
    };

    const clearUserFilter = (type) => {
        setClearType(clearType ? false : true)
        if (type === 'search') {
            setSearch('');
        } else if (type === 'source') {
            setSearchLeadSource('All Sources')
        } else if (type === 'user') {
            setSearchUser('All Users')
        } else {
            setSearchUser("All Users");
            setSearch('')
            setSearchLeadSource('All Sources')
        }

    }

    const displayStatusColor = {
        'Open': 'status-badge warning',
        'Closed Lost': 'status-badge error',
        'Closed Won': 'status-badge success',
    };

    const handleListViewFun = () => {
        setListView(listView ? false : true)
    }

    if (listView) {
        return (
            <>
                <Helmet>
                    <title>Leads | {capitalize(props?.name || 'SolvCRM')}</title>
                </Helmet>


                <Grid container className="page-action-bar">
                    <Grid
                        item
                        xxl={"auto"}
                        xs={12}
                        sx={{
                            marginLeft: { xxl: "auto" },
                            order: { xxl: 1 },
                            marginBottom: { xxl: 0, xs: "12px" },
                        }}
                    >
                        <Stack direction="row" spacing={1}>
                            {
                                userPermissions && userPermissions.includes("lead_create") &&
                                <Button
                                    size="small"
                                    onClick={handleLeadPageCreateNewContextMenuClick}
                                >
                                    <span>
                                        <Icon path={mdiPlus} color="currentColor" size={1} />
                                        <span>Create New</span>
                                    </span>
                                </Button>
                            }
                            <Menu
                                anchorEl={leadPageCreateNewContextMenuAnchorEl}
                                open={leadPageCreateNewContextMenuOpen}
                                onClose={handleLeadPageCreateNewContextMenuClose}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                            >
                                <MenuItem
                                    onClick={(e) => {
                                        handleLeadPageCreateNewContextMenuClose();
                                        openCreateNewLeadDialog();
                                    }}
                                >
                                    <Icon path={mdiPlus} color={"currentColor"} size={1} />
                                    <span>Add Manually</span>
                                </MenuItem>
                                <MenuItem
                                    onClick={(e) => {
                                        handleLeadPageCreateNewContextMenuClose();
                                        openImportLeadsDialog();
                                    }}
                                >
                                    <Icon path={mdiUploadOutline} color={"currentColor"} size={1} />
                                    <span>Import Leads</span>
                                </MenuItem>
                            </Menu>

                            <Button variant="contained" color="secondary" size="small" onClick={() => setListView(listView ? false : true)}>
                                <span>
                                    <Icon
                                        path={mdiViewColumnOutline}
                                        color="currentColor"
                                        size={1}
                                    />
                                    <span>Kanban View</span>
                                </span>
                            </Button>

                            <Button
                                variant="contained"
                                color="secondary"
                                size="small"
                                onClick={handleSortByClickToOpen}
                            >
                                <span>
                                    <Icon path={mdiSort} color="currentColor" size={1} />
                                    <span>Sort by</span>
                                </span>
                            </Button>

                            <Popover
                                open={sortByOpen}
                                anchorEl={sortByAnchorEl}
                                onClose={handleSortByClose}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                classes={{
                                    paper: "utility-menu",
                                }}
                            >
                                <Typography
                                    variant="label-small-semibold"
                                    className="small-title"
                                >
                                    Sort by
                                </Typography>
                                <RadioGroup
                                    //defaultValue="name"
                                    value={handleSortBy}
                                    name="sort-by-radio"
                                    onChange={handleSortChange}
                                >
                                    <FormControlLabel
                                        value="name"
                                        control={<Radio />}
                                        label="Name"
                                    />
                                    <FormControlLabel
                                        value="title"
                                        control={<Radio />}
                                        label="Title"
                                    />
                                    <FormControlLabel
                                        value="phone1"
                                        control={<Radio />}
                                        label="Phone"
                                    />
                                    <FormControlLabel
                                        value="organization_name"
                                        control={<Radio />}
                                        label="Org. Name"
                                    />
                                    <FormControlLabel
                                        value="added_by"
                                        control={<Radio />}
                                        label="Added by"
                                    />
                                    <FormControlLabel
                                        value="created_at"
                                        control={<Radio />}
                                        label="Created at"
                                    />
                                </RadioGroup>

                                <Typography
                                    variant="label-small-semibold"
                                    className="small-title"
                                >
                                    Sort Order
                                </Typography>
                                <RadioGroup
                                    //defaultValue="desc"
                                    value={handleOrderBy}
                                    name="sort-order-radio"
                                    onChange={handleSortChange}
                                >
                                    <FormControlLabel
                                        value="asc"
                                        control={<Radio />}
                                        label="Ascending"
                                    />
                                    <FormControlLabel
                                        value="desc"
                                        control={<Radio />}
                                        label="Descending"
                                    />
                                </RadioGroup>

                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <Button
                                            size="small"
                                            className="btn-block"
                                            onClick={handleSortByApply}
                                        >
                                            Apply
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button
                                            size="small"
                                            color="secondary"
                                            className="btn-block"
                                            onClick={handleSortByReset}
                                        >
                                            Reset
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Popover>

                            <Tooltip title="More">
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    size="small"
                                    className="icon-only"
                                    onClick={handleLeadPageContextMenuClick}
                                >
                                    <Icon path={mdiDotsVertical} color="currentColor" size={1} />
                                </Button>
                            </Tooltip>
                            <Menu
                                anchorEl={leadPageContextMenuAnchorEl}
                                open={leadPageContextMenuOpen}
                                onClose={handleLeadPageContextMenuClose}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                            >
                                <MenuItem onClick={(e) => { handleLeadPageContextMenuClose() }}>
                                    <Icon path={mdiExportVariant} color={"currentColor"} size={1} />
                                    <ExportData params={expParams} fileName="Lead" api="lead" handleReloadFunc={handleReloadFunc} />
                                </MenuItem>
                                {userPermissions && userPermissions.includes("lead_edit") &&
                                    <MenuItem
                                        onClick={(e) => {
                                            handleLeadPageContextMenuClose();
                                            openChangeOwnerDialog();
                                        }}
                                    >
                                        <Icon
                                            path={mdiAccountEditOutline}
                                            color={"currentColor"}
                                            size={1}
                                        />
                                        <span>Change Owner</span>
                                    </MenuItem>
                                }
                                {userPermissions && userPermissions.includes("lead_delete") &&
                                    <MenuItem
                                        onClick={() => {
                                            handleBulkDelete(); handleLeadPageContextMenuClose();
                                        }}
                                    >
                                        <Icon
                                            path={mdiTrashCanOutline}
                                            color={"currentColor"}
                                            size={1}
                                        />
                                        <span>Delete</span>
                                    </MenuItem>
                                }
                            </Menu>
                        </Stack>
                    </Grid>
                    <Grid item xxl={"auto"} xs={12}>
                        <Stack direction="row" spacing={1} alignItems={'center'}>
                            <Checkbox
                                className="grid-checkbox"
                                checked={isCheckAll}
                                onChange={handleCheckAll}
                            />
                            <TextField
                                variant="filled"
                                placeholder="Search leads"
                                name="searchData"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                onKeyDown={handleKeyDown}
                                className="size-small page-search no-margin"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Icon path={mdiMagnify} color="currentColor" size={1} />
                                            {search && <IconButton onClick={() => clearUserFilter('search')}>
                                                <Icon path={mdiClose} color={'currentColor'} size={'20px'} />
                                            </IconButton>
                                            }
                                        </InputAdornment>
                                    ),
                                }}
                            />

                            <TextField
                                select
                                variant="filled"
                                value={searchLeadSource}
                                className="size-small no-label no-margin"
                                onChange={HandleSearchSource}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end' className='clear-filter'>
                                            {
                                                searchLeadSource && searchLeadSource !== 'All Sources' &&
                                                <IconButton onClick={() => clearUserFilter('source')}>
                                                    <Icon path={mdiClose} color={'currentColor'} size={'20px'} />
                                                </IconButton>
                                            }

                                        </InputAdornment>
                                    ),
                                }}
                            >
                                <MenuItem key={1222} value="All Sources">
                                    All Sources
                                </MenuItem>
                                {leadSourceList &&
                                    leadSourceList.length > 0 &&
                                    leadSourceList.map((source, index) => (
                                        <MenuItem key={index} value={source.title}>
                                            {source.title}
                                        </MenuItem>
                                    ))}
                            </TextField>
                            <TextField
                                select
                                value={searchUser}
                                variant="filled"
                                className="size-small no-label no-margin"
                                onChange={HandleSearchUser}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end' className='clear-filter'>
                                            {
                                                searchUser && searchUser !== 'All Users' &&
                                                <IconButton onClick={() => clearUserFilter('user')}>
                                                    <Icon path={mdiClose} color={'currentColor'} size={'20px'} />
                                                </IconButton>
                                            }

                                        </InputAdornment>
                                    ),
                                }}
                            >
                                <MenuItem key={12224} value="All Users">
                                    All Users
                                </MenuItem>
                                {userLists &&
                                    userLists.length > 0 &&
                                    userLists.map((user, index) => (
                                        <MenuItem key={index} value={user.id}>
                                            {user.name}
                                        </MenuItem>
                                    ))}
                            </TextField>
                            {
                                (search || (searchLeadSource && searchLeadSource !== 'All Sources') || (searchUser && searchUser !== 'All Users')) &&
                                <a href="-" className="primary-link ml-12" onClick={e => { e.preventDefault(); clearUserFilter('all') }}><Typography variant='body-medium-medium'>Clear All</Typography></a>
                            }

                        </Stack>
                    </Grid>
                </Grid>
                {leads && leads.length > 0 && (
                    <ul className="primary-list">
                        {
                            leads.map((lead, index) => (
                                <li key={index + ""}>
                                    <div className="card">
                                        <Link to={userPermissions?.includes("lead_edit") ? '/lead-detail/' + lead?.id : '#'}>
                                            <Grid container alignItems={"flex-start"}>
                                                <Grid item xs={"auto"}>
                                                    <Checkbox
                                                        className="grid-checkbox"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                        }}
                                                        name={String(lead.id)}
                                                        checked={checkedItems[lead.id] || false}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                </Grid>
                                                <Grid item xs>
                                                    <Grid
                                                        container
                                                        alignItems={"flex-start"}
                                                        spacing={1.5}
                                                        className="lead-details"
                                                    >
                                                        <Grid item xs="auto">
                                                            <AvatarInitials
                                                                name={lead?.first_name + " " + lead?.last_name}
                                                            />
                                                        </Grid>
                                                        <Grid item xs>
                                                            <Typography
                                                                variant="title-medium-bold"
                                                                component={"h6"}
                                                            >
                                                                {lead?.salutation ? lead.salutation + ". " : ""}{" "}
                                                                {lead?.first_name + " " + lead?.last_name}
                                                            </Typography>
                                                            <ul className="inline-details">
                                                                <li>
                                                                    <Tooltip title="Title">
                                                                        <span>{lead?.title || "N/A"}</span>
                                                                    </Tooltip>
                                                                </li>
                                                                <li>
                                                                    <Tooltip title="Created Date">
                                                                        <span>
                                                                            <Icon
                                                                                path={mdiCalendarWeekBeginOutline}
                                                                                color="currentColor"
                                                                                size={1}
                                                                            />
                                                                            {lead?.created_at
                                                                                ? moment.utc(lead.created_at)
                                                                                    .tz(preTimeZoneKey)
                                                                                    .format(preferredDateFormat)
                                                                                : "N/A"}
                                                                        </span>
                                                                    </Tooltip>
                                                                </li>
                                                                <li>
                                                                    <Tooltip title="Company Name">
                                                                        <span>
                                                                            <Icon
                                                                                path={mdiDomain}
                                                                                color="currentColor"
                                                                                size={1}
                                                                            />
                                                                            {lead?.organization_name || "N/A"}
                                                                        </span>
                                                                    </Tooltip>
                                                                </li>
                                                                <li>
                                                                    <Tooltip title="Phone">
                                                                        <span>
                                                                            <Icon
                                                                                path={mdiPhoneOutline}
                                                                                color="currentColor"
                                                                                size={1}
                                                                            />
                                                                            <div
                                                                                onClick={(e) => {
                                                                                    e.stopPropagation();
                                                                                    e.preventDefault();
                                                                                    window.open(
                                                                                        "tel:" + lead?.phone1 ||
                                                                                        lead?.organization_phone1 ||
                                                                                        "N/A"
                                                                                    );
                                                                                }}
                                                                            >
                                                                                {lead?.phone1 ||
                                                                                    lead?.organization_phone1 ||
                                                                                    "N/A"}
                                                                            </div>
                                                                        </span>
                                                                    </Tooltip>
                                                                </li>
                                                            </ul>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <ul className="inline-details other-info">
                                                <li>
                                                    <span className="title">Source:</span>{" "}
                                                    {lead?.lead_source || "N/A"}
                                                </li>
                                                <li>
                                                    <Tooltip title="Stage">
                                                        <span>
                                                            <span className={`status-badge ${lead?.stage_name ? displayStatusColor[lead?.stage_name] : ''}`}>{lead?.stage_name}</span>
                                                        </span>
                                                    </Tooltip>
                                                </li>
                                                <li>
                                                    <Tooltip title="Last Updated">
                                                        <span>
                                                            <Icon
                                                                path={mdiHistory}
                                                                color="currentColor"
                                                                size={1}
                                                            />{" "}
                                                            {lead?.updated_at
                                                                ? moment
                                                                    .utc(lead.updated_at)
                                                                    .tz(preTimeZoneKey)
                                                                    .format(preferredDateFormat)
                                                                : "N/A"}
                                                        </span>
                                                    </Tooltip>
                                                </li>
                                                <li>
                                                    <Tooltip title="Added by">
                                                        <span>
                                                            <Icon
                                                                path={mdiAccountOutline}
                                                                color="currentColor"
                                                                size={1}
                                                            />{" "}
                                                            {lead?.added_by?.name || "N/A"}
                                                        </span>
                                                    </Tooltip>
                                                </li>
                                            </ul>
                                        </Link>
                                        <Stack direction={"row"} className="actions">
                                            {
                                                userPermissions && userPermissions.includes("lead_edit") &&
                                                <Tooltip title="Edit">
                                                    <span>
                                                        <Link to={'/lead-detail/' + lead?.id} className="icon-button">
                                                            <Icon
                                                                path={mdiPencilOutline}
                                                                color="currentColor"
                                                                size={1}
                                                            />
                                                        </Link>
                                                    </span>
                                                </Tooltip>
                                            }

                                            <Tooltip title="More">
                                                <button
                                                    type="button"
                                                    className="icon-button"
                                                    onClick={(e) => handleLeadContextMenuClick(e, lead)}
                                                >
                                                    <Icon
                                                        path={mdiDotsVertical}
                                                        color="currentColor"
                                                        size={1}
                                                    />
                                                </button>
                                            </Tooltip>

                                        </Stack>
                                    </div>
                                </li>
                            ))
                        }
                    </ul>
                )}

                {(leads?.length == 0) && !loading &&
                    <EmptyState type={'lead'} hideActionBtn />
                }

                <Menu
                    anchorEl={leadContextMenuAnchorEl}
                    open={leadContextMenuOpen}
                    onClose={handleLeadContextMenuClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                >
                    {
                        userPermissions && userPermissions.includes("deal_create") &&
                        <MenuItem onClick={() => { openConvertLeadToDealDialog(currentLead.id, currentLead?.selected_salutation ? currentLead.selected_salutation + ' ' + currentLead.first_name + ' ' + currentLead.last_name : currentLead.first_name + ' ' + currentLead.last_name, currentLead.organization_name, currentLead.title); handleLeadContextMenuClose() }}>
                            <Icon
                                path={mdiBriefcaseCheckOutline}
                                color={"currentColor"}
                                size={1}
                            />
                            <span>Convert to Deal</span>
                        </MenuItem>
                    }

                    {
                        userPermissions && userPermissions.includes("lead_delete") &&
                        <MenuItem onClick={() => { deleteData(currentLead.id); handleLeadContextMenuClose() }} >
                            <Icon path={mdiTrashCanOutline} color={"currentColor"} size={1} />
                            <span>Delete</span>
                        </MenuItem>
                    }

                </Menu>

                {
                    leads && leads.length > 0 &&
                    <TablePagination
                        component="div"
                        count={totalCount}
                        page={currentPage}
                        rowsPerPage={perPage}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleRowsPerPageChange}
                    />
                }


                {
                    createNewLeadDialogOpen && (
                        <Suspense fallback={<div>Loading...</div>}>
                            <CreateNewLeadDialog
                                open={createNewLeadDialogOpen}
                                onClose={closeCreateNewLeadDialog}
                                leadSalutation={leadSalutation}
                                leadSources={leadSourceList}
                                handleReloadFunc={handleReloadFunc}
                            />
                        </Suspense>
                    )}
                {
                    importLeadsDialogOpen && (
                        <Suspense fallback={<div>Loading...</div>}>
                            <ImportLeadsDialog
                                open={importLeadsDialogOpen}
                                onClose={closeImportLeadsDialog}
                                handleReloadFunc={handleReloadFunc}
                            />
                        </Suspense>
                    )}

                {
                    changeOwnerDialogOpen && (
                        <Suspense fallback={<div>Loading...</div>}>
                            <ChangeOwnerDialog
                                open={changeOwnerDialogOpen}
                                onClose={closeChangeOwnerDialog}
                                users={userLists}
                                handleChangeOwner={handleChangeOwner}
                                type='Lead'
                            /> </Suspense>
                    )
                }
                {
                    convertLeadToDealDialogOpen && (
                        <Suspense fallback={<div>Loading...</div>}>
                            <ConvertLeadToDealDialog
                                open={convertLeadToDealDialogOpen}
                                onClose={closeConvertLeadToDealDialog}
                                handleReloadFunc={handleReloadFunc}
                                leadData={converLeadState}
                            />
                        </Suspense>
                    )
                }
            </>
        );
    } else {
        return (
            <>
                <Helmet>
                    <title>Leads | {capitalize(props?.name || 'SolvCRM')}</title>
                </Helmet>

                {
                    !listView && (
                        <Suspense fallback={<div>Loading...</div>}>
                            <LeadKanbanView handleListViewFun={handleListViewFun} userPermissions={userPermissions} />
                        </Suspense>
                    )
                }

            </>
        );
    }
}

export default Leads;
