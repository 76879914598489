import { mdiClose, mdiFilePlusOutline, mdiFileDocument, mdiFilePdfBox, mdiImage, mdiMusicBox } from "@mdi/js";
import Icon from "@mdi/react";
import { Button, Dialog, Grid, TextField, Tooltip, Typography } from "@mui/material";
import AttachmentCenter from "../../../components/common/AttachmentCenter";
import React, { useEffect, useState } from "react";
import { useSnackbar } from "../../../components/context/SnackbarContext";
import useAxios from "../../../hooks/useAxios";
import { useLoader } from "../../../components/context/LoaderContext";
import { Formik } from "formik";
import * as yup from "yup";
import { useSelector } from "react-redux";
import MyCustomNumberFormat from "../../../components/MyCustomNumberFormat";

function CreateNewProductDialog({ onClose, open, handleReloadFunc }) {

    const axiosInstance = useAxios();
    const { showSnackbar } = useSnackbar();
    const { showLoader, hideLoader } = useLoader();
    const [selectedFiles, setSelectedFiles] = useState([]);
    const { currencyCode, currencySymbol, currencyStringValue } = useSelector(state => state?.user);

    const iconMapping = {
        image: mdiImage,
        audio: mdiMusicBox,
        file: mdiFileDocument,
        pdf: mdiFilePdfBox,
    };

    useEffect(() => {
        setSelectedFiles([]);
    }, [open]);

    const allowedMimeTypes = [
        'image/jpeg', 'image/png', 'image/gif',
        'video/mp4', 'video/quicktime', 'video/x-msvideo',
        'video/x-ms-wmv', 'video/x-matroska', 'audio/mpeg',
        'audio/ogg', 'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/vnd.oasis.opendocument.spreadsheet', 'application/vnd.oasis.opendocument.text',
        'application/vnd.oasis.opendocument.presentation', 'text/csv', 'application/pdf',
        'text/plain', 'application/vnd.ms-office', 'text/x-c', 'text/anytext', 'application/ogg', 'application/x-ogg',
        'text/comma-separated-values', 'application/x-ogg', 'text/anytext'
    ];


    const validationSchema = yup.object().shape({
        name: yup
            .string()
            .required("Enter name")
            .min(2, "The name must be at least 2 characters.")
            .max(50, "The name must not be greater than 50 characters."),
        product_code: yup.string().required('Enter product code'),
        price: yup.string().required('Enter price amount'),
        tax: yup.string().required('Enter tax amount'),
        file_url: yup
            .array()
            .nullable()
            .of(
                yup
                    .mixed()
                    .test('fileFormat', 'Unsupported file format. Please upload a valid file type.', (value) => {
                        return value && allowedMimeTypes.includes(value.type);
                    })
                    .test('fileSize', 'File size exceeds 4 MB', (value) => {
                        return value && value.size <= 4 * 1024 * 1024;
                    })
            )
            .test('validFiles', 'Files are not uploaded due to validation errors', function (value) {
                if (value) {
                    return value.every(file => {
                        const isValidFormat = allowedMimeTypes.includes(file.type);
                        const isValidSize = file.size <= 4 * 1024 * 1024;
                        return isValidFormat && isValidSize;
                    });
                }
                return true;
            }),
    });

    const handleSubmit = async (values, onSubmitProps) => {
        const formData = new FormData();
        formData.append('name', values?.name || '')
        formData.append('description', values?.description || '')
        formData.append('product_code', values?.product_code || '')
        formData.append('price', values?.price || '')
        formData.append('tax', values?.tax || '')

        for (var i = 0; i < selectedFiles.length; i++) {
            formData.append('file_url', selectedFiles[i])
        }

        try {
            showLoader();
            const response = await axiosInstance.post('/api/product/store', formData);
            if (response.status === 200) {
                hideLoader()
                showSnackbar(response.data.message, 'success');
                onClose();
                handleReloadFunc();
            }
        } catch (error) {
            hideLoader();
            if (error.response) {
                if (error.response.status === 422 || error.response.data.validation_errors) {
                    const errors = error.response.data.validation_errors;
                    Object.keys(errors).forEach(key => {
                        onSubmitProps.setFieldError(key, errors[key]);
                    });
                } else {
                    showSnackbar(error.response.data.message, 'error');
                }
            } else {

            }
        } finally {
            hideLoader();
        }
    }

    const handleFileChange = (event) => {
        const files = Array.from(event.currentTarget.files);
        const errors = [];
        const validFiles = [];

        files.forEach((file) => {
            if (file.size > 4 * 1024 * 1024) {
                errors.push(`${file.name} is too large.`);
            } else if (!allowedMimeTypes.includes(file.type)) {
                errors.push(`${file.name} has an unsupported file type.`);
            } else {
                validFiles.push(file);
            }
        });
        if (errors.length > 0) {
            showSnackbar(`${errors.length} file(s) not uploaded due to validation errors`, 'error');
        } else {

        }
        setSelectedFiles([...selectedFiles, ...validFiles]);
        event.target.value = ''
    };


    const removeFile = (index, setFieldValue, errors) => {
        const newFiles = [...selectedFiles];
        newFiles.splice(index, 1);
        setSelectedFiles(newFiles);
        const newErrors = Array.isArray(errors.file_url) ? [...errors.file_url] : [];
        newErrors.splice(index, 1);
        setFieldValue("file_url", newErrors);
    };

    return (
        <>
            <Dialog onClose={onClose} open={open} maxWidth={'md'}>
                <div className="dialog-title">
                    <h2>Create Product</h2>
                    <Tooltip title="Close">
                        <button className="icon-button dialog-close" onClick={onClose}>
                            <Icon path={mdiClose} color={'currentColor'} size={1} />
                        </button>
                    </Tooltip>
                </div>
                <Formik
                    initialValues={{
                        name: '',
                        description: '',
                        product_code: '',
                        price: '',
                        tax: '',
                        attachment: [],
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize
                >
                    {props => {
                        const {
                            touched,
                            errors,
                            values,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue
                        } = props;
                        return (
                            <form onSubmit={handleSubmit}>
                                <TextField
                                    label="Name"
                                    variant="filled"
                                    name="name"
                                    value={values?.name || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.name && touched.name}
                                    helperText={(errors.name && touched.name) && errors.name}
                                />

                                <TextField
                                    label="Product Code"
                                    variant="filled"
                                    name="product_code"
                                    value={values?.product_code || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.product_code && touched.product_code}
                                    helperText={(errors.product_code && touched.product_code) && errors.product_code}
                                />

                                <Grid item xs={12}>
                                    <div className="attachment-center">
                                        <div className="title">
                                            <Typography variant="title-medium-bold">Product Image</Typography> <Typography variant="title-small" className="file-size">(Max Size 4 MB)</Typography>
                                        </div>

                                        <div className="psuedo-upload-block">
                                            <div className="content">
                                                <Button color="secondary" className="btn-block" tabIndex={-1}>
                                                    <span>
                                                        <Icon path={mdiFilePlusOutline} color={'currentColor'} size={1} />
                                                        <span>Upload File</span>
                                                    </span>
                                                </Button>
                                            </div>
                                            <input
                                                type="file"
                                                //multiple
                                                name="file_url"
                                                className="psuedo-upload-element"
                                                onChange={(event) => {
                                                    handleFileChange(event);
                                                    setFieldValue("file_url", Array.from(event.currentTarget.files));
                                                }}
                                                onBlur={handleBlur}
                                                accept=".jpeg,.png,.jpg,.gif,.svg,.pdf,.mp4,.mp3,.3gp,.avi,.xls,.xlsx,.doc,.docx,.ppt,.pptx,.ods,.odt,.odp,.csv,.txt,.application/msword,.application/vnd.ms-powerpoint,.application/vnd.oasis.opendocument.text,.application/vnd.ms-excel,.application/vnd.openxmlformats-officedocument.wordprocessingml.document,.application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.application/vnd.openxmlformats-officedocument.presentationml.presentation"
                                                title="Allow file type are jpeg,png,jpg,gif,svg,pdf,mp4,mp3,3gpp,x-msvideo,xls,xlsx,doc,docx,ppt,pptx,ods,odt,odp,csv,anytext,plain,ms-office and MAX each file size are 4MB"
                                            />


                                        </div>
                                    </div>
                                </Grid>
                                {selectedFiles && selectedFiles.length > 0 && <Grid container component={'ul'} spacing={1.5} className="attachments">
                                    {selectedFiles.map((attachment, index) => {
                                        const fileExtension = attachment && attachment?.name?.split('.').pop().toLowerCase();

                                        let fileType = '';
                                        if (['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension)) {
                                            fileType = 'image';
                                        } else if (['mp3', 'wav', 'ogg', 'mp4'].includes(fileExtension)) {
                                            fileType = 'audio';
                                        } else if (['pdf'].includes(fileExtension)) {
                                            fileType = 'pdf';
                                        } else {
                                            fileType = 'file';
                                        }
                                        const IconComponent = iconMapping[fileType];

                                        return (
                                            <Grid item component={'li'} xs={12} key={index + ''}>
                                                <a href={attachment.fileUrl} className={(Array.isArray(errors.file_url) && errors.file_url.length > 0 && errors.file_url[index]) ? 'has-error' : ''}>
                                                    <div className="icon-wrapper">
                                                        {IconComponent && <Icon path={IconComponent} color={'currentColor'} size={1} />}
                                                    </div>
                                                    <div className="info">
                                                        <Typography variant="body-large" component={'p'}>{attachment.name}</Typography>
                                                        {attachment.size && <Typography variant="body-medium">Size: {(attachment?.size / 1024 / 1024).toFixed(2)} MB</Typography>}
                                                    </div>
                                                </a>
                                                {selectedFiles && <Tooltip title="Remove">
                                                    <button className="icon-button size-small" onClick={() => removeFile(index, setFieldValue, errors)}>
                                                        <Icon path={mdiClose} color={'currentColor'} size={1} />
                                                    </button>
                                                </Tooltip>}
                                                {Array.isArray(errors.file_url) && errors.file_url[index] && (
                                                    <ul className='error-text'>
                                                        {Array.isArray(errors.file_url[index]) ? errors.file_url[index].map((error, idx) => (
                                                            <li key={idx}>{error}</li>
                                                        )) : <li>{errors.file_url[index]}</li>}
                                                    </ul>
                                                )}
                                            </Grid>
                                        )
                                    }
                                    )}
                                </Grid>}

                                <TextField
                                    label="Description"
                                    variant="filled"
                                    multiline
                                    rows={3}
                                    name="description"
                                    value={values?.description || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />

                                <TextField
                                    variant="filled"
                                    label='Price'
                                    name="price"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter Price amount"
                                    value={values?.price || ''}
                                    InputProps={{
                                        inputComponent: MyCustomNumberFormat,
                                        inputProps: { 'currencySymbol': currencySymbol, 'currencyCode': currencyCode, 'currencyStringValue': currencyStringValue, 'displayType': 'input' },//displayType=input/text
                                        endAdornment: <span className="text-adornment">{currencyCode}</span>
                                    }}
                                    error={errors.price && touched.price}
                                    helperText={(errors.price && touched.price) && errors.price}
                                />
                                <TextField
                                    variant="filled"
                                    label='Tax'
                                    name="tax"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter Tax amount"
                                    value={values?.tax || ''}
                                    InputProps={{
                                        inputComponent: MyCustomNumberFormat,
                                        inputProps: { 'currencySymbol': currencySymbol, 'currencyCode': currencyCode, 'currencyStringValue': currencyStringValue, 'displayType': 'input' },
                                        endAdornment: <span className="text-adornment">{currencyCode}</span>
                                    }}
                                    error={errors.tax && touched.tax}
                                    helperText={(errors.tax && touched.tax) && errors.tax}
                                />

                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Button type="sumbit" className="btn-block">Create</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button color="secondary" className="btn-block" onClick={onClose}>Cancel</Button>
                                    </Grid>
                                </Grid>
                            </form>
                        );
                    }}
                </Formik>
            </Dialog>
        </>
    );
}

export default CreateNewProductDialog;