import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { Button, Dialog, Grid, TextField, Tooltip } from "@mui/material";
import React from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { useSnackbar } from '../../../components/context/SnackbarContext';
import useAxios from '../../../hooks/useAxios';
import { useLoader } from "../../../components/context/LoaderContext";

function CreateNewAccountDialog({ onClose, open, handleReloadFunc, accountId }) {
    const axiosInstance = useAxios();
    const { showSnackbar } = useSnackbar();
    const { showLoader, hideLoader } = useLoader();

    const validationSchema = yup.object().shape({
        organization_name: yup.string().trim('Company name cannot include space').strict(true).min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters').required('Enter company name'),
        organization_email: yup.string().email('Please enter valid email address').min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters'),
        organization_phone1: yup.string().max(25, 'Phone must be at most 25 characters'),
        organization_website: yup.string().trim('Website cannot include space').strict(true).min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters').required('Enter website name'),

    })

    const handleSubmit = async (values, onSubmitProps) => {
        const data = {
            organization_name: values.organization_name,
            organization_email: values.organization_email,
            organization_phone1: values.organization_phone1,
            organization_website: values.organization_website,
        }
        showLoader()
        await axiosInstance.post("/api/account/store", data).then(response => {
            if (response.status === 200) {
                hideLoader()
                showSnackbar(response.data.message, 'success')
                onClose()
                handleReloadFunc()
            }
        }).then(json => {

        }).catch(error => {
            if (error.response) {
                hideLoader()
                onSubmitProps.setSubmitting(false)
                if (error.response.status === 422 || error.response.data.validation_errors) {
                    const errors = error.response.data.message;
                    Object.keys(errors).forEach(key => {
                        onSubmitProps.setFieldError(key, errors[key]);
                    });
                } else {
                    showSnackbar(error.response.data.message, 'error')
                }
            }
        });
    }
    return (
        <>
            <Dialog onClose={onClose} open={open} maxWidth="md">
                <div className="dialog-title">
                    <h2>Create New Account</h2>
                    <Tooltip title="Close">
                        <button className="icon-button dialog-close" onClick={onClose}>
                            <Icon path={mdiClose} color={'currentColor'} size={1} />
                        </button>
                    </Tooltip>
                </div>
                <Formik
                    initialValues={{
                        organization_name: '',
                        organization_email: '',
                        organization_phone1: '',
                        organization_website: '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize
                >
                    {props => {
                        const {
                            touched,
                            errors,
                            values,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        } = props;
                        return (
                            <form onSubmit={handleSubmit}>
                                <TextField
                                    label="Company Name"
                                    variant="filled"
                                    name="organization_name"
                                    value={values.organization_name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.organization_name && touched.organization_name}
                                    helperText={(errors.organization_name && touched.organization_name) && errors.organization_name}
                                />

                                <TextField
                                    label="Company Email"
                                    variant="filled"
                                    name="organization_email"
                                    value={values.organization_email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.organization_email && touched.organization_email}
                                    helperText={(errors.organization_email && touched.organization_email) && errors.organization_email}
                                />

                                <TextField
                                    label="Company Phone"
                                    variant="filled"
                                    name="organization_phone1"
                                    value={values.organization_phone1}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.organization_phone1 && touched.organization_phone1}
                                    helperText={(errors.organization_phone1 && touched.organization_phone1) && errors.organization_phone1}
                                />
                                <TextField
                                    label="Website"
                                    variant="filled"
                                    name="organization_website"
                                    value={values.organization_website}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.organization_website && touched.organization_website}
                                    helperText={(errors.organization_website && touched.organization_website) && errors.organization_website}
                                />

                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Button type="submit" className="btn-block">Create</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button color="secondary" className="btn-block" onClick={onClose}>Cancel</Button>
                                    </Grid>
                                </Grid>
                            </form>
                        );
                    }}
                </Formik>
            </Dialog >
        </>
    );
}

export default CreateNewAccountDialog;