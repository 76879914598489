import { TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import MyCustomNumberFormat from "../MyCustomNumberFormat";
import PercentageFormatCustom from "../MyCustomPercentageFormat";

const EasyEditCustomAmountTextbox = props => {
    const [selectvalue, setSelectValue] = useState(props?.value)

    const handleChange = (event) => {
        props.setParentValue(event.target.value);
        setSelectValue(event.target.value);
    };
    props.setAllowEditInline(false)

    useEffect(() => {
        if (props.value === 'N/A') {
            setSelectValue('');
        }
    }, [props.value])

    return (
        props.label === 'Deal Amount' ?
            <TextField
                variant="filled"
                label={props.label}
                value={selectvalue}
                className="no-margin size-medium control"
                autoFocus
                name={props?.name || ''}
                InputProps={{
                    inputComponent: MyCustomNumberFormat, //here last is input/text to display on page
                    inputProps: {
                        currencySymbol: props.currencySymbol,
                        currencyCode: props.currencyCode,
                        currencyStringValue: props.currencyStringValue,
                        displayType: 'input'
                    },//displayType=input/text
                    endAdornment: <span className="text-adornment">{props.currencyCode}</span>
                }}
                onChange={handleChange}
                error={props?.errorMessages && props?.errorMessages !== undefined}
                helperText={props?.errorMessages && props?.errorMessages !== undefined}
            />
            :
            props.label === 'Deal Probability' ?
                <TextField
                    variant="filled"
                    label={props.label}
                    value={selectvalue}
                    name={props?.name || ''}
                    className="no-margin size-medium control"
                    autoFocus
                    InputProps={{
                        inputComponent: PercentageFormatCustom,
                        endAdornment: <span className="text-adornment">%</span>
                    }}
                    onChange={handleChange}
                    error={props?.errorMessages && props?.errorMessages !== undefined}
                    helperText={props?.errorMessages && props?.errorMessages !== undefined}
                />
                :
                props.label === 'Price' || props.label === 'Tax' ?
                    <TextField
                        variant="filled"
                        label={props.label}
                        value={selectvalue}
                        className="no-margin size-medium control"
                        autoFocus
                        name={props?.name || ''}
                        InputProps={{
                            inputComponent: MyCustomNumberFormat, //here last is input/text to display on page
                            inputProps: {
                                currencySymbol: props.currencySymbol,
                                currencyCode: props.currencyCode,
                                currencyStringValue: props.currencyStringValue,
                                displayType: 'input'
                            },//displayType=input/text
                            endAdornment: <span className="text-adornment">{props.currencyCode}</span>
                        }}
                        onChange={handleChange}
                        error={props?.errorMessages && props?.errorMessages !== undefined}
                        helperText={props?.errorMessages && props?.errorMessages !== undefined}
                    /> :
                    ''
    );
}

export default EasyEditCustomAmountTextbox;