import React from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "../components/context/SnackbarContext";
import useAxios from "../hooks/useAxios";
import { useLoader } from "../components/context/LoaderContext";
import moment from "moment-timezone";
import FileSaver from "file-saver";
import * as xlsx from 'xlsx';


const ExportData = ({ params, fileName = 'data', api, handleReloadFunc }) => {
    const navigate = useNavigate();
    const axiosInstance = useAxios();
    const { showSnackbar } = useSnackbar();
    const { showLoader, hideLoader } = useLoader();

    const exportDataCall = async () => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';

        showLoader();
        try {
            const response = await axiosInstance.get(`api/${api}?export=yes`, { params });
            if (response.status === 200) {
                const data = response.data.data.records;
                const ws = xlsx.utils.json_to_sheet(data);
                const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
                const excelBuffer = xlsx.write(wb, { bookType: 'xlsx', type: 'array' });
                const blob = new Blob([excelBuffer], { type: fileType });
                FileSaver.saveAs(blob, `${fileName}-${moment().format("YYYY-MM-DD HH:mm:ss")}${fileExtension}`);
                handleReloadFunc();
            }
        } catch (error) {
            if (error.response) {
                showSnackbar(error.response.data.message, 'error');
                navigate('/' + api);
            }
        } finally {
            hideLoader();
        }

    };

    return (
        <span onClick={exportDataCall}>Export {fileName}</span>
    );
};

export default ExportData;



