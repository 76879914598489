import React, { useEffect, useState } from "react";
import { Button, Popover, TextField, Typography, Tooltip, Grid } from "@mui/material";
import Icon from "@mdi/react";
import { mdiClose, mdiHelp, mdiFilePlusOutline, mdiFileDocument, mdiFilePdfBox, mdiImage, mdiMusicBox } from "@mdi/js";
import { Formik } from "formik";
import * as yup from "yup";
import useAxios from '../../hooks/useAxios';
import { useSnackbar } from "../context/SnackbarContext";

const HelpPopup = (props) => {

    const axiosInstance = useAxios();
    const { showSnackbar } = useSnackbar();
    const [selectedFiles, setSelectedFiles] = useState([]);

    const iconMapping = {
        image: mdiImage,
        audio: mdiMusicBox,
        file: mdiFileDocument,
        pdf: mdiFilePdfBox,
    };

    const allowedMimeTypes = [
        'image/jpeg', 'image/png', 'image/jpg',
    ];

    const validationSchema = yup.object().shape({
        subject: yup
            .string()
            .required("Enter Subject")
            .min(2, "The name must be at least 2 characters.")
            .max(50, "The name must not be greater than 50 characters."),
        description: yup
            .string()
            .required("Enter Description")
            .min(2, "The description must be at least 2 characters.")
            .max(150, "The description must not be greater than 150 characters."),
        file_url: yup
            .array()
            .nullable()
            .of(
                yup
                    .mixed()
                    .test('fileFormat', 'Unsupported file format. Please upload a valid file type.', (value) => {
                        return value && allowedMimeTypes.includes(value.type);
                    })
                    .test('fileSize', 'File size exceeds 4 MB', (value) => {
                        return value && value.size <= 4 * 1024 * 1024;
                    })
            )
            //.required('Please select at least one file')
            .test('validFiles', 'Files are not uploaded due to validation errors', function (value) {
                if (value) {
                    return value.every(file => {
                        const isValidFormat = allowedMimeTypes.includes(file.type);
                        const isValidSize = file.size <= 4 * 1024 * 1024;
                        return isValidFormat && isValidSize;
                    });
                }
                return true;
            }),
    });

    const handleFileChange = (event) => {
        const files = Array.from(event.currentTarget.files);
        const errors = [];
        const validFiles = [];

        files.forEach((file) => {
            if (file.size > 4 * 1024 * 1024) {
                errors.push(`${file.name} is too large.`);
            } else if (!allowedMimeTypes.includes(file.type)) {
                errors.push(`${file.name} has an unsupported file type.`);
            } else {
                validFiles.push(file);
            }
        });

        if (errors.length > 0) {
            showSnackbar(`${errors.length} file(s) not uploaded due to validation errors`, 'error');
        } else {
        }

        setSelectedFiles([...selectedFiles, ...validFiles]);
        event.target.value = ''
    };

    const removeFile = (index, setFieldValue, errors) => {
        const newFiles = [...selectedFiles];
        newFiles.splice(index, 1);
        setSelectedFiles(newFiles);

        const newErrors = Array.isArray(errors.attachment) ? [...errors.attachment] : [];

        newErrors.splice(index, 1);

        setFieldValue("attachment", newErrors);
    };

    const handleSubmit = async (values, onSubmitProps) => {
        const formData = new FormData();
        formData.append('subject', values.subject)
        formData.append('description', values.description)

        for (var i = 0; i < selectedFiles.length; i++) {
            formData.append('attachment[]', selectedFiles[i])
        }

        await axiosInstance.post(`/api/write-us-help`, formData).then(response => {
            if (response.status === 200) {
                showSnackbar(response.data.message, 'success')
                props.handleHelpMenuClose();
                removeFile();
            }
        }).then(json => {

        }).catch(error => {
            if (error.response) {
                if (error.response.status === 422 || error.response.data.validation_errors) {
                    const errors = error.response.data.validation_errors;
                    Object.keys(errors).forEach(key => {
                        onSubmitProps.setFieldError(key, errors[key]);
                    });
                } else {
                    showSnackbar(error.response.data.message, 'error')
                }
            }
        });

    }

    return (

        <Popover
            open={props.helpMenuOpen}
            anchorEl={props.helpMenuAnchorEl}
            onClose={props.handleHelpMenuClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}
            classes={{
                paper: 'help-menu spaced'
            }}
        >
            <div className="menu-title">
                <Typography variant="headline-small-bold">Write to us</Typography>
                <button type="button" className="icon-button close-btn" onClick={props.handleHelpMenuClose}>
                    <Icon path={mdiClose} size={1} color={'currentColor'} />
                </button>
            </div>
            <Formik
                initialValues={{
                    subject: '',
                    description: '',
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {props => {
                    const {
                        touched,
                        errors,
                        values,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue
                    } = props;
                    return (
                        <form onSubmit={handleSubmit}>
                            <TextField
                                label="Subject"
                                variant="filled"
                                name="subject"
                                value={values.subject}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.subject && touched.subject}
                                helperText={(errors.subject && touched.subject) && errors.subject}
                            />

                            <TextField
                                label="Description"
                                variant="filled"
                                multiline
                                rows={3}
                                name="description"
                                value={values.description}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.description && touched.description}
                                helperText={(errors.description && touched.description) && errors.description}

                            />
                            <Grid>
                                <div className="attachment-center">
                                    <div className="title">
                                        <Typography variant="title-medium-bold">Attachments</Typography> <Typography variant="title-small" className="file-size">(Max Size 4 MB)</Typography>
                                    </div>

                                    <div className="psuedo-upload-block">
                                        <div className="content">
                                            <Button color="secondary" className="btn-block" tabIndex={-1}>
                                                <span>
                                                    <Icon path={mdiFilePlusOutline} color={'currentColor'} size={1} />
                                                    <span>Upload File</span>
                                                </span>
                                            </Button>
                                        </div>
                                        <input
                                            type="file"
                                            multiple
                                            name="attachment"
                                            className="psuedo-upload-element"
                                            onChange={(event) => {
                                                handleFileChange(event);
                                                setFieldValue("attachment", Array.from(event.currentTarget.files));
                                            }}
                                            onBlur={handleBlur}
                                            accept=".jpeg,.png,.jpg,"
                                            title="Allow file type are jpeg,png,jpg and MAX each file size are 4MB"
                                        />


                                    </div>
                                </div>
                            </Grid>
                            {selectedFiles && selectedFiles.length > 0 && <Grid container component={'ul'} spacing={1.5} className="attachments">
                                {selectedFiles.map((attachment, index) => {
                                    const fileExtension = attachment && attachment?.name?.split('.').pop().toLowerCase();

                                    let fileType = '';
                                    if (['jpg', 'jpeg', 'png'].includes(fileExtension)) {
                                        fileType = 'image';
                                    } else {
                                        fileType = 'file';
                                    }
                                    const IconComponent = iconMapping[fileType];

                                    return (
                                        <Grid item component={'li'} xs={12} key={index + ''}>
                                            <a href={attachment.fileUrl} className={(Array.isArray(errors.attachment) && errors.attachment.length > 0 && errors.attachment[index]) ? 'has-error' : ''}>
                                                <div className="icon-wrapper">
                                                    {IconComponent && <Icon path={IconComponent} color={'currentColor'} size={1} />}
                                                </div>
                                                <div className="info">
                                                    <Typography variant="body-large" component={'p'}>{attachment.name}</Typography>
                                                    {attachment.size && <Typography variant="body-medium">Size: {(attachment?.size / 1024 / 1024).toFixed(2)} MB</Typography>}
                                                </div>
                                            </a>
                                            {selectedFiles && <Tooltip title="Remove">
                                                <button className="icon-button size-small" onClick={() => removeFile(index, setFieldValue, errors)}>
                                                    <Icon path={mdiClose} color={'currentColor'} size={1} />
                                                </button>
                                            </Tooltip>}
                                            {Array.isArray(errors.attachment) && errors.attachment[index] && (
                                                <ul className='error-text'>
                                                    {Array.isArray(errors.attachment[index]) ? errors.attachment[index].map((error, idx) => (
                                                        <li key={idx}>{error}</li>
                                                    )) : <li>{errors.attachment[index]}</li>}
                                                </ul>
                                            )}
                                        </Grid>
                                    )
                                }
                                )}
                            </Grid>}

                            <Button type="submit" size="large" className="btn-block">Submit</Button>
                        </form>
                    );
                }}
            </Formik>
        </Popover>
    )
}

export default HelpPopup;