import React from 'react';
import { CircularProgress, Typography } from '@mui/material';
import { useLoader } from './context/LoaderContext';

const Loader = () => {
    const { loading, message } = useLoader(); // Use the loader context

    return (
        <div className={loading ? 'loader-visible' : ''}>
            <div className="loader-wrapper">
                <CircularProgress size={64} className='loader' />
                {message !== '' && <Typography variant='title-medium-large-semibold' className='message'>{message}</Typography>}
            </div>
        </div>
    )
}

export default Loader;