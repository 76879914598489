import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import React, { useEffect, useState } from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { displayDate } from "../../utils/Utils";
import moment from "moment-timezone";

const EasyEditCustomDatePicker = props => {
    const [selectValue, setSelectValue] = useState(
        props.value ? moment(props.value) : moment()
    );

    props.setAllowEditInline(false)

    useEffect(() => {
        if (props.value === 'N/A') {
            setSelectValue('');
        }
        setSelectValue(props.value ? moment(props.value) : moment());
    }, [props.value])


    return (

        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
                label={props.label}
                format={props?.preferredDateFormat ? displayDate(props.preferredDateFormat, 'half') : 'YYYY-MM-DD'}
                views={['year', 'month', 'day']}
                slotProps={{
                    textField: {
                        variant: 'filled',
                        name: "expected_closing_date",
                        type: 'text',
                        className: "no-margin size-medium control"
                    }
                }}
                value={selectValue}
                onChange={(value) => {
                    //const formattedValue = value ? value.format(displayDate(props.preferredDateFormat, 'half')) : 'YYYY-MM-DD';
                    props.setParentValue(value);
                    setSelectValue(value);
                }}
                error={props.errorMessages || ''}
                helperText={props.errorMessages || ""}
            />
        </LocalizationProvider>
    );
}

export default EasyEditCustomDatePicker;