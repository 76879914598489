import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import { Button, Grid, Stack, Tooltip, Typography } from "@mui/material";
import Icon from "@mdi/react";
import { mdiHistory } from "@mdi/js";
import { Link, useNavigate } from "react-router-dom";
import { useSnackbar } from "../../components/context/SnackbarContext";
import useAxios from "../../hooks/useAxios";
import { useLoader } from "../../components/context/LoaderContext";
import { useSelector } from "react-redux";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import moment from "moment-timezone";
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import { MapMarker } from "../../assets/images/indexDex";
import TopMenu from "./components/TopMenu";
import { capitalize } from "../../utils/Utils";

// Custom component to get the map instance
function SetMapRef({ mapRef }) {
    const map = useMap();
    mapRef.current = map; // Set the map instance to the ref
    return null;
}

function TrackUsers(props) {
    const navigate = useNavigate();
    const axiosInstance = useAxios();
    const { showSnackbar } = useSnackbar();
    const { showLoader, hideLoader } = useLoader();
    const { preferredDateFormat, preTimeZoneKey } = useSelector((state) => state?.user);

    const [isfillData, setIsfillData] = useState(false);
    const [userData, setUserData] = useState([]);
    const [markers, setMarkers] = useState([]);

    const [defaultLatLng, setDefaultLatLng] = useState({ lat: 23.003250, lng: 72.663240 });

    // Ref for map instance
    const mapRef = useRef(null);
    const markerRefs = useRef({});

    useEffect(() => {
        getUserLocation();
        const interval = setInterval(getUserLocation, 60000);
        return () => clearInterval(interval);
    }, []);

    async function getUserLocation() {
        showLoader();
        try {
            const response = await axiosInstance.get("api/track-users");
            if (response.status === 200) {
                const dataRecords = response.data.data.dataRecords;
                if (dataRecords.length > 0 && dataRecords[0].latitude && dataRecords[0].longitude) {
                    setDefaultLatLng({
                        lat: parseFloat(dataRecords[0].latitude),
                        lng: parseFloat(dataRecords[0].longitude),
                    });
                }
                setUserData(dataRecords);
            }
        } catch (error) {
            if (error.response) {
                showSnackbar(error.response.data.message, "warning");
                navigate("/dashboard");
            }
        } finally {
            hideLoader();
        }
    }

    useEffect(() => {
        if (userData.length > 0) {
            const updatedMarkers = userData
                .filter(location => location.latitude && location.longitude)
                .map((location, index) => ({
                    id: index, // Ensure each marker has a unique ID
                    address: location.user_name,
                    lat: parseFloat(location.latitude),
                    lng: parseFloat(location.longitude),
                    locationTime: moment.utc(location.created_at).tz(preTimeZoneKey).format(preferredDateFormat)
                }));
            setMarkers(updatedMarkers);
        }
        setIsfillData(true);
    }, [userData, preTimeZoneKey, preferredDateFormat]);

    const handleButtonClick = (id) => {
        if (markerRefs.current[id] && mapRef.current) {
            // Center the map and zoom in to the selected marker
            mapRef.current.flyTo(
                [markerRefs.current[id].getLatLng().lat, markerRefs.current[id].getLatLng().lng],
                18 // Set the desired zoom level
            );

            // Open the popup of the selected marker
            markerRefs.current[id].openPopup();
        }
    };

    return (
        <>
            <Helmet>
                <title>Track Users | {capitalize(props?.name || 'SolvCRM')}</title>
            </Helmet>

            <TopMenu page={'track'} />

            <Grid container spacing={2}>
                <Grid item xxl={9} xl={8} xs={12}>
                    {!isfillData ? (
                        <center><h2>Please wait, finding user locations...</h2></center>
                    ) : markers.length ? (
                        <MapContainer
                            center={[defaultLatLng.lat, defaultLatLng.lng]}
                            zoom={9}
                            style={{ height: '500px', width: '100%' }}
                        >
                            <SetMapRef mapRef={mapRef} />
                            <TileLayer
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            {markers.map((marker) => (
                                <Marker
                                    key={marker.id}
                                    position={[marker.lat, marker.lng]}
                                    icon={L.icon({
                                        iconUrl: MapMarker,
                                        iconSize: [32, 32],
                                        iconAnchor: [16, 0],
                                    })}
                                    ref={(ref) => markerRefs.current[marker.id] = ref}
                                >
                                    <Popup>
                                        <Typography variant="h6">{marker.address}</Typography>
                                        <Typography variant="body2">Updated at: {marker.locationTime}</Typography>
                                    </Popup>
                                </Marker>
                            ))}
                        </MapContainer>
                    ) : (
                        <center><h2>No locations found.</h2></center>
                    )}
                </Grid>
                <Grid item xxl={3} xl={4} xs={12}>
                    <div className="track-users-wrapper">
                        <Grid component={'ul'} container spacing={1} className="primary-list track-users">
                            {markers && markers.length > 0 ?
                                markers.map(({ lat, lng, address, locationTime, id }, index) => (
                                    <Grid item component={'li'} xl={12} md={6} key={index} onClick={() => {
                                        handleButtonClick(id);
                                    }} >
                                        <Link className="card" href="#" onClick={(e) => e.preventDefault()}>
                                            <Typography variant='title-medium-bold' component={'h6'}>{address}</Typography>
                                            <ul className='inline-details'>
                                                <li key={index}>
                                                    <Tooltip title="Date">
                                                        <span>
                                                            <Icon path={mdiHistory} color="currentColor" size={1} /> Last updated: {locationTime}
                                                        </span>
                                                    </Tooltip>
                                                </li>
                                            </ul>
                                        </Link>
                                    </Grid>
                                ))
                                : ''}
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        </>
    );
}

export default TrackUsers;
