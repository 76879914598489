import React from "react";
import { EmptyStateEmptyBox, EmptyStateEmptyFolder, EmptyStateNoContent, EmptyStateNoProfile, EmptyStateTag, EmptyStateTask } from "../../assets/images";
import { Button, Typography } from "@mui/material";
import Icon from "@mdi/react";
import { mdiPlus } from "@mdi/js";

function EmptyState({ type, hideActionBtn = false, actionBtnIcon = mdiPlus, actionBtnText = 'Add New', hideMessage = false, actionBtnClick = () => { }, size = 'medium' }) {
    const [title, setTitle] = React.useState('No Data Found');
    const [message, setMessage] = React.useState('');
    const [emptyStateIcon, setEmptyStateIcon] = React.useState(EmptyStateEmptyBox);
    const [hideEmptyStateMessage, setHideEmptyStateMessage] = React.useState(false);
    const [hideEmptyStateActionBtn, setHideEmptyStateActionBtn] = React.useState(false);

    React.useEffect(() => {
        if (hideMessage) setHideEmptyStateMessage(hideMessage);
        if (hideActionBtn) setHideEmptyStateActionBtn(hideActionBtn);

        switch (type) {
            case 'activity':
                setTitle('No Activity Found');
                setMessage('Looks like there are no recent activities. Start engaging now to see updates here!');
                setEmptyStateIcon(EmptyStateTask);
                break;
            case 'note':
                setTitle('No Notes Found');
                setMessage('It looks like there are no notes here yet. Start adding notes to keep track of important details!');
                setEmptyStateIcon(EmptyStateNoContent);
                break;
            case 'lead':
                setTitle('No Leads Found');
                setMessage('Start adding contacts and potential deals to track your sales pipeline.');
                setEmptyStateIcon(EmptyStateEmptyFolder);
                break;
            case 'contact-deal':
                setTitle('No Contacts Found');
                setMessage('You haven\'t added any contacts for your deal. Start by adding new contacts to manage your deals effectively!');
                setEmptyStateIcon(EmptyStateNoProfile);
                break;
            case 'contact':
                setTitle('No Contacts Found');
                setMessage('Add contacts to start building relationships and growing your network effectively.');
                setEmptyStateIcon(EmptyStateNoProfile);
                break;
            case 'quote':
                setTitle('No Quotes Found');
                setMessage('It looks like there are no quotes for your deal yet. Create a new quote to kickstart your deal process!');
                setEmptyStateIcon(EmptyStateNoProfile);
                break;
            case 'deal':
                setTitle('No Deals Found');
                setMessage('Add deals and track their progress to manage your sales effectively.');
                setEmptyStateIcon(EmptyStateTag);
                break;
            case 'account':
                setTitle('No Accounts Found');
                setMessage('Looks like this list is empty. Add a new account to start tracking companies!');
                setEmptyStateIcon(EmptyStateEmptyFolder);
                break;
            case 'event':
                setTitle('No Events Found');
                setHideEmptyStateMessage(true);
                break;
            case 'task':
                setTitle('No Task Found');
                setHideEmptyStateMessage(true);
                break;
            case 'email-quote':
                setTitle('No Quote-Email Found');
                setHideEmptyStateMessage(true);
                break;
            case 'settings':
                setTitle('Access Denied');
                setMessage('You do not have the necessary permissions to perform this action.');
                setHideEmptyStateMessage(false);
                break;
            case 'product':
                setTitle('No Product Found');
                setHideEmptyStateMessage(true);
                break;
            default:
                setTitle('No Data Found');
                setEmptyStateIcon(EmptyStateEmptyFolder);
                setHideEmptyStateActionBtn(true);
                setHideEmptyStateMessage(true);
                break;
        }
    }, [type, hideActionBtn, actionBtnIcon, actionBtnText, hideMessage, actionBtnClick]);

    return (
        <>
            <div className={"empty-state " + ("size-" + size)}>
                <img src={emptyStateIcon} alt="Empty State" />
                <h4>{title}</h4>
                {!hideEmptyStateMessage && <p>{message}</p>}
                {!hideEmptyStateActionBtn &&
                    <Button type="button" className="action" onClick={actionBtnClick} size={size}>
                        <span>
                            <Icon path={actionBtnIcon} color={'currentColor'} size={1} />
                            <span>{actionBtnText}</span>
                        </span>
                    </Button>
                }
            </div>
        </>
    );
}

export default EmptyState;