import { mdiAccountCircleOutline, mdiArrowLeft, mdiBellOutline, mdiCartOutline, mdiCogOutline, mdiHelpCircleOutline, mdiLogoutVariant } from "@mdi/js";
import Icon from "@mdi/react";
import { Button, MenuItem, MenuList, Popover, TextField, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginUser, logout, notifications, notificationCount, workspaces, permissions, login, setTimeAndCurrency } from "../../redux/slices/userSlice";
import { useSnackbar } from '../../components/context/SnackbarContext';
import useAxios from '../../hooks/useAxios';
import { useLoader } from "../../components/context/LoaderContext";
import Cookies from "universal-cookie";
import moment from "moment-timezone";
import { getMainDomain, replaceSubdomain } from "../../utils/Utils";
import HelpPopup from "../common/helpPopup";
import useLogoutSubmit from "../../hooks/useLogoutSubmit";

function Header({ pageTitle }) {
    const location = useLocation();
    const { pathname } = location;
    const cookies = new Cookies();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const axiosInstance = useAxios();
    const { showSnackbar } = useSnackbar();
    const { showLoader, hideLoader } = useLoader();
    const hostname = getMainDomain()
    const logoutSubmit = useLogoutSubmit();

    const loginUserDetail = useSelector(loginUser);
    const user = loginUserDetail?.user || [];

    const notificationCounts = useSelector((state) => state?.user?.notificationCount);

    const currentUserWorkSpace = useSelector((state) => state?.user?.workspace);

    const { preferredDateFormat, preTimeZoneKey } = useSelector(state => state?.user);

    const [notificationLists, setNotificationLists] = useState([]);
    const [notificationCnts, setNotificationCnts] = useState(0);

    const [notificationAnchorEl, setNotificationAnchorEl] = React.useState(null);
    const [workspaceList, setWorkspaceList] = useState([]);



    const [workspace, setWorkspace] = useState(user?.currentWorkspaceId);

    const handleNotificationClick = (event) => {
        setNotificationAnchorEl(event.currentTarget);
    };

    const handleNotificationClose = () => {
        setNotificationAnchorEl(null);
    };

    const notificationOpen = Boolean(notificationAnchorEl);

    const [userMenuAnchorEl, setUserMenuAnchorEl] = React.useState(null);

    const handleUserMenuClick = (event) => {
        setUserMenuAnchorEl(event.currentTarget);
    };

    const handleUserMenuClose = () => {
        setUserMenuAnchorEl(null);
    };

    const userMenuOpen = Boolean(userMenuAnchorEl);

    useEffect(() => {
        async function fetchData() {
            await getUserWorkspace();
        }
        fetchData();
    }, [currentUserWorkSpace?.name]);

    async function getUserWorkspace() {
        await axiosInstance.get("api/user/getLoginUserWorkspace").then(response => {
            if (response.status === 200) {
                setWorkspaceList(response?.data?.data?.workspaces || [])
                setNotificationLists(response?.data?.data?.notifications || [])
                setNotificationCnts(response?.data?.data?.notificationCount || 0)
                dispatch(
                    notificationCount(response?.data?.data?.notificationCount || 0)
                )
                dispatch(
                    notifications(response?.data?.data?.notifications || []),
                );
            }
        }).then(json => {

        }).catch(error => {
            if (error.response) {
                if (error.response.status === 400) {
                    showSnackbar(error.response.data.message, 'error')
                }
            }
        });
    }

    const handleWorkspaceChange = async (e) => {
        //setWorkspace(e.target.value)
        const data = {
            workspace_id: e.target.value,
        }
        showLoader()
        await axiosInstance.post("api/change-workspace", data).then(response => {
            if (response.status === 200) {
                const workspaceData = response?.data?.data?.workspace;
                const currentTenantDomain = response?.data?.data?.currentTenant?.domain;

                cookies.remove('tenant_workspace', { domain: hostname || '' });
                cookies.set('tenant_workspace', e.target.value, { domain: hostname || '' });

                dispatch(login());
                dispatch(workspaces());
                dispatch(permissions());

                dispatch(setTimeAndCurrency({
                    preferredDateFormat: response?.data?.data?.staticData?.preDateFormat || 'YYYY-MM-DD HH:mm',
                    preTimeZoneKey: response?.data?.data?.staticData?.preTimeZoneKey || 'Asia/Calcutta',
                    currencySymbol: response?.data?.data?.staticData?.preCurrency?.currencySymbol || '₹',
                    currencyCode: response?.data?.data?.staticData?.preCurrency?.currencyCode || 'INR',
                    currencyStringValue: response?.data?.data?.staticData?.preCurrency?.currencyStringValue || 'en-IN'
                }));

                setWorkspace(e.target.value);
                cookies.remove('auth_tenant', { domain: hostname || '' });
                cookies.set('auth_tenant', workspaceData, { domain: hostname || '' });

                hideLoader()
                if (currentTenantDomain) {
                    const redirectUrl = replaceSubdomain(window.location.href, currentTenantDomain)
                    //if request has localhost than need to add new cookies for than subdomain
                    if (redirectUrl.includes('localhost')) {
                        window.location = redirectUrl + '?redirectData=' + workspaceData + '&token=' + cookies.get('auth_token') + '&wid=' + e.target.value;
                    } else {
                        window.location = redirectUrl;
                    }
                    window.location = redirectUrl;
                } else {
                    const redirectUrl = replaceSubdomain(window.location.href, 'app')
                    window.location = redirectUrl;
                }
            }
        }).then(json => {

        }).catch(error => {
            if (error.response) {
                hideLoader()
                if (error.response.status === 400) {
                    showSnackbar(error.response.data.message, 'error')
                }
            }
        });
    }

    useEffect(() => {
        user?.currentWorkspaceId && setWorkspace(user?.currentWorkspaceId);
    }, [currentUserWorkSpace?.name, user?.currentWorkspaceId])

    const addDefaultProfileSrc = (e) => {
        e.target.src = `${process.env.REACT_APP_API_URL}assets/default-profile-image.png`;
    }

    const isBackBtn = (pathname) => {
        if (/^\/settings\/user-detail\/\d+$/.test(pathname)) {
            return true;
        } else if (/^\/settings\/role-detail\/\d+$/.test(pathname)) {
            return true;
        } else if (/^\/settings\/product-detail\/\d+$/.test(pathname)) {
            return true;
        } else if (/^\/settings\/stage-detail\/\d+$/.test(pathname)) {
            return true;
        } else if (/^\/settings\/source-detail\/\d+$/.test(pathname)) {
            return true;
        } else {
            return false;
        }
    };

    const [helpMenuAnchorEl, setHelpMenuAnchorEl] = useState(null);
    const [helpMenuOpen, setHelpMenuOpen] = useState(false);

    const handleHelpMenuClick = (event) => {
        setHelpMenuOpen(true);
        setHelpMenuAnchorEl(true);
        document.body.classList.add('show-popover-backdrop');
    };

    const handleHelpMenuClose = () => {
        setHelpMenuOpen(false);
        setHelpMenuAnchorEl(null);
        document.body.classList.remove('show-popover-backdrop');
    };

    return (
        <>
            <header className="header">
                {
                    isBackBtn(pathname) &&
                    <Tooltip title="Go back">
                        <button type="button" className="icon-button size-large text-secondary ml-n8" onClick={e => { navigate(-1); }}>
                            <Icon path={mdiArrowLeft} color={"currentColor"} size={1} />
                        </button>
                    </Tooltip>
                }

                <Typography variant="headline-medium-bold" className="page-title">{pageTitle}</Typography>
                <ul className="right-nav">
                    <li>
                        <button type="button" className={notificationLists?.length > 0 ? 'icon-button size-large has-notification' : 'icon-button size-large'} onClick={handleNotificationClick}>
                            <Icon path={mdiBellOutline} color={'currentColor'} size={1} />
                        </button>

                        <Popover
                            open={notificationOpen}
                            anchorEl={notificationAnchorEl}
                            onClose={handleNotificationClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                            classes={{
                                paper: 'notification-menu spaced'
                            }}
                        >
                            <div className="menu-title">
                                <Typography variant="headline-small-bold">Notifications</Typography>
                                <span className="count">{notificationCounts} New</span>
                            </div>

                            <ul className="notifications">
                                {
                                    notificationLists?.length > 0 && notificationLists.map((item, index) => (
                                        <li key={index}>
                                            <a href="#" className={item.status === 1 ? 'has-notification' : ''}>
                                                <Typography variant="title-medium-bold" component={'h6'}>{item.title}</Typography>
                                                <Typography variant="body-medium" component={'p'}>{item.body}</Typography>
                                                <Typography variant="body-medium">{moment.tz(item.created_at, preTimeZoneKey).format(preferredDateFormat)}</Typography>
                                            </a>
                                        </li>
                                    ))
                                }

                            </ul>
                            {
                                notificationLists?.length > 0 && <Button color="secondary" onClick={e => { navigate('/notifications'); handleNotificationClose() }} className="btn-block">View All</Button>
                            }

                        </Popover>
                    </li>
                    <li>
                        <button type="button" className="user-menu-trigger" onClick={handleUserMenuClick}>
                            <img onError={addDefaultProfileSrc} src={user?.profile_image} alt={user?.name} />
                        </button>

                        <Popover
                            open={userMenuOpen}
                            anchorEl={userMenuAnchorEl}
                            onClose={handleUserMenuClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                            classes={{
                                paper: 'user-menu'
                            }}
                        >
                            <div className="head">
                                <img onError={addDefaultProfileSrc} src={user?.profile_image} alt={user?.name} />
                                <Typography variant="title-medium-bold" component={'h6'}>{user?.name}</Typography>
                                {user?.uuid &&
                                    <Typography variant="body-medium">User: {user?.uuid}</Typography>
                                }

                            </div>

                            <div className="account-select">
                                <TextField
                                    select
                                    label="Workspace"
                                    value={workspace}
                                    name="workspace_id"
                                    variant="filled"
                                    onChange={handleWorkspaceChange}
                                >
                                    {
                                        workspaceList && workspaceList.length > 0 ?
                                            workspaceList.map((item, index) => (
                                                <MenuItem key={index} value={item.tenant_id}>{item.name}</MenuItem>
                                            ))
                                            :
                                            <MenuItem value='-1'></MenuItem>
                                    }
                                </TextField>
                            </div>

                            <MenuList>
                                <MenuItem onClick={() => { navigate('/profile'); handleUserMenuClose(); }}>
                                    <Icon path={mdiAccountCircleOutline} color={'currentColor'} size={1} />
                                    <span>My Account</span>
                                </MenuItem>
                                <MenuItem onClick={() => { navigate('/settings'); handleUserMenuClose() }}>
                                    <Icon path={mdiCogOutline} color={'currentColor'} size={1} />
                                    <span>Settings</span>
                                </MenuItem>
                                {/* <MenuItem onClick={e => navigate('#')}>
                                    <Icon path={mdiCartOutline} color={'currentColor'} size={1} />
                                    <span>My Orders</span>
                                </MenuItem> */}
                                <MenuItem onClick={() => handleHelpMenuClick()}>
                                    <Icon path={mdiHelpCircleOutline} color={'currentColor'} size={1} />
                                    <span>Help</span>
                                </MenuItem>
                                <MenuItem onClick={logoutSubmit}>
                                    <Icon path={mdiLogoutVariant} color={'currentColor'} size={1} />
                                    <span>Sign Out</span>
                                </MenuItem>
                            </MenuList>
                        </Popover>
                    </li>
                </ul>
            </header >
            <HelpPopup helpMenuOpen={helpMenuOpen} helpMenuAnchorEl={helpMenuAnchorEl} handleHelpMenuClose={handleHelpMenuClose} />
        </>
    );
}

export default Header;