import React, { memo, useEffect, useState } from "react";
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Typography } from "@mui/material";
import Icon from "@mdi/react";
import { mdiAttachment } from "@mdi/js";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from '../../../components/context/SnackbarContext';
import useAxios from '../../../hooks/useAxios';
import { useLoader } from "../../../components/context/LoaderContext";
import { useSelector } from "react-redux";
import { capitalize, displayDate } from "../../../utils/Utils";
import moment from "moment-timezone";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import EmptyState from "../../../components/common/EmptyState";

function QuoteEmailHistory(props) {
    const navigate = useNavigate();
    const axiosInstance = useAxios();
    const { showSnackbar } = useSnackbar();
    const { showLoader, hideLoader, loading } = useLoader();
    const { preferredDateFormat, preTimeZoneKey } = useSelector((state) => state?.user);

    //default data for list api
    const [sortBy, setSortBy] = useState("created_at");
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(
        parseInt(process.env.REACT_APP_DEFAULT_PAGINATION)
    );
    const [totalCount, setTotalCount] = useState(0);
    const [sortOrder, setSortOrder] = useState(
        process.env.REACT_APP_DEFAULT_SORT
    );
    const [reloadPage, setReloadPage] = useState(false);

    const [emailHistory, setEmailHistory] = useState([]);

    useEffect(() => {
        if (props.quoteId) {
            async function fetchData() {
                showLoader();
                await getData();
            }
            fetchData();
        }
    }, [
        props.quoteId,
        props.reloadPage,
        reloadPage,
        currentPage,
        perPage,
        sortBy,
        sortOrder,
        reloadPage
    ]);

    const getData = async () => {
        const params = {
            sort_field: sortBy,
            sort_order: sortOrder,
            per_page: perPage,
            page: currentPage,
            quote_id: props.quoteId
        }

        await axiosInstance.get(`api/quote-email-history?page=${currentPage}`, { params }).then(response => {
            if (response.status === 200) {
                setEmailHistory(response?.data?.data?.records?.data);
                setTotalCount(response?.data?.data?.records?.total);
                hideLoader();
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                        navigate("/quote-detail/" + props.quoteId);
                    }
                }
            });
    };


    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleRowsPerPageChange = (event) => {
        setPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };


    const downloadNormal = async (id, fileType) => {
        if (id) {
            showLoader();
            await axiosInstance.get('api/download-pdf?id=' + id + '&fileType=' + fileType).then(response => {
                if (response.status === 200) {
                    if (response.data.data.fileName && response.data.data.fullPath) {
                        const linkSource = 'data:application/pdf;base64, ' + response.data.data.fullPath
                        const downloadLink = document.createElement("a");
                        downloadLink.href = linkSource;
                        downloadLink.download = response.data.data.fileName;
                        downloadLink.click();
                    } else {
                        showSnackbar('Sorry...! Uploaded file path are not properly.', "warning");
                    }
                    hideLoader();
                }
            }).then(json => {

            }).catch(error => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                        navigate("/quotes");
                    }
                }
            });
        } else {
            showSnackbar('Sorry...! No data available for download file.', "warning");
        }
    }

    const downloadZip = async (id, fileType) => {
        if (id) {
            showLoader()
            await axiosInstance.get('api/download-pdf?id=' + id + '&fileType=' + fileType).then(response => {
                if (response.status === 200) {
                    if (response.data.data.fileName && response.data.data.fullPath) {
                        try {
                            const zip = new JSZip();
                            const remoteZips = response.data.data.fullPath.map(async (file, i) => {
                                const extension = await file.split('fileName=')
                                if (extension && extension.length == 2) {
                                    const data = zip.file(extension[1], extension[0], { base64: true });
                                    return data;
                                }
                            });

                            Promise.all(remoteZips)
                                .then(() => {
                                    zip.generateAsync({ type: "blob" }).then((content) => {
                                        // give the zip file a name
                                        saveAs(content, response.data.data.fileName);
                                    });
                                    hideLoader()
                                })
                                .catch(() => {
                                    hideLoader()
                                });

                            hideLoader()
                        } catch (error) {
                            hideLoader()
                        }
                    } else {
                        showSnackbar('Sorry...! uploaded file path are not properly.', "warning");
                    }
                    hideLoader()
                }
            }).then(json => {

            }).catch(error => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                        navigate("/quotes");
                    }
                }
            });
        } else {
            showSnackbar('Sorry...! No data available for download file.', "warning");
        }
    }

    return (
        <>
            <Grid item xs={12}>
                <div className="card p-16">
                    <Typography variant="title-medium-large-semibold" component={'h4'} className="mb-16">Email History</Typography>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className="long-text">Subject</TableCell>
                                    <TableCell>Recipient</TableCell>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Sent by</TableCell>
                                    <TableCell>Attachments</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {emailHistory?.length > 0 && emailHistory.map((email, index) => (
                                    <TableRow key={index + ''}>
                                        <TableCell>{capitalize(email?.subject || 'N/A')}</TableCell>
                                        <TableCell><Tooltip title={email?.displayTooltip || 'N/A'}><span>{email?.subject_with_email_count || 'N/A'}</span></Tooltip></TableCell>
                                        <TableCell>{email?.created_at
                                            ? displayDate(moment.utc(email?.created_at).tz(preTimeZoneKey).format(preferredDateFormat), 'half')
                                            : "N/A"}</TableCell>
                                        <TableCell>{email?.added_by?.name || 'N/A'}</TableCell>
                                        <TableCell>
                                            {email?.attachFileName?.length > 0 &&
                                                <Tooltip title="Download Attachments">
                                                    <button type="button" className="attachment-btn" onClick={e => {
                                                        if (email.attachFileName.length > 1) {
                                                            downloadZip(email.id, 'zip');
                                                        } else {
                                                            downloadNormal(email.id, 'pdf');
                                                        }
                                                    }}>
                                                        {email?.attachFileName?.length}
                                                        <Icon path={mdiAttachment} color={'currentColor'} size={1} />
                                                    </button>
                                                </Tooltip>
                                            }
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {(!emailHistory || emailHistory.length === 0) && !loading &&
                        <EmptyState type={'email-quote'} hideActionBtn size="small" />
                    }

                    {
                        emailHistory && emailHistory.length > 0 &&
                        <TablePagination
                            component="div"
                            className="mt-12"
                            count={totalCount}
                            page={currentPage}
                            rowsPerPage={perPage}
                            onPageChange={handlePageChange}
                            onRowsPerPageChange={handleRowsPerPageChange}
                        />
                    }
                </div>
            </Grid>

        </>
    )
}

export default memo(QuoteEmailHistory);
