import { NumericFormat, } from "react-number-format";

function MyCustomNumberFormat(props) {
    const { inputRef, onChange, currencyCode, currencySymbol, currencyStringValue, displayType, ...other } = props;

    // const format = (numStr) => {
    //     var newValue = parseFloat(numStr).toFixed(2)
    //     if (newValue === '') return '';
    //     return new Intl.NumberFormat(currencyStringValue ? currencyStringValue : 'en-IN', {
    //         style: 'currency',
    //         currency: currencyCode ? currencyCode : 'INR',
    //         maximumFractionDigits: 0,
    //     }).format(newValue);
    // };

    return (
        // <NumberFormatBase
        //     {...other}
        //     getInputRef={inputRef}
        //     format={format}
        //     onValueChange={(values) => {
        //         console.log('values', values)
        //         // Send the numeric value without formatting
        //         //{"formattedValue":"₹5,69,859","value":"569859","floatValue":569859} you can used as per you requirment (formattedValue,value,floatValue)
        //         var numericValue = values.value;
        //         if (displayType === 'text') {
        //             numericValue = values.formattedValue;
        //         }

        //         onChange({
        //             target: {
        //                 name: props.name,
        //                 value: numericValue,
        //             },
        //         });
        //     }}
        // />
        // <NumberFormat thousandSeparator={true} prefix={currencySymbol} className="some" inputmode="numeric" />
        <NumericFormat
            {...other}
            value={props.value}
            prefix={currencySymbol}
            allowNegative={false}
            decimalScale={2}
            inputMode="decimal"
            onValueChange={(values, sourceInfo) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            allowLeadingZeros
            thousandsGroupStyle={currencyCode === 'INR' ? 'lakh' : 'thousand'}
            thousandSeparator={true}
            data-cy="price"
        />
    );
}

export default MyCustomNumberFormat;