import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { Autocomplete, Button, Dialog, Grid, MenuItem, Stack, TextField, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { useSnackbar } from '../../../components/context/SnackbarContext';
import useAxios from '../../../hooks/useAxios';
import { useLoader } from "../../../components/context/LoaderContext";
import { useSelector } from "react-redux";
import MyCustomNumberFormat from "../../../components/MyCustomNumberFormat";
import PercentageFormatCustom from "../../../components/MyCustomPercentageFormat";

function CreateDealForExistingAccountDialog({ open, onClose, sources, handleReloadFunc, accountId }) {
    const axiosInstance = useAxios();
    const { showSnackbar } = useSnackbar();
    const { showLoader, hideLoader } = useLoader();
    const { currencyCode, currencySymbol, currencyStringValue } = useSelector(state => state?.user);
    const [accountList, setAccountList] = useState([])
    const [contactList, setContactList] = useState([])
    const [accountContactList, setAccountContactList] = useState([])
    const [selectedAccountId, setSelectedAccountId] = useState()

    useEffect(() => {
        async function fetchData() {
            await getDealCreate();
        }
        fetchData();
    }, []);

    async function getDealCreate() {
        showLoader()
        const url = accountId ? 'api/deal/create?accountId=' + accountId : 'api/deal/create'

        await axiosInstance.get(url).then(response => {
            if (response.status === 200) {
                setAccountList(response?.data?.data?.selectAccountData)
                setAccountContactList(response?.data?.data?.selectContactData)
                hideLoader()
            }
        }).then(() => {

        }).catch(error => {
            if (error.response) {
                hideLoader()
                showSnackbar(error.response.data.message, 'error')
            }
        });
    }

    //=====Start::Handle change account to contact logic======//
    const HandleSelectAcount = (e) => {
        setSelectedAccountId(e.target.value)
    }

    useEffect(() => {
        if (selectedAccountId) {
            async function fetchData() {
                await getContactFromAccount(selectedAccountId);
            }
            fetchData();
        }
    }, [selectedAccountId]);

    const getContactFromAccount = async (account_id) => {
        setContactList('')
        await axiosInstance.get('api/deal/create?account_id=' + account_id + '&getContact=yes').then(response => {
            if (response.status === 200) {
                setContactList(response?.data?.data?.accountContacts)
            }
        }).then(() => {

        }).catch(error => {
            if (error.response) {
                hideLoader()
                showSnackbar(error.response.data.message, 'error')
            }
        });
    }

    //=====End::Handle change account to contact logic======//

    const validationSchema = yup.object().shape({
        account_id: yup.string().required('Please select Account'),
        contact_id: yup.string().required('Please select Contact'),
        organization_phone1: yup.string().max(25, 'Phone must be at most 25 characters'),
        deal_name: yup.string().trim('Deal name cannot include space').strict(true).required('Enter deal name').max(55, 'Must be at most 55 characters'),
        deal_amount: yup.string().required('Enter deal amount'),
        deal_description: yup.string().required('Enter deal description').min(2, 'Must be at least 2 characters').max(150, 'Must be at most 150 characters')
            .test('validator-custom-length', function (value) {
                const validation = lengthValidation(value);
                if (!validation.isValid) {
                    return this.createError({
                        path: this.path,
                        message: validation.errorMessage,
                    });
                }
                else {
                    return true;
                }
            }),
        deal_probability: yup.number('Only numeric value allow').transform((value) => (isNaN(value) ? undefined : value)).required('Enter deal probability').positive().max('100', "Please enter less than 100%")
    })

    const lengthValidation = (fieldLength) => {
        var valid = true;
        if (fieldLength) {
            var str = fieldLength.replace(/\s/g, '')
            if (str.length < 2) {
                valid = false;
            }
        }

        return valid ? {
            isValid: true,
        } : {
            isValid: false,
            errorMessage: 'Must be at least 2 characters, no space allow',
        }
    }


    const handleSubmit = async (values, onSubmitProps) => {
        const data = {
            account_id: values.account_id,
            contact_id: values.contact_id,
            deal_amount: values.deal_amount,
            deal_description: values.deal_description,
            deal_name: values.deal_name,
            deal_probability: values.deal_probability,
            lead_source: values.lead_source,
            phone1: values.organization_phone1,
            added_from: 'account',
        }

        showLoader()
        await axiosInstance.post("/api/deal/store", data).then(response => {
            if (response.status === 200) {
                hideLoader()
                showSnackbar(response.data.message, 'success')
                onClose()
                handleReloadFunc()
            }
        }).then(json => {

        }).catch(error => {
            if (error.response) {
                hideLoader()
                onSubmitProps.setSubmitting(false)
                if (error.response.status === 422 || error.response.data.validation_errors) {
                    const errors = error.response.data.validation_errors;
                    Object.keys(errors).forEach(key => {
                        onSubmitProps.setFieldError(key, errors[key][0]);
                    });
                } else {
                    showSnackbar(error.response.data.message, 'error')
                }
            }
        });
    }

    const isSingleAccount = accountList?.length === 1;

    return (
        <>
            <Dialog onClose={onClose} open={open}>
                <div className="dialog-title">
                    <h2>Create Deal for Existing Account</h2>
                    <Tooltip title="Close">
                        <button className="icon-button dialog-close" onClick={onClose}>
                            <Icon path={mdiClose} color={'currentColor'} size={1} />
                        </button>
                    </Tooltip>
                </div>
                <Formik
                    initialValues={{
                        account_id: isSingleAccount ? accountList[0].id : '',
                        contact_id: '',
                        deal_name: '',
                        lead_source: '',
                        organization_phone1: '',
                        deal_amount: '',
                        deal_description: '',
                        deal_probability: '',
                        error_list: [],
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize
                >
                    {props => {
                        const {
                            touched,
                            errors,
                            values,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        } = props;
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container spacing={1.5}>
                                    <Grid item xs={6}>
                                        <Autocomplete
                                            options={accountList || []}
                                            getOptionLabel={(option) => option.organization_name || ''}
                                            value={isSingleAccount ? accountList[0] : accountList?.find(account => account.id === values?.account_id) || null}
                                            disabled={isSingleAccount}
                                            onChange={(event, newValue) => {
                                                const e = {
                                                    target: {
                                                        name: 'account_id',
                                                        value: newValue ? newValue.id : ''
                                                    }
                                                };
                                                handleChange(e);
                                                HandleSelectAcount(e);
                                                setContactList([]);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Account"
                                                    variant="filled"
                                                    error={Boolean(errors.account_id && touched.account_id)}
                                                    helperText={(errors.account_id && touched.account_id) && errors.account_id}
                                                    onBlur={handleBlur}
                                                    name="account_id"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            select
                                            label="Contact"
                                            value={values?.contact_id || ''}
                                            name="contact_id"
                                            variant="filled"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.contact_id && touched.contact_id}
                                            helperText={(errors.contact_id && touched.contact_id) && errors.contact_id}
                                        >
                                            {
                                                accountId && accountContactList && accountContactList.length > 0
                                                    ? accountContactList.map((contact, index) => (
                                                        <MenuItem key={index} value={contact?.id}>
                                                            {contact?.salutation ? contact.salutation + '. ' : ''}{contact?.first_name} {contact?.last_name}
                                                        </MenuItem>
                                                    ))
                                                    : contactList && contactList.length > 0
                                                        ? contactList.map((contact, index) => (
                                                            <MenuItem key={index} value={contact?.id}>
                                                                {contact?.salutation ? contact.salutation + '. ' : ''}{contact?.first_name} {contact?.last_name}
                                                            </MenuItem>
                                                        ))
                                                        : <MenuItem value={'-1'}></MenuItem>
                                            }
                                        </TextField>
                                    </Grid>
                                </Grid>

                                <TextField
                                    label="Deal Name"
                                    variant="filled"
                                    name="deal_name"
                                    value={values?.deal_name || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.deal_name && touched.deal_name}
                                    helperText={(errors.deal_name && touched.deal_name) && errors.deal_name}
                                />

                                <Grid container spacing={1.5}>
                                    <Grid item xs={6}>
                                        <TextField
                                            select
                                            label="Deal Source"
                                            value={values.lead_source}
                                            name="lead_source"
                                            variant="filled"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.lead_source && touched.lead_source}
                                            helperText={(errors.lead_source && touched.lead_source) && errors.lead_source}
                                        >
                                            {
                                                sources && sources.length > 0 ?
                                                    sources.map((source, index) => (
                                                        <MenuItem key={index} value={source.title}>
                                                            {source.title}
                                                        </MenuItem>
                                                    )) :
                                                    <MenuItem value={-1}></MenuItem>

                                            }

                                        </TextField>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Phone"
                                            variant="filled"
                                            name="organization_phone1"
                                            value={values?.organization_phone1 || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            inputProps={{
                                                maxLength: 25
                                            }}
                                            error={errors.organization_phone1 && touched.organization_phone1}
                                            helperText={(errors.organization_phone1 && touched.organization_phone1) && errors.organization_phone1}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={1.5}>
                                    <Grid item xs={6}>
                                        <TextField
                                            variant="filled"
                                            label='Deal Amount'
                                            id="deal_amount"
                                            name="deal_amount"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder="Enter deal amount"
                                            value={values?.deal_amount || ''}
                                            InputProps={{
                                                inputComponent: MyCustomNumberFormat, //here last is input/text to display on page
                                                inputProps: { 'currencySymbol': currencySymbol, 'currencyCode': currencyCode, 'currencyStringValue': currencyStringValue, 'displayType': 'input' },//displayType=input/text
                                                endAdornment: <span className="text-adornment">{currencyCode}</span>
                                            }}
                                            error={errors.deal_amount && touched.deal_amount}
                                            helperText={(errors.deal_amount && touched.deal_amount) && errors.deal_amount}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Probability"
                                            variant="filled"
                                            name="deal_probability"
                                            value={values.deal_probability}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            InputProps={{
                                                inputComponent: PercentageFormatCustom,
                                                endAdornment: <span className="text-adornment">%</span>
                                            }}
                                            error={errors.deal_probability && touched.deal_probability}
                                            helperText={(errors.deal_probability && touched.deal_probability) && errors.deal_probability}
                                        />
                                    </Grid>
                                </Grid>

                                <TextField
                                    label="Description"
                                    variant="filled"
                                    multiline
                                    rows={3}
                                    name="deal_description"
                                    value={values?.deal_description || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.deal_description && touched.deal_description}
                                    helperText={(errors.deal_description && touched.deal_description) && errors.deal_description}
                                />

                                <Stack direction="row" spacing={1.5} justifyContent={'flex-end'}>
                                    <Button type="submit">Create</Button>
                                    <Button color="secondary" onClick={onClose}>Cancel</Button>
                                </Stack>
                            </form>
                        );
                    }}
                </Formik>
            </Dialog>
        </>
    );
}

export default CreateDealForExistingAccountDialog;