import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { Dialog, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import { MapMarker } from "../../../assets/images/indexDex";

function SetMapRef({ mapRef }) {
    const map = useMap();
    mapRef.current = map; // Set the map instance to the ref
    return null;
}

function ViewLocationDialog({ onClose, open, locationDetail }) {

    const [isfillData, setIsfillData] = useState(false);
    const [defaultLatLng, setDefaultLatLng] = useState({ lat: 23.003250, lng: 72.663240 });
    const [markers, setMarkers] = useState([]);

    useEffect(() => {
        if (locationDetail.latitude && locationDetail.longitude) {
            setDefaultLatLng({
                lat: parseFloat(locationDetail.latitude),
                lng: parseFloat(locationDetail.longitude),
            });
            setMarkers([{
                id: 1, // Ensure each marker has a unique ID
                address: locationDetail?.name || '',
                lat: parseFloat(locationDetail.latitude),
                lng: parseFloat(locationDetail.longitude),
            }])
        }

    }, [locationDetail])

    // Ref for map instance
    const mapRef = useRef(null);
    const markerRefs = useRef({});

    setTimeout(() => {
        setIsfillData(true);
    }, 2000);




    return (
        <>
            <Dialog onClose={onClose} open={open}>
                <div className="dialog-title">
                    <h2>View Location</h2>
                    <Tooltip title="Close">
                        <button className="icon-button dialog-close" onClick={onClose}>
                            <Icon path={mdiClose} color={'currentColor'} size={1} />
                        </button>
                    </Tooltip>
                </div>

                <div style={{ height: "58vh", width: "100%" }}>
                    {!isfillData ? (
                        <center><h2>Please wait, finding user locations...</h2></center>
                    ) : markers.length ? (
                        <MapContainer
                            center={[defaultLatLng.lat, defaultLatLng.lng]}
                            zoom={9}
                            style={{ height: '500px', width: '100%' }}
                        >
                            <SetMapRef mapRef={mapRef} />
                            <TileLayer
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            {markers.map((marker) => (
                                <Marker
                                    key={marker.id}
                                    position={[marker.lat, marker.lng]}
                                    icon={L.icon({
                                        iconUrl: MapMarker,
                                        iconSize: [32, 32],
                                        iconAnchor: [16, 0],
                                    })}
                                    ref={(ref) => markerRefs.current[marker.id] = ref}
                                >
                                    <Popup>
                                        <Typography variant="h6">{marker.address}</Typography>
                                        <Typography variant="body2">Visited at: {locationDetail.visitedTime}</Typography>
                                    </Popup>
                                </Marker>
                            ))}
                        </MapContainer>
                    ) : (
                        <center><h2>No locations found.</h2></center>
                    )}
                </div>
            </Dialog>
        </>
    );
}

export default ViewLocationDialog;