import { mdiTrashCanOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { Button, Dialog, Grid, Typography } from "@mui/material";
import React from "react";

function DeleteConfirmDialog({ onClose, open, title = 'Confirm Deletion', message = 'Are you sure you want to delete this? This action cannot be undone.', deleteButtonText = 'Delete' }) {
    const handleOnClose = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
        onClose(false);
    }

    return (
        <>
            <Dialog onClose={handleOnClose} open={open} disableEscapeKeyDown={true} maxWidth={'md'} className="delete-dialog">
                <div className="icon-wrapper">
                    <Icon path={mdiTrashCanOutline} color={'currentColor'} size={2} />
                </div>
                <Typography variant="headline-medium-bold" component={'h2'} className="title">{title}</Typography>
                <Typography variant="body-large-medium" component={'p'} className="message">{message}</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Button className="btn-block shadow-2" onClick={e => onClose(true)}>{deleteButtonText}</Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button color="secondary" className="btn-block" onClick={e => onClose(false)}>Cancel</Button>
                    </Grid>
                </Grid>
            </Dialog>
        </>
    );
}

export default DeleteConfirmDialog;