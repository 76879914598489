import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { Button, Dialog, Grid, TextField, Tooltip } from "@mui/material";
import React from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { useSnackbar } from "../../../components/context/SnackbarContext";
import useAxios from "../../../hooks/useAxios";

function CreateNewSourceDialog({ onClose, open, handleReloadFunc }) {
    const axiosInstance = useAxios();
    const { showSnackbar } = useSnackbar();

    const validationSchema = yup.object().shape({
        title: yup
            .string()
            .required("Enter title name"),
    });

    const handleSubmit = async (values, onSubmitProps) => {
        const data = {
            title: values.title,
        }
        await axiosInstance.post("/api/source/store", data).then(response => {
            if (response.status === 200) {
                showSnackbar(response.data.message, 'success')
                onClose()
                handleReloadFunc()
            }
        }).then(json => {

        }).catch(error => {
            if (error.response) {
                onSubmitProps.setSubmitting(false)
                if (error.response.status === 422 || error.response.data.validation_errors) {
                    const errors = error.response.data.validation_errors;
                    Object.keys(errors).forEach(key => {
                        onSubmitProps.setFieldError(key, errors[key][0]);
                    });
                } else {
                    showSnackbar(error.response.data.message, 'error')
                }
            }
        });
    }

    return (
        <>
            <Dialog onClose={onClose} open={open} maxWidth={'md'}>
                <div className="dialog-title">
                    <h2>Create New Source</h2>
                    <Tooltip title="Close">
                        <button className="icon-button dialog-close" onClick={onClose}>
                            <Icon path={mdiClose} color={'currentColor'} size={1} />
                        </button>
                    </Tooltip>
                </div>
                <Formik
                    initialValues={{
                        title: '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize
                >
                    {props => {
                        const {
                            touched,
                            errors,
                            values,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue
                        } = props;
                        return (
                            <form onSubmit={handleSubmit}>
                                <TextField
                                    label="Source"
                                    variant="filled"
                                    name="title"
                                    value={values?.title}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.title && touched.title}
                                    helperText={(errors.title && touched.title) && errors.title}
                                />

                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Button className="btn-block" type="submit">Create</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button color="secondary" className="btn-block" onClick={onClose}>Cancel</Button>
                                    </Grid>
                                </Grid>
                            </form>
                        );
                    }}
                </Formik>
            </Dialog>
        </>
    );
}

export default CreateNewSourceDialog;