import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

const EasyEditCustomMultiSelect = props => {
    const [selectValue, setSelectValue] = useState([]);
    props.setAllowEditInline(false)

    useEffect(() => {
        if (props.isMultiSelect) {
            if (props.fieldValue && props.items) {
                const selectedItems = props.items.filter(item =>
                    props.fieldValue.includes(item.key)
                );
                setSelectValue(selectedItems);
            }
        }
    }, [props.fieldValue, props.items]);

    const handleChange = (event, newValue) => {
        if (props.isMultiSelect) {
            setSelectValue(newValue);
            const selectedIds = newValue.map(item => item.key);
            props.setParentValue(selectedIds);
        } else {
            setSelectValue(newValue);
            props.setParentValue(newValue.key);
        }


        // Here you can also propagate the change to the parent component
        // if (props.onChange) {
        //     const selectedIds = newValue.map(item => item.key);
        //     props.onChange(selectedIds);
        // }
    };

    return (
        <Autocomplete
            multiple={props.isMultiSelect}
            id="multi_selects"
            options={props.items || []}
            getOptionLabel={(option) => option.value || ''}
            value={selectValue}
            onChange={handleChange}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="filled"
                    label={props.label}
                    placeholder={'Select ' + props.label}
                    autoFocus
                    inputProps={{
                        ...params.inputProps,
                        onFocus: props.focusCallback,
                    }}
                />
            )}
        />
    );
}

export default EasyEditCustomMultiSelect;