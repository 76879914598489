import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Grid, Stack, TablePagination, Tooltip, Typography } from "@mui/material";
import AvatarInitials from "../../components/common/AvatarInitials";
import Icon from "@mdi/react";
import { mdiPhoneOutline, mdiCalendarWeekBeginOutline, mdiTrashCanOutline, mdiHistory, mdiCalendarCheckOutline, mdiPencilOutline, mdiPercentBoxOutline } from "@mdi/js";
import EasyEdit, { Types } from "react-easy-edit";
import FormGroupStatic from '../../components/common/FormGroupStatic';
import EasyEditCustomTextbox from '../../components/common/EasyEditCustomTextbox';
import EasyEditCustomSelect from '../../components/common/EasyEditCustomSelect';
import { EasyEditCancelButton, EasyEditSaveButton } from "../../components/common/EasyEditButtons";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useLoader } from "../../components/context/LoaderContext";
import useAxios from "../../hooks/useAxios";
import { useSnackbar } from "../../components/context/SnackbarContext";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import { capitalize, DeleteDialog, DeleteDialogConfig, validateField } from "../../utils/Utils";
import EmptyState from "../../components/common/EmptyState";

function ContactDetails(props) {
    const { id } = useParams();
    const contactId = id
    const { showLoader, hideLoader, loading } = useLoader();
    const [reloadPage, setReloadPage] = useState(false);
    const navigate = useNavigate();
    const axiosInstance = useAxios();
    const [contactData, setContactData] = useState();
    const [salutationList, setSalutationList] = useState({});
    const [accountLists, setAccountLists] = useState([]);
    const { showSnackbar } = useSnackbar();
    const [countryArray, setCountryArray] = useState([]);
    const { preferredDateFormat, preTimeZoneKey } = useSelector((state) => state?.user);
    const userPermissions = useSelector((state) => state.user.permission);
    const [errorMessages, setErrorMessages] = useState({});
    const [backendErrorMessages, setBackendErrorMessages] = useState({});
    const [currentPage, setCurrentPage] = useState(0);
    const [deals, setDeals] = useState();
    const [selectedAccountName, setSelectedAccountName] = useState('');
    const [totalCount, setTotalCount] = useState(0);
    const [allowEditInline, setAllowEditInline] = React.useState(true);
    const [perPage, setPerPage] = useState(
        parseInt(process.env.REACT_APP_DEFAULT_PAGINATION)
    );



    useEffect(() => {
        async function fetchData() {
            showLoader();
            getData();
        }
        fetchData();
    }, [reloadPage]);



    const getData = async () => {
        await axiosInstance.get('api/contact/edit/' + contactId).then((response) => {
            if (response.status === 200) {
                setContactData(response?.data?.data?.record);
                setSalutationList(response?.data?.data?.salutationArray)
                setAccountLists(response?.data?.data?.accountLists)
                setCountryArray(response?.data?.data?.countries)
                hideLoader();
                //setBackendErrorMessages({});
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                        navigate("/contacts");
                    }
                }
            });
    }

    useEffect(() => {
        async function fetchData() {
            showLoader();
            if (userPermissions?.includes("deal_list")) {
                await getDealData(
                    currentPage,
                    perPage,
                );
            }
        }
        fetchData();
    }, [currentPage, perPage]);

    const getDealData = async (
        currentPage,
        perPage,
    ) => {
        const params = {
            per_page: perPage,
        };

        await axiosInstance.get(`api/deal`, {
            params: {
                page: currentPage + 1,
                ...params,
                contactId,
            },
        }).then((response) => {
            if (response.status === 200) {
                setDeals(response?.data?.data?.records?.data);
                setTotalCount(response?.data?.data?.records?.total);
                hideLoader();
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                        navigate("/dashboard");
                    }
                }
            });
    };

    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleRowsPerPageChange = (event) => {
        setPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };

    const handleSaveData = async (value, name, type = null) => {

        const data = {
            id: contactId,
            salutation: contactData?.salutation || '',
            first_name: contactData?.first_name || '',
            last_name: contactData?.last_name || '',
            country_id: contactData?.country_id || '',
            account_id: contactData?.account_id || '',
            [name]: value,
        };

        //get value from passing key on obj
        function getValueByKey(recordData, key) {
            return recordData[key];
        }

        // Check if new value and existing value are change than call api
        const newValue = getValueByKey(contactData, name);

        if (newValue !== value && value !== 'N/A') {
            await axiosInstance.post("api/contact/update", data).then(response => {
                if (response.status === 200) {
                    setReloadPage(reloadPage ? false : true)
                    showSnackbar(response.data.message, "success");
                    setAllowEditInline(allowEditInline ? false : true)
                    setBackendErrorMessages({});
                }
            }).then(json => {

            }).catch(error => {
                if (error.response) {
                    if (error.response.status === 422 || error.response.data.validation_errors) {
                        setAllowEditInline(true)
                        const errors = error.response.data.validation_errors;
                        Object.keys(errors).forEach(key => {
                            setBackendErrorMessages({ [key]: errors[key] });
                        });
                    } else {
                        setSelectedAccountName('jignesh')
                        setAllowEditInline(allowEditInline ? false : true)
                        showSnackbar(error.response.data.message, 'error')
                        setReloadPage(reloadPage ? false : true)
                    }
                }
            });
        } else {
            setReloadPage(reloadPage ? false : true)
            setAllowEditInline(allowEditInline ? false : true)
        }
    }

    async function deleteData(id) {
        const data = {
            id: id,
        }
        try {
            DeleteDialog.fire({
                ...DeleteDialogConfig({
                    confirm: async () => {
                        showLoader()
                        await axiosInstance.delete(`api/deal/destroy`, { data })
                            .then((response) => {
                                if (response.status === 200) {
                                    setDeals(prevDeals => prevDeals.filter(deal => deal.id !== id));
                                    showSnackbar(response.data.message, "success");
                                    hideLoader();
                                    setReloadPage(reloadPage ? false : true);
                                }
                            })
                            .then((json) => { })
                            .catch((error) => {
                                if (error.response) {
                                    hideLoader();
                                    if (error.response.status === 400) {
                                        showSnackbar(error.response.data.message, "warning");
                                        navigate("/contacts");
                                    }
                                }
                            });
                    },
                    cancel: () => {
                        hideLoader();
                    }
                })
            });
        } catch (error) {
            hideLoader();
            showSnackbar('Oops Something want wrong..!', "error");
        }
    }

    async function deleteContactData(id) {
        const data = {
            id: id,
        }
        try {
            DeleteDialog.fire({
                ...DeleteDialogConfig({
                    confirm: async () => {
                        showLoader()
                        await axiosInstance.delete(`api/contact/destroy`, { data })
                            .then((response) => {
                                if (response.status === 200) {
                                    showSnackbar(response.data.message, "success");
                                    hideLoader();
                                    setReloadPage(reloadPage ? false : true);
                                    navigate("/contacts");
                                }
                            })
                            .then((json) => { })
                            .catch((error) => {
                                if (error.response) {
                                    hideLoader();
                                    if (error.response.status === 400) {
                                        showSnackbar(error.response.data.message, "warning");
                                    }
                                }
                            });
                    },
                    cancel: () => {
                        hideLoader();
                    }
                })
            });
        } catch (error) {
            hideLoader();
            showSnackbar('Oops Something want wrong..!', "error");
        }
    }

    const handleClick = (e) => {
        setAllowEditInline(false)
    }

    const onCancelEdit = (fieldName) => {
        setErrorMessages({ [fieldName]: '' });
        setAllowEditInline(allowEditInline ? false : true)
    }

    const handleValidation = (value, fieldName, displayName, type, isrequired, min, max) => {
        const errorMsg = validateField(value, fieldName, displayName, type, isrequired, min, max);
        setErrorMessages(prevState => ({ ...prevState, [fieldName]: errorMsg }));
        return errorMsg === '';
    };

    useEffect(() => {
        const selectedAccName = accountLists && accountLists.find(account => account.id === parseInt(contactData.account_id));
        if (selectedAccName) {
            setSelectedAccountName(selectedAccName?.organization_name || '')
        }
    }, [contactData, reloadPage, accountLists]);


    return (
        <>
            <Helmet>
                <title>Contact Details | {capitalize(props?.name || 'SolvCRM')}</title>
            </Helmet>

            {contactData &&
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div className="card p-16">
                            <Grid container alignItems={"center"} flexWrap={"nowrap"}>
                                <Grid item xs={'auto'} className="flex-shrink-1">
                                    <Grid container alignItems={'flex-start'} spacing={1.5}>
                                        <Grid item xs="auto">
                                            <AvatarInitials name={contactData?.first_name + " " + contactData?.last_name} />
                                        </Grid>
                                        <Grid item xs>
                                            <Typography variant='title-medium-bold' component={'h6'}>
                                                {contactData?.salutation ? contactData.salutation + ". " : ""}{" "}
                                                {contactData?.first_name || 'N/A'} {contactData?.last_name || 'N/A'}
                                            </Typography>
                                            <ul className='inline-details'>
                                                <li><Tooltip title="Title"><span>{contactData?.title || 'N/A'}</span></Tooltip></li>
                                                <li>
                                                    <Tooltip title="Phone">
                                                        <span>
                                                            <Icon path={mdiPhoneOutline} color="currentColor" size={1} />
                                                            <div onClick={e => {
                                                                e.stopPropagation();
                                                                e.preventDefault();
                                                            }}>{contactData?.phone1 || 'N/A'}</div>
                                                        </span>
                                                    </Tooltip>
                                                </li>
                                                <li>
                                                    <Tooltip title="Created Date">
                                                        <span>
                                                            <Icon path={mdiCalendarWeekBeginOutline} color="currentColor" size={1} />
                                                            {contactData?.created_at
                                                                ? moment.utc(contactData.created_at)
                                                                    .tz(preTimeZoneKey)
                                                                    .format(preferredDateFormat)
                                                                : "N/A"}
                                                        </span>
                                                    </Tooltip>
                                                </li>
                                                <li>
                                                    <Tooltip title="Last Updated">
                                                        <span>
                                                            <Icon path={mdiHistory} color="currentColor" size={1} />
                                                            {contactData?.updated_at
                                                                ? moment.utc(contactData.updated_at)
                                                                    .tz(preTimeZoneKey)
                                                                    .format(preferredDateFormat)
                                                                : "N/A"}
                                                        </span>
                                                    </Tooltip>
                                                </li>
                                            </ul>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={'auto'} sx={{ marginLeft: 'auto' }}>
                                    {userPermissions && userPermissions.includes("contact_delete") &&
                                        <Button color="secondary" className="icon-only" size="small" onClick={() => { deleteContactData(contactId) }}>
                                            <Icon path={mdiTrashCanOutline} color={'currentColor'} size={1} />
                                        </Button>
                                    }
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>

                    <Grid item xs={12}>
                        <div className="card p-16 height-100p">
                            <Typography variant="title-medium-large-semibold" component={'h4'} className="mb-16">Personal Information</Typography>

                            <Grid container spacing={1.5}>
                                <Grid item lg={4} xxl={3} xs={6}>
                                    <EasyEdit
                                        type={Types.SELECT}
                                        onSave={(value) => { handleSaveData(value, 'salutation') }}
                                        onCancel={() => { setAllowEditInline(true); }}
                                        value={contactData?.salutation || ''}
                                        allowEdit={allowEditInline}
                                        editComponent={<EasyEditCustomSelect focusCallback={e => { setAllowEditInline(false); }} label="Salutation" fieldValue={contactData?.salutation || ''}
                                            items={
                                                Object.entries(salutationList).map(([key, value]) => (
                                                    { value: key, option: value }
                                                ))
                                            }
                                        />
                                        }
                                        displayComponent={<FormGroupStatic easyEdit label="Salutation" value="Mr." />}
                                        saveButtonLabel={<EasyEditSaveButton />}
                                        cancelButtonLabel={<EasyEditCancelButton />}
                                        onFocus={() => handleClick('')}
                                    />
                                    {backendErrorMessages['salutation'] && <span className="easy-edit-validation-error">{backendErrorMessages['salutation']}</span>}
                                </Grid>
                                <Grid item lg={4} xxl={3} xs={6}>
                                    <EasyEdit
                                        type={Types.TEXT}
                                        onSave={(value) => { handleSaveData(value, 'first_name') }}
                                        onCancel={() => { onCancelEdit('first_name') }}
                                        value={contactData?.first_name || 'N/A'}
                                        allowEdit={allowEditInline}
                                        editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="First Name" value={contactData?.first_name} errorMessages={errorMessages['first_name']} />}
                                        displayComponent={<FormGroupStatic easyEdit label="First Name" value={contactData?.first_name} />}
                                        onValidate={(value) => handleValidation(value, 'first_name', 'First Name')}
                                        validationMessage={errorMessages['first_name']}
                                        saveButtonLabel={<EasyEditSaveButton />}
                                        cancelButtonLabel={<EasyEditCancelButton />}
                                        onFocus={() => handleClick('')}
                                    />
                                    {backendErrorMessages['first_name'] && <span className="easy-edit-validation-error">{backendErrorMessages['first_name']}</span>}
                                </Grid>
                                <Grid item lg={4} xxl={3} xs={6}>
                                    <EasyEdit
                                        type={Types.TEXT}
                                        onSave={(value) => { handleSaveData(value, 'last_name') }}
                                        onCancel={() => { onCancelEdit('last_name') }}
                                        value={contactData?.last_name || 'N/A'}
                                        allowEdit={allowEditInline}
                                        editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Last Name" value={contactData?.last_name} errorMessages={errorMessages['last_name']} />}
                                        displayComponent={<FormGroupStatic easyEdit label="Last Name" value={contactData?.last_name || 'N/A'} />}
                                        onValidate={(value) => handleValidation(value, 'last_name', 'Last Name')}
                                        validationMessage={errorMessages['last_name']}
                                        saveButtonLabel={<EasyEditSaveButton />}
                                        cancelButtonLabel={<EasyEditCancelButton />}
                                        onFocus={() => handleClick('')}
                                    />
                                    {backendErrorMessages['last_name'] && <span className="easy-edit-validation-error">{backendErrorMessages['last_name']}</span>}
                                </Grid>
                                <Grid item lg={4} xxl={3} xs={6}>
                                    <EasyEdit
                                        type={Types.TEXT}
                                        onSave={(value) => { handleSaveData(value, 'primary_email') }}
                                        onCancel={() => { onCancelEdit('primary_email') }}
                                        value={contactData?.primary_email || 'N/A'}
                                        allowEdit={allowEditInline}
                                        editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Primary email" value={contactData?.primary_email} errorMessages={errorMessages['primary_email']} />}
                                        displayComponent={<FormGroupStatic easyEdit label="Primary email" element={
                                            contactData?.primary_email ? <a href={"mailto:" + contactData?.primary_email} className="primary-link">{contactData?.primary_email || 'N/A'}</a> : 'N/A'
                                        } />}
                                        onValidate={(value) => handleValidation(value, 'primary_email', 'Email', 'isEmail', false)}
                                        validationMessage={errorMessages['primary_email']}
                                        saveButtonLabel={<EasyEditSaveButton />}
                                        cancelButtonLabel={<EasyEditCancelButton />}
                                        onFocus={() => handleClick('')}
                                    />
                                    {backendErrorMessages['primary_email'] && <span className="easy-edit-validation-error">{backendErrorMessages['primary_email']}</span>}
                                </Grid>
                                <Grid item lg={4} xxl={3} xs={6}>
                                    <EasyEdit
                                        type={Types.TEXT}
                                        onSave={(value) => { handleSaveData(value, 'secondry_email') }}
                                        onCancel={() => { onCancelEdit('secondry_email') }}
                                        value={contactData?.secondry_email || 'N/A'}
                                        allowEdit={allowEditInline}
                                        editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Secondary email" value={contactData?.secondry_email} errorMessages={errorMessages['secondry_email']} />}
                                        displayComponent={<FormGroupStatic easyEdit label="Secondary email" element={
                                            contactData?.secondry_email ? <a href={"mailto:" + contactData?.secondry_email || 'N/A'} className="primary-link">{contactData?.secondry_email || 'N/A'}</a> : 'N/A'
                                        } />}
                                        onValidate={(value) => handleValidation(value, 'secondry_email', 'Email', 'isEmail', false)}
                                        validationMessage={errorMessages['secondry_email']}
                                        saveButtonLabel={<EasyEditSaveButton />}
                                        cancelButtonLabel={<EasyEditCancelButton />}
                                        onFocus={() => handleClick('')}
                                    />
                                    {backendErrorMessages['secondry_email'] && <span className="easy-edit-validation-error">{backendErrorMessages['secondry_email']}</span>}
                                </Grid>
                                <Grid item lg={4} xxl={3} xs={6}>
                                    <EasyEdit
                                        type={Types.TEXT}
                                        onSave={(value) => { handleSaveData(value, 'phone1') }}
                                        onCancel={() => { onCancelEdit('phone1') }}
                                        value={contactData?.phone1 || 'N/A'}
                                        allowEdit={allowEditInline}
                                        editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Phone 1" value={contactData?.phone1} errorMessages={errorMessages['phone1']} />}
                                        displayComponent={<FormGroupStatic easyEdit label="Phone 1" element={
                                            contactData?.phone1 ? <a href={"tel:" + contactData?.phone1 || 'N/A'} className="primary-link">{contactData?.phone1 || 'N/A'}</a> : 'N/A'
                                        } />}
                                        onValidate={(value) => handleValidation(value, 'phone1', 'Phone', 'phone', false)}
                                        validationMessage={errorMessages['phone1']}
                                        saveButtonLabel={<EasyEditSaveButton />}
                                        cancelButtonLabel={<EasyEditCancelButton />}
                                        onFocus={() => handleClick('')}
                                    />
                                    {backendErrorMessages['phone1'] && <span className="easy-edit-validation-error">{backendErrorMessages['phone1']}</span>}
                                </Grid>
                                <Grid item lg={4} xxl={3} xs={6}>
                                    <EasyEdit
                                        type={Types.TEXT}
                                        onSave={(value) => { handleSaveData(value, 'phone2') }}
                                        onCancel={() => { onCancelEdit('phone2') }}
                                        value={contactData?.phone2 || 'N/A'}
                                        allowEdit={allowEditInline}
                                        editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Phone 2" value={contactData?.phone2} errorMessages={errorMessages['phone2']} />}
                                        displayComponent={<FormGroupStatic easyEdit label="Phone 2" element={
                                            contactData?.phone2 ? <a href={"tel:" + contactData?.phone2 || 'N/A'} className="primary-link">{contactData?.phone2 || 'N/A'}</a> : 'N/A'
                                        } />}
                                        onValidate={(value) => handleValidation(value, 'phone2', 'Phone', 'phone', false)}
                                        validationMessage={errorMessages['phone2']}
                                        saveButtonLabel={<EasyEditSaveButton />}
                                        cancelButtonLabel={<EasyEditCancelButton />}
                                        onFocus={() => handleClick('')}
                                    />
                                    {backendErrorMessages['phone2'] && <span className="easy-edit-validation-error">{backendErrorMessages['phone2']}</span>}
                                </Grid>
                                <Grid item xs={12}>
                                    <EasyEdit
                                        type={Types.TEXT}
                                        onSave={(value) => { handleSaveData(value, 'description') }}
                                        onCancel={() => { onCancelEdit('description') }}
                                        value={contactData?.description || 'N/A'}
                                        allowEdit={allowEditInline}
                                        editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Description" value={contactData?.description} errorMessages={errorMessages['description']} />}
                                        displayComponent={<FormGroupStatic easyEdit label="Description" value={contactData?.description || 'N/A'} />}
                                        onValidate={(value) => handleValidation(value, 'description', 'Description', '', false, '2', '150')}
                                        validationMessage={errorMessages['description']}
                                        saveButtonLabel={<EasyEditSaveButton />}
                                        cancelButtonLabel={<EasyEditCancelButton />}
                                        onFocus={() => handleClick('')}
                                    />
                                    {backendErrorMessages['description'] && <span className="easy-edit-validation-error">{backendErrorMessages['description']}</span>}
                                </Grid>
                            </Grid>

                            <hr className="hr my-16" />

                            <Typography variant="title-medium-large-semibold" component={'h4'} className="mb-16">Professional Information</Typography>

                            <Grid container spacing={1.5}>
                                <Grid item xxl={3} lg={4} xs={6}>
                                    <EasyEdit
                                        type={Types.TEXT}
                                        onSave={(value) => { handleSaveData(value, 'title') }}
                                        onCancel={() => { onCancelEdit('title') }}
                                        value={contactData?.title || 'N/A'}
                                        allowEdit={allowEditInline}
                                        editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Title" value={contactData?.title} errorMessages={errorMessages['title']} />}
                                        displayComponent={<FormGroupStatic easyEdit label="Title" value={contactData?.title || 'N/A'} />}
                                        onValidate={(value) => handleValidation(value, 'title', 'Title', '', false, '2', '150')}
                                        validationMessage={errorMessages['title']}
                                        saveButtonLabel={<EasyEditSaveButton />}
                                        cancelButtonLabel={<EasyEditCancelButton />}
                                        onFocus={() => handleClick('')}
                                    />
                                    {backendErrorMessages['title'] && <span className="easy-edit-validation-error">{backendErrorMessages['title']}</span>}
                                </Grid>
                                <Grid item xxl={3} lg={4} xs={6}>
                                    <EasyEdit
                                        type={Types.SELECT}
                                        onSave={(value) => { handleSaveData(value, 'account_id') }}
                                        onCancel={() => { onCancelEdit() }}
                                        value={selectedAccountName || 'N/A'}
                                        allowEdit={allowEditInline}
                                        editComponent={
                                            <EasyEditCustomSelect focusCallback={e => { setAllowEditInline(false); }} label="Account" fieldValue={contactData?.account_id || ''}
                                                items={
                                                    accountLists && accountLists.length > 0 && accountLists.map((account) => (
                                                        { value: account.id, option: account?.organization_name }
                                                    ))
                                                }
                                            />}
                                        displayComponent={<FormGroupStatic easyEdit label="Account" value={selectedAccountName || 'N/A'} />}
                                        saveButtonLabel={<EasyEditSaveButton />}
                                        cancelButtonLabel={<EasyEditCancelButton />}
                                        onFocus={() => handleClick('')}
                                    />
                                    {backendErrorMessages['account_id'] && <span className="easy-edit-validation-error">{backendErrorMessages['account_id']}</span>}
                                </Grid>
                                <Grid item xxl={3} lg={4} xs={6}>
                                    <EasyEdit
                                        type={Types.TEXT}
                                        onSave={(value) => { handleSaveData(value, 'department') }}
                                        onCancel={() => { onCancelEdit('department') }}
                                        value={contactData?.department || 'N/A'}
                                        allowEdit={allowEditInline}
                                        editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Department" value={contactData?.department} errorMessages={errorMessages['department']} />}
                                        displayComponent={<FormGroupStatic easyEdit label="Department" value={contactData?.department || 'N/A'} />}
                                        onValidate={(value) => handleValidation(value, 'department', 'Department', '', false, '2', '150')}
                                        validationMessage={errorMessages['department']}
                                        saveButtonLabel={<EasyEditSaveButton />}
                                        cancelButtonLabel={<EasyEditCancelButton />}
                                        onFocus={() => handleClick('')}
                                    />
                                    {backendErrorMessages['department'] && <span className="easy-edit-validation-error">{backendErrorMessages['department']}</span>}
                                </Grid>
                                <Grid item xxl={3} lg={4} xs={6}>
                                    <EasyEdit
                                        type={Types.TEXT}
                                        onSave={(value) => { handleSaveData(value, 'facebook_url') }}
                                        onCancel={() => { onCancelEdit('facebook_url') }}
                                        value={contactData?.facebook_url || 'N/A'}
                                        allowEdit={allowEditInline}
                                        editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Facebook" value={contactData?.facebook_url} errorMessages={errorMessages['facebook_url']} />}
                                        displayComponent={<FormGroupStatic easyEdit label="Facebook" element={
                                            contactData?.facebook_url ? <a href={"mailto:" + contactData?.facebook_url || 'N/A'} className="primary-link">{contactData?.facebook_url || 'N/A'}</a> : 'N/A'
                                        } />}
                                        onValidate={(value) => handleValidation(value, 'facebook_url', 'Facebook', 'isWebsite', false, 2, 255)}
                                        saveButtonLabel={<EasyEditSaveButton />}
                                        cancelButtonLabel={<EasyEditCancelButton />}
                                        onFocus={() => handleClick('')}
                                    />
                                    {backendErrorMessages['facebook_url'] && <span className="easy-edit-validation-error">{backendErrorMessages['facebook_url']}</span>}
                                </Grid>
                                <Grid item xxl={3} lg={4} xs={6}>
                                    <EasyEdit
                                        type={Types.TEXT}
                                        onSave={(value) => { handleSaveData(value, 'linkedin_url') }}
                                        onCancel={() => { onCancelEdit('linkedin_url') }}
                                        value={contactData?.linkedin_url || 'N/A'}
                                        allowEdit={allowEditInline}
                                        editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="LinkedIn" value={contactData?.linkedin_url} errorMessages={errorMessages['linkedin_url']} />}
                                        displayComponent={<FormGroupStatic easyEdit label="LinkedIn" element={
                                            contactData?.linkedin_url ? <a href={"mailto:" + contactData?.linkedin_url || 'N/A'} className="primary-link">{contactData?.linkedin_url || 'N/A'}</a> : 'N/A'
                                        } />}
                                        onValidate={(value) => handleValidation(value, 'linkedin_url', 'LinkedIn', 'isWebsite', false, 2, 255)}
                                        saveButtonLabel={<EasyEditSaveButton />}
                                        cancelButtonLabel={<EasyEditCancelButton />}
                                        onFocus={() => handleClick('')}
                                    />
                                    {backendErrorMessages['linkedin_url'] && <span className="easy-edit-validation-error">{backendErrorMessages['linkedin_url']}</span>}
                                </Grid>
                                <Grid item xxl={3} lg={4} xs={6}>
                                    <EasyEdit
                                        type={Types.TEXT}
                                        onSave={(value) => { handleSaveData(value, 'twitter_url') }}
                                        onCancel={() => { onCancelEdit('twitter_url') }}
                                        value={contactData?.twitter_url || 'N/A'}
                                        allowEdit={allowEditInline}
                                        editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Twitter" value={contactData?.twitter_url} errorMessages={errorMessages['twitter_url']} />}
                                        displayComponent={<FormGroupStatic easyEdit label="Twitter" element={
                                            contactData?.twitter_url ? <a href={"mailto:" + contactData?.twitter_url || 'N/A'} className="primary-link">{contactData?.twitter_url || 'N/A'}</a> : 'N/A'
                                        } />}
                                        onValidate={(value) => handleValidation(value, 'twitter_url', 'Twitter', 'isWebsite', false, 2, 255)}
                                        saveButtonLabel={<EasyEditSaveButton />}
                                        cancelButtonLabel={<EasyEditCancelButton />}
                                        onFocus={() => handleClick('')}
                                    />
                                    {backendErrorMessages['twitter_url'] && <span className="easy-edit-validation-error">{backendErrorMessages['twitter_url']}</span>}
                                </Grid>
                                <Grid item xxl={3} lg={4} xs={6}>
                                    <EasyEdit
                                        type={Types.TEXT}
                                        onSave={(value) => { handleSaveData(value, 'instagram_url') }}
                                        onCancel={() => { onCancelEdit('instagram_url') }}
                                        value={contactData?.instagram_url || 'N/A'}
                                        allowEdit={allowEditInline}
                                        editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Instagram" value={contactData?.instagram_url} errorMessages={errorMessages['instagram_url']} />}
                                        displayComponent={<FormGroupStatic easyEdit label="Instagram" element={
                                            contactData?.instagram_url ? <a href={"mailto:" + contactData?.instagram_url || 'N/A'} className="primary-link">{contactData?.instagram_url || 'N/A'}</a> : 'N/A'
                                        } />}
                                        onValidate={(value) => handleValidation(value, 'instagram_url', 'Instagram', 'isWebsite', false, 2, 255)}
                                        saveButtonLabel={<EasyEditSaveButton />}
                                        cancelButtonLabel={<EasyEditCancelButton />}
                                        onFocus={() => handleClick('')}
                                    />
                                    {backendErrorMessages['instagram_url'] && <span className="easy-edit-validation-error">{backendErrorMessages['instagram_url']}</span>}
                                </Grid>
                            </Grid>

                            <hr className="hr my-16" />

                            <Typography variant="title-medium-large-semibold" component={'h4'} className="mb-16">Address Information</Typography>

                            <Grid container spacing={1.5}>
                                <Grid item lg={6} xs={12}>
                                    <EasyEdit
                                        type={Types.TEXT}
                                        onSave={(value) => { handleSaveData(value, 'address1') }}
                                        onCancel={() => { onCancelEdit('address1') }}
                                        value={contactData.address1 || 'N/A'}
                                        allowEdit={allowEditInline}
                                        editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Address 1" value={contactData.address1} errorMessages={errorMessages['address1']} maxLength={100} />}
                                        displayComponent={<FormGroupStatic easyEdit label="Address 1" value={contactData.address1 || 'N/A'} />}
                                        onValidate={(value) => handleValidation(value, 'address1', 'Address 1', '', false, 2, 100)}
                                        validationMessage={errorMessages['address1']}
                                        saveButtonLabel={<EasyEditSaveButton />}
                                        cancelButtonLabel={<EasyEditCancelButton />}
                                        onFocus={() => handleClick('')}
                                    />
                                    {backendErrorMessages['address1'] && <span className="easy-edit-validation-error">{backendErrorMessages['address1']}</span>}
                                </Grid>
                                <Grid item lg={6} xs={12}>
                                    <EasyEdit
                                        type={Types.TEXT}
                                        onSave={(value) => { handleSaveData(value, 'address2') }}
                                        onCancel={() => { onCancelEdit('address2') }}
                                        value={contactData.address2 || 'N/A'}
                                        allowEdit={allowEditInline}
                                        editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Address 2" value={contactData.address2} errorMessages={errorMessages['address2']} maxLength={100} />}
                                        displayComponent={<FormGroupStatic easyEdit label="Address 2" value={contactData.address2 || 'N/A'} />}
                                        onValidate={(value) => handleValidation(value, 'address2', 'Address 2', '', false, 2, 100)}
                                        validationMessage={errorMessages['address2']}
                                        saveButtonLabel={<EasyEditSaveButton />}
                                        cancelButtonLabel={<EasyEditCancelButton />}
                                        onFocus={() => handleClick('')}
                                    />
                                    {backendErrorMessages['address2'] && <span className="easy-edit-validation-error">{backendErrorMessages['address2']}</span>}
                                </Grid>
                                <Grid item lg={3} xs={6}>
                                    <EasyEdit
                                        type={Types.TEXT}
                                        onSave={(value) => { handleSaveData(value, 'city') }}
                                        onCancel={() => { onCancelEdit('city') }}
                                        value={contactData.city || 'N/A'}
                                        allowEdit={allowEditInline}
                                        editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="City" value={contactData.city} errorMessages={errorMessages['city']} maxLength={50} />}
                                        displayComponent={<FormGroupStatic easyEdit label="City" value={contactData.city || 'N/A'} />}
                                        onValidate={(value) => handleValidation(value, 'city', 'City', '', false)}
                                        validationMessage={errorMessages['city']}
                                        saveButtonLabel={<EasyEditSaveButton />}
                                        cancelButtonLabel={<EasyEditCancelButton />}
                                        onFocus={() => handleClick('')}
                                    />
                                    {backendErrorMessages['city'] && <span className="easy-edit-validation-error">{backendErrorMessages['city']}</span>}
                                </Grid>
                                <Grid item lg={3} xs={6}>
                                    <EasyEdit
                                        type={Types.TEXT}
                                        onSave={(value) => { handleSaveData(value, 'state') }}
                                        onCancel={() => { onCancelEdit('state') }}
                                        value={contactData.state || 'N/A'}
                                        allowEdit={allowEditInline}
                                        editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="State" value={contactData.state} errorMessages={errorMessages['state']} maxLength={50} />}
                                        displayComponent={<FormGroupStatic easyEdit label="State" value={contactData.state || 'N/A'} />}
                                        onValidate={(value) => handleValidation(value, 'state', 'State', '', false)}
                                        validationMessage={errorMessages['state']}
                                        saveButtonLabel={<EasyEditSaveButton />}
                                        cancelButtonLabel={<EasyEditCancelButton />}
                                        onFocus={() => handleClick('')}
                                    />
                                    {backendErrorMessages['state'] && <span className="easy-edit-validation-error">{backendErrorMessages['state']}</span>}
                                </Grid>
                                <Grid item lg={3} xs={6}>
                                    <EasyEdit
                                        type={Types.TEXT}
                                        onSave={(value) => { handleSaveData(value, 'zipcode') }}
                                        onCancel={() => { onCancelEdit('zipcode') }}
                                        value={contactData.zipcode || 'N/A'}
                                        allowEdit={allowEditInline}
                                        editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Zipcode" value={contactData.zipcode} errorMessages={errorMessages['zipcode']} maxLength={10} />}
                                        displayComponent={<FormGroupStatic easyEdit label="Zipcode" value={contactData.zipcode || 'N/A'} />}
                                        onValidate={(value) => handleValidation(value, 'zipcode', 'Zipcode', '', false, 2, 10)}
                                        validationMessage={errorMessages['zipcode']}
                                        saveButtonLabel={<EasyEditSaveButton />}
                                        cancelButtonLabel={<EasyEditCancelButton />}
                                        onFocus={() => handleClick('')}
                                    />
                                    {backendErrorMessages['zipcode'] && <span className="easy-edit-validation-error">{backendErrorMessages['zipcode']}</span>}
                                </Grid>
                                <Grid item lg={3} xs={6}>
                                    <EasyEdit
                                        type={Types.SELECT}
                                        onSave={(value) => { handleSaveData(value, 'country_id') }}
                                        onCancel={() => { onCancelEdit('country_id') }}
                                        value={contactData?.country?.country_name || 'N/A'}
                                        allowEdit={allowEditInline}
                                        editComponent={<EasyEditCustomSelect focusCallback={e => { setAllowEditInline(false); }} label="Country" fieldValue={contactData?.country_id || ''}
                                            items={
                                                countryArray && countryArray.length > 0 && countryArray.map((country) => (
                                                    { value: country.id, option: country.country_name }
                                                ))
                                            }
                                        />}
                                        displayComponent={<FormGroupStatic easyEdit label="Country" value={contactData?.country?.country_name} />}
                                        saveButtonLabel={<EasyEditSaveButton />}
                                        cancelButtonLabel={<EasyEditCancelButton />}
                                        onFocus={() => handleClick('')}
                                    />
                                    {backendErrorMessages['country_id'] && <span className="easy-edit-validation-error">{backendErrorMessages['country_id']}</span>}
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    {
                        userPermissions && userPermissions?.includes("deal_list") &&
                        <Grid item xs={12}>
                            <div className="card p-16">
                                <div className="card-header">
                                    <h6 className="title">Deals</h6>
                                </div>
                                {deals && deals.length > 0 && (
                                    <ul className="primary-list">
                                        {
                                            deals.map((deal, index) => (
                                                <li key={index + deal?.id}>
                                                    <Link to={userPermissions?.includes("deal_edit") ? '/deal-detail/' + deal?.id : '#'} className="card">
                                                        <Typography variant='title-medium-bold' component={'h6'}>{deal?.deal_name}</Typography>
                                                        <ul className='inline-details'>
                                                            <li><span className="bold">₹{deal?.deal_amount}</span></li>
                                                            <li>
                                                                <Tooltip title="Stage">
                                                                    <span>
                                                                        {deal?.get_stage?.name === 'New' && <span className="status-badge">{deal?.get_stage?.name}</span>}
                                                                        {deal?.get_stage?.name === 'Open' && <span className="status-badge warning">{deal?.get_stage?.name}</span>}
                                                                        {deal?.get_stage?.name === 'Closed Won' && <span className="status-badge success">{deal?.get_stage?.name}</span>}
                                                                        {deal?.get_stage?.name === 'Closed Lost' && <span className="status-badge danger">{deal?.get_stage?.name}</span>}
                                                                    </span>
                                                                </Tooltip>
                                                            </li>
                                                            <li>
                                                                <Tooltip title="Probability">
                                                                    <span>
                                                                        <Icon path={mdiPercentBoxOutline} color="currentColor" size={1} />{deal?.deal_probability}%
                                                                    </span>
                                                                </Tooltip>
                                                            </li>
                                                            <li>
                                                                <Tooltip title="Expected Closing Date">
                                                                    <span>
                                                                        <Icon path={mdiCalendarCheckOutline} color="currentColor" size={1} />
                                                                        {deal?.expected_closing_date
                                                                            ? moment.utc(deal?.expected_closing_date)
                                                                                .tz(preTimeZoneKey)
                                                                                .format(preferredDateFormat)
                                                                            : "N/A"}
                                                                    </span>
                                                                </Tooltip>
                                                            </li>
                                                        </ul>
                                                        <ul className='inline-details other-info ml-0'>
                                                            <li><span className='title'>Closing Date:</span>
                                                                {deal?.closing_date
                                                                    ? moment.utc(deal?.closing_date)
                                                                        .tz(preTimeZoneKey)
                                                                        .format(preferredDateFormat)
                                                                    : "N/A"}
                                                            </li>
                                                            <li>
                                                                <Tooltip title="Created Date">
                                                                    <span>
                                                                        <Icon path={mdiHistory} color="currentColor" size={1} />
                                                                        {deal?.created_at
                                                                            ? moment.utc(deal?.created_at)
                                                                                .tz(preTimeZoneKey)
                                                                                .format(preferredDateFormat)
                                                                            : "N/A"}
                                                                    </span>
                                                                </Tooltip>
                                                            </li>
                                                        </ul>
                                                    </Link>
                                                    <Stack direction={'row'} className='actions'>
                                                        <Tooltip title="Edit">
                                                            <span>
                                                                <Link to={'/deal-detail/' + deal?.id} className='icon-button'>
                                                                    <Icon path={mdiPencilOutline} color="currentColor" size={1} />
                                                                </Link>
                                                            </span>
                                                        </Tooltip>
                                                        <Tooltip title="Delete">
                                                            <button type="button" className='icon-button' onClick={() => { deleteData(deal?.id) }}>
                                                                <Icon path={mdiTrashCanOutline} color="currentColor" size={1} />
                                                            </button>
                                                        </Tooltip>
                                                    </Stack>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                )}

                                {(!deals || deals.length === 0) && !loading &&
                                    <EmptyState type={'deal'} hideActionBtn size="small" />
                                }

                                {
                                    deals && deals.length > 0 &&
                                    <TablePagination
                                        component="div"
                                        count={totalCount}
                                        page={currentPage}
                                        rowsPerPage={perPage}
                                        onPageChange={handlePageChange}
                                        onRowsPerPageChange={handleRowsPerPageChange}
                                    />
                                }

                            </div>
                        </Grid>
                    }

                </Grid >
            }
        </>
    );
}

export default ContactDetails;