import { Button, Stack } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function SettingsTab() {
    const navigate = useNavigate();
    const userPermissions = useSelector((state) => state.user.permission);
    const getActiveButtonProps = (link) => {
        const url = window.location.pathname;
        let variant = 'text';
        let color = 'primary';
        let redirectUrl = '/settings';
        if (url.includes(link) || (link === 'root' && url === '/settings')) {
            variant = 'contained';
            color = 'secondary';
        }
        switch (link) {
            case 'subscription':
                redirectUrl = '/settings/subscription';
                break;
            case 'users':
                redirectUrl = '/settings/users';
                break;
            case 'roles':
                redirectUrl = '/settings/roles';
                break;
            case 'products':
                redirectUrl = '/settings/products';
                break;
            case 'stages':
                redirectUrl = '/settings/stages';
                break;
            case 'sources':
                redirectUrl = '/settings/sources';
                break;
            case 'activity-logs':
                redirectUrl = '/settings/activity-logs';
                break;
            default:
                break;
        }
        return {
            variant,
            size: 'small',
            color,
            onClick: e => { navigate(redirectUrl); }
        }
    }

    return (
        <>
            <Stack spacing={{ xl: 0.5, xs: 0 }} direction={'row'} className="page-tabs">
                <Button {...getActiveButtonProps('root')}>Overview</Button>
                {/* <Button {...getActiveButtonProps('subscription')}>Subscription</Button> */}
                {userPermissions && userPermissions.includes("user_list") &&
                    <Button {...getActiveButtonProps('users')}>Users</Button>
                }
                {userPermissions && userPermissions.includes("role_list") &&
                    <Button {...getActiveButtonProps('roles')}>Roles</Button>
                }
                {userPermissions && userPermissions.includes("product_list") &&
                    <Button {...getActiveButtonProps('products')}>Products</Button>
                }
                {userPermissions && userPermissions.includes("stage_list") &&
                    <Button {...getActiveButtonProps('stages')}>Stages</Button>
                }
                {userPermissions && userPermissions.includes("source_list") &&
                    <Button {...getActiveButtonProps('sources')}>Sources</Button>
                }
                {userPermissions && userPermissions.includes("activitylog_list") &&
                    <Button {...getActiveButtonProps('activity-logs')}>Activity Logs</Button>
                }
            </Stack>
        </>
    );
}

export default SettingsTab;