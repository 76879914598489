export const LoginBannerImage = require('./login-banner.jpg');
export const LogoMark = require('./logo-mark.png');
export const LogoMarkXlFinancial = require('./logo-mark-xl-financial.png');
export const LogoMarkWhite = require('./logo-mark-white.png');
export const LogoMarkPrimary = require('./logo-mark-primary.png');
export const LogoWhiteFull = require('./logo-white-full.png');
export const LogoFullXlFinancial = require('./logo-full-xl-financial.png');
export const UserAvatar = require('./user-avatar.jpg');
export const UserAvatar2 = require('./user-avatar-2.jpg');
export const UserAvatar3 = require('./user-avatar-3.jpg');
export const LoginCut = require('./login-cut.svg').default;
export const MobileDetected = require('./mobile-detected.svg').default;
export const MobileDetectedDark = require('./mobile-detected-dark.svg').default;
export const GooglePlayStore = require('./google-play-store-badge.svg').default;
export const AppleAppStore = require('./apple-app-store-badge.svg').default;
export const ForgotPasswordIllustration = require('./forgot-password-illustration.svg').default;
export const EmptyStateEmptyBox = require('./empty-state-empty-box.svg').default;
export const EmptyStateEmptyFolder = require('./empty-state-empty-folder.svg').default;
export const EmptyStateNoContent = require('./empty-state-no-content.svg').default;
export const EmptyStateNoProfile = require('./empty-state-no-profile.svg').default;
export const EmptyStateTag = require('./empty-state-tag.svg').default;
export const EmptyStateTask = require('./empty-state-task.svg').default;
export const RectangleProgress95 = require('./rectangle-progress-95.svg').default;
export const RectangleProgress90 = require('./rectangle-progress-90.svg').default;
export const RectangleProgress30 = require('./rectangle-progress-30.svg').default;
export const pageNotFoundIcon = require('./404.svg').default;
export const internalServerErrorIcon = require('./500.svg').default;
export const pageNotFoundIconXlFinancial = require('./404-xl-financial.svg').default;
export const internalServerErrorIconXlFinancial = require('./500-xl-financial.svg').default;
export const faviconXl = require('./favicon-xl.ico');
export const favicon = require('./favicon.ico');