import React, { Suspense, useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { Grid, TextField, MenuItem, InputAdornment, Button, Stack, Checkbox, Typography, Menu, Popover, FormControlLabel, RadioGroup, Radio, Tooltip, IconButton } from '@mui/material';
import Icon from '@mdi/react';
import { mdiAccountOutline, mdiCalendarWeekBeginOutline, mdiClose, mdiDotsVertical, mdiEmailOutline, mdiExportVariant, mdiHistory, mdiMagnify, mdiPencilOutline, mdiPhoneOutline, mdiPlus, mdiSort, mdiTrashCanOutline } from '@mdi/js';
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import TablePagination from '@mui/material/TablePagination';
import CreateNewAccountDialog from "./components/CreateNewAccountDialog";
import useAxios from "../../hooks/useAxios";
import { useLoader } from "../../components/context/LoaderContext";
import { useSnackbar } from "../../components/context/SnackbarContext";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import ExportData from "../../components/ExportData";
import EmptyState from "../../components/common/EmptyState";
import { capitalize, DeleteDialog, DeleteDialogConfig } from "../../utils/Utils";

function Accounts(props) {
    const navigate = useNavigate();
    const { showLoader, hideLoader, loading } = useLoader();
    const [checkedItems, setCheckedItems] = useState({});
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [accounts, setAccounts] = useState();
    const axiosInstance = useAxios();
    const [userLists, setUserLists] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [sortBy, setSortBy] = useState("created_at");
    const { preferredDateFormat, preTimeZoneKey } = useSelector((state) => state?.user);
    const userPermissions = useSelector((state) => state.user.permission);

    const [perPage, setPerPage] = useState(
        parseInt(process.env.REACT_APP_DEFAULT_PAGINATION)
    );
    const [sortOrder, setSortOrder] = useState(
        process.env.REACT_APP_DEFAULT_SORT
    );
    const { showSnackbar } = useSnackbar();
    const [searchUser, setSearchUser] = useState('All Users');
    const [reloadPage, setReloadPage] = useState(false);
    const [search, setSearch] = useState("");
    const [searchParams] = useSearchParams();
    const orderByParam = searchParams.get("sort_order") || process.env.REACT_APP_DEFAULT_SORT;
    const sortByParam = searchParams.get("sort_field") || "created_at";
    const [handleSortBy, setHandleSortBy] = useState(sortByParam);
    const [handleOrderBy, setHandleOrderBy] = useState(orderByParam);

    const [clearType, setClearType] = useState(false);

    useEffect(() => {
        async function fetchData() {
            showLoader();
            await getData(
                currentPage,
                perPage,
                sortBy,
                sortOrder,
                search,
                searchUser
            );
        }
        fetchData();
    }, [
        currentPage,
        perPage,
        sortBy,
        sortOrder,
        searchUser,
        reloadPage,
        clearType,
    ]);

    const getData = async (
        currentPage,
        perPage,
        sortBy,
        sortOrder,
        search,
        searchUser
    ) => {
        const params = {
            search: search,
            search_user: searchUser === "All Users" ? "" : searchUser,
            sort_field: sortBy,
            sort_order: sortOrder,
            per_page: perPage,
        };

        await axiosInstance
            .get(`api/account?page=${currentPage + 1}`, { params })
            .then((response) => {
                if (response.status === 200) {
                    setAccounts(response?.data?.data?.records?.data);
                    setTotalCount(response?.data?.data?.records?.total);
                    setUserLists(response?.data?.data?.users || []);
                    hideLoader();
                    setCheckedItems({});
                    setIsCheckAll(false);
                }
            })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                        navigate("/dashboard");
                    }
                }
            });
    };

    const getCheckedIds = () => {
        return Object.keys(checkedItems).filter((id) => checkedItems[id]);
    };

    const checkedIds = getCheckedIds();
    const expParams = {
        search: search,
        search_user: searchUser === "All Users" ? "" : searchUser,
        sort_field: sortBy,
        sort_order: sortOrder,
        per_page: perPage,
        selectedIds: checkedIds && checkedIds.length > 0 ? checkedIds : []
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault(); // Prevent the default form submission behavior
            getData(
                currentPage,
                perPage,
                sortBy,
                sortOrder,
                search,
                searchUser
            );
        }
    };

    const HandleSearchUser = (event) => {
        setSearchUser(event.target.value);
    };

    const handleCheckAll = (event) => {
        const { checked } = event.target;
        setIsCheckAll(checked);
        const newCheckedItems = {};
        accounts.forEach((lead) => {
            newCheckedItems[lead.id] = checked;
        });
        setCheckedItems(newCheckedItems);
    };

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setCheckedItems({
            ...checkedItems,
            [name]: checked,
        });

        if (!checked) {
            setIsCheckAll(false);
        } else {
            const allChecked = accounts.every(
                (deal) => checkedItems[deal.id] || deal.name === name
            );
            setIsCheckAll(allChecked);
        }
    };

    const handleSortChange = (event) => {
        const { name, value } = event.target;
        if (name === "sort-by-radio") {
            //setSortBy(value); //if dont want to click on apply button than open it and hide setHandleSortBy
            setHandleSortBy(value);
        } else if (name === "sort-order-radio") {
            //setSortOrder(value);
            setHandleOrderBy(value);
        }
    };

    const handleSortByApply = () => {
        //click to manage apply button....
        setSortBy(handleSortBy);
        setSortOrder(handleOrderBy);

        setSortByAnchorEl(null);
    };
    const handleSortByReset = () => {
        setSortByAnchorEl(null);

        setSortBy("created_at");
        setSortOrder("desc");
    };

    const handleReloadFunc = () => {
        setCheckedItems({});
        setIsCheckAll(false);
        hideLoader();
        setReloadPage(reloadPage ? false : true);
    }

    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleRowsPerPageChange = (event) => {
        setPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };

    async function deleteData(id) {
        const data = {
            id: id,
        }
        try {
            DeleteDialog.fire({
                ...DeleteDialogConfig({
                    confirm: async () => {
                        showLoader()
                        await axiosInstance.delete(`api/account/destroy`, { data })
                            .then((response) => {
                                if (response.status === 200) {
                                    showSnackbar(response.data.message, "success");
                                    hideLoader();
                                    setReloadPage(reloadPage ? false : true);
                                    navigate("/accounts");
                                }
                            })
                            .then((json) => { })
                            .catch((error) => {
                                if (error.response) {
                                    hideLoader();
                                    if (error.response.status === 400) {
                                        showSnackbar(error.response.data.message, "warning");
                                    }
                                }
                            });
                    },
                    cancel: () => {
                        hideLoader();
                    }
                })
            });
        } catch (error) {
            hideLoader();
            showSnackbar('Oops Something want wrong..!', "error");
        }
    }


    const bulkDeleteData = async (ids) => {
        const data = {
            id: ids,
        }

        try {
            DeleteDialog.fire({
                ...DeleteDialogConfig({
                    confirm: async () => {
                        showLoader()
                        await axiosInstance.delete('api/account/bulk-destroy', { data })
                            .then((response) => {
                                if (response.status === 200) {
                                    showSnackbar(response.data.message, "success");
                                    setCheckedItems({});
                                    setIsCheckAll(false);
                                    hideLoader();
                                    setReloadPage(reloadPage ? false : true);
                                }
                            })
                            .then((json) => { })
                            .catch((error) => {
                                if (error.response) {
                                    hideLoader();
                                    if (error.response.status === 400) {
                                        showSnackbar(error.response.data.message, "warning");
                                        navigate("/accounts");
                                    }
                                }
                            });
                    },
                    cancel: () => {
                        hideLoader();
                    }
                })
            });
        } catch (error) {
            hideLoader();
            showSnackbar('Oops Something want wrong..!', "error");
        }
    }

    async function handleBulkDelete() {
        const checkedIds = getCheckedIds();
        if (checkedIds && checkedIds.length > 0) {
            bulkDeleteData(checkedIds)
        } else {
            showSnackbar('Please select at least one record to Delete.', 'warning')
        }
    }

    const [createNewAccountDialogOpen, setCreateNewAccountDialogOpen] = React.useState(false);
    const openCreateNewAccountDialog = () => {
        setCreateNewAccountDialogOpen(true);
    }
    const closeCreateNewAccountDialog = () => {
        setCreateNewAccountDialogOpen(false);
    }

    const [accountPageContextMenuAnchorEl, setAccountPageContextMenuAnchorEl] = React.useState(null);
    const accountPageContextMenuOpen = Boolean(accountPageContextMenuAnchorEl);
    const handleAccountPageContextMenuClick = (event) => {
        setAccountPageContextMenuAnchorEl(event.currentTarget);
    };
    const handleAccountPageContextMenuClose = () => {
        setAccountPageContextMenuAnchorEl(null);
    };

    const [sortByAnchorEl, setSortByAnchorEl] = React.useState(null);
    const sortByOpen = Boolean(sortByAnchorEl);
    const handleSortByClick = (event) => {
        setSortByAnchorEl(event.currentTarget);
    };
    const handleSortByClose = () => {
        setSortByAnchorEl(null);
    };

    const clearUserFilter = (type) => {
        setClearType(clearType ? false : true)
        if (type === 'search') {
            setSearch('');
        } else if (type === 'user') {
            setSearchUser('All Users')
        } else {
            setSearchUser("All Users");
            setSearch('')
        }
    }

    return (
        <>
            <Helmet>
                <title>Accounts | {capitalize(props?.name || 'SolvCRM')}</title>
            </Helmet>

            <Grid container className='page-action-bar'>
                <Grid item xl={'auto'} xs={12} sx={{ marginLeft: { xl: 'auto' }, order: { xl: 1 }, marginBottom: { xl: 0, xs: '12px' } }}>
                    <Stack direction="row" spacing={1}>
                        {userPermissions && userPermissions.includes("account_create") &&
                            <Button size="small" onClick={openCreateNewAccountDialog}>
                                <span>
                                    <Icon path={mdiPlus} color="currentColor" size={1} />
                                    <span>Create New</span>
                                </span>
                            </Button>
                        }
                        <Button variant="contained" color="secondary" size='small' onClick={handleSortByClick}>
                            <span>
                                <Icon path={mdiSort} color="currentColor" size={1} />
                                <span>Sort by</span>
                            </span>
                        </Button>
                        <Popover
                            open={sortByOpen}
                            anchorEl={sortByAnchorEl}
                            onClose={handleSortByClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                            classes={{
                                paper: 'utility-menu'
                            }}
                        >
                            <Typography variant='label-small-semibold' className='small-title'>Sort by</Typography>
                            <RadioGroup
                                value={handleSortBy}
                                name="sort-by-radio"
                                onChange={handleSortChange}
                            >
                                <FormControlLabel value="name" control={<Radio />} label="Name" />
                                <FormControlLabel value="title" control={<Radio />} label="Title" />
                                <FormControlLabel value="organization_phone1" control={<Radio />} label="Phone" />
                            </RadioGroup>

                            <Typography variant='label-small-semibold' className='small-title'>Sort Order</Typography>
                            <RadioGroup
                                value={handleOrderBy}
                                name="sort-order-radio"
                                onChange={handleSortChange}
                            >
                                <FormControlLabel value="asc" control={<Radio />} label="Ascending" />
                                <FormControlLabel value="desc" control={<Radio />} label="Descending" />
                            </RadioGroup>

                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <Button size='small' className='btn-block' onClick={handleSortByApply}>Apply</Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button size='small' color="secondary" className='btn-block' onClick={handleSortByReset}>Reset</Button>
                                </Grid>
                            </Grid>
                        </Popover>

                        <Tooltip title="More">
                            <Button variant="contained" color="secondary" size='small' className='icon-only' onClick={handleAccountPageContextMenuClick}>
                                <Icon path={mdiDotsVertical} color="currentColor" size={1} />
                            </Button>
                        </Tooltip>
                        <Menu
                            anchorEl={accountPageContextMenuAnchorEl}
                            open={accountPageContextMenuOpen}
                            onClose={handleAccountPageContextMenuClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right'
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                        >
                            <MenuItem onClick={handleAccountPageContextMenuClose}>
                                <Icon path={mdiExportVariant} color={'currentColor'} size={1} />
                                <ExportData params={expParams} fileName="Accounts" api="account" handleReloadFunc={handleReloadFunc} />
                            </MenuItem>
                            {userPermissions && userPermissions.includes("account_delete") &&
                                <MenuItem onClick={e => {
                                    handleBulkDelete(); handleAccountPageContextMenuClose();
                                }}>
                                    <Icon path={mdiTrashCanOutline} color={'currentColor'} size={1} />
                                    <span>Delete</span>
                                </MenuItem>
                            }
                        </Menu>
                    </Stack>
                </Grid>
                <Grid item xl={'auto'} xs={12}>
                    <Stack direction="row" spacing={1} alignItems={'center'}>
                        <Checkbox className='grid-checkbox' checked={isCheckAll} onChange={handleCheckAll} />
                        <TextField
                            variant="filled"
                            placeholder="Search accounts"
                            className='size-small page-search no-margin'
                            onChange={(e) => setSearch(e.target.value)}
                            onKeyDown={handleKeyDown}
                            value={search}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Icon path={mdiMagnify} color="currentColor" size={1} />
                                        {
                                            search &&
                                            <IconButton onClick={() => clearUserFilter('search')} >
                                                <Icon path={mdiClose} color={'currentColor'} size={'20px'} />
                                            </IconButton>
                                        }
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            select
                            //defaultValue="All Users"
                            value={searchUser}
                            variant="filled"
                            className='size-small no-label no-margin'
                            onChange={HandleSearchUser}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end' className='clear-filter'>
                                        {
                                            searchUser && searchUser !== 'All Users' &&
                                            <IconButton onClick={() => clearUserFilter('user')}>
                                                <Icon path={mdiClose} color={'currentColor'} size={'20px'} />
                                            </IconButton>
                                        }
                                    </InputAdornment>
                                ),
                            }}
                        >
                            <MenuItem key={12224} value="All Users">All Users</MenuItem>
                            {userLists &&
                                userLists.length > 0 &&
                                userLists.map((user, index) => (
                                    <MenuItem key={index} value={user.id}>
                                        {user.name}
                                    </MenuItem>
                                ))}
                        </TextField>
                        {
                            (search || (searchUser && searchUser !== 'All Users')) &&
                            <a href="-" className="primary-link ml-12" onClick={e => { e.preventDefault(); clearUserFilter('all') }}><Typography variant='body-medium-medium'>Clear All</Typography></a>
                        }

                    </Stack>
                </Grid>
            </Grid>

            {accounts && accounts.length > 0 && (
                <ul className="primary-list">
                    {
                        accounts.map((account, index) => (
                            <li key={index + ''}>
                                <div className="card">
                                    <Link to={userPermissions?.includes("account_edit") ? '/account-detail/' + account?.id : '#'}>
                                        <Grid container alignItems={'flex-start'}>
                                            <Grid item xs={'auto'}>
                                                <Checkbox className='grid-checkbox' onClick={e => { e.stopPropagation(); }}
                                                    name={String(account?.id)}
                                                    checked={checkedItems[account?.id] || false}
                                                    onChange={handleCheckboxChange}
                                                />
                                            </Grid>
                                            <Grid item xs>
                                                <Typography variant='title-medium-bold' component={'h6'}>{capitalize(account?.organization_name || 'N/A')}</Typography>
                                                <ul className='inline-details'>
                                                    <li>
                                                        <Tooltip title="Email">
                                                            <span>
                                                                <Icon path={mdiEmailOutline} color="currentColor" size={1} />{account?.organization_email || 'N/A'}
                                                            </span>
                                                        </Tooltip>
                                                    </li>
                                                    <li>
                                                        <Tooltip title="Created Date">
                                                            <span>
                                                                <Icon path={mdiCalendarWeekBeginOutline} color="currentColor" size={1} />
                                                                {account?.created_at
                                                                    ? moment.utc(account.created_at)
                                                                        .tz(preTimeZoneKey)
                                                                        .format(preferredDateFormat)
                                                                    : "N/A"}
                                                            </span>
                                                        </Tooltip>
                                                    </li>
                                                    <li>
                                                        <Tooltip title="Phone">
                                                            <span>
                                                                <Icon path={mdiPhoneOutline} color="currentColor" size={1} /> {account?.organization_phone1 || 'N/A'}
                                                            </span>
                                                        </Tooltip>
                                                    </li>
                                                </ul>
                                            </Grid>
                                        </Grid>
                                        <ul className='inline-details other-info no-avatar'>
                                            <li>
                                                <Tooltip title="Owner">
                                                    <span>
                                                        <Icon path={mdiAccountOutline} color="currentColor" size={1} /> {account?.added_by?.name || 'N/A'}
                                                    </span>
                                                </Tooltip>
                                            </li>
                                            <li>
                                                <Tooltip title="Last Updated">
                                                    <span>
                                                        <Icon path={mdiHistory} color="currentColor" size={1} />
                                                        {account?.updated_at
                                                            ? moment.utc(account.updated_at)
                                                                .tz(preTimeZoneKey)
                                                                .format(preferredDateFormat)
                                                            : "N/A"}
                                                    </span>
                                                </Tooltip>
                                            </li>
                                        </ul>
                                    </Link>
                                    <Stack direction={'row'} className='actions'>
                                        {userPermissions && userPermissions.includes("account_edit") &&
                                            <Tooltip title="Edit">
                                                <span>
                                                    <Link to={'/account-detail/' + account?.id} className='icon-button'>
                                                        <Icon path={mdiPencilOutline} color="currentColor" size={1} />
                                                    </Link>
                                                </span>
                                            </Tooltip>
                                        }
                                        {userPermissions && userPermissions.includes("account_delete") &&
                                            <Tooltip title="Delete">
                                                <button type="button" className='icon-button' onClick={() => { deleteData(account?.id) }}>
                                                    <Icon path={mdiTrashCanOutline} color={'currentColor'} size={1} />
                                                </button>
                                            </Tooltip>
                                        }
                                    </Stack>
                                </div>
                            </li>
                        ))
                    }
                </ul>
            )}


            {(accounts?.length == 0) && !loading &&
                <EmptyState type={'account'} hideActionBtn />
            }

            {
                accounts && accounts.length > 0 &&
                <TablePagination
                    component="div"
                    count={totalCount}
                    page={currentPage}
                    rowsPerPage={perPage}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                />
            }

            {
                createNewAccountDialogOpen && (
                    <Suspense fallback={<div>Loading...</div>}>
                        <CreateNewAccountDialog
                            open={createNewAccountDialogOpen}
                            onClose={closeCreateNewAccountDialog}
                            handleReloadFunc={handleReloadFunc}
                        />
                    </Suspense>
                )}
        </>
    );
}

export default Accounts;