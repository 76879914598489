import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import SettingsTab from "../components/SettingsTab";
import { Grid } from "@mui/material";
import LeadStages from "../components/LeadStages";
import DealStages from "../components/DealStages";
import { useLoader } from "../../../components/context/LoaderContext";
import useAxios from "../../../hooks/useAxios";
import { useSnackbar } from "../../../components/context/SnackbarContext";
import { capitalize } from "../../../utils/Utils";

function Stages(props) {

    const [leadCurrentPage, setLeadCurrentPage] = useState(0);
    const [leadPerPage, setLeadPerPage] = useState(
        parseInt(process.env.REACT_APP_DEFAULT_PAGINATION)
    );
    const [dealCurrentPage, setDealCurrentPage] = useState(0);
    const [dealPerPage, setDealPerPage] = useState(
        parseInt(process.env.REACT_APP_DEFAULT_PAGINATION)
    );
    const { showLoader, hideLoader } = useLoader();
    const [reloadPage, setReloadPage] = useState(false);

    const { showSnackbar } = useSnackbar();
    const axiosInstance = useAxios();
    const [leadtotalCount, setLeadtotalCount] = useState(0);
    const [dealtotalCount, setDealtotalCount] = useState(0);
    const [leadStages, setLeadStages] = useState();
    const [dealStages, setDealStages] = useState();

    const getData = async (currentPage, perPage, type) => {
        const params = {
            per_page: perPage,
        };

        try {
            const response = await axiosInstance.get(`api/stage?page=${currentPage + 1}&type=${type}`, { params });
            if (response.status === 200) {
                if (type === 'lead') {
                    setLeadStages(response?.data?.data?.records?.data);
                    setLeadtotalCount(response?.data?.data?.records?.total);
                } else if (type === 'deal') {
                    setDealStages(response?.data?.data?.recordsDeal?.data);
                    setDealtotalCount(response?.data?.data?.recordsDeal?.total);
                }
                hideLoader();
            }
        } catch (error) {
            if (error.response) {
                hideLoader();
                if (error.response.status === 400 || error.response.status === 300) {
                    showSnackbar(error.response.data.message, "warning");
                }
            }
        }
    };
    const handleReloadFunc = () => {
        hideLoader();
        setReloadPage(reloadPage ? false : true);
    }

    const handleLeadPageChange = (event, newPage) => {
        setLeadCurrentPage(newPage);
    };

    const handleDealPageChange = (event, newPage) => {
        setDealCurrentPage(newPage);
    };

    const handleLeadRowsPerPageChange = (event) => {
        setLeadPerPage(parseInt(event.target.value, 10));
        setLeadCurrentPage(0);
    };

    const handleDealRowsPerPageChange = (event) => {
        setDealPerPage(parseInt(event.target.value, 10));
        setDealCurrentPage(0);
    };

    useEffect(() => {
        async function fetchData() {
            showLoader();
            await getData(leadCurrentPage, leadPerPage, 'lead');
        }
        fetchData();
    }, [leadCurrentPage, leadPerPage, reloadPage]);

    useEffect(() => {
        async function fetchData() {
            showLoader();
            await getData(dealCurrentPage, dealPerPage, 'deal');
        }
        fetchData();
    }, [dealCurrentPage, dealPerPage, reloadPage]);


    return (
        <>
            <Helmet>
                <title>Stages | {capitalize(props?.name || 'SolvCRM')}</title>
            </Helmet>

            <SettingsTab />

            <Grid container spacing={2}>
                <Grid item xl={6} xs={12}>
                    <div className="card p-16 height-100p">
                        <LeadStages leadData={leadStages} handleReloadFunc={handleReloadFunc} perPage={leadPerPage} currentPage={leadCurrentPage} totalCount={leadtotalCount} handlePageChange={handleLeadPageChange} handleRowsPerPageChange={handleLeadRowsPerPageChange} />
                    </div>
                </Grid>

                <Grid item xl={6} xs={12}>
                    <div className="card p-16 height-100p">
                        <DealStages DealData={dealStages} handleReloadFunc={handleReloadFunc} perPage={dealPerPage} currentPage={dealCurrentPage} totalCount={dealtotalCount} handlePageChange={handleDealPageChange} handleRowsPerPageChange={handleDealRowsPerPageChange} />
                    </div>
                </Grid>
            </Grid>
        </>
    );
}

export default Stages;