import React, { Suspense, useEffect, useState } from "react";
import { Button, Grid, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import Icon from "@mdi/react";
import { mdiAttachment, mdiDotsVertical, mdiPencilOutline, mdiPlus, mdiTrashCanOutline } from "@mdi/js";
import AddNoteDialog from "./AddNoteDialog";
import AttachmentViewerDialog from "./AttachmentViewerDialog";
import { useSnackbar } from "../../components/context/SnackbarContext";
import useAxios from "../../hooks/useAxios";
import { useLoader } from "../../components/context/LoaderContext";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { DeleteDialog, DeleteDialogConfig } from "../../utils/Utils";
import EmptyState from "./EmptyState";


const NoteList = (props) => {
    const { type, dataId } = props
    const axiosInstance = useAxios();
    const { showSnackbar } = useSnackbar();
    const { showLoader, hideLoader, loading } = useLoader();
    const { preferredDateFormat, preTimeZoneKey } = useSelector((state) => state?.user);
    const [reloadPage, setReloadPage] = useState(false);
    const [editId, setEditId] = useState();

    const [notes, setNotes] = useState([]);
    const [attachments, setAttachments] = useState([]);

    useEffect(() => {
        async function fetchData() {
            await getNotes();
        }
        fetchData();
    }, [reloadPage]);

    async function getNotes() {
        await axiosInstance.get('api/' + type + '-note?' + type + 'Id=' + dataId).then(response => {
            if (response.data.code === 200) {
                setNotes(response.data.data.records);
            }
        }).then(json => {

        }).catch(error => {
            if (error.response) {
                if (error.response.status === 400) {
                    showSnackbar(error.response.data.message, "warning");
                }
            }
        });
    }

    const handleReloadFunc = () => {
        setReloadPage(reloadPage ? false : true);
    }


    const [addNoteDialogOpen, setAddNoteDialogOpen] = React.useState(false);
    const openAddNoteDialog = () => {
        setAddNoteDialogOpen(true);
    }
    const closeAddNoteDialog = () => {
        setEditId('')
        setAddNoteDialogOpen(false);
    }

    //=======Start::Write a code for delete lead=======//
    async function deleteData(id) {
        const data = {
            id: id,
            leadId: dataId,
            dealId: dataId,
        }

        try {
            DeleteDialog.fire({
                ...DeleteDialogConfig({
                    confirm: async () => {
                        showLoader()
                        await axiosInstance.delete(`api/${type}-note/destroy`, { data }).then((response) => {
                            if (response.status === 200) {
                                showSnackbar(response.data.message, "success");
                                hideLoader();
                                setReloadPage(reloadPage ? false : true);
                            }
                        })
                            .then((json) => { })
                            .catch((error) => {
                                if (error.response) {
                                    hideLoader();
                                    if (error.response.status === 400) {
                                        showSnackbar(error.response.data.message, "warning");
                                    }
                                }
                            });
                    },
                    cancel: () => {
                        hideLoader();
                    }
                })
            });
        } catch (error) {
            hideLoader();
            showSnackbar('Oops Something want wrong..!', "error");
        }
    }
    //=======Start::Write a code for delete lead=======//

    const [attachmentViewerDialogOpen, setAttachmentViewerDialogOpen] = React.useState(false);
    const openAttachmentViewerDialog = (attachment) => {
        setAttachments(attachment)
        setAttachmentViewerDialogOpen(true);
    }
    const closeAttachmentViewerDialog = () => {
        setAttachments([])
        setAttachmentViewerDialogOpen(false);
    }

    //=======Start::Write code for handle small pop menu for edit and delete button=======//
    const [notesContextMenuAnchorEl, setNotesContextMenuAnchorEl] = React.useState(null);
    const notesContextMenuOpen = Boolean(notesContextMenuAnchorEl);
    const handleNotesContextMenuClick = (event, id) => {
        setNotesContextMenuAnchorEl(event.currentTarget);
        setEditId(id);
    };
    const handleNotesContextMenuClose = () => {
        setNotesContextMenuAnchorEl(null);
    };
    //=======End::Write code for handle small pop menu for edit and delete button=======//

    //=====Start::Write a code for handle delete attachments=======//
    const HandleDeleteAttachment = async (id, parentId) => {
        const data = {
            id: id,
            leadId: dataId,
            dealId: dataId,
            note_id: parentId,
        }

        try {
            DeleteDialog.fire({
                ...DeleteDialogConfig({
                    confirm: async () => {
                        showLoader()
                        await axiosInstance.delete(`api/${type}-note-attachment/destroy`, { data }).then((response) => {
                            if (response.status === 200) {
                                showSnackbar(response.data.message, "success");
                                hideLoader();
                                setReloadPage(reloadPage ? false : true);
                                closeAttachmentViewerDialog()
                            }
                        })
                            .then((json) => { })
                            .catch((error) => {
                                if (error.response) {
                                    hideLoader();
                                    if (error.response.status === 400) {
                                        showSnackbar(error.response.data.message, "warning");
                                    }
                                }
                            });
                    },
                    cancel: () => {
                        hideLoader();
                    }
                })
            });
        } catch (error) {
            showSnackbar('Oops Something want wrong..!', "error");
        }
    }
    //=====End::Write a code for handle delete attachments=======//

    const checkPermission = ["note_edit", "note_delete"].some(permission => props?.userPermissions?.includes(permission))

    return (
        <>
            <div className="card p-16">

                <div className="card-header">
                    <h6 className="title">Notes</h6>
                    {props?.userPermissions && props?.userPermissions.includes("note_create") &&
                        <Button color="secondary" size="small" className="action" onClick={() => openAddNoteDialog()}>
                            <span>
                                <Icon path={mdiPlus} color={'currentColor'} size={1} />
                                <span>Add</span>
                            </span>
                        </Button>
                    }
                </div>

                {notes && notes?.length > 0 &&
                    <Grid container spacing={1.5}>
                        {(notes.map((note, index) =>
                            <Grid item xxl={4} lg={6} xs={12} key={index + ''}>
                                <div className={"note " + (note?.attachment?.length > 0 ? 'has-attachment' : '')}>
                                    <Typography variant="title-medium-bold" component={'h6'}>{note?.notes}</Typography>
                                    <Typography variant="body-medium">{note && note.created_at ? moment.utc(note.created_at).tz(preTimeZoneKey).format(preferredDateFormat) : 'N/A'}</Typography>

                                    <Typography variant="body-medium" component={'p'}>
                                        {note?.description}
                                    </Typography>

                                    {note?.attachment?.length > 0 && <Tooltip title="View Attachments">
                                        <button type="button" className="attachment-btn" onClick={() => openAttachmentViewerDialog(note?.attachment)}>
                                            {note?.attachment?.length}
                                            <Icon path={mdiAttachment} color={'currentColor'} size={1} />
                                        </button>
                                    </Tooltip>}
                                    {
                                        checkPermission && <Tooltip title="More">
                                            <button type="button" className="icon-button action" onClick={e => { handleNotesContextMenuClick(e, note?.id) }}>
                                                <Icon path={mdiDotsVertical} color={'currentColor'} size={1} />
                                            </button>
                                        </Tooltip>
                                    }

                                </div>
                            </Grid>
                        ))
                        }
                    </Grid>
                }

                {(notes?.length === 0 && !loading) &&
                    <EmptyState type={'note'} hideActionBtn size="small" />
                }

                <Menu
                    anchorEl={notesContextMenuAnchorEl}
                    open={notesContextMenuOpen}
                    onClose={handleNotesContextMenuClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                >
                    {props?.userPermissions && props?.userPermissions.includes("note_edit") &&
                        <MenuItem onClick={() => { handleNotesContextMenuClose(); openAddNoteDialog(editId) }}>
                            <Icon path={mdiPencilOutline} color={'currentColor'} size={1} />
                            <span>Edit</span>
                        </MenuItem>
                    }
                    {props?.userPermissions && props?.userPermissions.includes("note_delete") &&
                        <MenuItem onClick={e => {
                            handleNotesContextMenuClose();
                            deleteData(editId);
                        }}>
                            <Icon path={mdiTrashCanOutline} color={'currentColor'} size={1} />
                            <span>Delete</span>
                        </MenuItem>
                    }
                </Menu>
            </div>


            {
                addNoteDialogOpen && (
                    <Suspense fallback={<div>Loading...</div>}>
                        <AddNoteDialog open={addNoteDialogOpen} onClose={closeAddNoteDialog} type={type} dataId={dataId} editId={editId} handleReloadFunc={handleReloadFunc} />
                    </Suspense>
                )
            }

            {
                attachmentViewerDialogOpen && (
                    <Suspense fallback={<div>Loading...</div>}>
                        <AttachmentViewerDialog open={attachmentViewerDialogOpen} onClose={closeAttachmentViewerDialog} attachments={attachments} attachmentType={'/notes/'} HandleDeleteAttachment={HandleDeleteAttachment} />
                    </Suspense>
                )
            }

        </>
    )
}

export default NoteList;