import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { Button, Dialog, Grid, MenuItem, Select, TextField, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSnackbar } from "../../../components/context/SnackbarContext";
import useAxios from "../../../hooks/useAxios";
import { useLoader } from "../../../components/context/LoaderContext";
import { Formik } from "formik";
import * as yup from "yup";

const options = [
    { label: 'Please Select', value: '' },
    { label: 'Qualified', value: 'qualified' },
    { label: 'Not Qualified', value: 'notqualified' }
]

function CreateEditStageDialog({ onClose, open, stageID, handleReloadFunc, stages }) {

    const axiosInstance = useAxios();
    const { showSnackbar } = useSnackbar();

    const validationSchema = yup.object().shape({
        name: yup
            .string()
            .required("Enter stage name"),
        hierarchy: yup
            .number()
            .required("Enter a valid position")
            .typeError("Position must be a number")
    });

    const handleSubmit = async (values, onSubmitProps) => {
        const data = {
            name: values?.name,
            hierarchy: values?.hierarchy,
            type: values?.type,
            is_used: values?.is_used,
        };

        const isUpdate = Boolean(stageID);

        try {
            let response;

            if (isUpdate) {
                response = await axiosInstance.post("api/stage/update", {
                    id: stageID,
                    ...data
                });
            } else {
                response = await axiosInstance.post("api/stage/store", data);
            }

            if (response.status === 200) {
                showSnackbar(response.data.message, 'success');
                onClose();
                handleReloadFunc();
                handleReloadFunc();
            }

        } catch (error) {
            if (error.response) {
                onSubmitProps.setSubmitting(false);
                if (error.response.status === 422 || error.response.data.validation_errors) {
                    const errors = error.response.data.validation_errors;
                    Object.keys(errors).forEach(key => {
                        onSubmitProps.setFieldError(key, errors[key][0]);
                    });
                } else {
                    showSnackbar(error.response.data.message, 'error');
                }
            } else {
                showSnackbar('An unexpected error occurred.', 'error');
            }
        }
    };

    return (
        <>
            <Dialog onClose={onClose} open={open} maxWidth={'md'}>
                <div className="dialog-title">
                    <h2>Create Lead Stage</h2>
                    <Tooltip title="Close">
                        <button className="icon-button dialog-close" onClick={onClose}>
                            <Icon path={mdiClose} color={'currentColor'} size={1} />
                        </button>
                    </Tooltip>
                </div>
                <Formik
                    initialValues={{
                        name: stages?.name || '',
                        type: stages?.type || '',
                        hierarchy: stages?.hierarchy || '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize
                >
                    {props => {
                        const {
                            touched,
                            errors,
                            values,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue
                        } = props;
                        return (
                            <form onSubmit={handleSubmit}>
                                <TextField
                                    label="Name"
                                    variant="filled"
                                    name="name"
                                    value={values?.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.name && touched.name}
                                    helperText={(errors.name && touched.name) && errors.name}
                                />
                                <TextField
                                    label="Position"
                                    variant="filled"
                                    name="hierarchy"
                                    value={values?.hierarchy}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.hierarchy && touched.hierarchy}
                                    helperText={(errors.hierarchy && touched.hierarchy) && errors.hierarchy}
                                />

                                <TextField
                                    select
                                    variant="filled"
                                    label="Type"
                                    name="type"
                                    value={values?.type || 'Select Type'}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                >
                                    {options.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>

                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Button className="btn-block" type="submit">Create</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button color="secondary" className="btn-block" onClick={onClose}>Cancel</Button>
                                    </Grid>
                                </Grid>
                            </form>
                        );
                    }}
                </Formik>
            </Dialog>
        </>
    );
}

export default CreateEditStageDialog;