import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { Button, Dialog, Grid, MenuItem, TextField, Tooltip } from "@mui/material";
import React from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { useSnackbar } from '../../../components/context/SnackbarContext';
import useAxios from '../../../hooks/useAxios';
import { useLoader } from "../../../components/context/LoaderContext";

function CreateNewUserDialog({ onClose, open, roles, handleReloadFunc }) {
    const axiosInstance = useAxios();
    const { showSnackbar } = useSnackbar();
    const { showLoader, hideLoader } = useLoader();

    const validationSchema = yup.object().shape({
        email: yup.string().email('Please enter valid email address').required('Enter email').min(2, 'Must be at least 2 characters').max(50, 'Must be at most 50 characters'),
        role: yup.string().required("Please select role"),
    })

    const handleSubmit = async (values, onSubmitProps) => {
        const data = {
            email: values.email,
            role_id: values.role,
            type: "send",
        };
        showLoader()
        await axiosInstance.post("/api/user/invitation/store", data).then(response => {
            if (response.status === 200) {
                hideLoader()
                showSnackbar(response.data.message, 'success')
                onClose()
                handleReloadFunc()
            }
        }).then(json => {

        }).catch(error => {
            if (error.response) {
                hideLoader()
                onSubmitProps.setSubmitting(false)
                if (error.response.status === 422 || error.response.data.validation_errors) {
                    const errors = error.response.data.validation_errors;
                    Object.keys(errors).forEach(key => {
                        onSubmitProps.setFieldError(key, errors[key][0]);
                    });
                } else {
                    showSnackbar(error.response.data.message, 'error')
                }
            }
        });
    }

    return (
        <>
            <Dialog onClose={onClose} open={open} maxWidth={'md'}>
                <div className="dialog-title">
                    <h2>Create New User</h2>
                    <Tooltip title="Close">
                        <button className="icon-button dialog-close" onClick={onClose}>
                            <Icon path={mdiClose} color={'currentColor'} size={1} />
                        </button>
                    </Tooltip>
                </div>
                <Formik
                    initialValues={{
                        email: '',
                        role: '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize
                >
                    {props => {
                        const {
                            touched,
                            errors,
                            values,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        } = props;
                        return (
                            <form onSubmit={handleSubmit}>
                                <TextField
                                    label="Email"
                                    variant="filled"
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.email && touched.email}
                                    helperText={(errors.email && touched.email) && errors.email}
                                />

                                <TextField
                                    select
                                    label="Role"
                                    value={values.role}
                                    name="role"
                                    variant="filled"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.role && touched.role}
                                    helperText={(errors.role && touched.role) && errors.role}
                                >
                                    {
                                        roles && roles.length > 0 ?
                                            roles.map((role, index) => (
                                                <MenuItem key={index} value={role.id}>
                                                    {role.name}
                                                </MenuItem>
                                            )) :
                                            <MenuItem value={-1}></MenuItem>
                                    }
                                </TextField>

                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Button type="submit" className="btn-block">Send Invitation</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button color="secondary" className="btn-block" onClick={onClose}>Cancel</Button>
                                    </Grid>
                                </Grid>
                            </form>
                        );
                    }}
                </Formik>
            </Dialog>
        </>
    );
}

export default CreateNewUserDialog;