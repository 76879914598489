import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Grid, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { ForgotPasswordIllustration } from "../../assets/images/index";
import { useSnackbar } from '../../components/context/SnackbarContext';
import useAxios from '../../hooks/useAxios';
import { useLoader } from "../../components/context/LoaderContext";
import { capitalize } from "../../utils/Utils";

function VerifyEmail(props) {
    const navigate = useNavigate();
    const axiosInstance = useAxios();
    const { showSnackbar } = useSnackbar();
    const { showLoader, hideLoader } = useLoader();

    const pathname = window.location.pathname;
    const getArray = pathname.split('/');
    const id = getArray[2];
    const token = getArray[3];
    const search = useLocation().search;
    const expires = new URLSearchParams(search).get('expires');
    const signature = new URLSearchParams(search).get('signature');

    useEffect(() => {
        async function fetchData() {
            verifyEmailData()
        }
        fetchData();
    }, [id]);


    async function verifyEmailData() {
        const data = {
            expires: expires,
            signature: signature,
        };

        showLoader('Please wait, we are creating your panel. This will only take a moment.');

        try {
            const response = await axiosInstance.get(`/api/verify-email/${id}/${token}`, data);
            if (response.status === 200) {
                hideLoader();
                showSnackbar(response.data.message, 'success');
                navigate('/sign-in');
            }

        } catch (error) {
            hideLoader();
            if (error.response) {
                if (error.response.status === 400) {
                    showSnackbar(error.response.data.message, 'warning');
                } else if (error.response.status === 403) {
                    navigate('/sign-in');
                } else {
                    showSnackbar('Unexpected error occurred', 'error');
                    navigate('/sign-in');
                }
            } else {
                showSnackbar('Email has been successfully verified. Please login to continue', 'success');
                navigate('/sign-in');
            }
        }
    }


    return (
        <>
            <Helmet>
                <title>Verify Email | {capitalize(props?.name || 'SolvCRM')}</title>
            </Helmet>

            <Grid container spacing={0}>
                <Grid item xs={12} xl={6} sx={{ display: { xl: 'block', xs: 'none' } }}>
                    <div className="login-banner"></div>
                </Grid>
                <Grid item xs={12} xl={6} className="login-form-outer">
                    <div className="login-form-wrapper">
                        <div className="reset-password-info">
                            <img src={ForgotPasswordIllustration} alt="Illustration" />
                            <Typography variant="headline-medium-bold" className="login-title" component={'h2'}>Verifying your email please wait.</Typography>
                        </div>

                    </div>
                </Grid>
            </Grid>
        </>
    );
}

export default VerifyEmail;