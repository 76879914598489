import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Button, Grid, Tooltip, Typography } from "@mui/material";
import Icon from "@mdi/react";
import { mdiLockOutline, mdiPencilOutline } from "@mdi/js";
import EasyEdit, { Types } from "react-easy-edit";
import EasyEditCustomTextbox from "../../components/common/EasyEditCustomTextbox";
import FormGroupStatic from "../../components/common/FormGroupStatic";
import { EasyEditCancelButton, EasyEditSaveButton } from "../../components/common/EasyEditButtons";
import EasyEditCustomSelect from "../../components/common/EasyEditCustomSelect";
import ChangePasswordDialog from "./components/ChangePasswordDialog";
import useAxios from "../../hooks/useAxios";
import { useLoader } from "../../components/context/LoaderContext";
import { useSnackbar } from "../../components/context/SnackbarContext";
import { useNavigate } from "react-router-dom";
import { validateField } from "../../utils/Utils";
import AvatarInitials from "../../components/common/AvatarInitials";

import { useDispatch, useSelector } from "react-redux";
import { login, loginUser } from "../../redux/slices/userSlice";

function MyAccount() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const loginUserDetail = useSelector(loginUser);
    const user = loginUserDetail?.user || [];

    const [profileData, setProfileData] = useState();
    const axiosInstance = useAxios();
    const { showLoader, hideLoader } = useLoader();
    const { showSnackbar } = useSnackbar();
    const [reloadPage, setReloadPage] = useState(false);
    const [errorMessages, setErrorMessages] = useState({});
    const [backendErrorMessages, setBackendErrorMessages] = useState({});
    const [countryArray, setCountryArray] = useState([]);


    useEffect(() => {
        async function fetchData() {
            getData();
        }
        fetchData();
    }, [reloadPage]);

    const getData = async () => {
        showLoader();
        await axiosInstance.get('api/profile').then((response) => {
            if (response.status === 200) {
                setProfileData(response?.data?.data?.record);
                setCountryArray(response?.data?.data?.countries)
                hideLoader();
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                        navigate("/profile");
                    }
                }
            });
    }

    const handleClick = (e) => {
        setAllowEditInline(false)
    }
    const handleReloadFunc = () => {
        hideLoader();
        setReloadPage(reloadPage ? false : true);
    }

    const handleSaveData = async (value, name, type = null) => {
        const data = {
            [name]: value,
        };
        showLoader();
        await axiosInstance.post("api/update-profile", data).then(response => {
            if (response.status === 200) {
                hideLoader();
                showSnackbar(response.data.message, "success");
                setAllowEditInline(true)
                handleReloadFunc();

                if (name === 'name') {
                    const updatedUser = { ...user, name: value };
                    dispatch(login(updatedUser));
                }
            }
        }).then(json => {

        }).catch(error => {
            if (error.response) {
                hideLoader();
                if (error.response.status === 422 || error.response.data.validation_errors) {
                    setAllowEditInline(true)
                    const errors = error.response.data.validation_errors;
                    Object.keys(errors).forEach(key => {
                        setBackendErrorMessages({ [key]: errors[key] });
                    });
                } else {
                    showSnackbar(error.response.data.message, 'error')
                }
            }
        });

    }

    const onCancelEdit = (fieldName) => {
        setErrorMessages({ [fieldName]: '' });
        setAllowEditInline(allowEditInline ? false : true)
    }

    const handleValidation = (value, fieldName, displayName, type, isrequired, min, max) => {
        const errorMsg = validateField(value, fieldName, displayName, type, isrequired, min, max);
        setErrorMessages(prevState => ({ ...prevState, [fieldName]: errorMsg }));
        return errorMsg === '';
    };


    const handleImageChange = async (e) => {
        e.persist();
        const formData = new FormData();
        formData.append('profile_image', e.target.files[0])

        showLoader()
        await axiosInstance.post("/api/update-profile", formData).then(response => {
            if (response.status === 200) {
                hideLoader();
                const updatedUser = { ...user, profile_image: response.data.data.userData.profile_image };
                dispatch(login(updatedUser));
                showSnackbar(response.data.message, "success");
                setAllowEditInline(true)
                handleReloadFunc();
            }
        }).then(json => {

        }).catch(error => {
            if (error.response) {
                hideLoader()
                if (error.response.status === 422 || error.response.data.validation_errors) {
                    setAllowEditInline(true)
                    const errors = error.response.data.validation_errors;
                    Object.keys(errors).forEach(key => {
                        showSnackbar(errors[key], 'error')
                    });
                } else {
                    showSnackbar(error.response.data.message, 'error')
                }
            }
        });
    };

    const [allowEditInline, setAllowEditInline] = React.useState(true);
    const [changePasswordDialogOpen, setChangePasswordDialogOpen] = React.useState(false);
    const openChangePasswordDialog = (message, actionText) => { setChangePasswordDialogOpen(true); }
    const closeChangePasswordDialog = () => { setChangePasswordDialogOpen(false); }

    const addDefaultProfileSrc = (e) => {
        e.target.src = `${process.env.REACT_APP_API_URL}assets/default-profile-image.png`;
    }

    return (
        <>
            <Helmet>
                <title>My Account | SolvCRM </title>
            </Helmet>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <div className="card p-16">
                        <Grid container alignItems={"center"} flexWrap={"nowrap"}>
                            <Grid item xs={'auto'} className="flex-shrink-1">
                                <Grid container alignItems={'center'} spacing={1.5}>
                                    <Grid item xs="auto">
                                        {user?.profile_image ? (
                                            <img
                                                onError={addDefaultProfileSrc}
                                                src={user?.profile_image}
                                                alt="Profile"
                                                title="Profile Image"
                                                className="user-avatar"
                                            />
                                        ) : (
                                            <AvatarInitials name={profileData?.name} />
                                        )}
                                    </Grid>
                                    <Grid item xs>
                                        <Typography variant='title-medium-bold' component={'h6'}>{user?.name || 'N/A'}</Typography>
                                        <ul className='inline-details'>
                                            <li><Tooltip title="Email"><span>{profileData?.email || 'N/A'}</span></Tooltip></li>
                                            <li>
                                                <Tooltip title="Role">
                                                    <span className="status-badge">{profileData?.selected_role || 'N/A'}</span>
                                                </Tooltip>
                                            </li>
                                        </ul>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={'auto'} sx={{ marginLeft: 'auto' }}>
                                <div className="psuedo-upload-block">
                                    <div className="content">
                                        <Button size={"small"} color="secondary" tabIndex={-1} >
                                            <span>
                                                <Icon path={mdiPencilOutline} color={'currentColor'} size={1} />
                                                <span>Change Photo</span>
                                            </span>
                                        </Button>
                                    </div>
                                    <input type="file" className="psuedo-upload-element" onChange={handleImageChange} />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <div className="card p-16">
                        <Typography variant="title-medium-large-semibold" component={'h4'} className="mb-16">Personal Information</Typography>

                        <Grid container spacing={1.5}>
                            <Grid item xl={3} lg={4} xs={6}>
                                <EasyEdit
                                    type={Types.TEXT}
                                    onSave={(value) => { handleSaveData(value, 'name') }}
                                    onCancel={() => { onCancelEdit('name') }}
                                    value={profileData?.name || 'N/A'}
                                    allowEdit={allowEditInline}
                                    editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Name" value={profileData?.name} />}
                                    displayComponent={<FormGroupStatic easyEdit label="Name" value={profileData?.name || 'N/A'} />}
                                    onValidate={(value) => handleValidation(value, 'name', 'Name')}
                                    validationMessage={errorMessages['name']}
                                    saveButtonLabel={<EasyEditSaveButton />}
                                    cancelButtonLabel={<EasyEditCancelButton />}
                                    onFocus={() => handleClick('')}
                                />
                                {backendErrorMessages['name'] && <span className="easy-edit-validation-error">{backendErrorMessages['name']}</span>}
                            </Grid>

                            <Grid item xl={3} lg={4} xs={6}>
                                <FormGroupStatic label="Email" element={
                                    <a href={"mailto:" + profileData?.email} className="primary-link">{profileData?.email}</a>
                                } />
                            </Grid>

                            <Grid item xl={3} lg={4} xs={6}>
                                <EasyEdit
                                    type={Types.TEXT}
                                    onSave={(value) => { handleSaveData(value, 'secondry_email') }}
                                    onCancel={() => { onCancelEdit('secondry_email') }}
                                    value={profileData?.secondry_email || 'N/A'}
                                    allowEdit={allowEditInline}
                                    editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Secondary email" value={profileData?.secondry_email} />}
                                    displayComponent={<FormGroupStatic easyEdit label="Secondary email" element={
                                        <a href={"mailto:" + profileData?.secondry_email} className="primary-link">{profileData?.secondry_email || 'N/A'}</a>
                                    } />}
                                    onValidate={(value) => handleValidation(value, 'secondry_email', 'Secondary email')}
                                    validationMessage={errorMessages['secondry_email']}
                                    saveButtonLabel={<EasyEditSaveButton />}
                                    cancelButtonLabel={<EasyEditCancelButton />}
                                    onFocus={() => handleClick('')}
                                />
                                {backendErrorMessages['secondry_email'] && <span className="easy-edit-validation-error">{backendErrorMessages['secondry_email']}</span>}
                            </Grid>
                        </Grid>

                        <hr className="hr my-16" />

                        <Typography variant="title-medium-large-semibold" component={'h4'} className="mb-16">Address Information</Typography>

                        <Grid container spacing={1.5}>
                            <Grid item lg={6} xs={12}>
                                <EasyEdit
                                    type={Types.TEXT}
                                    onSave={(value) => { handleSaveData(value, 'address1') }}
                                    onCancel={() => { onCancelEdit('address1') }}
                                    value={profileData?.address1 || 'N/A'}
                                    allowEdit={allowEditInline}
                                    editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Address 1" value={profileData?.address1} />}
                                    displayComponent={<FormGroupStatic easyEdit label="Address 1" value={profileData?.address1 || 'N/A'} />}
                                    onValidate={(value) => handleValidation(value, 'address1', 'Address 1')}
                                    validationMessage={errorMessages['address1']}
                                    saveButtonLabel={<EasyEditSaveButton />}
                                    cancelButtonLabel={<EasyEditCancelButton />}
                                    onFocus={() => handleClick('')}
                                />
                                {backendErrorMessages['address1'] && <span className="easy-edit-validation-error">{backendErrorMessages['address1']}</span>}
                            </Grid>

                            <Grid item lg={6} xs={12}>
                                <EasyEdit
                                    type={Types.TEXT}
                                    onSave={(value) => { handleSaveData(value, 'address2') }}
                                    onCancel={() => { onCancelEdit('address2') }}
                                    value={profileData?.address2 || 'N/A'}
                                    allowEdit={allowEditInline}
                                    editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Address 2" value={profileData?.address2} />}
                                    displayComponent={<FormGroupStatic easyEdit label="Address 2" value={profileData?.address2 || 'N/A'} />}
                                    onValidate={(value) => handleValidation(value, 'address2', 'Address 2')}
                                    validationMessage={errorMessages['address2']}
                                    saveButtonLabel={<EasyEditSaveButton />}
                                    cancelButtonLabel={<EasyEditCancelButton />}
                                    onFocus={() => handleClick('')}
                                />
                                {backendErrorMessages['address2'] && <span className="easy-edit-validation-error">{backendErrorMessages['address2']}</span>}
                            </Grid>
                            <Grid item xl={3} lg={4} xs={6}>
                                <EasyEdit
                                    type={Types.TEXT}
                                    onSave={(value) => { handleSaveData(value, 'phone1') }}
                                    onCancel={() => { onCancelEdit('phone1') }}
                                    value={profileData?.phone1 || 'N/A'}
                                    allowEdit={allowEditInline}
                                    editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Phone 1" value={profileData?.phone1} />}
                                    displayComponent={<FormGroupStatic easyEdit label="Phone 1" value={profileData?.phone1 || 'N/A'} />}
                                    onValidate={(value) => handleValidation(value, 'phone1', 'Phone 1')}
                                    validationMessage={errorMessages['phone1']}
                                    saveButtonLabel={<EasyEditSaveButton />}
                                    cancelButtonLabel={<EasyEditCancelButton />}
                                    onFocus={() => handleClick('')}
                                />
                                {backendErrorMessages['phone1'] && <span className="easy-edit-validation-error">{backendErrorMessages['phone1']}</span>}
                            </Grid>

                            <Grid item xl={3} lg={4} xs={6}>
                                <EasyEdit
                                    type={Types.TEXT}
                                    onSave={(value) => { handleSaveData(value, 'phone2') }}
                                    onCancel={() => { onCancelEdit('phone2') }}
                                    value={profileData?.phone2 || 'N/A'}
                                    allowEdit={allowEditInline}
                                    editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Phone 2" value={profileData?.phone2} />}
                                    displayComponent={<FormGroupStatic easyEdit label="Phone 2" value={profileData?.phone2 || 'N/A'} />}
                                    onValidate={(value) => handleValidation(value, 'phone2', 'Phone 2')}
                                    validationMessage={errorMessages['phone2']}
                                    saveButtonLabel={<EasyEditSaveButton />}
                                    cancelButtonLabel={<EasyEditCancelButton />}
                                    onFocus={() => handleClick('')}
                                />
                                {backendErrorMessages['phone2'] && <span className="easy-edit-validation-error">{backendErrorMessages['phone2']}</span>}
                            </Grid>

                            <Grid item xl={3} lg={4} xs={6}>
                                <EasyEdit
                                    type={Types.TEXT}
                                    onSave={(value) => { handleSaveData(value, 'city') }}
                                    onCancel={() => { onCancelEdit('city') }}
                                    value={profileData?.city || 'N/A'}
                                    allowEdit={allowEditInline}
                                    editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="City" value={profileData?.city} />}
                                    displayComponent={<FormGroupStatic easyEdit label="City" value={profileData?.city || 'N/A'} />}
                                    onValidate={(value) => handleValidation(value, 'city', 'City')}
                                    validationMessage={errorMessages['city']}
                                    saveButtonLabel={<EasyEditSaveButton />}
                                    cancelButtonLabel={<EasyEditCancelButton />}
                                    onFocus={() => handleClick('')}
                                />
                                {backendErrorMessages['city'] && <span className="easy-edit-validation-error">{backendErrorMessages['city']}</span>}
                            </Grid>

                            <Grid item xl={3} lg={4} xs={6}>
                                <EasyEdit
                                    type={Types.TEXT}
                                    onSave={(value) => { handleSaveData(value, 'state') }}
                                    onCancel={() => { onCancelEdit('state') }}
                                    value={profileData?.state || 'N/A'}
                                    allowEdit={allowEditInline}
                                    editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="State" value={profileData?.state} />}
                                    displayComponent={<FormGroupStatic easyEdit label="State" value={profileData?.state || 'N/A'} />}
                                    onValidate={(value) => handleValidation(value, 'state', 'State')}
                                    validationMessage={errorMessages['state']}
                                    saveButtonLabel={<EasyEditSaveButton />}
                                    cancelButtonLabel={<EasyEditCancelButton />}
                                    onFocus={() => handleClick('')}
                                />
                                {backendErrorMessages['state'] && <span className="easy-edit-validation-error">{backendErrorMessages['state']}</span>}
                            </Grid>

                            <Grid item xl={3} lg={4} xs={6}>
                                <EasyEdit
                                    type={Types.TEXT}
                                    onSave={(value) => { handleSaveData(value, 'zipcode') }}
                                    onCancel={() => { onCancelEdit('zipcode') }}
                                    value={profileData?.zipcode || 'N/A'}
                                    allowEdit={allowEditInline}
                                    editComponent={<EasyEditCustomTextbox focusCallback={e => { setAllowEditInline(false); }} label="Zipcode" value={profileData?.zipcode} />}
                                    displayComponent={<FormGroupStatic easyEdit label="Zipcode" value={profileData?.zipcode || 'N/A'} />}
                                    onValidate={(value) => handleValidation(value, 'zipcode', 'Zipcode')}
                                    validationMessage={errorMessages['zipcode']}
                                    saveButtonLabel={<EasyEditSaveButton />}
                                    cancelButtonLabel={<EasyEditCancelButton />}
                                    onFocus={() => handleClick('')}
                                />
                                {backendErrorMessages['zipcode'] && <span className="easy-edit-validation-error">{backendErrorMessages['zipcode']}</span>}
                            </Grid>

                            <Grid item xl={3} lg={4} xs={6}>
                                <EasyEdit
                                    type={Types.SELECT}
                                    onSave={(value) => { handleSaveData(value, 'country_id') }}
                                    onCancel={() => { onCancelEdit('country_id') }}
                                    value={profileData?.countryName || 'N/A'}
                                    allowEdit={allowEditInline}
                                    editComponent={
                                        <EasyEditCustomSelect focusCallback={e => { setAllowEditInline(false); }} label="Country" fieldValue={profileData?.countryName || ''}
                                            items={
                                                countryArray && countryArray.length > 0 && countryArray.map((country) => (
                                                    { value: country.id, option: country.country_name }
                                                ))
                                            }
                                        />
                                    }
                                    displayComponent={<FormGroupStatic easyEdit label="Country" value={profileData?.country_id || 'N/A'} />}
                                    saveButtonLabel={<EasyEditSaveButton />}
                                    cancelButtonLabel={<EasyEditCancelButton />}
                                    onFocus={() => handleClick('')}
                                />
                                {backendErrorMessages['country_id'] && <span className="easy-edit-validation-error">{backendErrorMessages['country_id']}</span>}
                            </Grid>
                        </Grid>

                        <hr className="hr my-16" />

                        <Button size="small" className="action" onClick={openChangePasswordDialog}>
                            <span>
                                <Icon path={mdiLockOutline} color={'currentColor'} size={1} />
                                <span>Change Password</span>
                            </span>
                        </Button>
                    </div>
                </Grid>
            </Grid>

            <ChangePasswordDialog open={changePasswordDialogOpen} onClose={closeChangePasswordDialog} />
        </>
    );
}

export default MyAccount;