import { useEffect } from 'react';
import axiosInstance from '../config/axiosConfig';
import { useSnackbar } from '../components/context/SnackbarContext';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { useLoader } from "../components/context/LoaderContext";
import { getMainDomain } from '../../src/utils/Utils';

const useAxios = () => {
    const { showSnackbar } = useSnackbar();
    const { hideLoader } = useLoader();
    const navigate = useNavigate();
    const cookies = new Cookies();

    useEffect(() => {
        const responseInterceptor = axiosInstance.interceptors.response.use(
            response => {
                hideLoader(); // Hide loader after a successful response
                return response;
            },
            error => {
                if (error.response) {
                    hideLoader()
                    switch (error.response.status) {
                        // case 300:
                        //     showSnackbar(error.response.data.message, 'error');
                        //     break;
                        // case 400:
                        //     showSnackbar('Bad Request - ' + error.response.data.message, 'error');
                        //     break;
                        case 401:
                            const hostname = getMainDomain()
                            cookies.remove("auth_token", { domain: hostname || '' });
                            cookies.remove("auth_name", { domain: hostname || '' });
                            cookies.remove("auth_tenant", { domain: hostname || '' });
                            cookies.remove("tenant_workspace", { domain: hostname || '' });
                            showSnackbar(error?.response?.data?.message || 'Oops Something went wrong..!', 'error');
                            navigate('/sign-in');  // Redirect to login
                            break;
                        case 403:
                            showSnackbar('Forbidden - ' + error?.response?.data?.message, 'error');
                            //navigate('/dashboard');
                            break;
                        case 404:
                            showSnackbar('Not Found - ' + error?.response?.data?.message, 'error');
                            break;
                        case 405:
                            showSnackbar('Method Not Allowed - ' + error?.response?.data?.message, 'error');
                            break;
                        case 500:
                            showSnackbar('Internal Server Error', 'error');
                            const token = cookies.get('auth_token');
                            if (!token || token === 'undefined') {
                                navigate('/sign-in');
                            } else {
                                navigate('/dashboard');
                            }
                            break;
                        default:
                            error.response.status !== 300 || error.response.status !== 400 &&
                                showSnackbar('An unexpected error occurred', 'error');
                            break;
                    }
                } else if (error?.message.includes('Network Error')) {
                    hideLoader()
                    showSnackbar('No response from the server. Please check your network.', 'error');
                } else if (error?.message.includes('ERR_CONNECTION_REFUSED')) {
                    hideLoader()
                    const hostname = getMainDomain()
                    cookies.remove("auth_token", { domain: hostname || '' });
                    cookies.remove("auth_name", { domain: hostname || '' });
                    cookies.remove("auth_tenant", { domain: hostname || '' });
                    cookies.remove("tenant_workspace", { domain: hostname || '' });
                    showSnackbar('Unauthorized - ' + error?.response?.data?.message, 'error');
                    navigate('/sign-in');  // Redirect to login
                } else {
                    showSnackbar('Error setting up the request', 'error');
                }
                return Promise.reject(error);
            }
        );

        return () => {
            axiosInstance.interceptors.response.eject(responseInterceptor);
        };
    }, [navigate, showSnackbar]);

    return axiosInstance;
};

export default useAxios;