import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { Button, Dialog, Grid, MenuItem, Popper, TextField, Tooltip } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import React, { useEffect, useState } from "react";
import { useSnackbar } from "../../components/context/SnackbarContext";
import useAxios from "../../hooks/useAxios";
import { useLoader } from "../../components/context/LoaderContext";
import { Formik } from "formik";
import * as yup from "yup";
import moment from "moment-timezone";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useSelector } from "react-redux";
import { PickerWrapper } from "../../utils/Utils";

function AddTaskDialog({ onClose, open, type, dataId, handleReloadFunc, editId, priority, status }) {
    const axiosInstance = useAxios();
    const { showSnackbar } = useSnackbar();
    const { showLoader, hideLoader, loading } = useLoader();
    const [taskList, setTaskList] = useState([])
    const { preferredDateFormat, preTimeZoneKey } = useSelector((state) => state?.user);

    //=======Start::Write a code for manage Edit Notes======//
    useEffect(() => {
        if (editId) {
            const fetchData = async () => {
                await getEvent();
            };
            fetchData();
        }
    }, [editId]);

    async function getEvent() {
        showLoader()
        try {
            const response = await axiosInstance.get(`api/${type}-task/edit/${editId}?${type}Id=${dataId}`);
            if (response.data.code === 200) {
                setTaskList(response.data.data.record);
            }
        } catch (error) {
            if (error.response) {
                showSnackbar(error.response.data.message, "warning");
            }
        } finally {
            hideLoader();
        }
    }
    //=======Start::Write a code for manage Edit Notes======//


    const validationSchema = yup.object().shape({
        name: yup
            .string()
            .required("Enter event subject")
            .min(2, "The name must be at least 2 characters.")
            .max(50, "The name must not be greater than 50 characters."),
        description: yup
            .string()
            .required("Enter event description")
            .min(2, "The description must be at least 2 characters.")
            .max(150, "The description must not be greater than 150 characters."),
        end_date: yup
            .string()
            .required("Enter Due date"),
    });


    const handleSubmit = async (values, onSubmitProps) => {
        const data = {
            id: taskList?.id,
            leadId: dataId,
            dealId: dataId,
            name: values?.name || '',
            description: values?.description || '',
            end_date: values.end_date
                ? moment.utc(values.end_date).tz(preTimeZoneKey).format('YYYY-MM-DD HH:mm:ss')
                : moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            priority: values.priority,
            activity_status: values.activity_status,
        };
        await axiosInstance.post(`/api/${type}-task/${editId ? 'update' : 'store'}`, data).then(response => {
            if (response.status === 200) {
                hideLoader()
                showSnackbar(response.data.message, 'success')
                onClose()
                handleReloadFunc()
            }
        }).then(json => {

        }).catch(error => {
            if (error.response) {
                hideLoader()
                if (error.response.status === 422 || error.response.data.validation_errors) {
                    const errors = error.response.data.validation_errors;
                    Object.keys(errors).forEach(key => {
                        onSubmitProps.setFieldError(key, errors[key][0]);
                    });

                } else {
                    showSnackbar(error.response.data.message, 'error')
                }
            }
        });
    }

    return (
        <>

            <Dialog onClose={onClose} open={open} maxWidth={'md'}>
                <div className="dialog-title">
                    <h2>{editId ? 'Edit' : 'Add'} Task</h2>
                    <Tooltip title="Close">
                        <button className="icon-button dialog-close" onClick={onClose}>
                            <Icon path={mdiClose} color={'currentColor'} size={1} />
                        </button>
                    </Tooltip>
                </div>
                <Formik
                    initialValues={{
                        name: taskList?.name || '',
                        description: taskList?.description || '',
                        end_date: taskList?.end_date ? moment(taskList?.end_date) : moment(),
                        priority: taskList?.priority || '',
                        activity_status: taskList?.activity_status || '',
                        error_list: [],
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize
                >
                    {props => {
                        const {
                            touched,
                            errors,
                            values,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue
                        } = props;
                        return (
                            <form onSubmit={handleSubmit}>
                                <TextField
                                    label="Subject"
                                    variant="filled"
                                    name="name"
                                    value={values?.name || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.name && touched.name}
                                    helperText={(errors.name && touched.name) && errors.name}
                                />

                                <TextField
                                    label="Description"
                                    variant="filled"
                                    name="description"
                                    value={values?.description || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    multiline
                                    rows={3}
                                    error={errors.description && touched.description}
                                    helperText={(errors.description && touched.description) && errors.description}
                                />


                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DateTimePicker
                                        label="Due Date"
                                        format={preferredDateFormat}
                                        views={["day", "hours", "minutes", "month", "year"]}
                                        name="end_date"
                                        slots={{
                                            popper: PickerWrapper
                                        }}
                                        slotProps={{
                                            textField: {
                                                variant: 'filled',
                                                name: "end_date",
                                                type: 'text',
                                                error: errors.end_date && touched.end_date,
                                                helperText: (errors.end_date && touched.end_date) && errors.end_date
                                            }
                                        }}
                                        value={values?.end_date ? moment.utc(values.end_date).tz(preTimeZoneKey) : moment()}
                                        onChange={(value) => setFieldValue("end_date", value, true)}
                                        onBlur={handleBlur}
                                    />

                                </LocalizationProvider>
                                <TextField
                                    select
                                    label="Priority"
                                    variant="filled"
                                    name="priority"
                                    value={values?.priority || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.priority && touched.priority}
                                    helperText={(errors.priority && touched.priority) && errors.priority}
                                >
                                    {
                                        Object.entries(priority).map(([key, value]) => (
                                            <MenuItem key={key} value={key}>{value}</MenuItem>
                                        ))
                                    }


                                </TextField>
                                <TextField
                                    select
                                    label="Status"
                                    variant="filled"
                                    name="activity_status"
                                    value={values?.activity_status || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.activity_status && touched.activity_status}
                                    helperText={(errors.activity_status && touched.activity_status) && errors.activity_status}
                                >
                                    {
                                        Object.entries(status).map(([key, value]) => (
                                            <MenuItem key={key} value={key}>{value}</MenuItem>
                                        ))
                                    }
                                </TextField>


                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Button className="btn-block" type="submit">{editId ? 'Edit' : 'Add'} Task</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button color="secondary" className="btn-block" onClick={onClose}>Cancel</Button>
                                    </Grid>
                                </Grid>
                            </form>
                        );
                    }}
                </Formik>
            </Dialog>


        </>
    );
}

export default AddTaskDialog;