import React, { useEffect } from "react";

const WhatsAppSignup = () => {
    useEffect(() => {
        // SDK initialization
        // Initialize Facebook SDK
        window.fbAsyncInit = function () {
            window.FB.init({
                appId: "477586348219179",
                autoLogAppEvents: true,
                xfbml: true,
                version: "v21.0",
            });
        };

        // Load the Facebook SDK script
        const script = document.createElement("script");
        script.async = true;
        script.defer = true;
        script.crossOrigin = "anonymous";
        script.src = "https://connect.facebook.net/en_US/sdk.js";
        document.body.appendChild(script);

        // Add event listener for messages
        const handleMessage = (event) => {
            if (
                event.origin !== "https://www.facebook.com" &&
                event.origin !== "https://web.facebook.com"
            ) {
                return;
            }

            try {
                const data = JSON.parse(event.data);
                if (data.type === "WA_EMBEDDED_SIGNUP") {
                    if (data.event === "FINISH") {
                        const { phone_number_id, waba_id } = data.data;
                        console.log(
                            "Phone number ID ",
                            phone_number_id,
                            " WhatsApp business account ID ",
                            waba_id
                        );
                    } else if (data.event === "CANCEL") {
                        const { current_step } = data.data;
                        console.warn("Cancel at ", current_step);
                    } else if (data.event === "ERROR") {
                        const { error_message } = data.data;
                        console.error("error ", error_message);
                    }
                }
                document.getElementById("session-info-response").textContent = JSON.stringify(data, null, 2);
            } catch {
                console.log("Non JSON Responses", event.data);
            }
        };

        window.addEventListener("message", handleMessage);

        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, []);

    // Callback after login
    const fbLoginCallback = (response) => {
        if (response.authResponse) {
            const code = response.authResponse.code;
            console.log('jignesh', code)
            // Handle the server-to-server call with the code here
        }
        document.getElementById("sdk-response").textContent = JSON.stringify(response, null, 2);
    };



    const launchWhatsAppSignup = () => {
        window.FB.login(fbLoginCallback, {
            config_id: "8492665870832096", // Configuration ID
            response_type: "code", // Must be 'code' for System User access token
            override_default_response_type: true,
            extras: {
                setup: {},
                featureType: "",
                sessionInfoVersion: "2",
            },
        });
    };

    return (
        <button
            onClick={launchWhatsAppSignup}
            style={{
                backgroundColor: "#1877f2",
                border: "none",
                borderRadius: "4px",
                color: "#fff",
                cursor: "pointer",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontSize: "16px",
                fontWeight: "bold",
                height: "40px",
                padding: "0 24px",
            }}
        >
            Login with Facebook
        </button>
    );
};

export default WhatsAppSignup;
