import React, { useEffect, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import useAxios from "../../../hooks/useAxios";
import { useSnackbar } from "../../../components/context/SnackbarContext";
import { useLoader } from "../../../components/context/LoaderContext";
import VirtualizedCardList from "./VirtualizedCardList";
import { Helmet } from "react-helmet";
import { Button, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { mdiClose, mdiFormatListBulleted, mdiMagnify } from "@mdi/js";
import Icon from "@mdi/react";
import { capitalize } from "../../../utils/Utils";

const LeadKanbanView = ({ handleListViewFun, props, userPermissions }) => {
    const axiosInstance = useAxios();
    const { showSnackbar } = useSnackbar();
    const { showLoader, hideLoader } = useLoader();
    const [search, setSearch] = useState();
    const [lanes, setLanes] = useState({})
    const [clearType, setClearType] = useState(false);

    useEffect(() => {
        async function fetchData() {
            await getData();
        }
        fetchData();
    }, [clearType]);

    const getData = async () => {
        const params = {
            search: search,
            search_canban_list: 'yes',
        };

        try {
            showLoader();
            const response = await axiosInstance.get(`api/lead`, { params });
            if (response.status === 200) {
                const lanes = {};
                response?.data?.data?.leadStage?.length && response?.data?.data?.leadStage.forEach(stage => {
                    lanes[`lane${stage.id}`] = {
                        id: stage.id.toString(),
                        title: stage?.name || '',
                        label: `${stage?.leadCount || 0} leads`,
                        // Filter records based on the stage id and map them into cards
                        cards: response?.data?.data?.records?.length > 0
                            ? response.data.data.records
                                .filter(card => card.stage_id === stage.id) // Only include cards matching the stage
                                .map(card => ({
                                    id: card.id.toString(),
                                    salutation: `${card?.salutation ? card?.salutation + '.' : ''}`,
                                    name: `${card?.first_name || ''} ${card?.last_name || ''}`,
                                    title: card?.title || '',
                                    date: card?.created_at || '',
                                    organization: card?.organization_name || '',
                                    phone: card?.phone1 || card?.phone2 || '',
                                }))
                            : [] // Return an empty array if there are no matching cards
                    };
                });
                setLanes(lanes);
            }
        } catch (error) {
            hideLoader();
            if (error.response && error.response.status === 400) {
                showSnackbar(error.response.data.message, "warning");
            }
        } finally {
            hideLoader();
        }
    };

    const clearUserFilter = (type) => {
        setClearType(clearType ? false : true)
        if (type === 'search') {
            setSearch('');
        }
    }

    const updateNewState = async (leadId, stageId, destinationIndex) => {
        const data = {
            id: leadId,
            stage: stageId,
            position: destinationIndex,
        }

        await axiosInstance.put('api/lead-stage/mode', data).then(response => {
            if (response.status === 200) {
                showSnackbar(response.data.message, "success");
            }
        }).then(json => {

        }).catch(error => {
            if (error.response) {
                hideLoader()
                if (error.response && error.response.status === 400) {
                    showSnackbar(error.response.data.message, "warning");
                }
            }
        });
    }

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;

        const { source, destination } = result;

        const sourceLane = lanes[source.droppableId];
        const destinationLane = lanes[destination.droppableId];

        //get id from result to update stage value on lead
        const stageId = destinationLane?.id ? parseInt(destinationLane.id) : ''
        const draggableLeadId = result?.draggableId ? parseInt(result?.draggableId) : ''
        const destinationIndex = destination?.index || 0;

        // Moving within the same lane
        if (source.droppableId === destination.droppableId) {
            const newCards = Array.from(sourceLane.cards);
            const [movedCard] = newCards.splice(source.index, 1);
            newCards.splice(destination.index, 0, movedCard);

            setLanes({
                ...lanes,
                [source.droppableId]: { ...sourceLane, cards: newCards },
            });
        }
        // Moving to a different lane
        else {
            const sourceCards = Array.from(sourceLane.cards);
            const destinationCards = Array.from(destinationLane.cards);

            const [movedCard] = sourceCards.splice(source.index, 1);
            destinationCards.splice(destination.index, 0, movedCard);

            setLanes({
                ...lanes,
                [source.droppableId]: { ...sourceLane, cards: sourceCards },
                [destination.droppableId]: { ...destinationLane, cards: destinationCards },
            });

            //lets update new state for lead
            stageId && draggableLeadId && updateNewState(draggableLeadId, stageId, destinationIndex)
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault(); // Prevent the default form submission behavior
            getData(search);
        }
    };

    const backToList = () => {
        handleListViewFun()
    }

    return (
        <>
            <Helmet>
                <title>Leads | {capitalize(props?.name || 'SolvCRM')}</title>
            </Helmet>
            <Grid container className='page-action-bar'>
                <Grid item xs={'auto'}>
                    <TextField
                        variant="filled"
                        placeholder="Search leads"
                        name="searchData"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        onKeyDown={handleKeyDown}
                        className="size-small page-search no-margin"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Icon path={mdiMagnify} color="currentColor" size={1} />
                                    {
                                        search &&
                                        <IconButton onClick={() => clearUserFilter('search')}>
                                            <Icon path={mdiClose} color={'currentColor'} size={'20px'} />
                                        </IconButton>
                                    }

                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs={'auto'} sx={{ marginLeft: 'auto' }}>
                    <Button variant="contained" color="secondary" size='small' onClick={() => backToList()}>
                        <span>
                            <Icon path={mdiFormatListBulleted} color="currentColor" size={1} />
                            <span>List View</span>
                        </span>
                    </Button>
                </Grid>
            </Grid>
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <div className="kanban-wrapper">
                    <ul className="title-wrapper">
                        {Object.keys(lanes).map((laneId) => (
                            <li key={laneId}>
                                <Typography variant="body-large-semibold">{lanes[laneId]?.title || 'N/A'}</Typography> {lanes[laneId]?.label !== 0 && <span className="status-badge primary-filled">{lanes[laneId]?.cards?.length || 0}</span>}
                            </li>
                        ))}
                    </ul>
                    <div className="board-wrapper">
                        {Object.keys(lanes).map((laneId) => (
                            <div key={laneId} className="stage">
                                <VirtualizedCardList
                                    droppableId={laneId}
                                    cards={lanes[laneId].cards}
                                    setCardsInLane={(updatedCards) =>
                                        setLanes({
                                            ...lanes,
                                            [laneId]: { ...lanes[laneId], cards: updatedCards },
                                        })
                                    }
                                    userPermissions={userPermissions}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </DragDropContext>
        </>
    );
};

export default LeadKanbanView;
