import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import authService from '../../services/AuthService';

const PublicLayout = () => {
    if (authService.isAuthenticated()) {
        return <Navigate to="/dashboard" />;
    }

    return (
        <Outlet />
    );
};

export default PublicLayout;