import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Grid, TextField, InputAdornment, Button, Stack, Checkbox, Typography, IconButton } from '@mui/material';
import Icon from '@mdi/react';
import { mdiCheckAll, mdiMagnify, mdiTrashCanOutline, mdiClose } from '@mdi/js';
import TablePagination from '@mui/material/TablePagination';
import { useLoader } from "../../components/context/LoaderContext";
import useAxios from "../../hooks/useAxios";
import { useSnackbar } from "../../components/context/SnackbarContext";
import { notificationCount } from "../../redux/slices/userSlice";

import EmptyState from "../../components/common/EmptyState";
import moment from "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
import { capitalize, DeleteDialog, DeleteDialogConfig } from "../../utils/Utils";

function Notifications(props) {
    const axiosInstance = useAxios();
    const dispatch = useDispatch();
    const { showLoader, hideLoader, loading } = useLoader();
    const { showSnackbar } = useSnackbar();
    const { preferredDateFormat, preTimeZoneKey } = useSelector((state) => state?.user);
    const [clearType, setClearType] = useState(false);
    const [notifications, setNotifications] = useState([]);

    // Extract URL parameters
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(
        parseInt(process.env.REACT_APP_DEFAULT_PAGINATION)
    );
    const [totalCount, setTotalCount] = useState(0);
    const [sortOrder, setSortOrder] = useState(
        process.env.REACT_APP_DEFAULT_SORT
    );
    const [sortBy, setSortBy] = useState("created_at");

    // State management for search fields
    const [search, setSearch] = useState("");

    //handle checkbox
    const [checkedItems, setCheckedItems] = useState({});
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [reloadPage, setReloadPage] = useState(false);

    useEffect(() => {
        async function fetchData() {
            await getData(
                currentPage,
                perPage,
                sortBy,
                sortOrder,
                search,
            );
        }
        fetchData();
    }, [
        currentPage,
        perPage,
        sortBy,
        sortOrder,
        reloadPage,
        clearType,
    ]);

    const getData = async (
        currentPage,
        perPage,
        sortBy,
        sortOrder,
        search,
    ) => {
        const params = {
            search: search,
            sort_field: sortBy,
            sort_order: sortOrder,
            per_page: perPage,
        };
        showLoader()
        await axiosInstance.get(`api/notification?page=${currentPage + 1}`, { params }).then((response) => {
            if (response.status === 200) {
                setNotifications(response?.data?.data?.records?.data || [])
                setTotalCount(response?.data?.data?.records?.total);
                dispatch(
                    notificationCount(response?.data?.data?.notificationCNT?.length || 0)
                )
                hideLoader()
            }
        })
            .then((json) => { })
            .catch((error) => {
                if (error.response) {
                    hideLoader();
                    if (error.response.status === 400) {
                        showSnackbar(error.response.data.message, "warning");
                    }
                }
            });
    };


    //=====Start::Write a code for handle headeMenu option ======//
    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleRowsPerPageChange = (event) => {
        setPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault(); // Prevent the default form submission behavior
            getData(
                currentPage,
                perPage,
                sortBy,
                sortOrder,
                search,
            );
        }
    };

    const handleCheckAll = (event) => {
        const { checked } = event.target;
        setIsCheckAll(checked);
        const newCheckedItems = {};
        notifications.forEach((notification) => {
            newCheckedItems[notification.id] = checked;
        });
        setCheckedItems(newCheckedItems);
    };

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setCheckedItems({
            ...checkedItems,
            [name]: checked,
        });

        if (!checked) {
            setIsCheckAll(false);
        } else {
            const allChecked = notifications.every(
                (notification) => checkedItems[notification.id] || notification.name === name
            );
            setIsCheckAll(allChecked);
        }
    };


    // Get list of checked item IDs
    const getCheckedIds = () => {
        return Object.keys(checkedItems).filter((id) => checkedItems[id]);
    };


    // Handle multiple delete records
    async function handleBulkDelete() {
        const checkedIds = getCheckedIds();
        if (checkedIds && checkedIds.length > 0) {
            bulkDeleteData(checkedIds)
        } else {
            showSnackbar('Please select at one record to Delete.', 'warning')
        }
    }

    // Handle multiple delete records
    const bulkDeleteData = async (ids) => {
        const data = {
            id: ids,
        }

        try {
            DeleteDialog.fire({
                ...DeleteDialogConfig({
                    confirm: async () => {
                        showLoader()
                        await axiosInstance.delete('api/notification/bulk-destroy', { data })
                            .then((response) => {
                                if (response.status === 200) {
                                    showSnackbar(response.data.message, "success");
                                    setCheckedItems({});
                                    setIsCheckAll(false);
                                    setReloadPage(reloadPage ? false : true);
                                    hideLoader();

                                }
                            })
                            .then((json) => { })
                            .catch((error) => {
                                if (error.response) {
                                    hideLoader();
                                    if (error.response.status === 400) {
                                        showSnackbar(error.response.data.message, "warning");
                                    }
                                }
                            });
                    },
                    cancel: () => {
                        hideLoader();
                        setCheckedItems({});
                        setIsCheckAll(false);
                    }
                })
            });
        } catch (error) {
            hideLoader();
            showSnackbar('Oops Something want wrong..!', "error");
        }
    }
    //=====End::Write a code for handle headeMenu option ======//

    const handleBulkRead = () => {
        const checkedIds = getCheckedIds();
        if (checkedIds && checkedIds.length > 0) {
            bulkReadData(checkedIds)
        } else {
            showSnackbar('Please select at one record.', 'warning')
        }

    }

    async function bulkReadData(ids) {
        const data = {
            id: ids,
        }
        await axiosInstance.post('api/notification/bulk-update', data).then(response => {
            if (response.status === 200) {
                showSnackbar(response.data.message, "success");
                setCheckedItems({});
                setIsCheckAll(false);
                setReloadPage(reloadPage ? false : true);
                hideLoader();
            }
        }).then(json => {

        }).catch(error => {
            if (error.response) {
                hideLoader();
                if (error.response.status === 400) {
                    showSnackbar(error.response.data.message, "warning");
                }
            }
        });
    }

    //======Start:Write a Export data=====//
    const checkedIds = getCheckedIds();
    const expParams = {
        search: search,
        sort_field: sortBy,
        sort_order: sortOrder,
        per_page: perPage,
        selectedIds: checkedIds && checkedIds.length > 0 ? checkedIds : []
    };

    //======End:Write a Export data=====//

    const clearUserFilter = (type) => {
        setClearType(clearType ? false : true)
        if (type === 'search') {
            setSearch('');
        } else {
            setSearch('')
        }
    }

    return (
        <>
            <Helmet>
                <title>Notifications | {capitalize(props?.name || 'SolvCRM')}</title>
            </Helmet>

            <Grid container className='page-action-bar'>
                <Grid item xs={'auto'}>
                    <Stack direction="row" spacing={1}>
                        <Checkbox
                            className="grid-checkbox"
                            checked={isCheckAll}
                            onChange={handleCheckAll}
                        />
                        <TextField
                            variant="filled"
                            placeholder="Search notifications"
                            name="searchData"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            onKeyDown={handleKeyDown}
                            className="size-small page-search no-margin"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Icon path={mdiMagnify} color="currentColor" size={1} />
                                        {
                                            search &&
                                            <IconButton onClick={() => clearUserFilter('search')} >
                                                <Icon path={mdiClose} color={'currentColor'} size={'20px'} />
                                            </IconButton>
                                        }

                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Stack>
                </Grid>
                <Grid item xs={'auto'} sx={{ marginLeft: 'auto' }}>
                    <Stack direction="row" spacing={1}>
                        <Button size="small" onClick={() => handleBulkRead()}>
                            <span>
                                <Icon path={mdiCheckAll} color="currentColor" size={1} />
                                <span>Mark as Read</span>
                            </span>
                        </Button>
                        <Button variant="contained" color="secondary" size='small' onClick={() => handleBulkDelete()}>
                            <span>
                                <Icon path={mdiTrashCanOutline} color="currentColor" size={1} />
                                <span>Delete</span>
                            </span>
                        </Button>
                    </Stack>
                </Grid>
            </Grid>

            {notifications && notifications.length > 0 && (
                <ul className="notification-list">
                    {notifications.map((notification, index) => {
                        return (<li key={index + ''} className={notification?.status == 1 ? 'unread' : ''}>
                            <Grid container className='card' alignItems={'flex-start'}>
                                <Grid item xs={'auto'}>
                                    <Checkbox
                                        className="grid-checkbox"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                        }}
                                        name={String(notification.id)}
                                        checked={checkedItems[notification.id] || false}
                                        onChange={handleCheckboxChange}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <Typography variant='title-medium-bold' component={'h6'}>{notification.title}</Typography>
                                    <Typography variant='body-medium' component={'p'}>{notification.body}</Typography>
                                    <Typography variant='body-medium' className='date'>{notification?.created_at
                                        ? moment.utc(notification.created_at)
                                            .tz(preTimeZoneKey)
                                            .format(preferredDateFormat)
                                        : "N/A"}</Typography>
                                </Grid>
                            </Grid>
                        </li>)
                    }
                    )}
                </ul>
            )}

            {(notifications?.length == 0) && !loading &&
                <EmptyState type={'notification'} hideActionBtn />
            }

            {
                notifications?.length > 0 &&
                <TablePagination
                    component="div"
                    count={totalCount}
                    page={currentPage}
                    rowsPerPage={perPage}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                />
            }

        </>
    );
}

export default Notifications;