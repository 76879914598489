import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { Button, Dialog, Grid, TextField, Tooltip } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import React from "react";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Formik } from "formik";
import * as yup from "yup";
import { useSnackbar } from '../../../components/context/SnackbarContext';
import useAxios from '../../../hooks/useAxios';
import { useLoader } from "../../../components/context/LoaderContext";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import MyCustomNumberFormat from "../../../components/MyCustomNumberFormat";
import { displayDate } from "../../../utils/Utils";

function ClosedWonDealDialog({ onClose, open, handleReloadFunc, dealData, dealType, accountData, stageId }) {
    const axiosInstance = useAxios();
    const { showSnackbar } = useSnackbar();
    const { showLoader, hideLoader } = useLoader();
    const { preferredDateFormat, currencyCode, currencySymbol, currencyStringValue } = useSelector(state => state?.user);

    const validationSchema = yup.object().shape({
        deal_amount: yup.string().required('Enter deal amount'),
        closing_date: yup.string().required('Enter closing date'),
    })

    const handleSubmit = async (values, onSubmitProps) => {
        const data = {
            id: dealData?.id,
            contact_id: dealData?.contact_id || '',
            organization_name: accountData?.organization_name || '',
            deal_description: dealData?.deal_description || '',
            deal_probability: dealData?.deal_probability || '',
            deal_amount: values.deal_amount || '',
            stage_id: stageId || '',
            closing_date: values.closing_date ? moment(values.closing_date).format('YYYY-MM-DD') : moment().add(2, 'days').format('YYYY-MM-DD'),
            note: values.note || '',
            deal_source: dealData?.deal_source || ''
        }

        showLoader()
        await axiosInstance.post("/api/deal/update", data).then(response => {
            if (response.status === 200) {
                hideLoader()
                showSnackbar(response.data.message, 'success')
                onClose()
                handleReloadFunc()
            }
        }).then(json => {

        }).catch(error => {
            if (error.response) {
                hideLoader()
                if (error.response.status === 422 || error.response.data.validation_errors) {
                    const errors = error.response.data.validation_errors;
                    Object.keys(errors).forEach(key => {
                        onSubmitProps.setFieldError(key, errors[key][0]);
                    });
                } else {
                    showSnackbar(error.response.data.message, 'error')
                }
            }
        });
    }

    const formikRef = React.useRef();

    return (
        <>
            <Dialog onClose={onClose} open={open} maxWidth={'md'}>
                <div className="dialog-title">
                    <h2>Closed {dealType} Deal</h2>
                    <Tooltip title="Close">
                        <button className="icon-button dialog-close" onClick={onClose}>
                            <Icon path={mdiClose} color={'currentColor'} size={1} />
                        </button>
                    </Tooltip>
                </div>
                <Formik
                    innerRef={formikRef}
                    initialValues={{
                        deal_amount: dealData?.deal_amount || '',
                        closing_date: '',
                        note: '',
                        error_list: [],
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize
                >
                    {props => {
                        const {
                            touched,
                            errors,
                            values,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue
                        } = props;
                        return (
                            <form onSubmit={handleSubmit}>
                                <TextField
                                    variant="filled"
                                    label='Deal Amount'
                                    id="deal_amount"
                                    name="deal_amount"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter deal amount"
                                    value={values?.deal_amount || ''}
                                    InputProps={{
                                        inputComponent: MyCustomNumberFormat, //here last is input/text to display on page
                                        inputProps: { currencySymbol: currencySymbol, currencyCode: currencyCode, currencyStringValue: currencyStringValue, displayType: 'input' },//displayType=input/text
                                        endAdornment: <span className="text-adornment">{currencyCode}</span>
                                    }}
                                    error={errors.deal_amount && touched.deal_amount}
                                    helperText={(errors.deal_amount && touched.deal_amount) && errors.deal_amount}
                                />

                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        label="Closing Date"
                                        format={displayDate(preferredDateFormat, 'half')}
                                        views={['year', 'month', 'day']}
                                        slotProps={{
                                            textField: {
                                                variant: 'filled',
                                                name: "closing_date",
                                                type: 'text',
                                                error: errors.closing_date && touched.closing_date,
                                                helperText: (errors.closing_date && touched.closing_date) && errors.closing_date
                                            }
                                        }}
                                        value={values?.closing_date || null}
                                        onChange={(value) => setFieldValue("closing_date", value, true)}
                                        onBlur={handleBlur}
                                    />
                                </LocalizationProvider>
                                {
                                    dealType === 'Lost' ?
                                        <TextField
                                            label="Lost Reason"
                                            variant="filled"
                                            multiline
                                            rows={3}
                                            name="note"
                                            value={values?.note || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.note && touched.note}
                                            helperText={(errors.note && touched.note) && errors.note}
                                        />
                                        : ''
                                }

                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Button className="btn-block" type="submit" >Save</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button color="secondary" className="btn-block" onClick={onClose}>Cancel</Button>
                                    </Grid>
                                </Grid>
                            </form>
                        );
                    }}
                </Formik>
            </Dialog>
        </>
    );
}

export default ClosedWonDealDialog;