import { MenuItem, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

const EasyEditCustomSelect = props => {
    const [selectvalue, setSelectValue] = useState(props?.fieldValue)
    const handleSelect = (event) => {
        setSelectValue(event.target.value);
        props.setParentValue(event.target.value);
    };

    useEffect(() => {
        setSelectValue(props.fieldValue); // Sync when props change
    }, [props.fieldValue]);

    return (
        <TextField
            select
            variant="filled"
            className="no-margin size-medium control"
            label={props.label}
            value={selectvalue}
            autoFocus
            inputProps={{
                onFocus: props.focusCallback
            }}
            onChange={handleSelect}
        >
            {
                props?.hideDefaultSelectValue ?
                    ''
                    :
                    <MenuItem key={0} value={''}>Select</MenuItem>
            }
            {
                props.label === 'Preferred Date Format' ?
                    props?.items.map((item, index) =>
                        <MenuItem key={index + ''} value={item?.key}>{item?.option}</MenuItem>
                    )
                    :
                    props?.items.map((item, index) =>
                        <MenuItem key={index + ''} value={item?.value}>{item?.option}</MenuItem>
                    )
            }

        </TextField>
    );
}

export default EasyEditCustomSelect;