import { faviconXl, internalServerErrorIconXlFinancial, pageNotFoundIconXlFinancial } from "../assets/images";

const primaryMain = '#07F2F2';
const primary25 = '#F0FFFF';
const primary50 = '#E6FEFE';
const primary100 = '#CDFEFE';
const primary200 = '#9CFCFC';
const primary300 = '#6AFBFB';
const primary400 = '#39F9F9';
const primary500 = '#07F2F2';
const primary600 = '#06C6C6';
const primary700 = '#049595';
const primary800 = '#036363';
const primary900 = '#013232';

const neutral25 = '#f5f5f5';
const neutral50 = '#e1e1e1';
const neutral100 = '#cccccc';
const neutral200 = '#b7b7b7';
const neutral300 = '#a3a3a3';
const neutral400 = '#8e8e8e';
const neutral500 = '#7a7a7a';
const neutral600 = '#666666';
const neutral700 = '#525252';
const neutral800 = '#3d3d3d';
const neutral900 = '#292929';
const neutral950 = '#1a1a1a';

const warningMain = '#efc56c';
const warning25 = '#fdf8ed';
const warning50 = '#fbf1da';
const warning100 = '#f7e2b6';
const warning200 = '#f3d491';
const warning300 = '#efc56c';
const warning400 = '#ebb747';
const warning500 = '#e6a71e';
const warning600 = '#ca9116';
const warning700 = '#a57712';
const warning800 = '#815c0e';
const warning900 = '#5c420a';

const successMain = '#6cdabf';
const success25 = '#effbf8';
const success50 = '#cef3ea';
const success100 = '#adebdb';
const success200 = '#8de2cd';
const success300 = '#6cdabf';
const success400 = '#4bd2b0';
const success500 = '#30c39e';
const success600 = '#29a385';
const success700 = '#21836a';
const success800 = '#186250';
const success900 = '#104135';

const errorMain = '#ff897d';
const error25 = '#fff6f5';
const error50 = '#ffedea';
const error100 = '#ffdad6';
const error200 = '#ffb4ab';
const error300 = '#ff897d';
const error400 = '#ff5449';
const error500 = '#de3730';
const error600 = '#ba1a1a';
const error700 = '#93000a';
const error800 = '#690005';
const error900 = '#410002';

const infoMain = '#6AFBFB';
const info25 = '#F0FFFF';
const info50 = '#E6FEFE';
const info100 = '#CDFEFE';
const info200 = '#9CFCFC';
const info300 = '#6AFBFB';
const info400 = '#39F9F9';
const info500 = '#07F2F2';
const info600 = '#06C6C6';
const info700 = '#049595';
const info800 = '#036363';
const info900 = '#013232';
const info950 = '#011919';

const systemWhite = '#ffffff';
const systemBlack = '#000000';
const systemTextPrimary = '#CCCCCC';
const systemTextSecondary = '#888888';
const systemTextTertiary = '#666666';
const systemBorder = '#454545';
const systemBorderLight = '#333333';

const fontFamily = '"DM Sans", sans-serif';

const chartColor = {
    colorOne200: primary200,
    colorOne400: primary400,
    colorOne500: primary500,
    colorTwo200: primary600,
    colorTwo400: primary700,
    colorTwo500: primary800,
    colorThree200: success400,
    colorThree400: success600,
    colorThree500: success700,
    colorFour400: error400,
    colorFive400: primary400
}

export const xlFinancialTheme = {
    name: 'xl-financial',
    pageNotFoundImage: pageNotFoundIconXlFinancial,
    internalServerErrorImage: internalServerErrorIconXlFinancial,
    favicon: faviconXl,
    themeColor: '#292929',
    colors: {
        primary: {
            main: primaryMain,
            25: primary25,
            50: primary50,
            100: primary100,
            200: primary200,
            300: primary300,
            400: primary400,
            500: primary500,
            600: primary600,
            700: primary700,
            800: primary800,
            900: primary900
        },
        grey: {
            25: neutral25,
            50: neutral50,
            100: neutral100,
            200: neutral200,
            300: neutral300,
            400: neutral400,
            500: neutral500,
            600: neutral600,
            700: neutral700,
            800: neutral800,
            900: neutral900
        },
        warning: {
            main: warningMain,
            25: warning25,
            50: warning50,
            100: warning100,
            200: warning200,
            300: warning300,
            400: warning400,
            500: warning500,
            600: warning600,
            700: warning700,
            800: warning800,
            900: warning900
        },
        success: {
            main: successMain,
            25: success25,
            50: success50,
            100: success100,
            200: success200,
            300: success300,
            400: success400,
            500: success500,
            600: success600,
            700: success700,
            800: success800,
            900: success900
        },
        error: {
            main: errorMain,
            25: error25,
            50: error50,
            100: error100,
            200: error200,
            300: error300,
            400: error400,
            500: error500,
            600: error600,
            700: error700,
            800: error800,
            900: error900
        },
        info: {
            main: infoMain,
            25: info25,
            50: info50,
            100: info100,
            200: info200,
            300: info300,
            400: info400,
            500: info500,
            600: info600,
            700: info700,
            800: info800,
            900: info900,
            950: info950
        },
        system: {
            white: systemWhite,
            black: systemBlack,
            textPrimary: systemTextPrimary,
            textSecondary: systemTextSecondary,
            textTertiary: systemTextTertiary,
            border: systemBorder,
            borderLight: systemBorderLight,
            cardBackground: neutral900
        }
    },
    colorVariables: {
        textBox: {
            hoverBorderColor: neutral500,
            focusBorderColor: primary500,
            errorBorderColor: error300,
            focusLabelColor: primary500,
            errorLabelColor: error300
        },
        button: {
            focusRing: primary400,

            primaryBackground: primary400,
            priamryTextColor: primary900,
            hoverPrimaryBackground: primary300,

            secondaryBackgroundColor: neutral800,
            hoverSecondaryBackgroundColor: neutral700,
            secondaryTextColor: neutral50,

            textTextColor: neutral50,
            hoverTextBackgroundColor: neutral800
        },
        menu: {
            menuItemHoverBackgroundColor: neutral800,
            backgroundColor: neutral900,
            backgroundDark: neutral950
        },
        checkboxRadio: {
            textColor: neutral600,
            focusRing: primary500,
            checkedTextColor: primary500,

            switchThumbColor: neutral900,
            switchTrackColor: neutral600
        },
        progress: {
            color: primary500
        },
        alert: {
            errorBackgroundColor: error800,
            errorTextColor: error100,
            warningBackgroundColor: warning800,
            warningTextColor: warning100,
            successBackgroundColor: success800,
            successTextColor: success100,
            infoBackgroundColor: info800,
            infoTextColor: info100,
        },
        chip: {
            primaryBackgroundColor: primary800,
            primaryTextColor: primary100
        },
        dialog: {
            backgroundColor: neutral900,
            backdropBackgroundColor: 'rgba(0, 0, 0, 0.64)'
        }
    },
    shadows: {
        shadow3: {
            boxShadow: '0 1px 4px rgba(0, 0, 0, 0.2), 0 8px 16px rgba(0, 0, 0, 0.32)'
        }, shadow4: {
            boxShadow: '0 1px 8px rgba(0, 0, 0, 0.2), 0 16px 32px rgba(0, 0, 0, 0.32)'
        }, shadow5: {
            boxShadow: '0 2px 12px rgba(0, 0, 0, 0.2), 0 32px 64px rgba(0, 0, 0, 0.4)'
        }
    },
    fontFamily: fontFamily,
    chartColor: chartColor
}