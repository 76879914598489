import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { ThemeContext } from "../../App";
import { Button, Typography } from "@mui/material";
import Icon from "@mdi/react";
import { mdiHomeOutline } from "@mdi/js";
import { useNavigate } from "react-router-dom";

function PageNotFound() {
    let { currentTheme } = useContext(ThemeContext);

    const navigate = useNavigate();

    return (
        <>
            <Helmet>
                <title>Page Not Found</title>
            </Helmet>

            <section className="error-page">
                <img src={currentTheme?.pageNotFoundImage} alt="404" />
                <div className="content">
                    <Typography variant="headline-medium-bold" component={"h4"}>Oops! Looks Like You're Lost in Space...</Typography>
                    <Typography variant="body-large-medium" component={"p"}>It seems the page you're looking for has drifted off into the digital cosmos. But don’t worry, we're here to help you find your way back to solid ground. Explore other destinations or head to the homepage to start fresh!</Typography>
                </div>
                <Button variant="contained" onClick={e => navigate('/dashboard')}>
                    <span>
                        <Icon path={mdiHomeOutline} color={'currentColor'} size={1} />
                        <span>Take Me Home</span>
                    </span>
                </Button>
            </section>
        </>
    );
}

export default PageNotFound;