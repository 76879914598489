import { Tooltip } from "@mui/material";
import React from "react";

function DashedBarChart({ items, className = '', baseHeight = 200 }) {

    const getHeight = (index) => {
        const max = Math.max(...items.map(obj => parseInt(obj?.data || 0)));
        let height = (parseFloat(items[index].data * baseHeight)) / max;
        return height || 0;
    }

    return (
        <>
            <ul className={`dashed-bar-chart ${className}`}>
                {items?.map((item, index) =>
                    <li key={index + ''} style={{ borderColor: item.borderColor, height: getHeight(index) }}>
                        {item.label &&
                            <Tooltip title={item.label + ': ' + item.data}>
                                <span className="tooltip-object"></span>
                            </Tooltip>}
                        <div className="dash-wrapper" style={{ color: item.dashColor }}>
                            <svg width="670" height="670" viewBox="0 0 670 670" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.6875 354.241L354.241 0.687866L355.655 2.10208L2.10171 355.655L0.6875 354.241Z" fill="currentColor" />
                                <path d="M4.93014 358.484L358.484 4.93051L359.898 6.34472L6.34435 359.898L4.93014 358.484Z" fill="currentColor" />
                                <path d="M9.17278 362.727L362.726 9.17315L364.14 10.5874L10.587 364.141L9.17278 362.727Z" fill="currentColor" />
                                <path d="M13.4154 366.969L366.969 13.4158L368.383 14.83L14.8296 368.383L13.4154 366.969Z" fill="currentColor" />
                                <path d="M17.6581 371.212L371.211 17.6584L372.626 19.0726L19.0723 372.626L17.6581 371.212Z" fill="currentColor" />
                                <path d="M21.9007 375.454L375.454 21.9011L376.868 23.3153L23.3149 376.869L21.9007 375.454Z" fill="currentColor" />
                                <path d="M26.1433 379.697L379.697 26.1437L381.111 27.5579L27.5576 381.111L26.1433 379.697Z" fill="currentColor" />
                                <path d="M30.386 383.94L383.939 30.3864L385.354 31.8006L31.8002 385.354L30.386 383.94Z" fill="currentColor" />
                                <path d="M34.6286 388.182L388.182 34.629L389.596 36.0432L36.0428 389.597L34.6286 388.182Z" fill="currentColor" />
                                <path d="M38.8713 392.425L392.425 38.8716L393.839 40.2858L40.2855 393.839L38.8713 392.425Z" fill="currentColor" />
                                <path d="M43.1139 396.668L396.667 43.1143L398.082 44.5285L44.5281 398.082L43.1139 396.668Z" fill="currentColor" />
                                <path d="M47.3565 400.91L400.91 47.3569L402.324 48.7711L48.7708 402.325L47.3565 400.91Z" fill="currentColor" />
                                <path d="M51.5992 405.153L405.153 51.5996L406.567 53.0138L53.0134 406.567L51.5992 405.153Z" fill="currentColor" />
                                <path d="M55.8418 409.396L409.395 55.8422L410.809 57.2564L57.256 410.81L55.8418 409.396Z" fill="currentColor" />
                                <path d="M60.0845 413.638L413.638 60.0848L415.052 61.4991L61.4987 415.052L60.0845 413.638Z" fill="currentColor" />
                                <path d="M64.3271 417.881L417.88 64.3275L419.295 65.7417L65.7413 419.295L64.3271 417.881Z" fill="currentColor" />
                                <path d="M68.5697 422.124L422.123 68.5701L423.537 69.9843L69.984 423.538L68.5697 422.124Z" fill="currentColor" />
                                <path d="M72.8124 426.366L426.366 72.8128L427.78 74.227L74.2266 427.78L72.8124 426.366Z" fill="currentColor" />
                                <path d="M77.055 430.609L430.608 77.0554L432.023 78.4696L78.4692 432.023L77.055 430.609Z" fill="currentColor" />
                                <path d="M81.2977 434.851L434.851 81.298L436.265 82.7122L82.7119 436.266L81.2977 434.851Z" fill="currentColor" />
                                <path d="M85.5403 439.094L439.094 85.5407L440.508 86.9549L86.9545 440.508L85.5403 439.094Z" fill="currentColor" />
                                <path d="M89.783 443.337L443.336 89.7833L444.751 91.1975L91.1972 444.751L89.783 443.337Z" fill="currentColor" />
                                <path d="M94.0256 447.579L447.579 94.026L448.993 95.4402L95.4398 448.994L94.0256 447.579Z" fill="currentColor" />
                                <path d="M98.2682 451.822L451.822 98.2686L453.236 99.6828L99.6824 453.236L98.2682 451.822Z" fill="currentColor" />
                                <path d="M102.511 456.065L456.064 102.511L457.478 103.925L103.925 457.479L102.511 456.065Z" fill="currentColor" />
                                <path d="M106.754 460.307L460.307 106.754L461.721 108.168L108.168 461.721L106.754 460.307Z" fill="currentColor" />
                                <path d="M110.996 464.55L464.55 110.997L465.964 112.411L112.41 465.964L110.996 464.55Z" fill="currentColor" />
                                <path d="M115.239 468.793L468.792 115.239L470.206 116.653L116.653 470.207L115.239 468.793Z" fill="currentColor" />
                                <path d="M119.481 473.035L473.035 119.482L474.449 120.896L120.896 474.449L119.481 473.035Z" fill="currentColor" />
                                <path d="M123.724 477.278L477.277 123.724L478.692 125.139L125.138 478.692L123.724 477.278Z" fill="currentColor" />
                                <path d="M127.967 481.52L481.52 127.967L482.934 129.381L129.381 482.935L127.967 481.52Z" fill="currentColor" />
                                <path d="M132.209 485.763L485.763 132.21L487.177 133.624L133.624 487.177L132.209 485.763Z" fill="currentColor" />
                                <path d="M136.452 490.006L490.005 136.452L491.42 137.867L137.866 491.42L136.452 490.006Z" fill="currentColor" />
                                <path d="M140.695 494.248L494.248 140.695L495.662 142.109L142.109 495.663L140.695 494.248Z" fill="currentColor" />
                                <path d="M144.937 498.491L498.491 144.938L499.905 146.352L146.352 499.905L144.937 498.491Z" fill="currentColor" />
                                <path d="M149.18 502.734L502.733 149.18L504.148 150.594L150.594 504.148L149.18 502.734Z" fill="currentColor" />
                                <path d="M153.423 506.976L506.976 153.423L508.39 154.837L154.837 508.391L153.423 506.976Z" fill="currentColor" />
                                <path d="M157.665 511.219L511.219 157.666L512.633 159.08L159.079 512.633L157.665 511.219Z" fill="currentColor" />
                                <path d="M161.908 515.462L515.461 161.908L516.875 163.322L163.322 516.876L161.908 515.462Z" fill="currentColor" />
                                <path d="M166.15 519.704L519.704 166.151L521.118 167.565L167.565 521.118L166.15 519.704Z" fill="currentColor" />
                                <path d="M170.393 523.947L523.947 170.393L525.361 171.808L171.807 525.361L170.393 523.947Z" fill="currentColor" />
                                <path d="M174.636 528.19L528.189 174.636L529.603 176.05L176.05 529.604L174.636 528.19Z" fill="currentColor" />
                                <path d="M178.878 532.432L532.432 178.879L533.846 180.293L180.293 533.846L178.878 532.432Z" fill="currentColor" />
                                <path d="M183.121 536.675L536.674 183.121L538.089 184.536L184.535 538.089L183.121 536.675Z" fill="currentColor" />
                                <path d="M187.364 540.917L540.917 187.364L542.331 188.778L188.778 542.332L187.364 540.917Z" fill="currentColor" />
                                <path d="M191.606 545.16L545.16 191.607L546.574 193.021L193.021 546.574L191.606 545.16Z" fill="currentColor" />
                                <path d="M195.849 549.403L549.402 195.849L550.817 197.264L197.263 550.817L195.849 549.403Z" fill="currentColor" />
                                <path d="M200.092 553.645L553.645 200.092L555.059 201.506L201.506 555.06L200.092 553.645Z" fill="currentColor" />
                                <path d="M204.334 557.888L557.888 204.335L559.302 205.749L205.748 559.302L204.334 557.888Z" fill="currentColor" />
                                <path d="M208.577 562.131L562.13 208.577L563.544 209.991L209.991 563.545L208.577 562.131Z" fill="currentColor" />
                                <path d="M212.82 566.373L566.373 212.82L567.787 214.234L214.234 567.787L212.82 566.373Z" fill="currentColor" />
                                <path d="M217.062 570.616L570.616 217.063L572.03 218.477L218.476 572.03L217.062 570.616Z" fill="currentColor" />
                                <path d="M221.305 574.859L574.858 221.305L576.272 222.719L222.719 576.273L221.305 574.859Z" fill="currentColor" />
                                <path d="M225.547 579.101L579.101 225.548L580.515 226.962L226.962 580.515L225.547 579.101Z" fill="currentColor" />
                                <path d="M229.79 583.344L583.344 229.79L584.758 231.205L231.204 584.758L229.79 583.344Z" fill="currentColor" />
                                <path d="M234.033 587.586L587.586 234.033L589 235.447L235.447 589.001L234.033 587.586Z" fill="currentColor" />
                                <path d="M238.275 591.829L591.829 238.276L593.243 239.69L239.69 593.243L238.275 591.829Z" fill="currentColor" />
                                <path d="M242.518 596.072L596.071 242.518L597.486 243.933L243.932 597.486L242.518 596.072Z" fill="currentColor" />
                                <path d="M246.761 600.314L600.314 246.761L601.728 248.175L248.175 601.729L246.761 600.314Z" fill="currentColor" />
                                <path d="M251.003 604.557L604.557 251.004L605.971 252.418L252.418 605.971L251.003 604.557Z" fill="currentColor" />
                                <path d="M255.246 608.8L608.799 255.246L610.214 256.661L256.66 610.214L255.246 608.8Z" fill="currentColor" />
                                <path d="M259.489 613.042L613.042 259.489L614.456 260.903L260.903 614.457L259.489 613.042Z" fill="currentColor" />
                                <path d="M263.731 617.285L617.285 263.732L618.699 265.146L265.145 618.699L263.731 617.285Z" fill="currentColor" />
                                <path d="M267.974 621.528L621.527 267.974L622.941 269.388L269.388 622.942L267.974 621.528Z" fill="currentColor" />
                                <path d="M272.216 625.77L625.77 272.217L627.184 273.631L273.631 627.184L272.216 625.77Z" fill="currentColor" />
                                <path d="M276.459 630.013L630.013 276.46L631.427 277.874L277.873 631.427L276.459 630.013Z" fill="currentColor" />
                                <path d="M280.702 634.256L634.255 280.702L635.669 282.116L282.116 635.67L280.702 634.256Z" fill="currentColor" />
                                <path d="M284.944 638.498L638.498 284.945L639.912 286.359L286.359 639.912L284.944 638.498Z" fill="currentColor" />
                                <path d="M289.187 642.741L642.74 289.187L644.155 290.602L290.601 644.155L289.187 642.741Z" fill="currentColor" />
                                <path d="M293.43 646.983L646.983 293.43L648.397 294.844L294.844 648.398L293.43 646.983Z" fill="currentColor" />
                                <path d="M297.672 651.226L651.226 297.673L652.64 299.087L299.087 652.64L297.672 651.226Z" fill="currentColor" />
                                <path d="M301.915 655.469L655.468 301.915L656.883 303.33L303.329 656.883L301.915 655.469Z" fill="currentColor" />
                                <path d="M306.158 659.711L659.711 306.158L661.125 307.572L307.572 661.126L306.158 659.711Z" fill="currentColor" />
                                <path d="M310.4 663.954L663.954 310.401L665.368 311.815L311.814 665.368L310.4 663.954Z" fill="currentColor" />
                                <path d="M314.643 668.197L668.196 314.643L669.61 316.057L316.057 669.611L314.643 668.197Z" fill="currentColor" />
                            </svg>
                        </div>
                    </li>
                )}
            </ul>
        </>
    );
}

export default DashedBarChart;